<!-- modal-content.component.html -->
<div class="my-custom-modal">
    <div class="header-modal">
        <p>{{ dataModal?.filial ? 'Adicionar Filial' : 'Fornecedores' }}</p>
    </div>

    <div class="body-modal">
        <form [formGroup]="form" class="body-modal">
            <div class="form-group" tabindex="-1">
                <input style="width:350px;" type="text" formControlName="documentProvider"
                    mask="000.000.000-00||00.000.000/0000-00" class="form-input" (change)="onChangeInput()" />
                <span class="alert" *ngIf="
                    form.get('documentProvider')?.errors &&
                    (form.get('documentProvider')?.touched ||
                    form.get('documentProvider')?.dirty)
                ">Campo obrigatório</span>
                <label [ngClass]="{ focus : form.get('documentProvider')?.value }" class="form-label">
                    {{ dataModal?.filial ? 'CNPJ Filial' : 'Documento do fornecedor'}}
                </label>
            </div>


            <div class="form-group" id="input-provider" tabindex="-1">
                <input style="width:350px;" type="text" class="input-search form-input" id="company" name="company"
                    formControlName="company" style="display: none;" />
                <input style="width:350px;" type="text" class="input-search form-input" id="provider" name="provider"
                    formControlName="provider" (click)="onClickOpenOptions()" (change)="getProviders()" />
                <label class="form-label" for="provider" [ngClass]="{ focus: form.get('provider')?.value  }">
                    Fornecedor
                </label>
                <span class="alert" *ngIf="
                    form.get('provider')?.errors &&
                    (form.get('provider')?.touched ||
                    form.get('provider')?.dirty)
                ">Campo obrigatório</span>

                <div *ngIf="openOptions && companyList.length > 0" class=" container-options"
                    style="margin-top:-8px;height:150px;z-index: 9;">
                    <div class="option" *ngFor="let company of companyList" (click)="getValue(company)">
                        <p>{{company?.corporateName}}</p>
                    </div>
                </div>
            </div>


        </form>
    </div>

    <div class="buttons">
        <button class="button-export" id="first-button-export" (click)="confirmModal()">
            <h1 style="font-weight: 700;font-family: 'Roboto';font-style: normal;">Confirmar</h1>
        </button>

        <button class="button-clean" (click)="closeModal()">
            <h1>Cancelar</h1>
        </button>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>