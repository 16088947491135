import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RankingService {
  private token: any;
  private item: any;
  private httpOtions: any;
  storage: Storage;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};

    this.httpOtions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
  }

  public listRankingFlv(options: {
    startDate: string;
    endDate: string;
    provider?: string;
    product?: string;
    storeCD?: string;
    productType?: string | null;
    page: number;
    size: number;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.endDate)
      .set('size', options.size)
      .set('page', options.page)
      .set('category', options.productType ? options.productType : 'FLV');

    if(options.provider) {
       params = params.set('providerDocument', options.provider);
    }
  
    if(options.product) {
      params = params.set('productHash', options.product);
    }
  
    if(options.storeCD) {
      params = params.set('customerDocument', options.storeCD);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(
        `${environment.api}/movement-service/ranking-view`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public exportRankingFlv(options: {
    startDate: string;
    endDate: string;
    provider?: string;
    product?: string;
    storeCD?: string;
    productType?: string | null;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.endDate)
      .set('category', options.productType ? options.productType : 'FLV');

    if(options.provider) {
       params = params.set('providerDocument', options.provider);
    }
  
    if(options.product) {
      params = params.set('productHash', options.product);
    }
  
    if(options.storeCD) {
      params = params.set('customerDocument', options.storeCD);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    let httpRequest : any = http;
    httpRequest.responseType = 'text';

    return this.http
      .get(
        `${environment.api}/movementXLSX-service/ranking-view`,
        httpRequest
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public listRankingBovinos(options: {
    startDate: string;
    endDate: string;
    provider?: string;
    product?: string;
    storeCD?: string;
    productType: string | null;
    page: number;
    size: number;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.endDate)
      .set('size', options.size)
      .set('page', options.page)
      .set('category', options.productType ? options.productType : 'FLV');

    if(options.provider) {
       params = params.set('providerDocument', options.provider);
    }
  
    if(options.product) {
      params = params.set('productHash', options.product);
    }
  
    if(options.storeCD) {
      params = params.set('customerDocument', options.storeCD);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(
        `${environment.api}/movement-service/ranking-view`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public exportRankingBovinos(options: {
    startDate: string;
    endDate: string;
    provider?: string;
    product?: string;
    storeCD?: string;
    productType: string | null;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.endDate)
      .set('category', options.productType ? options.productType : 'FLV');

    if(options.provider) {
       params = params.set('providerDocument', options.provider);
    }
  
    if(options.product) {
      params = params.set('productHash', options.product);
    }
  
    if(options.storeCD) {
      params = params.set('customerDocument', options.storeCD);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    let httpRequest : any = http;
    httpRequest.responseType = 'text';

    return this.http
      .get(
        `${environment.api}/movement-service/ranking-view`,
        httpRequest
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }
}
