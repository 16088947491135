import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-laudo',
  templateUrl: './upload-laudo.component.html',
  styleUrls: ['./upload-laudo.component.scss']
})
export class UploadLaudoComponent implements OnInit {

  formFile: FormGroup = this.fb.group({
    uploadFile: ['', Validators.required],
  });

  uploadFiles: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadLaudoComponent>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.formFile.valid) {
      this.dialogRef.close({confirm: true, file: this.uploadFiles });
      return;
    }
  }

  closeModal() {
    this.dialogRef.close({confirm: false});
  }

  deleteFile(index: number) {
    this.uploadFiles.splice(index, 1);
  }

  changeFile(event: any) {
    if (event.target.files.length > 0) {
      this.uploadFiles = [];
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.uploadFiles.push(file);
      }
    }
  }
}
