<div class="flex-area">
  <input type="text" placeholder="Texto da opção" [(ngModel)]="option.optionText">
  <button type="button" class="button" (click)="addConditional()">Adicionar Condicional</button>
  <button type="button" class="button" (click)="remove.emit()">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.91671 2.7487H7.08337C7.08337 2.46138 6.96924 2.18583 6.76607 1.98267C6.56291 1.7795 6.28736 1.66536 6.00004 1.66536C5.71272 1.66536 5.43717 1.7795 5.23401 1.98267C5.03084 2.18583 4.91671 2.46138 4.91671 2.7487V2.7487ZM3.83337 2.7487C3.83337 2.17406 4.06165 1.62296 4.46798 1.21663C4.8743 0.810304 5.4254 0.582031 6.00004 0.582031C6.57468 0.582031 7.12578 0.810304 7.5321 1.21663C7.93843 1.62296 8.16671 2.17406 8.16671 2.7487H10.875C11.0187 2.7487 11.1565 2.80577 11.2581 2.90735C11.3596 3.00893 11.4167 3.14671 11.4167 3.29036C11.4167 3.43402 11.3596 3.5718 11.2581 3.67338C11.1565 3.77496 11.0187 3.83203 10.875 3.83203H10.3973L9.91737 9.43286C9.87124 9.9737 9.62378 10.4775 9.22396 10.8446C8.82414 11.2118 8.30109 11.4154 7.75829 11.4154H4.24179C3.69899 11.4154 3.17594 11.2118 2.77612 10.8446C2.3763 10.4775 2.12885 9.9737 2.08271 9.43286L1.60279 3.83203H1.12504C0.981382 3.83203 0.843607 3.77496 0.742024 3.67338C0.640442 3.5718 0.583374 3.43402 0.583374 3.29036C0.583374 3.14671 0.640442 3.00893 0.742024 2.90735C0.843607 2.80577 0.981382 2.7487 1.12504 2.7487H3.83337ZM7.62504 5.9987C7.62504 5.85504 7.56797 5.71726 7.46639 5.61568C7.36481 5.5141 7.22703 5.45703 7.08337 5.45703C6.93971 5.45703 6.80194 5.5141 6.70036 5.61568C6.59878 5.71726 6.54171 5.85504 6.54171 5.9987V8.16536C6.54171 8.30902 6.59878 8.4468 6.70036 8.54838C6.80194 8.64996 6.93971 8.70703 7.08337 8.70703C7.22703 8.70703 7.36481 8.64996 7.46639 8.54838C7.56797 8.4468 7.62504 8.30902 7.62504 8.16536V5.9987ZM4.91671 5.45703C4.77305 5.45703 4.63527 5.5141 4.53369 5.61568C4.43211 5.71726 4.37504 5.85504 4.37504 5.9987V8.16536C4.37504 8.30902 4.43211 8.4468 4.53369 8.54838C4.63527 8.64996 4.77305 8.70703 4.91671 8.70703C5.06037 8.70703 5.19814 8.64996 5.29972 8.54838C5.40131 8.4468 5.45837 8.30902 5.45837 8.16536V5.9987C5.45837 5.85504 5.40131 5.71726 5.29972 5.61568C5.19814 5.5141 5.06037 5.45703 4.91671 5.45703Z" fill="white"/>
    </svg>
  </button>
</div>

<div class="conditional-container" *ngIf="showConditional">
  <div class="flex-area">
    <input type="text" placeholder="Texto da pergunta condicional" [(ngModel)]="option.conditionalQuestionText">
    <select [(ngModel)]="option.conditionalQuestionType">
      <option value="TEXT">Texto</option>
      <option value="MULTIPLE_CHOICE">Múltipla Escolha</option>
    </select>
  </div>

  <div class="conditional-options-container" *ngIf="option.conditionalQuestionType === 'MULTIPLE_CHOICE'">
    <app-option
      *ngFor="let condOption of option.conditionalOptions; let i = index"
      [option]="condOption"
      (remove)="removeConditionalOption(i)"
    ></app-option>
    <button type="button" class="button" (click)="addConditionalOption()">Adicionar Opção Condicional</button>
  </div>
</div>
