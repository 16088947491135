import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeomonitoringComponent } from './geomonitoring.component';
import { SeeDatasGeomonitoringComponent } from 'src/app/components/see-datas-geomonitoring/see-datas-geomonitoring.component';
const routes: Routes = [{
  path: '',
  component: GeomonitoringComponent,

  children: [
    { path: 'bovinos', component: SeeDatasGeomonitoringComponent },
    { path: 'suinos', component: SeeDatasGeomonitoringComponent },
    { path: 'flv', component: SeeDatasGeomonitoringComponent },
    { path: 'aves', component: SeeDatasGeomonitoringComponent },
    { path: 'pescado', component: SeeDatasGeomonitoringComponent },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeomonitoringRoutingModule { }
