<ul class="accordion">
    <li class="question">
        <div class="title-accordion" (click)="toggleOpen()">
            <div class="text">
                <p>
                    {{ questionCreated ? (form.get('ordemPergunta')?.value + ' - ' + form.get('pergunta')?.value) :
                    ('Pergunta
                    ' + (nQuestion + 1)) }}
                </p>
            </div>

            <button style='margin-right: 40px;color:#99CA3C' type="button"
                [ngClass]="isOpen  ? 'button-arrow open' : 'button-arrow'">
                <svg width="18" height="18" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.2287 0.589844L6.63867 5.16984L2.04867 0.589844L0.638672 1.99984L6.63867 7.99984L12.6387 1.99984L11.2287 0.589844Z"
                        fill="black" fill-opacity="0.38" />
                </svg>
            </button>
        </div>

        <div class="text-open" style="padding-left: 1.25vw" *ngIf="isOpen">
            <div class="form-question" style="margin-top: -50px;">

                <form [formGroup]="form">
                    <div class="datas-supplier">
                        <h4>Dados da pergunta</h4>
                        <hr />
                        <div class="input-form">
                            <div class="form-group" tabindex="-1">
                                <input type="text" class="form-input" id="pergunta" name="pergunta"
                                    formControlName="pergunta" />
                                <label [ngClass]="{ focus: form.get('pergunta')?.value}" for="pergunta"
                                    class="form-label">Pergunta</label>
                            </div>

                            <div class="form-group" tabindex="-1">
                                <input type="number" class="form-input" id="ordem" name="ordem"
                                    formControlName="ordemPergunta" mask="0000000000" />
                                <label [ngClass]="{ focus: form.get('ordemPergunta')?.value}" for="ordem"
                                    class="form-label">Ordem</label>
                            </div>

                            <button type="button" (click)="openModal()" class="actions-button button-edit"
                                style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.2904 7.4137L12.5644 3.73151L13.7918 2.50411C14.1279 2.16804 14.5408 2 15.0306 2C15.5204 2 15.933 2.16804 16.2685 2.50411L17.4959 3.73151C17.832 4.06758 18.0073 4.47321 18.0219 4.94838C18.0365 5.42356 17.8758 5.82889 17.5397 6.16438L16.2904 7.4137ZM15.0192 8.70685L5.72603 18H2V14.274L11.2932 4.98082L15.0192 8.70685Z"
                                        fill="white" />
                                </svg>
                                <span>Criar pergunta</span>
                            </button>
                        </div>
                        <div class="input-form">
                            <div class="form-group" tabindex="-1">
                                <input type="text" class="form-input" id="descricao-pergunta" name="descricao-pergunta"
                                    formControlName="descricao" />
                                <label [ngClass]="{ focus: form.get('descricao')?.value}" class="form-label"
                                    for="descricao-pergunta">Descrição da pergunta</label>
                            </div>
                        </div>
                        <div class="input-form">
                            <div class="form-group" style="width:22.9%" tabindex="-1">
                                <input type="number" class="form-input" id="peso-pergunta" name="peso-pergunta"
                                    formControlName="pesoPergunta" mask="0000000000" />
                                <label class="form-label" [ngClass]="{ focus: form.get('pesoPergunta')?.value}"
                                    for="peso-pergunta">Peso da pergunta (numérico)</label>
                            </div>
                            <div class="form-group" tabindex="-1" style="width:22.9%">
                                <input type="number" class="form-input" id="impacto-financeiro"
                                    name="impacto-financeiro" mask="0000000000" formControlName="impactoFinanceiro" />
                                <label class="form-label" for="impacto-financeiro"
                                    [ngClass]="{ focus: form.get('impactoFinanceiro')?.value}">Impacto financeiro
                                    (unidades)</label>
                            </div>
                            <div class="check">
                                <input type="checkbox" id="obrigatorio" name="obrigatorio" formControlName="required">
                                <label for="obrigatorio" style="margin-left:10px">Obrigatório</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="formAlternativa">
                        <div class="info-analyses"
                            *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                            <h4>Não conformidade</h4>
                            <hr />
                            <div style="display: flex;">
                                <div class="div-op">
                                    <span>Respostas que apresentam não Conformidade:</span>
                                    <div style="display: flex;">
                                        <div>
                                            <div class="option" *ngFor="let key of formKeys">
                                                <input type="radio" [value]="formAlternativa[key]" [id]="key + '-nc'"
                                                    name="naoConformidade"
                                                    [checked]="form.get('naoConformidade')?.value === formAlternativa[key] || form.get('naoConformidade')?.value === key"
                                                    (change)="onCheckboxChange($event, key, 'naoConformidade')">
                                                <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>
                                            </div>
                                        </div>
                                        <button type="button" (click)="clearField('naoConformidade')"
                                            class="actions-button button-edit"
                                            style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px;margin-top: 10px;">
                                            <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path _ngcontent-iax-c211=""
                                                    d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                                    fill="white"></path>
                                            </svg>
                                            <span>Excluir Resposta</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="div-select" *ngIf="form.get('naoConformidade')?.value">
                                    <div class="select"
                                        *ngIf="!form.get('permissaoClassificacaoNaoConformidade')?.value">
                                        <select class="input-search form-input"
                                            formControlName="gravidadeNaoConformidade">
                                            <option value="leve" selected>Leve</option>
                                            <option value="media">Média</option>
                                            <option value="grave">Grave</option>
                                            <option value="gravissima">Gravissima</option>
                                        </select>
                                        <label class="form-label"
                                            [ngClass]="{ focus: form.get('gravidadeNaoConformidade')?.value}">Classificação
                                            de NC</label>
                                    </div>
                                    <div class="check-permission">
                                        <input type="checkbox" id="permitir-classificacao" name="permitir-classificacao"
                                            formControlName="permissaoClassificacaoNaoConformidade">
                                        <label for="permitir-classificacao"
                                            [ngClass]="{ focus: form.get('permissaoClassificacaoNaoConformidade')?.value}">Permitir
                                            que o usuário classifique a não
                                            conformidade</label>
                                    </div>
                                </div>

                                <div class="div-select" style="margin-left: 20px;"
                                    *ngIf="form.get('permissaoClassificacaoNaoConformidade')?.value && form.get('naoConformidade')?.value">
                                    <span>Ocultar as seguintes classificações:</span>
                                    <div class="check-permission" *ngFor="let gravidade of gravidades">
                                        <input type="checkbox" [value]="gravidade.name" id="{{gravidade.id}}"
                                            name="permitir-classificacao"
                                            [checked]="isGravidadeSelected(gravidade.label)"
                                            (change)="onCheckboxChangeGravidade($event, gravidade.name)">
                                        <label [for]="gravidade.id">{{gravidade.label}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info-analyses" *ngIf="formAlternativa.type != 'text'">
                            <h4>Alerta / Bloqueio</h4>
                            <hr />
                            <div style="display:flex;width:1000px">
                                <div class="column-1"
                                    *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                    <span>Alerta:</span>
                                    <div style="display: flex;">
                                        <div>
                                            <div class="option" *ngFor="let key of formKeys">
                                                <input type="radio" [id]="key + '-nc'" name="alerta"
                                                    (change)="onCheckboxChange($event, key, 'alerta')"
                                                    [checked]="form.get('alerta')?.value === formAlternativa[key] || form.get('alerta')?.value === key">
                                                <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>
                                            </div>
                                        </div>

                                        <button type="button" *ngIf="formAlternativa.type == 'multiple'"
                                            (click)="clearField('alerta')" class="actions-button button-edit"
                                            style="margin-top:10px; background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px ;">
                                            <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path _ngcontent-iax-c211=""
                                                    d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                                    fill="white"></path>
                                            </svg>
                                            <span>Excluir Resposta</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="column-2">
                                    <div class="form-group" tabindex="-1" *ngIf="formAlternativa.type == 'number'">
                                        <input type="text" class="form-input" id="limite-maximo-alerta"
                                            name="limite-maximo-alerta" formControlName="limiteMaximoAlerta" />
                                        <label [ngClass]="{ focus: form.get('limiteMaximoAlerta')?.value}"
                                            class="form-label" for="limite-maximo-alerta">Limite máximo Alerta</label>
                                    </div>
                                    <div class="form-group" tabindex="-1">
                                        <input type="text" class="form-input" id="mensagem-modal-alerta"
                                            name="mensagem-modal-alerta" formControlName="mensagemModalAlerta" />
                                        <label class="form-label"
                                            [ngClass]="{ focus: form.get('mensagemModalAlerta')?.value}"
                                            for="mensagem-modal-alerta">Mensagem modal de
                                            Alerta</label>
                                    </div>
                                    <div class="option">
                                        <input type="checkbox" id="gerar-formula" name="gerar-formula"
                                            formControlName="gerarFormulaMaximo">
                                        <label for="gerar-formula">Gerar fórmula</label>
                                    </div>

                                </div>
                                <div class="column-3"
                                    *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                    <span>Bloqueia continuação:</span>
                                    <div style="display: flex;">
                                        <div>
                                            <div class="option" *ngFor="let key of formKeys">
                                                <input type="radio" [id]="key + '-nc'" name="bloqueio"
                                                    (change)="onCheckboxChange($event, key, 'bloqueioContinuacao')"
                                                    [checked]="form.get('bloqueioContinuacao')?.value === formAlternativa[key] || form.get('bloqueioContinuacao')?.value === key">
                                                <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>
                                            </div>
                                        </div>
                                        <button type="button" *ngIf="formAlternativa.type == 'multiple'"
                                            (click)="clearField('bloqueioContinuacao')"
                                            class="actions-button button-edit"
                                            style="margin-top:10px; background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px ;">
                                            <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path _ngcontent-iax-c211=""
                                                    d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                                    fill="white"></path>
                                            </svg>
                                            <span>Excluir Resposta</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="column-4">
                                    <div class="form-group" tabindex="-1" *ngIf="formAlternativa.type == 'number'">
                                        <input type="text" class="form-input" id="limite-maximo-bloqueio"
                                            name="limite-maximo-bloqueio" formControlName="limiteMaximoBloqueio" />
                                        <label class="form-label"
                                            [ngClass]="{ focus: form.get('limiteMaximoBloqueio')?.value}"
                                            for="limite-maximo-bloqueio">Limite máximo Bloqueio</label>
                                    </div>
                                    <div class="form-group" tabindex="-1">
                                        <input type="text" class="form-input" id="mensagem-modal-bloqueio"
                                            name="mensagem-modal-bloqueio" formControlName="mensagemModalBloqueio" />
                                        <label class="form-label"
                                            [ngClass]="{ focus: form.get('mensagemModalBloqueio')?.value}"
                                            for="mensagem-modal-bloqueio">Mensagem modal de
                                            Bloqueio</label>
                                    </div>
                                    <div class="option">
                                        <input type="checkbox" id="gerar-formula" name="gerar-formula"
                                            formControlName="gerarFormulaBloqueio">
                                        <label for="gerar-formula">Gerar fórmula</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info-analyses">
                            <h4>Fotos</h4>
                            <hr />
                            <div>
                                <div style="display: flex;">
                                    <div>
                                        <span>Fotos:</span>
                                        <div *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                            <div class="option" *ngFor="let key of formKeys">
                                                <input type="radio" [id]="key + '-nc'" name="fotos"
                                                    (change)="onCheckboxChange($event, key, 'fotos')"
                                                    [checked]="form.get('fotos')?.value === formAlternativa[key] || form.get('fotos')?.value === key">
                                                <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin-left: 20px;" *ngIf="form.get('fotos')?.value">
                                        <span>Fotos Obrigatoria:</span>
                                        <div *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                            <div class="option">
                                                <input type="checkbox" formControlName="fotosObrigatorio">
                                                <label>Obrigatório</label>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" *ngIf="formAlternativa.type == 'multiple'"
                                        (click)="clearField('fotos')" class="actions-button button-edit"
                                        style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px ;">
                                        <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path _ngcontent-iax-c211=""
                                                d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                                fill="white"></path>
                                        </svg>
                                        <span>Excluir Resposta</span>
                                    </button>
                                </div>
                                <div class="option"
                                    *ngIf="formAlternativa.type == 'number' || formAlternativa.type == 'text'">
                                    <input type="checkbox" formControlName="fotosObrigatorio">
                                    <label>Solicitar foto</label>
                                </div>
                            </div>
                        </div>

                        <div class="info-analyses">
                            <h4>Anexos</h4>
                            <hr />
                            <div>
                                <div style="display: flex;">
                                    <div>
                                        <span>Anexos:</span>
                                        <div class="option" *ngFor="let key of formKeys">
                                            <div
                                                *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                                <input type="radio" [id]="key + '-nc'" name="anexos"
                                                    (change)="onCheckboxChange($event, key, 'anexos')"
                                                    [checked]="form.get('anexos')?.value === formAlternativa[key] || form.get('anexos')?.value === key">
                                                <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-left: 20px;" *ngIf="form.get('anexos')?.value">
                                        <span>Anexo obrigatorio:</span>
                                        <div class="option">
                                            <div
                                                *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                                <input type="checkbox" formControlName="anexosObrigatorio">
                                                <label>Obrigatório</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" *ngIf="formAlternativa.type == 'multiple'"
                                        (click)="clearField('anexos')" class="actions-button button-edit"
                                        style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px ;">
                                        <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path _ngcontent-iax-c211=""
                                                d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                                fill="white"></path>
                                        </svg>
                                        <span>Excluir Resposta</span>
                                    </button>
                                </div>
                                <div *ngIf="formAlternativa.type == 'number' || formAlternativa.type == 'text'">
                                    <input type="checkbox" formControlName="anexosObrigatorio">
                                    <label style="margin-top: 10px;margin-left: 8px;">Solicitar anexo</label>
                                </div>
                            </div>
                        </div>

                        <div class="info-analyses"
                            *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                            <h4>Subalternativas</h4>
                            <hr />
                            <div style="display: flex;">
                                <div>
                                    <div class="option" *ngFor="let key of formKeys;let last = last">
                                        <div *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                                            <input type="radio" id="key" #radioInputSubOption name="first-op"
                                                (click)="setOptionSelected(formAlternativa[key])"
                                                [checked]="mainOptionForSuboptions == key">
                                            <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>

                                            <button type="button"
                                                (click)="openModalSubOptions(-1,mainOptionForSuboptions)"
                                                class="actions-button button-edit"
                                                *ngIf="last &&  mainOptionForSuboptions"
                                                style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.2904 7.4137L12.5644 3.73151L13.7918 2.50411C14.1279 2.16804 14.5408 2 15.0306 2C15.5204 2 15.933 2.16804 16.2685 2.50411L17.4959 3.73151C17.832 4.06758 18.0073 4.47321 18.0219 4.94838C18.0365 5.42356 17.8758 5.82889 17.5397 6.16438L16.2904 7.4137ZM15.0192 8.70685L5.72603 18H2V14.274L11.2932 4.98082L15.0192 8.70685Z"
                                                        fill="white" />
                                                </svg>
                                                <span>Criar pergunta</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="clearSubOptions()" class="actions-button button-edit"
                                    style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px ;margin-top: 10px;">
                                    <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path _ngcontent-iax-c211=""
                                            d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                            fill="white"></path>
                                    </svg>
                                    <span>Excluir Resposta</span>
                                </button>

                            </div>
                        </div>

                        <div class="info-analyses" *ngIf="subOptions">
                            <div style="margin-top: 30px;" *ngFor="let option of subOptions; let i = index">
                                <span *ngIf="checkIfSingleItemAndEquals(i)">{{getQuestion(option)}}</span>
                                <div *ngIf="option?.subOptions">
                                    <div *ngFor="let item of filterItemsStartingWithItem(i);let last = last"
                                        class="option">
                                        <div *ngIf="item != 'itemNumberText'">
                                            <input type="radio" name="suboption{{i}}"
                                                (click)="setSuboptionSelected(item, option.index)"
                                                [checked]="getOptionRoot(option.index+1) == item">
                                            <label>{{item}}</label>
                                        </div>

                                        <div *ngIf="item == 'itemNumberText'">
                                            <input type="text" class="form-input" id="mensagem-modal-alerta"
                                                name="mensagem-modal-alerta" readonly />
                                            <label class="form-label" [ngClass]="{ focus: false }" for="mensagem">
                                                {{ getQuestion(option) }}
                                            </label>
                                        </div>

                                        <button type="button" (click)="openModalSubOptions(i,getSuboptionSelected())"
                                            *ngIf="last && item != 'itemNumberText'  &&  selectedIndexSuboptionsArray.includes(option.index)"
                                            class="actions-button button-edit"
                                            style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.2904 7.4137L12.5644 3.73151L13.7918 2.50411C14.1279 2.16804 14.5408 2 15.0306 2C15.5204 2 15.933 2.16804 16.2685 2.50411L17.4959 3.73151C17.832 4.06758 18.0073 4.47321 18.0219 4.94838C18.0365 5.42356 17.8758 5.82889 17.5397 6.16438L16.2904 7.4137ZM15.0192 8.70685L5.72603 18H2V14.274L11.2932 4.98082L15.0192 8.70685Z"
                                                    fill="white" />
                                            </svg>
                                            <span>Criar pergunta</span>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="info-analyses"
                            *ngIf="formAlternativa.type != 'number' && formAlternativa.type != 'text'">
                            <h4>Caixas Texto</h4>
                            <hr />
                            <div style="display:flex">
                                <div>
                                    <span>Caixa texto:</span>
                                    <div class="option" *ngFor="let key of formKeys">
                                        <input type="radio" [id]="key + '-nc'" name="caixaTexto"
                                            (change)="onCheckboxChange($event, key, 'caixaTextos')"
                                            [checked]="form.get('caixaTextos')?.value === formAlternativa[key] || form.get('caixaTextos')?.value === key">
                                        <label [for]="key + '-nc'">{{ formAlternativa[key] }}</label>
                                    </div>
                                </div>
                                <div style="margin-left: 20px;" *ngIf="form.get('caixaTextos')?.value">
                                    <span>Caixa de texto obrigatorio:</span>
                                    <div class="option">
                                        <input type="checkbox" formControlName="caixaTextosObrigatorio">
                                        <label>Obrigatorio</label>
                                    </div>
                                </div>
                                <button type="button" (click)="clearField('caixaTextos')"
                                    class="actions-button button-edit"
                                    style="background: linear-gradient(49.93deg, #04244D 1.27%, #297CA8 99.91%);margin-left:25px ;">
                                    <svg _ngcontent-iax-c211="" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path _ngcontent-iax-c211=""
                                            d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                            fill="white"></path>
                                    </svg>
                                    <span>Excluir Resposta</span>
                                </button>
                            </div>
                            <div class="input-form">
                                <div class="form-group" tabindex="-1">
                                    <input type="text" class="form-input" id="titulo-caixa-texto"
                                        name="titulo-caixa-texto" formControlName="tituloCaixaTextos" />
                                    <label class="form-label" [ngClass]="{ focus: form.get('tituloCaixaTextos')?.value}"
                                        for="titulo-caixa-texto">Título da Caixa de Texto</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div style="float:right;display: flex;">
                    <button class="button delete" style="margin-right: 10px;" (click)="deleteQuestion()">
                        <svg _ngcontent-iax-c211="" style="background-color: #cccccc;" width="18" height="18"
                            viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path _ngcontent-iax-c211=""
                                d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z"
                                fill="white"></path>
                        </svg>
                        <span>Excluir pergunta</span>
                    </button>

                    <button type="submit" style="width:129px;height:36px;" class="button save" (click)="createJSON()">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.45833 15.125H13.5417C13.9616 15.125 14.3643 14.9582 14.6613 14.6613C14.9582 14.3643 15.125 13.9616 15.125 13.5417V4.83333L11.1667 0.875H2.45833C2.03841 0.875 1.63568 1.04181 1.33875 1.33875C1.04181 1.63568 0.875 2.03841 0.875 2.45833V13.5417C0.875 13.9616 1.04181 14.3643 1.33875 14.6613C1.63568 14.9582 2.03841 15.125 2.45833 15.125ZM4.04167 2.45833H7.20833V4.04167H8.79167V2.45833H10.375V5.625H4.04167V2.45833ZM4.04167 8.79167H11.9583V13.5417H4.04167V8.79167Z"
                                fill="white" />
                        </svg>
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </li>
</ul>