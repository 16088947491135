import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  private token: any;
  private item: any;

  constructor(private http: HttpClient) {
    this.item = sessionStorage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};
  }

  listAllForms() {
    let http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    return this.http.get(`${environment.api}/api/forms`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  getFormById(hash: string) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Access-Control-Allow-Origin': '*'
      }),
    };

    const mock = '66bb3fb126f78b6bc24aa35c';

    return this.http.get(`${environment.api}/api/forms/${hash}`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  registerForm(payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.post(
      `${environment.api}/api/forms`,
      payload,
      http
    );
  }

  submitResponse(hash: string, payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.post(
      `${environment.api}/api/form-responses?formId=${hash}`,
      payload,
      http
    );
  }
}
