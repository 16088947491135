import { ArquivosService } from './../../../../../services/arquivos.service';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-resultado-analise',
  templateUrl: './resultado-analise.component.html',
  styleUrls: ['./resultado-analise.component.scss']
})
export class ResultadoAnaliseComponent implements OnInit {

  @Input() title: string = '';
  @Input() legend: string = '';
  @Input() red: boolean = false;
  @Input() imageUrl: string = '';
  @Input() requestDate: string = '';

  arquivoBlob!: Blob;
  objectURL: any;
  blobLoad: boolean = false;

  constructor(
    private arquivosService: ArquivosService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    if (this.imageUrl) {
      this.arquivosService.getFile(this.imageUrl).subscribe(
        res => {
          this.arquivoBlob = res;
          this.blobLoad = true;
        }
      )
    }
  }

  convertBlobToUrl(): SafeUrl {
    this.objectURL = URL.createObjectURL(this.arquivoBlob);
    return this.sanitizer.bypassSecurityTrustUrl(this.objectURL);
  }
}
