<div class="content" [ngClass]="{ divMargin: isUserProvider }">
  <div class="row-buttons">
    <button class="button-export" (click)="exportToExcel(tableData)">
      <img src="../../../assets/images/export-icon.svg" alt="Icone de exportação">
      <p>Exportar</p>
    </button>
    <button class="button-clean" (click)="clearFilters()">
      <img src="../../../assets/images/clean-icon.svg" alt="Icone de limpar filtros">
      <span>Limpar filtros</span>
    </button>
  </div>

  <form [formGroup]="form">
    <div class="form-group">
      <input
        type="date"
        class="form-input"
        id="start"
        name="start"
        formControlName="start"
        (change)="checkStartCompletionDate()"
        [max]="maxCompletionDate | date:'yyyy-MM-dd'"
      />
      <label class="form-label" for="start" [ngClass]="{ focus: form.get('start')?.value }">
        Periodo de tempo: Início
      </label>

      <span class="alert" *ngIf="form.get('start')?.getError('required') && (form.get('start')?.touched || form.get('start')?.dirty)">
        Campo Obrigatório
      </span>
      <span class="alert" *ngIf="form.get('start')?.getError('invalidDate') && (form.get('start')?.touched || form.get('start')?.dirty)">
        Data Inválida
      </span>
    </div>

    <div class="form-group">
      <input
        type="date"
        class="form-input"
        id="finish"
        name="finish"
        formControlName="finish"
        [min]="minCompletionDate | date:'yyyy-MM-dd'"
        [max]="maxCompletionDate | date:'yyyy-MM-dd'"
      />
      <label class="form-label" for="finish" [ngClass]="{ focus: form.get('finish')?.value }">
        Periodo de tempo: Final
      </label>

      <span class="alert" *ngIf="form.get('finish')?.getError('required') && (form.get('finish')?.touched || form.get('finish')?.dirty)">
        Campo Obrigatório
      </span>
      <span class="alert" *ngIf="form.get('finish')?.getError('invalidDate') && (form.get('finish')?.touched || form.get('finish')?.dirty)">
        Data Inválida
      </span>
    </div>

    <!-- <div
      tabindex="1"
      class="checkbox-dropdown"
      (click)="onClickCheck(checkboxActive, 'provider')"
      [ngClass]="{ 'is-active': checkboxActive.provider }"
      [ngStyle]="{ display: tableActive && !isUserProvider ? '' : 'none' }"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2424 7.63558C12.2424 6.4293 11.8137 5.39744 10.9563 4.54C10.0988 3.68256 9.06695 3.25385 7.86067 3.25385C6.65439 3.25385 5.62254 3.68256 4.7651 4.54C3.90766 5.39744 3.47894 6.4293 3.47894 7.63558C3.47894 8.84186 3.90766 9.87372 4.7651 10.7312C5.62254 11.5886 6.65439 12.0173 7.86067 12.0173C9.06695 12.0173 10.0988 11.5886 10.9563 10.7312C11.8137 9.87372 12.2424 8.84186 12.2424 7.63558ZM17.2501 15.7731C17.2501 16.1121 17.1262 16.4056 16.8784 16.6533C16.6307 16.9011 16.3372 17.025 15.9982 17.025C15.6461 17.025 15.3527 16.9011 15.1179 16.6533L11.7632 13.3084C10.596 14.1169 9.29517 14.5212 7.86067 14.5212C6.92825 14.5212 6.03658 14.3402 5.18567 13.9783C4.33475 13.6164 3.6012 13.1274 2.98502 12.5112C2.36884 11.8951 1.87981 11.1615 1.51792 10.3106C1.15604 9.45967 0.975098 8.568 0.975098 7.63558C0.975098 6.70316 1.15604 5.81149 1.51792 4.96057C1.87981 4.10965 2.36884 3.3761 2.98502 2.75992C3.6012 2.14374 4.33475 1.65471 5.18567 1.29283C6.03658 0.930942 6.92825 0.75 7.86067 0.75C8.7931 0.75 9.68477 0.930942 10.5357 1.29283C11.3866 1.65471 12.1201 2.14374 12.7363 2.75992C13.3525 3.3761 13.8415 4.10965 14.2034 4.96057C14.5653 5.81149 14.7463 6.70316 14.7463 7.63558C14.7463 9.07007 14.342 10.3709 13.5335 11.5381L16.8882 14.8928C17.1295 15.1341 17.2501 15.4275 17.2501 15.7731Z"
          fill="url(#paint0_linear_496_7506)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_496_7506"
            x1="1.15297"
            y1="16.7833"
            x2="18.4629"
            y2="2.2242"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#001D45" />
            <stop offset="1" stop-color="#2B81AD" />
          </linearGradient>
        </defs>
      </svg>

      <span>Fornecedor</span>

      <ul class="checkbox-dropdown-list">
        <li *ngFor="let provider of providerCompanyList; let i = index">
          <label class="filter-option">
            <input
              #value
              type="checkbox"
              (change)="onChanged($event, i, 'provider')"
              [checked]="provider.checked"
              [value]="provider.document"
              name="document"
            />
            {{ provider.value }}
          </label>
        </li>
        <li></li>
      </ul>
    </div> -->

    <app-provider-select-filter
      #inputProvider
      [isUserProvider]="isUserProvider"
      (handleChangeProviderValue)="changeProviderValue($event)"
    ></app-provider-select-filter>

    <button
      type="button"
      class="button"
      (click)="submitForm()"
      [disabled]="isUserProvider && buttonDisabled"
    >
      Pesquisar
    </button>
  </form>

  <div class="table-area">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="integration" *ngIf="isDivergence">
        <th mat-header-cell *matHeaderCellDef>Integração</th>
        <td mat-cell *matCellDef="let element">{{ element.integration }}</td>
      </ng-container>

      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef>Fornecedor</th>
        <td mat-cell *matCellDef="let element">
          {{ !isCarrefour ? element.provider : element.origem.corporateName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentProvider">
        <th mat-header-cell *matHeaderCellDef>Documento do Fornecedor</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? formatCpfCnpj(element.documentProvider)
              : formatCpfCnpj(element.origem.document)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Produto</th>
        <td mat-cell *matCellDef="let element">
          {{ !isCarrefour ? element.productName : element.product.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <th mat-header-cell *matHeaderCellDef>Nota Fiscal</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? removeSemicolonAtEnd(element.invoiceNumber)
              : removeSemicolonAtEnd(element.invoice.number)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="identifier" *ngIf="isReceipt || isDivergence">
        <th mat-header-cell *matHeaderCellDef>Código de Rastreio</th>
        <td mat-cell *matCellDef="let element">
          {{ !isCarrefour ? element.identifier : "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="movementDate" *ngIf="isDivergence">
        <th mat-header-cell *matHeaderCellDef>Data de Envio</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? (element.movementDate | date : "dd/MM/yyyy")
              : (element.movement.movementDate | date : "dd/MM/yyyy")
          }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="completionDate"
        *ngIf="isReceipt || isDivergence"
      >
        <th mat-header-cell *matHeaderCellDef>Data de Recebimento</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? (element.completionDate | date : "dd/MM/yyyy")
              : (element.movement.completionDate | date : "dd/MM/yyyy")
          }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="requestedQuantity"
        *ngIf="isReceipt || isDivergence"
      >
        <th mat-header-cell *matHeaderCellDef>Qtd Solicitada</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? element.requestedQuantity
              : element.invoiceItem.requestedQuantity
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount" *ngIf="isDate || isDivergence">
        <th mat-header-cell *matHeaderCellDef>Qtd Enviada</th>
        <td mat-cell *matCellDef="let element">
          {{ !isCarrefour ? element.amount : element.invoiceItem.amount }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="receivedQuantity"
        *ngIf="isReceipt || isDivergence"
      >
        <th mat-header-cell *matHeaderCellDef>Qtd Recebida</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? element.receivedQuantity
              : element.invoiceItem.receivedQuantity
          }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="returnedQuantity"
        *ngIf="isReceipt || isDivergence"
      >
        <th mat-header-cell *matHeaderCellDef>Qtd Devolvida</th>
        <td mat-cell *matCellDef="let element">
          {{
            !isCarrefour
              ? element.returnedQuantity
              : element.invoiceItem.returnedQuantity
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="error" *ngIf="isDate || isDivergence">
        <th mat-header-cell *matHeaderCellDef>Problema</th>
        <td mat-cell *matCellDef="let element">
          {{ !isCarrefour ? element.error : element.integrationMessage }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">Nenhum registro encontrado</td>
      </tr>
    </table>
  </div>
  <div class="paginacao" *ngIf="!isCarrefour">
    <mat-paginator
      id="paginator"
      *ngIf="length > 5"
      [pageSizeOptions]="[5, 15, 10, 25]"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      (page)="listData($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>

  <div class="paginacao" *ngIf="isCarrefour">
    <mat-paginator
      id="paginator"
      *ngIf="length > 5"
      [pageSizeOptions]="[5, 15, 10, 25]"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      (page)="listCarrefour($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>
