import { AuthService } from './services/auth.service';
import { AppService } from './services/app.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showLogin = true;
  loginByToken: string | null = "";

  constructor(
    private appService: AppService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loginByToken = sessionStorage.getItem('loginByToken');
    this.authService.showLoginEmmiter.subscribe((s) => {
      this.showLogin = s;
    });
  }
  getClasses() {
    const classes = {
      active: this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled,
    };
    return classes;
  }
  /*  toggleSidebar() {
    this.appService.toggleSidebar();
  } */
}
