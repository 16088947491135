<button class="button back" (click)="handleClickBack()">
  Voltar
</button>
<div class="container">
  <h1 class="form-title">{{ formJson?.title || '-'}}</h1>
  <form id="formulario-dinamico" (ngSubmit)="onSubmit($event)">
    <button type="submit" class="button submit-button">Enviar</button>
  </form>
</div>

<app-spinner *ngIf="loading" ></app-spinner>
