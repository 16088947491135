import { ModalFileComponent } from './../../../../components/modal-file/modal-file.component';
import { C, K } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { ArquivosService } from 'src/app/services/arquivos.service';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';

interface ObjetoLocalidade {
  id: number;
  nome: string;
  microrregiao: {
    id: number;
    nome: string;
    mesorregiao: {
      id: number;
      nome: string;
      UF: {
        id: number;
        sigla: string;
        nome: string;
        regiao: {
          id: number;
          sigla: string;
          nome: string;
        };
      };
    };
  };
  'regiao-imediata': {
    id: number;
    nome: string;
    'regiao-intermediaria': {
      id: number;
      nome: string;
      UF: {
        id: number;
        sigla: string;
        nome: string;
        regiao: {
          id: number;
          sigla: string;
          nome: string;
        };
      };
    };
  };
}
@Component({
  selector: 'app-modal-client-request',
  templateUrl: './modal-client-request.component.html',
  styleUrls: ['./modal-client-request.component.scss']
})

export class ModalClientRequestComponent implements OnInit {

  form!: FormGroup;
  loading: boolean = false;
  view: boolean = false;
  private storage!: Storage;
  isAdmin: boolean = false;
  validating: boolean = false;
  fileList: any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalClientRequestComponent>,
    private solicitacoesService: SolicitacoesService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private arquivosService: ArquivosService,
  ) {
    this.storage = window.sessionStorage;
    this.form = this.fb.group({
      requestNumber: [''],
      producerName: ['', Validators.required],
      ownerDocument: ['', Validators.required],
      propertyName: ['', Validators.required],
      uf: ['', Validators.required],
      town: ['', Validators.required],
      car: ['', Validators.required],
      ccir: [''],
      statusCar: [''],

      //documents
      ccirFilePath: [''],
      producerPropertyFilePath: [''],
      carFilePath: [''],
      producerDocumentPath: [''],
      kmlPath: [''],

      // options
      slavery: [true],
      ibamaEmbargo: [true],
      deter: [true],
      prodes: [true],
      indigenousLand: [true],
      conservationUnit: [true],
      archeologicalSites: [true],
      publicForests: [true],
      quilombolaAreas: [true]
    })
  }

  ngOnInit(): void {
    if (this.data && this.data.view) {
      this.form.get('requestNumber')?.setValue(this.data.element.requestNumber);
      this.form.get('producerName')?.setValue(this.data.element.producer.corporateName);
      this.form.get('ownerDocument')?.setValue(this.data.element.producer.document);
      this.form.get('propertyName')?.setValue(this.data.element.producer.place.nickname);
      this.form.get('uf')?.setValue(this.data.element.producer.place.address.stateIdentifier);
      this.form.get('town')?.setValue(this.data.element.producer.place.address.city);
      this.form.get('car')?.setValue(this.data.element.producer.place.code.replace('-', '').toUpperCase());
      this.form.get('ccir')?.setValue(this.data.element.producer.place.ccir);
      this.form.get('statusCar')?.setValue(true);
      this.form.get('slavery')?.setValue(this.data.element.request && this.data.element.request.slavery ? true : false);
      this.form.get('ibamaEmbargo')?.setValue(this.data.element.request && this.data.element.request.ibamaEmbargo ? true : false);
      this.form.get('deter')?.setValue(this.data.element.request && this.data.element.request.deter ? true : false);
      this.form.get('prodes')?.setValue(this.data.element.request && this.data.element.request.prodes ? true : false);
      this.form.get('indigenousLand')?.setValue(this.data.element.request && this.data.element.request.indigenousLand ? true : false);
      this.form.get('conservationUnit')?.setValue(this.data.element.request && this.data.element.request.conservationUnit ? true : false);
      this.form.get('archeologicalSites')?.setValue(this.data.element.request && this.data.element.request.archeologicalSites ? true : false);
      this.form.get('publicForests')?.setValue(this.data.element.request && this.data.element.request.publicForests ? true : false);
      this.form.get('quilombolaAreas')?.setValue(this.data.element.request && this.data.element.request.quilombolaAreas ? true : false);

      this.form.get('ccirFilePath')?.setValue(this.data.element.fileList.ccirFilePath);
      this.form.get('producerPropertyFilePath')?.setValue(this.data.element.fileList.producerPropertyFilePath);
      this.form.get('carFilePath')?.setValue(this.data.element.fileList.carFilePath);
      this.form.get('producerDocumentPath')?.setValue(this.data.element.fileList.producerDocumentPath);
      this.form.get('kmlPath')?.setValue(this.data.element.fileList.kmlPath);
      this.setFileList(this.data.element.fileList)
      this.form.disable();
      this.view = true;
    }
    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};
    if (user.authorities.includes('ROLE_GEOMONITORING_ADMIN')) {
      this.isAdmin = true;
    } else {
      this.form.get('ccirFilePath')?.setValidators(Validators.required);
      this.form.get('carFilePath')?.setValidators(Validators.required);
      this.form.get('producerDocumentPath')?.setValidators(Validators.required);
    }
    if (this.data && this.data.element && this.data.element.status === 'VALIDATING') {
      this.validating = true;
    }
  }

  getCity() {
    let car = this.form.get('car')?.value;
    if (car && car.length === 41) {
      let cityCode = car.slice(2, 9);
      this.solicitacoesService.getCityById(cityCode).subscribe(res => {
        const address: ObjetoLocalidade = res as ObjetoLocalidade;
        this.form.get('town')?.setValue(address.nome);
        this.form.get('uf')?.setValue(address.microrregiao.mesorregiao.UF.sigla);
      }, error => {
        this.openModalSuccessOrError(true, 'CAR inválido, não foi possível encontrar a cidade');
      });
    } else {
      this.openModalSuccessOrError(true, 'CAR inválido, verifique a quantidade de caracteres');
    }
  }

  protected closeModal(reload?: boolean) {
    this.dialogRef.close(reload);
  }

  protected sendRequest(): void {


    this.form.markAllAsTouched();
    if (this.form.valid) {
      const dialogref = this.dialog.open(ModalConfirmComponent, {
        data: {
          fornecedor: this.form.value,
        },
      });

      dialogref.afterClosed().subscribe(result => {
        if (result && result.confirm) {
          this.loading = true;
          let date = new Date();
          let month = ((date.getMonth() + 1)).toString();
          month = month.length < 2 ? "0" + month : month;
          let day = (date.getDate()).toString();
          day = day.length < 2 ? "0" + day : day;

          let requestDate = (date.getFullYear() + "-" + month + "-" + day);
          const item = this.storage.getItem('user');
          const user = item ? JSON.parse(item) : {};
          const whiteList = user.whiteList
          whiteList.push(user.customerId);
          const body = {
            requestNumber: this.form.get('requestNumber')?.value.toUpperCase(),
            requestDate: requestDate,
            request: {
              slavery: this.form.get('slavery')?.value ? true : false,
              ibamaEmbargo: this.form.get('ibamaEmbargo')?.value ? true : false,
              deter: this.form.get('deter')?.value ? true : false,
              prodes: this.form.get('prodes')?.value ? true : false,
              indigenousLand: this.form.get('indigenousLand')?.value ? true : false,
              conservationUnit: this.form.get('conservationUnit')?.value ? true : false,
              archeologicalSites: this.form.get('archeologicalSites')?.value ? true : false,
              publicForests: this.form.get('publicForests')?.value ? true : false,
              quilombolaAreas: this.form.get('quilombolaAreas')?.value ? true : false
            },
            producer: {
              document: this.form.get('ownerDocument')?.value,
              corporateName: this.form.get('producerName')?.value,
              place: {
                address: {
                  stateIdentifier: this.form.get('uf')?.value,
                  city: this.form.get('town')?.value,
                },
                ccir: this.form.get('ccir')?.value,
                code: this.formatCar(),
                codeType: "CAR",
                nickname: this.form.get('propertyName')?.value
              },
            },
            whiteList: whiteList,
            fileList: {
              'ccirFilePath': this.form.get('ccirFilePath')?.value,
              'producerPropertyFilePath': this.form.get('producerPropertyFilePath')?.value,
              'carFilePath': this.form.get('carFilePath')?.value,
              'producerDocumentPath': this.form.get('producerDocumentPath')?.value,
              'kmlPath': this.form.get('kmlPath')?.value,
              ...this.fileList
            }
          }

          this.solicitacoesService.registerRequest(body).subscribe({
            next: (res: any) => {
              this.loading = false;
              this.openModalSuccessOrError();
            },
            error: (err) => {
              this.openModalSuccessOrError(true);
            },
          });
        }
      });
    } else {
      this.openModalSuccessOrError(true, 'É necessário preencher todos os campos obrigatórios');
    }
  }

  openModalFile(type: string) {
    if (!this.view) {
      let title = '';
      switch (type) {
        case 'ccirFilePath':
          title = 'CCIR';
          break;
        case 'producerPropertyFilePath':
          title = 'Vinculo produtor x propriedade'
          break;
        case 'carFilePath':
          title = 'CAR'
          break;
        case 'producerDocumentPath':
          title = 'Documento proprietário'
          break;
        case 'kmlPath':
          title = 'Arquivo KML'
          break;
        default:
          title = 'Arquivo';
      }
      this.dialog.open(ModalFileComponent, {
        data: {
          title: `Inserir o ${title}`,
        },
      }).afterClosed().subscribe(result => {
        if (result && result.confirm) {
          this.loading = true;
          this.arquivosService.uploadFile(result.file).subscribe(res => {
            this.loading = false;
            this.form.get(type)?.markAsTouched();
            this.form.get(type)?.setValue(res);
          }, error => {
            this.form.get(type)?.setValue(error.error.text);
            this.loading = false;
            this.form.get(type)?.markAsTouched();
          })
        } else {
          this.form.get(type)?.markAsTouched();
        }
      });
    }
  }

  validateRequest() {
    if (this.view) {
      const id = this.data.element.id;
      this.solicitacoesService.validateRequest(id).subscribe(res => {
        this.openModalSuccessOrError(false, 'Validada com sucesso');
      }, error => {
        this.openModalSuccessOrError(true, 'Não foi possível validar');
      })
    }
  }

  formatCar(): string {
    let car = this.form.get('car')?.value as String;
    car = car.toUpperCase();
    const siglaEstado = car.substring(0, 2);
    const codigoCidade = car.substring(2, 9);
    const restante = car.substring(9);
    const carFormatado = `${siglaEstado}-${codigoCidade}-${restante}`;
    return carFormatado;
  }

  openModalSuccessOrError(error = false, msg?: string) {
    this.loading = false;
    if (error) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: msg ? msg : '',
        },
      });
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          success: true,
          successText: msg ? msg : '',
        },
      }).afterClosed().subscribe(result => {
        this.closeModal(true);
      });
    }
  }

  getFileName(filePath: string) {
    const fileName = atob(filePath).split('/');
    return fileName.pop();
  }

  deleteFilePath(type: string) {
    this.form.get(type)?.setValue('');
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.uploadFiles(files);
    }
  }
  uploadFiles(files: FileList): void {

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      this.loading = true;
      this.solicitacoesService.uploadFile([file]).subscribe(res => {
        this.loading = false;
        let fileName = this.sanitizeFileName(file.name);
        this.fileList[fileName] = res;
      }, error => {
        console.error(`Erro ao enviar o arquivo ${this.sanitizeFileName(file.name)}.`, error);
      });
    }
  }

  sanitizeFileName(fileName: string): string {
    const normalizedFileName = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const noSpacesFileName = normalizedFileName.replace(/\s+/g, '_');

    const sanitizedFileName = noSpacesFileName.replace(/[@$!%^&*()-+|~=`{}\[\]:";'<>?,.\/]/g, '');

    return sanitizedFileName;
  }

  downloadFile(filePath: string) {
    const fileName = atob(filePath);

    if (filePath) {
      this.arquivosService.getFile(filePath).subscribe((arquivo: Blob) => {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.split('/').pop() as string;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

      }, error => {
        this.openModalSuccessOrError(true, 'Não foi possível baixar o arquivo');
      });
    }
  }

  downloadFiles() {
    if (this.fileList) {
      Object.keys(this.fileList).map(key => {
        this.downloadFile(this.fileList[key])
      })
    }
  }
  getFormArray(type: string) {
    return this.fileList;
  }
  hasFiles() {
    return Object.keys(this.fileList).length > 0
  }
  setFileList(fileList: any) {

    Object.keys(fileList).forEach(key => {
      if (!this.isDefaultPath(key)) {
        this.fileList[key] = fileList[key]
      }
    })
  }

  isDefaultPath(key: any): boolean {
    let defaultPath = false;
    switch (key) {
      case 'ccirFilePath':
        defaultPath = true;
        break;
      case 'producerPropertyFilePath':
        defaultPath = true
        break;
      case 'carFilePath':
        defaultPath = true
        break;
      case 'producerDocumentPath':
        defaultPath = true
        break;
      case 'kmlPath':
        defaultPath = true
        break;
    }
    return defaultPath;
  }



  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  removeFromFileList(key: any) {
    delete this.fileList[key];
  }
}
