import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectInputComponent } from 'src/app/components/select-input/select-input.component';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';
import * as L from 'leaflet';
import 'leaflet-draw';
import * as turf from '@turf/turf';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-mapa-sobreposicao',
  templateUrl: './mapa-sobreposicao.component.html',
  styleUrls: ['./mapa-sobreposicao.component.scss']
})
export class MapaSobreposicaoComponent implements OnInit {

  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  @ViewChild('inputRequest') inputRequest!: SelectInputComponent;

  @ViewChild('mapArea', { static: false }) mapArea!: ElementRef;

  editMode: boolean = false;
  map: any;
  formFilter!: FormGroup;
  loading: boolean = false;
  requestOptionsList: Array<{ name: string, value: string }> = [];
  producerOptionsList: Array<{ name: string, value: string }> = [];
  files: any;
  layerMappings: { [key: string]: any } = {};
  geoJsonMappings: { [key: string]: any } = {};
  requestsList: any;
  initialRequestList: any;
  request: any;
  mapLayerControl: any;
  geoJSON: any;
  comparison1: any;
  comparison2: any;
  renderOverlapAreas: any;
  private storage!: Storage;
  isAdmin: boolean= false;
  multipleFiles: boolean;
  constructor(
    private fb: FormBuilder,
    private solicitacoesService: SolicitacoesService,
  ) {

    this.formFilter = this.fb.group({
      producer: [null],
      request: '',
      slavery: false,
      archeologicalSites: true,
      conservationUnit: true,
      deterAmazon: true,
      deterCerrado: true,
      deterPantanal: true,
      ibamaEmbargo: true,
      indigenousLand: true,
      prodesAmazon: true,
      prodesAtlanticForest: true,
      prodesCaatinga: true,
      prodesCerrado: true,
      prodesPampa: true,
      prodesPantanal: true,
      publicForests: true,
      quilombolaAreas: true,
      property: true,
      editing: true
    }), this.renderOverlapAreas = false
      , this.multipleFiles = false;

  }

  ngOnInit(): void {

    this.requestsList = this.solicitacoesService.listRequestAnswered().subscribe(res => {
      this.requestsList = res;
      this.initialRequestList = res;
    });
    this.storage = window.sessionStorage;
    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};
    if (user.authorities.includes('ROLE_GEOMONITORING_ADMIN')) {
      this.isAdmin = true;
    }
  }
  ngAfterViewInit(): void {
    this.initMap();
  }


  renderOverlap(layer: any): boolean {
    return this.geoJsonMappings[layer];
  }

  isRequestValid(): boolean {
    return this.request?.response?.layers != null;
  }
  changeRequestValue(event: any) {

    this.clearMap();
    this.formFilter.get('request')?.setValue(event);
    this.request = this.initialRequestList.find((item: any) => item.requestNumber === event);
    if (this.isRequestValid()) {

      this.files = this.request.response.layers;
      if (this.request.fileList.kmlPath ) {
        this.solicitacoesService.getTextFile(this.request.fileList.kmlPath).then(data => {
          this.geoJSON = data;
        }).then(() => {
          this.chargeMap();
        });
      } else {
        this.chargeMap();
      }
    } else {
      alert("Esta solicitação não possui acompanhamento geoespacial ativo.")
    }
  }

  changeProducerValue(event: any) {
    this.formFilter.get('producer')?.setValue(event);
    this.getRequestSearchOptions('');
  }

  getProducerSearchOptions(event: any) {
    if (event.length > 2) {
      this.loading = true;
      this.requestsList = this.initialRequestList.filter((item: any) => item.producer.corporateName && item.producer.corporateName.toLowerCase().includes(event.toLowerCase() && this.requestsList.some((request: any) => request.producer.document === item.producer.document)));
      this.getProducerList();
    } else {
      this.loading = true;
      this.requestsList = this.initialRequestList.filter((item: any) => item.producer.corporateName && this.requestsList.some((request: any) => request.producer.document === item.producer.document));
      this.getProducerList();
    }
  }

  getRequestSearchOptions(event: any) {
    if (event.length > 2) {
      this.loading = true;
      this.requestsList = this.initialRequestList.filter((item: any) => item.producer.document && item.producer.document === this.formFilter.get('producer')?.value && this.requestsList.some((request: any) => request.producer.document === item.producer.document));
      this.getRequestList();
    } else {
      this.loading = true;
      this.requestsList = this.initialRequestList.filter((item: any) => item.producer.document && item.producer.document === this.formFilter.get('producer')?.value && this.requestsList.some((request: any) => request.producer.document === item.producer.document));
      this.getRequestList();
    }
  }

  public getRequestList(): void {
    this.loading = true;
    this.requestOptionsList = [];

    this.requestOptionsList = this.requestsList.map((item: any) => {
      let code = item.producer.place ? item.producer.place.code : '';
      let option = {
        name: `${item.requestNumber} - ${code}`, value: item.requestNumber
      }
      return option;
    });
    this.inputRequest.changeOptionsFilter(this.requestOptionsList);
    this.loading = false;
  }

  public getProducerList(): void {
    this.loading = true;
    this.producerOptionsList = [];
    this.producerOptionsList = this.requestsList.map((item: any) => {
      let option = {
        name: item.producer.corporateName, value: item.producer.document
      }
      return option;
    });
    this.inputProvider.changeOptionsFilter(this.producerOptionsList);
    this.loading = false;
  }

  checkRequestOptions(option: string): boolean {
    if (option && this.request && this.request.response.layers) {
      return this.request.response.layers[option];
    }
    return false;
  }
  checkRequestOptionsMarked(option: string): boolean {
    if (option && this.request && this.request.response.layers && this.layerMappings[option]) {
      return true;
    }
    return false;
  }





  initMap(): void {
    this.map = L.map(this.mapArea.nativeElement).setView([0, 0], 2);

    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Esri World Imagery',
    }).addTo(this.map);

    var editableLayers = new L.FeatureGroup();
    this.map.addLayer(editableLayers);


    var drawControl = new L.Control.Draw({
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#e1e100',
            message: '<strong>Error:</strong> Shape edges cannot cross!',
          },
          shapeOptions: {
            color: '#bada55',
          },
        },
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: editableLayers,
        remove: true,
      },
    });
    this.map.addControl(drawControl);

    this.map.on('draw:created', function (e: any) {
      var type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        layer.bindPopup('A polygon!');
      }

      editableLayers.addLayer(layer);
    });

  }

  chargeMap() {


    const layers = this.files;

    Object.keys(layers).forEach((layer) => {
      this.loadAndAddGeoJSONFile(this.map, layers[layer], layer);
    });
    if (this.geoJSON) {
      this.geoJSON = this.kmlToGeoJSON(this.geoJSON);
      this.addGeoJSONToMap(this.map, this.geoJSON, 'kml');
    }
    this.map.fitBounds(this.map.getBounds());

    this.renderOverlapAreas = true;
    if (!this.request.response.layers.editing && this.isAdmin) {
      this.saveKMLArea()
    }

  }

  loadAndAddGeoJSONFile(map: any, fileName: any, layerName: string) {

    const decryptedFileName = this.decryptFileName(fileName);
    const timestamp = this.extractTimestampFromFileName(decryptedFileName);
    let layerKey = layerName;
    if (timestamp && !layerName.match('editing') ) {
      let formattedDate = this.formatTimestamp(timestamp);
      layerKey += ';' + formattedDate
    }
    this.solicitacoesService.getGeoJsonFile(fileName)
      .then(data => {

        this.addGeoJSONToMap(map, data, layerKey);
      })
      .catch(error => console.error('Erro ao carregar o arquivo', error));
  }


  addGeoJSONToMap(map: any, geoJSONData: any, layerName: string) {
    let geoJSONLayer: any;
    let geoJSONOptions: any;
    let toEdit: boolean;
    let editedLayer: boolean = false;
    let layerKey = layerName;
    let fitBounds = layerName === 'property';
    if (layerName === 'kml') {
      toEdit = !this.request.response.layers.editing && this.isAdmin
    } else if (layerName === 'editing') {
      toEdit = true && this.isAdmin
    } else {
      toEdit = false;
    }

    let title = this.getTitle(layerKey)

    if (layerName.match("edited")) {
      layerKey = "edited"
      editedLayer = true;
      title = this.getTitle(layerKey) + ': ' + layerName.split(";")[1]
    }

    geoJSONOptions = {
      style: {
        color: this.getColor(layerKey),
      },
      editable: toEdit,
      removable: true,
      editedLayer: editedLayer
    };
    geoJSONLayer = L.geoJSON(geoJSONData, geoJSONOptions);

    if (layerName === 'property' || layerName.match("edited")) {
      geoJSONLayer.addTo(map).bringToBack();
    } else {
      geoJSONLayer.addTo(map);
    }


    this.layerMappings[layerName] = geoJSONLayer;
    this.geoJsonMappings[layerName] = geoJSONData;
    this.addToLayerControl(title, geoJSONLayer);
    if (fitBounds)
      map.fitBounds(geoJSONLayer.getBounds());
  }

  addToLayerControl(layerName: string, layer: any) {
    if (!this.mapLayerControl) {
      this.mapLayerControl = L.control.layers().addTo(this.map);
    }

    this.mapLayerControl.addOverlay(layer, layerName);

    this.mapLayerControl.getContainer().addEventListener('change', (event: any) => {
      const checked = event.target.checked;

      if (layerName === 'Propriedade') {
        layer.bringToBack();
      }

      if (checked) {
        this.layerMappings[layerName].addTo(this.map);
      } else {
        this.map.removeLayer(this.layerMappings[layerName]);
      }
    });
  }


  removeFromLayerControl(layer: any) {
    if (!this.mapLayerControl) {
      this.mapLayerControl = L.control.layers().addTo(this.map);
    }

    this.map.removeLayer(layer);
    this.mapLayerControl.removeLayer(layer);
  }

  kmlToGeoJSON(kmlData: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(kmlData, 'text/xml');
    const placemarks = xmlDoc.querySelectorAll('Placemark');

    const features = Array.from(placemarks).map((placemark: any) => {
      return this.convertPlacemark(placemark);
    });

    return {
      type: 'FeatureCollection',
      features: features
    };
  }
  convertPlacemark(placemarkNode: any): any {
    const coordinatesNode = placemarkNode.querySelector('coordinates');
    const coordinatesText = coordinatesNode.textContent.trim();
    const coordinatePairs = coordinatesText.split(' ');

    const coordinates = coordinatePairs
      .filter((pair: string) => pair !== 'null,null,0')
      .map((pair: string) => {
        const [lng, lat, alt] = pair.split(',').map((coord: string) => parseFloat(coord));

        if (!isNaN(lng) && !isNaN(lat)) {
          return [lng, lat] as [number, number];
        }
        return null;
      }).filter((coord: [number, number, number] | null) => coord !== null);

    const nameNode = placemarkNode.getElementsByTagName('value')[0];
    const name = nameNode ? nameNode.textContent.trim() : '';

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      properties: {
        name: name,
      },
    };
  }


  getTitle(type: string): string {
    switch (type) {
      case 'archeologicalSites':
        return 'Sitios Arqueológicos';
      case 'conservationUnit':
        return 'Unidade de Conservação';
      case 'deterAmazon':
        return 'Deter Amazônia';
      case 'deterCerrado':
        return 'Deter Cerrado';
      case 'deterPantanal':
        return 'Deter Pantanal';
      case 'ibamaEmbargo':
        return 'Embargo Ibama';
      case 'indigenousLand':
        return 'Terra Indígena';
      case 'prodesAmazon':
        return 'Prodes Amazônia';
      case 'prodesAtlanticForest':
        return 'Prodes Mata Atlântica';
      case 'prodesCaatinga':
        return 'Prodes Caatinga';
      case 'prodesCerrado':
        return 'Prodes Cerrado';
      case 'prodesPampa':
        return 'Prodes Pampa';
      case 'prodesPantanal':
        return 'Prodes Pantanal';
      case 'publicForests':
        return 'Florestas Públicas';
      case 'quilombolaAreas':
        return 'Áreas Quilombolas';
      case 'mineArea':
        return 'Área de Mineração';
      case 'slavery':
        return 'Trabalho Escravo';
      case 'miningAreas':
        return 'Área de Mineração'
      case 'property':
        return 'Propriedade';
      case 'kml':
        return 'Área resumida';
      case 'editing':
        return 'Área resumida atual';
      case 'edited':
        return 'Edição salva em';
      default:
        return '';
    }
  }

  getColor(layerName: string): string {
    switch (layerName) {
      case 'archeologicalSites':
        return 'light-blue';
      case 'conservationUnit':
        return 'green';
      case 'kml':
        return 'black';
      case 'deterAmazon':
        return 'red';
      case 'deterCerrado':
        return 'orange';
      case 'deterPantanal':
        return 'yellow';
      case 'ibamaEmbargo':
        return 'purple';
      case 'indigenousLand':
        return 'brown';
      case 'prodesAmazon':
        return 'cyan';
      case 'prodesAtlanticForest':
        return 'magenta';
      case 'prodesCaatinga':
        return 'lime';
      case 'prodesCerrado':
        return 'pink';
      case 'prodesPampa':
        return 'teal';
      case 'prodesPantanal':
        return 'olive';
      case 'publicForests':
        return 'navy';
      case 'quilombolaAreas':
        return 'gray';
      case 'property':
        return 'blue';
      case 'editing':
        return 'tomato';
      default:
        return 'white';
    }
  }

  extractEditableLayers(): any {
    if (this.validateEditing()) {
      window.alert("A área em edição excede os limites da propriedade");
    } else
      if (!this.layerMappings['editing'])
        this.map.eachLayer((layer: L.Layer) => {
          if (layer == this.layerMappings['kml']) {
            let editedLayer = this.leafletLayerToGeoJSON(layer);
            let editedLayerString = JSON.stringify(editedLayer, null, 2);
            let fileName = 'edit-' + Date.now() + '.js';
            let editedLayerFile = new File([editedLayerString], fileName, { type: 'application/json' });
            this.solicitacoesService.uploadFile([editedLayerFile]).subscribe(res => {
              this.loadAndAddGeoJSONFile(this.map, this.request.response.layers.editing, 'edited')
              this.request.response.layers.editing = res;
              this.loadAndAddGeoJSONFile(this.map, this.request.response.layers.editing, 'editing')
              const geoJSONOptions = {
                style: {
                  color: this.getColor('kml'),
                },
                editable: false,
              };
              layer = L.geoJSON(this.leafletLayerToGeoJSON(layer), geoJSONOptions);
              let result = this.solicitacoesService.updateRequest(this.request);
              result.subscribe(res => {
                this.clearMap().then(() => this.chargeMap());
              })
            }
            )
          }
        });
      else {
        this.map.eachLayer((layer: L.Layer) => {
          const type = layer instanceof L.Polygon ? 'Polygon' :
            layer instanceof L.Polyline ? 'LineString' :
              layer instanceof L.Marker ? 'Point' : null;
          var la: any = layer;
          if (la.options.editable && type) {
            let editedLayerString = JSON.stringify(this.leafletLayerToGeoJSON(layer), null, 2);
            let fileName = 'edit-' + Date.now() + '.js';
            let editedLayerFile = new File([editedLayerString], fileName, { type: 'application/json' });
            this.solicitacoesService.uploadFile([editedLayerFile]).subscribe(res => {
              let count = 1;
              if (this.request.response.layers.editing) {
                Object.keys(this.request.response.layers).forEach((layer) => {
                  if (layer.match("edited")) {
                    count++
                  }
                })
                this.request.response.layers['edited' + count] = this.request.response.layers.editing;
                this.loadAndAddGeoJSONFile(this.map, this.request.response.layers.editing, 'edited')
                this.request.response.layers.editing = res;
              }
              this.geoJsonMappings['editing'] = this.leafletLayerToGeoJSON(layer);
              let result = this.solicitacoesService.updateRequest(this.request);
              result.subscribe(res => {
                this.clearMap().then(() => this.chargeMap());

              })
            }
            )
          }
        })


      }

  }




  leafletLayerToGeoJSON(layer: any): any {
    let geoJSON;
    if (layer.toGeoJSON) {
      geoJSON = layer.toGeoJSON();
    } else {
      const type = layer instanceof L.Polygon ? 'Polygon' :
        layer instanceof L.Polyline ? 'LineString' :
          layer instanceof L.Marker ? 'Point' : null;

      if (type) {
        const coordinates = [];

        if (type === 'Point') {
          coordinates.push(layer.getLatLng().lng, layer.getLatLng().lat);
        } else {
          const latLngs = type === 'Polygon' ? layer.getLatLngs()[0] : layer.getLatLngs();
          latLngs.forEach((latlng: L.LatLng) => {
            coordinates.push([latlng.lng, latlng.lat]);
          });
        }

        geoJSON = {
          type: 'Feature',
          geometry: {
            type: type,
            coordinates: coordinates
          },
          properties: {}
        };
      }
    }
    return geoJSON;
  }

  renderOverlapArea(layer: any) {
    this.comparison2 = this.geoJsonMappings[layer];
    this.comparison1 = this.geoJsonMappings['editing'];
    return this.calculateOverlapArea();
  }

  hasPointsOutsidePolygon(a: any, b: any): boolean {
    const pointsA = turf.explode(a).features;
    for (const pointFeature of pointsA) {
      const point = pointFeature.geometry.coordinates;
      if (!turf.booleanPointInPolygon(point, b.features[0])) {
        return true;
      }
    }
    return false;
  }

  validateEditing() {
    if (this.geoJsonMappings['property'] && (this.geoJsonMappings['editing'] || this.geoJsonMappings['kml'])) {
      let b: any = this.geoJsonMappings['property'];
      let a: any = this.geoJsonMappings['editing'] ?
        this.leafletLayerToGeoJSON(this.layerMappings['editing'])
        : this.leafletLayerToGeoJSON(this.layerMappings['kml']);
      if (this.hasPointsOutsidePolygon(a, b)) {
        return true;
      }
    }
    return false;
  }

  calculateOverlapArea(): number {
    try {
      let a: any = this.comparison2.features[0];
      let b: any = this.comparison1;
      const overlap = turf.intersect(a, b);

      if (overlap) {
        const areaOverlap = turf.area(overlap);
        return areaOverlap > 0 ? areaOverlap / 10000 : areaOverlap;
      }

    } catch (e) {
    }
    return 0
  }



  async clearMap() {
    this.map.eachLayer((layer: L.Layer) => {
      let aux: any = layer
      if (aux.options.removable) {
        this.removeFromLayerControl(layer)
      }
    })
    if (this.request)
      this.solicitacoesService.getRequestById(this.request.id).subscribe(data => {
        this.request = data
      })
    this.layerMappings = {};
    this.geoJsonMappings = {};
  }

  async removeEditedLayers() {
    this.map.eachLayer((layer: L.Layer) => {
      let aux: any = layer
      if (aux.options.editedLayer) {
        this.map.removeLayer(layer)
      }
    })
  }

  decryptFileName(fileName: string): string {
    return atob(fileName);
  }

  extractTimestampFromFileName(decryptedFileName: string): number | null {
    const match = decryptedFileName.match(/edit-(\d+)\.js/);
    if (match && match[1]) {
      return parseInt(match[1], 10);
    }
    return null;
  }

  formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  saveKMLArea() {
    this.map.eachLayer((layer: L.Layer) => {
      if (layer == this.layerMappings['kml']) {
        let editedLayer = this.leafletLayerToGeoJSON(layer);
        let editedLayerString = JSON.stringify(editedLayer, null, 2);
        let fileName = 'edit-' + Date.now() + '.js';
        let editedLayerFile = new File([editedLayerString], fileName, { type: 'application/json' });
        this.solicitacoesService.uploadFile([editedLayerFile]).subscribe(res => {
          this.request.response.layers.editing = res;
          const geoJSONOptions = {
            style: {
              color: this.getColor('kml'),
            },
            editable: false,
          };
          layer = L.geoJSON(this.leafletLayerToGeoJSON(layer), geoJSONOptions);
          let result = this.solicitacoesService.updateRequest(this.request);
          result.subscribe(res => {
            this.clearMap().then(() => this.chargeMap());
          })
        }
        )
      }
    });
  }
}
