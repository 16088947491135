import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterModule, Routes } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SocialAuditModalComponent } from './social-audit-modal/social-audit-modal.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuditoriaService } from 'src/app/services/auditoria.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-social-audit',
  templateUrl: './social-audit.component.html',
  styleUrls: ['./social-audit.component.scss']
})
export class SocialAuditComponent implements OnInit {

  productSelected: string = "";
  modalResult: boolean = false;
  numberOfErrors: number = 0;
  numberOfSuccess: number = 0;
  errorsList: any;
  result: any;

  loading: boolean = false;

  private storage!: Storage;
  protected isUserProvider: boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public auditoriaService: AuditoriaService,
  ) {
    this.storage = window.sessionStorage;
  }

  ngOnInit(): void {

    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      this.isUserProvider = true;

      this.router.navigate(['/minha-empresa/auditoria-social/todos']);
    }

  }

  back() {

    // COMENTADO POR ENQUANTO
    // if(this.isUserProvider == true){
    //   this.router.navigate(['/minha-empresa/sustentabilidade']);
    // }else{
    //   this.router.navigate(['/sustentabilidade']);
    // }


    if (this.isUserProvider == true) {
      this.router.navigate(['/minha-empresa/']);
    } else {
      this.router.navigate(['/']);
    }
  }

  selectTypeProduct(product: string) {
    this.productSelected = product;
    localStorage.setItem('classProduct', product);
  }

  novaAuditoriaSocial() {
    const dialogref = this.dialog.open(SocialAuditModalComponent, {
      data: {
        selectType: true,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.router.navigate(['/nova-auditoria-social'], { queryParams: { type: result.type } });
      }
    });
  }

  inserirViaArquivo() {
    const dialogref = this.dialog.open(SocialAuditModalComponent, {
      data: {
        selectFile: true,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.loading = true;
        this.auditoriaService.regitrarAuditoriaSocialViaArquivo(result.file).subscribe(res => {
          this.result = res;
          if (this.result.list) {
            this.numberOfErrors = 0;
            this.numberOfSuccess = 0;
            this.errorsList = [];
            this.result.list.map((item: any) => {
              if (item.error) {
                this.numberOfErrors += 1;
                this.errorsList.push(item);
              } else {
                this.numberOfSuccess += 1;
              }
            })
          }
          this.loading = false;
          this.modalResult = true;
        })
      }
    });
  }


  exportData(json: any[], fileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, fileName);
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  closeModal() {
    this.modalResult = false;
  }

  downloadTemplate(): SafeUrl {
    const filePath = '../../../assets/templates/auditoria_social.xlsx';
    return this.sanitizer.bypassSecurityTrustUrl(filePath);
  }
}
