import { Component, EventEmitter, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProdutoService } from 'src/app/services/produto.service';
import { SelectInputComponent } from 'src/app/components/select-input/select-input.component';

@Component({
  selector: 'app-modal-list-tecnica',
  templateUrl: './modal-list-tecnica.component.html',
  styleUrls: ['./modal-list-tecnica.component.scss']
})
export class ModalListTecnicaComponent implements OnInit {

  openOptions: boolean = false;
  formTable: FormGroup;
  products: any;
  posProduct: number = 0;
  productSwitched: any;
  productSelect: any;
  loading: boolean = false;
  nameProduct: any;
  providerOptionsList: Array<{ name: string, value: string }> = [];
  storeCDOptionsList: Array<{ name: string, value: string }> = [];
  productOptionsList: Array<{ name: string, value: string }> = [];
  operationalType: string = '';
  economicGroup: string = '';

  providersList: any;
  storeCDsList: any;
  productsList: any;
  @ViewChild('inputProduct') inputProduct!: SelectInputComponent;

  constructor(public dialogRef: MatDialogRef<ModalListTecnicaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productService: ProdutoService) {

    this.formTable = new FormGroup({
      produto: new FormControl('', Validators.required),
      quantidade: new FormControl('', Validators.required),
      unidade: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.data.produto) {
      this.setDatas();
    }
  }

  changeProductValue(event: any) {
    this.formTable.get('produto')?.setValue(event);
  }

  getValue(event: any) {
    this.formTable.get('produto')?.setValue(event);
  }

  getProductSearchOptions(event: any) {
    if (event.length > 0) {
      this.loading = true;
      this.productService.getProductByName(event).subscribe((res: any) => {
        this.productsList = res;
        this.getProductList();
        this.inputProduct.changeOptionsFilter(this.productOptionsList);
        this.inputProduct.optionsShow = true;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    } else {
      // this.loading = true;
      // this.productService.getProductByName('').subscribe((res: any) => {
      //   this.productsList = res;
      //   this.getProductList();
      //   this.inputProduct.changeOptionsFilter(this.productOptionsList);
      //   this.loading = false;
      //   this.inputProduct.optionsShow = true;
      // }, error => {
      //   this.loading = false;
      // });
    }

  }

  public getProductList(): void {

    this.loading = true;
    this.productOptionsList = [];
    this.productOptionsList = this.productsList.map((item: any) => {
      let option = {
        name: item.name, value: item.hash
      }
      return option;
    });
    this.inputProduct.changeOptionsFilter(this.productOptionsList);
    this.loading = false;
  }

  setDatas() {
    // this.inputProduct.change = false;
    //this.inputProduct.selectOption({ name: this.data.produto.name, value: this.data.produto.hash });
    //this.changeProductValue(this.data.produto.hash);
    this.formTable.get('quantidade')?.setValue(this.data.quantidade);
    this.formTable.get('unidade')?.setValue(this.data.unidade);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {

    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('#input-product') && this.openOptions) {
      this.openOptions = false;
    }

  }

  confirmModal() {

    let data;
    this.productService.findProductsFilter({ hash: this.formTable.get('produto')?.value }).subscribe(r => {
      this.productSelect = r;
      data = {
        quantidade: this.formTable.get("quantidade")?.value,
        unidade: this.formTable.get("unidade")?.value,
        produto: this.productSelect
      };

      this.dialogRef.close(data);
    });


  }

  closeModal() {
    this.dialogRef.close();
  }
}
