<!-- modal-content.component.html -->
<div class="classification-modal">
    <div class="header-modal">
        <p>Classe de produto</p>
    </div>
    <div class="body-modal">
        <p class="msg-error">Preencha todos os dados antes de continuar!</p>

        <form [formGroup]="formClass" class="body-modal">
            <div style="display: flex;width:100%">
                <select style="width:80%" formControlName="class" (change)="cleanClass()"
                    class="input-search form-input" required>
                    <option *ngFor="let op of optionsClassification" [value]="op">{{op}}</option>
                </select>
            </div>

            <div class="container-checkbox" *ngIf="formClass.get('class')?.value == 'Marca'">
                <div class="checkbox-item" *ngFor="let item of brand">
                    <input style="margin-top:20px;margin-left: -100px;" (change)="addClass(item)" type="checkbox"
                        [checked]="isChecked(item)" id="{{'checkbox-' + item}}" name="checkbox1">
                    <label style="margin-top:20px;margin-left: -200px;">{{item}}</label>
                </div>
            </div>


            <div class="container-checkbox" *ngIf="formClass.get('class')?.value == 'Nacional/importado'">
                <div class="checkbox-item" *ngFor="let item of nationalImport">
                    <input style="margin-top:20px;margin-left: -100px;" (change)="addClass(item)" type="checkbox"
                        [checked]="isChecked(item)" id="{{'checkbox-' + item}}" name="checkbox1">
                    <label style="margin-top:20px;margin-left: -200px;">{{item}}</label>
                </div>
            </div>


            <div class="container-checkbox" *ngIf="formClass.get('class')?.value == 'Categoria'">


                <div class="checkbox-item" *ngFor="let item of category">
                    <input style="margin-top:20px;margin-left: -100px;" (change)="addClass(item)" type="checkbox"
                        [checked]="isChecked(item)" id="{{'checkbox-' + item}}" name="checkbox1">
                    <label style="margin-top:20px;margin-left: -200px;">{{item}}</label>
                </div>

            </div>


            <div class="container-checkbox" style="overflow-y: scroll;height:200px;margin-top: 20px;"
                id="checkbox-container" *ngIf="formClass.get('class')?.value == 'Sub-categoria'">
                <div class="checkbox-item" *ngFor="let item of subcategory">
                    <input style="margin-top:20px;margin-left: -100px;" (change)="addClass(item)" type="checkbox"
                        [checked]="isChecked(item)" id="{{'checkbox-' + item}}" name="checkbox1">
                    <label style="margin-top:20px;margin-left: -200px;">{{item}}</label>
                </div>
            </div>

            <div class="container-checkbox" style="overflow-y: scroll;height:200px;margin-top: 20px;"
                id="checkbox-container" *ngIf="formClass.get('class')?.value == 'Grupo'">
                <div class="checkbox-item" *ngFor="let item of groups">
                    <input style="margin-top:20px;margin-left: -100px;" (change)="addClass(item)" type="checkbox"
                        [checked]="isChecked(item)" id="{{'checkbox-' + item}}" name="checkbox1">
                    <label style="margin-top:20px;margin-left: -200px;">{{item}}</label>
                </div>
            </div>

            <div class="container-checkbox" style="overflow-y: scroll;height:200px;margin-top: 20px;"
                id="checkbox-container" *ngIf="formClass.get('class')?.value == 'Sub-grupo'">
                <div class="checkbox-item" *ngFor="let item of subgroups">
                    <input style="margin-top:20px;margin-left: -100px;" (change)="addClass(item)" type="checkbox"
                        [checked]="isChecked(item)" id="{{'checkbox-' + item}}" name="checkbox1">
                    <label style="margin-top:20px;margin-left: -200px;">{{item}}</label>
                </div>
            </div>

        </form>
    </div>

    <div class="buttons" style="margin-bottom: 20px">
        <button class="button-export" id="first-button-export" (click)="confirmModal()">
            <h1 style="font-weight: 700;font-family: 'Roboto';font-style: normal;">Confirmar</h1>
        </button>

        <button class="button-clean" (click)="closeModal()">
            <h1>Cancelar</h1>
        </button>
    </div>
</div>