import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-alternativas',
  templateUrl: './modal-alternativas.component.html',
  styleUrls: ['./modal-alternativas.component.scss']
})
export class ModalAlternativasComponent implements OnInit {

  form: FormGroup;
  additionalDivs: boolean[] = [];
  itemControls: FormControl[] = [];
  orderControls: FormControl[] = [];
  pointsControls: FormControl[] = [];
  subOptions: boolean = false;
  submited: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalAlternativasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      question: ['']
    });

    if (!this.data?.options?.type) {
      this.additionalDivs.push(true);
      const newItemControl = new FormControl('');
      const newOrderControl = new FormControl('');
      const newPointsControl = new FormControl('');

      this.form.addControl(`item_1`, newItemControl);
      this.form.addControl(`order_1`, newOrderControl);
      this.form.addControl(`points_1`, newPointsControl);
    }

  }

  ngOnInit(): void {
    this.subOptions = this.data?.subOptions;

    if (this.data?.options?.type) {
      const options = this.data.options;

      this.form.patchValue({
        name: options.name,
        type: options.type,
        question: options.question
      });

      let i = 1;
      while (options[`item_${i}`] !== undefined) {
        this.addAdditionalDivWithData(options[`item_${i}`], options[`order_${i}`], options[`points_${i}`]);
        i++;
      }
    }

  }

  addAdditionalDivWithData(item: string = '', order: string = '', points: string = '') {
    const newItemControl = new FormControl(item);
    const newOrderControl = new FormControl(order);
    const newPointsControl = new FormControl(points);
    this.itemControls.push(newItemControl);
    this.orderControls.push(newOrderControl);
    this.pointsControls.push(newPointsControl);
    this.additionalDivs.push(true);
    const index = this.additionalDivs.length;
    this.form.addControl(`item_${index}`, newItemControl);
    this.form.addControl(`order_${index}`, newOrderControl);
    this.form.addControl(`points_${index}`, newPointsControl);
  }

  closeModal() {
    this.submited = true;
    if (this.form.valid) {
      this.dialogRef.close(this.form);

    }
  }

  closeModalCancel() {
    this.dialogRef.close();
  }

  removeAdditionalDiv(index: number) {
    this.form.removeControl(`item_${index + 1}`);
    this.form.removeControl(`order_${index + 1}`);
    this.form.removeControl(`points_${index + 1}`);

    this.itemControls.splice(index, 1);
    this.orderControls.splice(index, 1);
    this.pointsControls.splice(index, 1);
    this.additionalDivs.splice(index, 1);
  }


  addAdditionalDiv() {
    const newItemControl = new FormControl('');
    const newOrderControl = new FormControl('');
    const newPointsControl = new FormControl('');
    this.itemControls.push(newItemControl);
    this.orderControls.push(newOrderControl);
    this.pointsControls.push(newPointsControl);
    this.additionalDivs.push(true);
    const index = this.additionalDivs.length;
    this.form.addControl(`item_${index}`, newItemControl);
    this.form.addControl(`order_${index}`, newOrderControl);
    this.form.addControl(`points_${index}`, newPointsControl);
  }

}
