import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SeekAuditComponent } from './seek-audit/seek-audit.component';
import { ModalComponent } from './modal/modal.component';
import { SeeDatasLevelServiceComponent } from './see-datas-level-service/see-datas-level-service.component';
import { SeeDatasSutentabiltyComponent } from './see-datas-sutentabilty/see-datas-sutentabilty.component';
import { SearchTraceabilityComponent } from './search-traceability/search-traceability.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatSelectModule } from '@angular/material/select';
import { SeeDatasGeomonitoringComponent } from './see-datas-geomonitoring/see-datas-geomonitoring.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProviderComponent } from './provider/provider.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ProductsListInfoComponent } from './provider/products-list-info/products-list-info.component';
import { ModalTabelaNutricionalComponent } from './modal-tabela-nutricional/modal-tabela-nutricional.component';
import { ModalDevolucaoComponent } from './modal-devolucao/modal-devolucao.component';
import { ModalClassificacaoComponent } from './modal-classificacao/modal-classificacao.component';
import { SeekRankingComponent } from './seek-ranking/seek-ranking.component';
import { ModalOverlayMapLinkComponent } from './modal-overlay-map-link/modal-overlay-map-link.component';
import { ModalListTecnicaComponent } from './modal-list-tecnica/modal-list-tecnica.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { ModalSupplierComponent } from './modal-supplier/modal-supplier.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SeeIntegrationComponent } from './see-integration/see-integration.component';
import { ModalFileComponent } from './modal-file/modal-file.component';
import { MatIconModule } from '@angular/material/icon';
import { CarrosselComponent } from './carrossel/carrossel.component';
import { ModalProvidersComponent } from './modal-providers/modal-providers.component';
import { ModalOriginsComponent } from './modal-origins/modal-origins.component';
import { GraphComponent } from './graph/graph.component';
import { RemoveSemicolonAtEndPipe } from '../pipes/remove-semicolon-at-end.pipe';
import { ModalBlockAlertQuestionProcessComponent } from './modal-block-alert-question-process/modal-block-alert-question-process.component';
import { AccordionPerguntasComponent } from './accordion-perguntas/accordion-perguntas.component';
import { ModalTabelaProcessoComponent } from './modal-tabela-processo/modal-tabela-processo.component';
import { ModalAlternativasComponent } from './modal-alternativas/modal-alternativas.component';
import { ProviderSelectFilterComponent } from './provider-select-filter/provider-select-filter.component';
import { ProductSelectFilterComponent } from './product-select-filter/product-select-filter.component';
import { StoreCDSelectFilterComponent } from './store-cd-select-filter/store-cd-select-filter.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    SeekAuditComponent,
    ModalComponent,
    SeeDatasLevelServiceComponent,
    SeeDatasSutentabiltyComponent,
    SearchTraceabilityComponent,
    SpinnerComponent,
    SeeDatasGeomonitoringComponent,
    ModalConfirmComponent,
    ProviderComponent,
    ModalConfirmComponent,
    ProductsListInfoComponent,
    ModalTabelaNutricionalComponent,
    ModalDevolucaoComponent,
    ModalClassificacaoComponent,
    SeekRankingComponent,
    ModalOverlayMapLinkComponent,
    ModalListTecnicaComponent,
    SelectInputComponent,
    ModalSupplierComponent,
    SeeIntegrationComponent,
    ModalFileComponent,
    CarrosselComponent,
    ModalProvidersComponent,
    ModalOriginsComponent,
    GraphComponent,
    RemoveSemicolonAtEndPipe,
    ModalBlockAlertQuestionProcessComponent,

    AccordionPerguntasComponent,
    ModalTabelaProcessoComponent,
    ModalAlternativasComponent,
    ProviderSelectFilterComponent,
    ProductSelectFilterComponent,
    StoreCDSelectFilterComponent,

  ],
  imports: [
    RouterModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    MatIconModule,
  ],
  exports: [
    GraphComponent,
    SidebarComponent,
    HeaderComponent,
    SeekAuditComponent,
    ModalComponent,
    ProviderComponent,
    SpinnerComponent,
    SelectInputComponent,
    CarrosselComponent,
    AccordionPerguntasComponent,
    ProviderSelectFilterComponent,
    ProductSelectFilterComponent,
    StoreCDSelectFilterComponent,
  ]
})
export class ComponentsModule { }
