import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalysesService {
  storage: Storage;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
  }

  getAnalyseByID(id: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/find?id=${id}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getAnalysesByType(type: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/list?type=${type}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getAnalysesByPage(page: number, type: string, operationalType: string, size: number, document: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    let url = "";

    if (document == undefined || document == "") {
      url = `${environment.api}/analysis-service/list-paginated?page=${page}&type=${type}&collectionCompanyOperationalType=${operationalType}&size=${size}`;
    } else {
      url = `${environment.api}/analysis-service/list-paginated?page=${page}&type=${type}&collectionCompanyOperationalType=${operationalType}&size=${size}&document=${document}`;
    }

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getAnalysesByProduct(product: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };
    const url = `${environment.api}/analysis-service/list?productName=${product}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  uploadAnalisesFile(files: any): Observable<any> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let uploadFiles
    if (files.length > 0) {
      uploadFiles = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        uploadFiles.push(file);
      }
    }

    const formData = new FormData();

    if (uploadFiles) {
      for (var i = 0; i < uploadFiles?.length; i++) {
        formData.append('file', uploadFiles[i]);
      }
    }

    return this.http.post(`${environment.api}/analysis-service/register-xlsx`, formData, {
      headers: new HttpHeaders({
        Authorization: token
      }),
    });
  }

  getAnalysesByEAN(ean: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/list?productEan=${ean}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getAnalysesByCode(code: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/list?productCode=${code}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  uploadDocument(uploadFiles: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const formData = new FormData();
    for (var i = 0; i < uploadFiles.length; i++) {
      formData.append('file', uploadFiles[i]);
    }

    return this.http.post(`${environment.api}/reader-service/upload `, formData, httpOptions);
  }

  getAnalysesByPLU(plu: string): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/list?productPlu=${plu}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getAllAnalyses(): Observable<any> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/list`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getListPaginated(filters: {
    productType?: string,
    type?: string,
    provider?: string,
    localType?: string,
    storeCD?: string,
    year: number | string,
    product?: string,
    plu?: string,
    ean?: string,
    page: number,
    size: number,
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('page', filters.page)
      .set('size', filters.size)
      .set('year', filters.year);

    if (filters.productType) {
      params = params.set('productType', filters.productType);
    }

    if (filters.type) {
      params = params.set('type', filters.type);
    }

    if (filters.provider) {
      params = params.set('providerCompanyDocument', filters.provider);
    }

    if (filters.localType) {
      params = params.set('collectionCompanyOperationalType', filters.localType);
    }

    if (filters.storeCD) {
      params = params.set('collectionCompanyDocument', filters.storeCD);
    }

    if (filters.product) {
      params = params.set('productName', filters.product);
    }

    if (filters.ean) {
      params = params.set('productEan', filters.ean);
    }

    if (filters.plu) {
      params = params.set('productPlu', filters.plu);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/analysis-service/list-paginated`, http).pipe(
      res => res,
      error => error
    );
  }

  exportGetListPaginated(filters: {
    productType?: string,
    type?: string,
    provider?: string,
    localType?: string,
    storeCD?: string,
    year: number | string,
    product?: string,
    plu?: string,
    ean?: string,
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('year', filters.year);

    if (filters.productType) {
      params = params.set('productType', filters.productType);
    }

    if (filters.type) {
      params = params.set('type', filters.type);
    }

    if (filters.provider) {
      params = params.set('providerCompanyDocument', filters.provider);
    }

    if (filters.localType) {
      params = params.set('collectionCompanyOperationalType', filters.localType);
    }

    if (filters.storeCD) {
      params = params.set('collectionCompanyDocument', filters.storeCD);
    }

    if (filters.product) {
      params = params.set('productName', filters.product);
    }

    if (filters.ean) {
      params = params.set('productEan', filters.ean);
    }

    if (filters.plu) {
      params = params.set('productPlu', filters.plu);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    let httpRequest: any = http;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/qualityXLSX-service/analysis`, httpRequest).pipe(
      res => res,
      error => error
    );
  }

  createNewAnalyses(body: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    return this.http.post<Object>(`${environment.api}/analysis-service/register`, body, httpOptions);
  }

  deleteAnalyses(id: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params: {
        id: id
      }
    };

    return this.http.delete<Object>(`${environment.api}/analysis-service/delete`, httpOptions);
  }

  editNewAnalyses(body: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    return this.http.post<Object>(`${environment.api}/analysis-service/update`, body, httpOptions);
  }

  getProductByProviderCompany(providerDocument: any) {
    const url = `${environment.api}/product-service/products-by-provider?document=${providerDocument}`;
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  getAnalysesByCollectionCompanyDocument(document: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    const url = `${environment.api}/analysis-service/list/corporate-document?collectionCompanyDocument=${document}`;

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );
  }

  downloadTemplate() {
    const filePath = 'L3ZvbHVtZS9wcm9jZXNzZWQvQW5hbGlzZXNfVGVtcGxhdGUgKDIpLnhsc3g=';
    const url = `${environment.api}/reader-service/download?filePath=${filePath}`;

    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      })
    };

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response)
    );

  }

  public getAnalysisReports(startDate: string, endDate: string, productType: string, optionsAnalysis: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let optionsReports: any = [];

    optionsAnalysis.collectionDate ? optionsReports.push("collectionDate") : null;
    optionsAnalysis.providerCompany ? optionsReports.push("providerCompany") : null,
      optionsAnalysis.collectingCompany ? optionsReports.push("collectingCompany") : null,
      optionsAnalysis.collectionCompany ? optionsReports.push("collectionCompany") : null,
      optionsAnalysis.productName ? optionsReports.push("productName") : null,
      optionsAnalysis.actionPlan ? optionsReports.push("actionPlan") : null,
      optionsAnalysis.result ? optionsReports.push("result") : null,
      optionsAnalysis.type ? optionsReports.push("type") : null,
      optionsAnalysis.residueResult ? optionsReports.push("residueResult") : null,
      optionsAnalysis.productType ? optionsReports.push("productType") : null,
      optionsAnalysis.providerCompany ? optionsReports.push("providerCompanyCode") : null,
      optionsAnalysis.providerCompany ? optionsReports.push("providerCompanyName") : null,
      optionsAnalysis.productName ? optionsReports.push("productPlu") : null,
      optionsAnalysis.productName ? optionsReports.push("productEan") : null,
      optionsAnalysis.trackingCode ? optionsReports.push("trackingCode") : null,
      optionsAnalysis.exclusiveBrand ? optionsReports.push("exclusiveBrand") : null

    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('analysisType', "RESIDUOS_AGROTOXICOS")

    const httpOptions: any = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
      responseType: 'arraybuffer'
    };

    return this.http.post(`${environment.api}/analysis-service/analysisReports`, optionsReports, httpOptions).pipe(
      res => res,
      error => error
    );
  }

  listPendingActionPlan(customerId: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerId', customerId)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/analysis-service/pending-action-plan`, http).pipe(
      res => res,
      error => error
    )
  }

  listReprovedStatusAnalysis(customerId: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerId', customerId)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/analysis-service/reproved-status-analysis`, http).pipe(
      res => res,
      error => error
    )
  }
}

