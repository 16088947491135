import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Customer, iEnterpriseCustomer } from '../interfaces/clients.interface';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FornecedoresService {
  storage: Storage;
  constructor(private router: Router, private http: HttpClient) {
    this.storage = window.sessionStorage;
  }

  public listarFornecedores(
    customerId: string,
    name?: string,
    document?: string,
    enterpriseCode?: string
  ) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams();
    if (name) {
      params = params.set('outsourcedCorporateName', name);
    }

    if (customerId) {
      params = params.set('customerId', customerId);
    }

    if (document) {
      params = params.set('document', document);
    }

    if (enterpriseCode) {
      params = params.set('enterpriseCode', enterpriseCode);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
          customerId: customerId,
        }),
        params,
      };
    }

    return this.http
      .get(`${environment.api}/enterprise-service/list`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public createEnterprise(enterprise: any) {

    const newProvider = {
      document: this.limparCnpjCpf(enterprise.cnpj),
      customerId: null,
      description: '',
      stateRegistration: '',
      address: {
        zipCode: enterprise.postalCode ? enterprise.postalCode : 'N/A',
        stateIdentifier: enterprise.state ? enterprise.state : 'N/A',
        stateName: enterprise.state ? enterprise.state : 'N/A',
        city: enterprise.city ? enterprise.city : 'N/A',
        district: enterprise.neighborhood ? enterprise.neighborhood : 'N/A',
        street: enterprise.street ? enterprise.street : 'N/A',
        number: enterprise.number ? enterprise.number : 'N/A',
      },
      contacts: [],
      places: [],
      enterpriseCode: enterprise.enterpriseCode ? enterprise.enterpriseCode : null,
      propertyNumber: '',
      corporateName: enterprise.providerName,
      technicalManager: '',
      operationalType: enterprise.type,
      documentType: '',
      geomonitoringTool: enterprise.geomonitoringTool,
      contractPath: '',
      environmentalLicense: '',
      apiGeo: '',
      employeeCount: enterprise.employeeCount,
      overdue: false,
      outsourced: [],
    };

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    return this.http
      .put(
        `${environment.api}/enterprise-service/create-or-update-provider-from-enterprise`,
        newProvider,
        {
          headers: new HttpHeaders({
            Authorization: token,
          }),
        }
      )
      .pipe(
        (res) => {
          return res;
        },
        (error) => error
      );
  }

  public criarFornecedor(provider: any, customerId?: string) {
    let contacts = [];
    let places = [];
    let features = [];
    let certificates = [];

    if (provider.places) {
      places = provider.places.filter((item: any) => item.nickname);
      if (provider.contacts) {
        contacts = provider.contacts.filter((item: any) => item.name);
      }
    }

    if (provider.eTerceiro) {
      features.push({
        name: 'eTerceiro',
        value: provider.eTerceiro,
        usage: 'É terceiro?',
      });
    }

    if (provider.smallProducer) {
      features.push({
        name: 'smallProducer',
        value: provider.smallProducer,
        usage: 'Pequeno Produtor',
      });
    }

    if (provider.deliveryPlace) {
      features.push({
        name: 'deliveryPlace',
        value: provider.deliveryPlace,
        usage: 'Local de Entrega',
      });
    }

    if (provider.rastreabilityIntegration) {
      features.push({
        name: 'rastreabilityIntegration',
        value: provider.rastreabilityIntegration,
        usage: 'Integração Rastreabilidade',
      });
    }

    if (provider.fantasyName) {
      features.push({
        name: 'fantasyName',
        value: provider.fantasyName,
        usage: 'Nome Fantasia',
      });
    }

    if (provider.socialReason) {
      features.push({
        name: 'socialReason',
        value: provider.socialReason,
        usage: 'Razão Social',
      });
    }

    if (provider.sif) {
      features.push({
        name: 'sif',
        value: provider.sif,
        usage: 'SIF',
      });
    }

    if (provider.socialCertifiedDescription) {
      certificates.push({
        name: 'Certificado Social',
        description: provider.socialCertifiedDescription,
        expiration: provider.socialCertifiedValidity,
      });
    }

    if (provider.qualityCertifiedValidity) {
      certificates.push({
        name: 'Certificado de Qualidade',
        description: provider.qualityCertifiedValidity,
        expiration: provider.qualityCertifiedValidity,
      });
    }

    if (provider.sustainabilityCertifiedDescription) {
      certificates.push({
        name: 'Certificado de Sustentabilidade',
        description: provider.sustainabilityCertifiedDescription,
        expiration: provider.sustainabilityCertifiedValidity,
      });
    }

    const newProvider = {
      document: this.limparCnpjCpf(provider.cnpj),
      customerId: null,
      description: '',
      stateRegistration: '',
      address: {
        zipCode: provider.postalCode ? provider.postalCode : 'N/A',
        stateIdentifier: provider.state ? provider.state : 'N/A',
        stateName: provider.state ? provider.state : 'N/A',
        city: provider.city ? provider.city : 'N/A',
        district: provider.neighborhood ? provider.neighborhood : 'N/A',
        street: provider.street ? provider.street : 'N/A',
        number: provider.number ? provider.number : 'N/A',
        null: false,
      },
      contacts: contacts,
      enterpriseCode: provider.enterpriseCode ? provider.enterpriseCode : null,
      propertyNumber: '',
      corporateName: provider.providerName,
      technicalManager: '',
      operationalType: provider.type,
      documentType: '',
      geomonitoringTool: provider.geomonitoringTool,
      contractPath: '',
      environmentalLicense: '',
      apiGeo: '',
      employeeCount: provider.employeeCount,
      overdue: false,
      places: places,
      features: features,
      certificates: certificates,
      outsourced: [],
    };

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    return this.http
      .post(
        `${environment.api}/enterprise-service/create-outsourced?customerId=${customerId ? customerId : user.customerId
        }`,
        newProvider,
        {
          headers: new HttpHeaders({
            Authorization: token,
            customerId: customerId ? customerId : user.customerId,
          }),
        }
      )
      .pipe(
        (res) => {
          return res;
        },
        (error) => error
      );
  }

  public registerEnterprise(provider: any) {
    let contacts = [];
    let places = [];
    let features = [];
    let certificates = [];

    if (provider.places) {
      places = provider.places.filter((item: any) => item.nickname);
      if (provider.contacts) {
        contacts = provider.contacts.filter((item: any) => item.name);
      }
    }

    if (provider.eTerceiro) {
      features.push({
        name: 'eTerceiro',
        value: provider.eTerceiro,
        usage: 'É terceiro?',
      });
    }

    if (provider.smallProducer) {
      features.push({
        name: 'smallProducer',
        value: provider.smallProducer,
        usage: 'Pequeno Produtor',
      });
    }

    if (provider.deliveryPlace) {
      features.push({
        name: 'deliveryPlace',
        value: provider.deliveryPlace,
        usage: 'Local de Entrega',
      });
    }

    if (provider.rastreabilityIntegration) {
      features.push({
        name: 'rastreabilityIntegration',
        value: provider.rastreabilityIntegration,
        usage: 'Integração Rastreabilidade',
      });
    }

    if (provider.fantasyName) {
      features.push({
        name: 'fantasyName',
        value: provider.fantasyName,
        usage: 'Nome Fantasia',
      });
    }

    if (provider.socialReason) {
      features.push({
        name: 'socialReason',
        value: provider.socialReason,
        usage: 'Razão Social',
      });
    }

    if (provider.sif) {
      features.push({
        name: 'sif',
        value: provider.sif,
        usage: 'SIF',
      });
    }

    if (provider.socialCertifiedDescription) {
      certificates.push({
        name: 'Certificado Social',
        description: provider.socialCertifiedDescription,
        expiration: provider.socialCertifiedValidity,
      });
    }

    if (provider.qualityCertifiedValidity) {
      certificates.push({
        name: 'Certificado de Qualidade',
        description: provider.qualityCertifiedValidity,
        expiration: provider.qualityCertifiedValidity,
      });
    }

    if (provider.sustainabilityCertifiedDescription) {
      certificates.push({
        name: 'Certificado de Sustentabilidade',
        description: provider.sustainabilityCertifiedDescription,
        expiration: provider.sustainabilityCertifiedValidity,
      });
    }

    const newProvider = {
      document: this.limparCnpjCpf(provider.cnpj),
      customerId: null,
      description: '',
      stateRegistration: '',
      economicGroup: provider.economicGroup ? provider.economicGroup : '',
      address: {
        zipCode: provider.postalCode ? provider.postalCode : 'N/A',
        stateIdentifier: provider.state ? provider.state : 'N/A',
        stateName: provider.state ? provider.state : 'N/A',
        city: provider.city ? provider.city : 'N/A',
        district: provider.neighborhood ? provider.neighborhood : 'N/A',
        street: provider.street ? provider.street : 'N/A',
        number: provider.number ? provider.number : 'N/A',
        null: false,
      },
      contacts: contacts,
      enterpriseCode: provider.enterpriseCode ? provider.enterpriseCode : null,
      propertyNumber: '',
      corporateName: provider.providerName,
      technicalManager: '',
      operationalType: provider.type,
      documentType: '',
      geomonitoringTool: provider.geomonitoringTool,
      contractPath: '',
      environmentalLicense: '',
      apiGeo: '',
      employeeCount: provider.employeeCount,
      overdue: false,
      places: places,
      features: features,
      certificates: certificates,
      outsourced: [],
    };

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    return this.http
      .post(`${environment.api}/enterprise-service/register`, newProvider, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(
        (res) => {
          return res;
        },
        (error) => error
      );
  }

  public editarFornecedor(provider: any, id: string) {
    let contacts = [];
    let places = [];
    let features = [];
    let certificates = [];

    places = provider.places.filter((item: any) => item.nickname);
    if (provider.contacts) {
      contacts = provider.contacts.filter((item: any) => item.name);
    }

    if (provider.eTerceiro) {
      features.push({
        name: 'eTerceiro',
        value: provider.eTerceiro,
        usage: 'É terceiro?',
      });
    }

    if (provider.smallProducer) {
      features.push({
        name: 'smallProducer',
        value: provider.smallProducer,
        usage: 'Pequeno Produtor',
      });
    }

    if (provider.deliveryPlace) {
      features.push({
        name: 'deliveryPlace',
        value: provider.deliveryPlace,
        usage: 'Local de Entrega',
      });
    }

    if (provider.rastreabilityIntegration) {
      features.push({
        name: 'rastreabilityIntegration',
        value: provider.rastreabilityIntegration,
        usage: 'Integração Rastreabilidade',
      });
    }

    if (provider.fantasyName) {
      features.push({
        name: 'fantasyName',
        value: provider.fantasyName,
        usage: 'Nome Fantasia',
      });
    }

    if (provider.socialReason) {
      features.push({
        name: 'socialReason',
        value: provider.socialReason,
        usage: 'Razão Social',
      });
    }

    if (provider.sif) {
      features.push({
        name: 'sif',
        value: provider.sif,
        usage: 'SIF',
      });
    }

    if (provider.socialCertifiedDescription) {
      certificates.push({
        name: 'Certificado Social',
        description: provider.socialCertifiedDescription,
        expiration: provider.socialCertifiedValidity,
      });
    }

    if (provider.qualityCertifiedValidity) {
      certificates.push({
        name: 'Certificado de Qualidade',
        description: provider.qualityCertifiedValidity,
        expiration: provider.qualityCertifiedValidity,
      });
    }

    if (provider.sustainabilityCertifiedDescription) {
      certificates.push({
        name: 'Certificado de Sustentabilidade',
        description: provider.sustainabilityCertifiedDescription,
        expiration: provider.sustainabilityCertifiedValidity,
      });
    }

    const newProvider = {
      id: id,
      document: this.limparCnpjCpf(provider.cnpj),
      customerId: null,
      description: '',
      stateRegistration: '',
      address: {
        hash: provider.addressHash ? provider.addressHash : null,
        zipCode: provider.postalCode ? provider.postalCode : 'N/A',
        stateIdentifier: provider.state ? provider.state : 'N/A',
        stateName: provider.state ? provider.state : 'N/A',
        city: provider.city ? provider.city : 'N/A',
        district: provider.neighborhood ? provider.neighborhood : 'N/A',
        street: provider.street ? provider.street : 'N/A',
        number: provider.number ? provider.number : 'N/A',
        null: false,
      },
      contacts: contacts,
      enterpriseCode: provider.enterpriseCode ? provider.enterpriseCode : '',
      propertyNumber: '',
      corporateName: provider.providerName,
      technicalManager: '',
      operationalType: provider.type,
      documentType: '',
      geomonitoringTool: provider.geomonitoringTool,
      contractPath: '',
      environmentalLicense: '',
      apiGeo: '',
      employeeCount: provider.employeeCount,
      overdue: false,
      places: places,
      features: features,
      certificates: certificates,
      outsourced: [],
    };

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    return this.http
      .post(`${environment.api}/enterprise-service/update`, newProvider, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(
        (res) => {
          return res;
        },
        (error) => error
      );
  }

  public getProviderOutsourcedByName(
    name: string,
    size?: number,
    page?: number
  ) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const user = this.storage.getItem('user');
    const newUser = user ? JSON.parse(user) : {};

    let isUserProvider = null;

    if (newUser.authorities.some((item: string) => item === 'ROLE_PROVIDER')) {
      isUserProvider = true;
    }

    let http = {};

    let params = new HttpParams()
      .set('outsourcedCorporateName', name)
      .set('customerId', newUser.customerId)
      .set('page', page ? page : 0)
      .set('size', size ? size : 100000);

    // .set('customerId', isUserProvider? "NDc1MDg0MTEyMTAwMDA=" : newUser.customerId)

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(
        `${environment.api}/enterprise-service/get-outsourced-by-customerId/optimized`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public getProviderByName(name: string, size?: number, page?: number) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const user = this.storage.getItem('user');
    const newUser = user ? JSON.parse(user) : {};

    let http = {};
    let params = new HttpParams()
      .set('outsourcedCorporateName', name)
      .set('customerId', newUser.customerId)
      .set('page', page ? page : 0)
      .set('size', size ? size : 100000);

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(
        `${environment.api}/enterprise-service/get-outsourced-by-customerId/optimized`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  getStatusProvider(providerDocument: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    let params = new HttpParams().set('providerDocument', providerDocument);
    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(`${environment.api}/movement-service/getProviderData`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  limparCnpjCpf(cnpjCpf: string) {
    return cnpjCpf.replace(/\D/g, '');
  }

  addOutSourced(customerId: string, enterprise: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    return this.http
      .post(
        `${environment.api}/enterprise-service/create-outsourced?customerId=${customerId ? customerId : user.customerId
        }`,
        enterprise,
        {
          headers: new HttpHeaders({
            Authorization: token,
            customerId: customerId ? customerId : user.customerId,
          }),
        }
      )
      .pipe(
        (res) => {
          return res;
        },
        (error) => error
      );
  }

  public listEnterpriseProvider(filter: any): Observable<iEnterpriseCustomer> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams();
    if (filter.providerCorporateName) {
      params = new HttpParams().set(
        'providerCorporateName',
        filter.providerCorporateName
      ).set('page', filter.page ? filter.page : 0)
      .set('size', filter.size ? filter.size : 10);
    }

    if (filter.providerOperationalType) {
      params = new HttpParams().set(
        'providerOperationalType',
        filter.providerOperationalType
      ).set('page', filter.page ? filter.page : 0)
      .set('size', filter.size ? filter.size : 10);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get<iEnterpriseCustomer>(
        `${environment.api}/enterprise-service/list-enterprise-provider`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public listEnterpriseProviderByDocument(
    enterpriseDocument: string,
    filter?: any
  ): Observable<iEnterpriseCustomer> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams()
    .set('enterpriseDocument', enterpriseDocument)
    .set('page', filter && filter.page ? filter.page : 0)
    .set('size', filter && filter.size ? filter.size : 10);

    if (filter && filter.providerCorporateName) {
      params = new HttpParams().set(
        'providerCorporateName',
        filter.providerCorporateName
      ).set('page', filter && filter.page ? filter.page : 0)
      .set('size', filter && filter.size ? filter.size : 10);
    }

    if (filter && filter.providerOperationalType) {
      params = new HttpParams().set(
        'providerOperationalType',
        filter.providerOperationalType
      ).set('page', filter.page ? filter.page : 0)
      .set('size', filter.size ? filter.size : 10);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get<iEnterpriseCustomer>(
        `${environment.api}/enterprise-service/list-enterprise-provider-by-document`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public findOneEnterpriseProvider(filter: any): Observable<Customer> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams();

    if (filter.providerDocument) {
      params = new HttpParams().set(
        'providerDocument',
        filter.providerDocument
      );
    }

    if (filter.providerEnterpriseCode) {
      params = new HttpParams().set(
        'providerEnterpriseCode',
        filter.providerEnterpriseCode
      );
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get<Customer>(
        `${environment.api}/enterprise-service/find-one-enterprise-provider`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public createOrUpdateProviderFromEnterprise(body: any) {
    let contacts = [];
    let places = [];
    let features = [];
    let certificates = [];

    if (body.places) {
      places = body.places.filter((item: any) => item.nickname);
      if (body.contacts) {
        contacts = body.contacts.filter((item: any) => item.name);
      }
    }

    if (body.eTerceiro) {
      features.push({
        name: 'eTerceiro',
        value: body.eTerceiro,
        usage: 'É terceiro?',
      });
    }

    if (body.smallProducer) {
      features.push({
        name: 'smallProducer',
        value: body.smallProducer,
        usage: 'Pequeno Produtor',
      });
    }

    if (body.deliveryPlace) {
      features.push({
        name: 'deliveryPlace',
        value: body.deliveryPlace,
        usage: 'Local de Entrega',
      });
    }

    if (body.rastreabilityIntegration) {
      features.push({
        name: 'rastreabilityIntegration',
        value: body.rastreabilityIntegration,
        usage: 'Integração Rastreabilidade',
      });
    }

    if (body.fantasyName) {
      features.push({
        name: 'fantasyName',
        value: body.fantasyName,
        usage: 'Nome Fantasia',
      });
    }

    if (body.socialReason) {
      features.push({
        name: 'socialReason',
        value: body.socialReason,
        usage: 'Razão Social',
      });
    }

    if (body.sif) {
      features.push({
        name: 'sif',
        value: body.sif,
        usage: 'SIF',
      });
    }

    if (body.socialCertifiedDescription) {
      certificates.push({
        name: 'Certificado Social',
        description: body.socialCertifiedDescription,
        expiration: body.socialCertifiedValidity,
      });
    }

    if (body.qualityCertifiedValidity) {
      certificates.push({
        name: 'Certificado de Qualidade',
        description: body.qualityCertifiedValidity,
        expiration: body.qualityCertifiedValidity,
      });
    }

    if (body.sustainabilityCertifiedDescription) {
      certificates.push({
        name: 'Certificado de Sustentabilidade',
        description: body.sustainabilityCertifiedDescription,
        expiration: body.sustainabilityCertifiedValidity,
      });
    }

    const newProvider = {
      document: this.limparCnpjCpf(body.cnpj),
      customerId: null,
      description: '',
      stateRegistration: '',
      address: {
        zipCode: body.postalCode ? body.postalCode : 'N/A',
        stateIdentifier: body.state ? body.state : 'N/A',
        stateName: body.state ? body.state : 'N/A',
        city: body.city ? body.city : 'N/A',
        district: body.neighborhood ? body.neighborhood : 'N/A',
        street: body.street ? body.street : 'N/A',
        number: body.number ? body.number : 'N/A',
        null: false,
      },
      contacts: contacts,
      enterpriseCode: body.enterpriseCode ? body.enterpriseCode : null,
      propertyNumber: '',
      corporateName: body.providerName,
      technicalManager: '',
      operationalType: body.type,
      documentType: '',
      geomonitoringTool: body.geomonitoringTool,
      contractPath: '',
      environmentalLicense: '',
      apiGeo: '',
      employeeCount: body.employeeCount,
      overdue: false,
      places: places,
      features: features,
      certificates: certificates,
      outsourced: [],
    };

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }

    return this.http
      .put(
        `${environment.api}/enterprise-service/create-or-update-provider-from-enterprise`,
        newProvider,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public createOrUpdateProviderFromEnterpriseByDocument(
    enterpriseDocument: string,
    body: any
  ) {
    let contacts = [];
    let places = [];
    let features = [];
    let certificates = [];

    if (body.places) {
      places = body.places.filter((item: any) => item.nickname);
      if (body.contacts) {
        contacts = body.contacts.filter((item: any) => item.name);
      }
    }

    if (body.eTerceiro) {
      features.push({
        name: 'eTerceiro',
        value: body.eTerceiro,
        usage: 'É terceiro?',
      });
    }

    if (body.smallProducer) {
      features.push({
        name: 'smallProducer',
        value: body.smallProducer,
        usage: 'Pequeno Produtor',
      });
    }

    if (body.deliveryPlace) {
      features.push({
        name: 'deliveryPlace',
        value: body.deliveryPlace,
        usage: 'Local de Entrega',
      });
    }

    if (body.rastreabilityIntegration) {
      features.push({
        name: 'rastreabilityIntegration',
        value: body.rastreabilityIntegration,
        usage: 'Integração Rastreabilidade',
      });
    }

    if (body.fantasyName) {
      features.push({
        name: 'fantasyName',
        value: body.fantasyName,
        usage: 'Nome Fantasia',
      });
    }

    if (body.socialReason) {
      features.push({
        name: 'socialReason',
        value: body.socialReason,
        usage: 'Razão Social',
      });
    }

    if (body.sif) {
      features.push({
        name: 'sif',
        value: body.sif,
        usage: 'SIF',
      });
    }

    if (body.socialCertifiedDescription) {
      certificates.push({
        name: 'Certificado Social',
        description: body.socialCertifiedDescription,
        expiration: body.socialCertifiedValidity,
      });
    }

    if (body.qualityCertifiedValidity) {
      certificates.push({
        name: 'Certificado de Qualidade',
        description: body.qualityCertifiedValidity,
        expiration: body.qualityCertifiedValidity,
      });
    }

    if (body.sustainabilityCertifiedDescription) {
      certificates.push({
        name: 'Certificado de Sustentabilidade',
        description: body.sustainabilityCertifiedDescription,
        expiration: body.sustainabilityCertifiedValidity,
      });
    }

    const newProvider = {
      document: this.limparCnpjCpf(body.cnpj),
      customerId: null,
      description: '',
      stateRegistration: '',
      address: {
        zipCode: body.postalCode ? body.postalCode : 'N/A',
        stateIdentifier: body.state ? body.state : 'N/A',
        stateName: body.state ? body.state : 'N/A',
        city: body.city ? body.city : 'N/A',
        district: body.neighborhood ? body.neighborhood : 'N/A',
        street: body.street ? body.street : 'N/A',
        number: body.number ? body.number : 'N/A',
        null: false,
      },
      contacts: contacts,
      enterpriseCode: body.enterpriseCode ? body.enterpriseCode : null,
      propertyNumber: '',
      corporateName: body.providerName,
      technicalManager: '',
      operationalType: body.type,
      documentType: '',
      geomonitoringTool: body.geomonitoringTool,
      contractPath: '',
      environmentalLicense: '',
      apiGeo: '',
      employeeCount: body.employeeCount,
      overdue: false,
      places: places,
      features: features,
      certificates: certificates,
      outsourced: [],
    };

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams().set('enterpriseDocument', enterpriseDocument);

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .put(
        `${environment.api}/enterprise-service/create-or-update-provider-from-enterprise-by-document`,
        newProvider,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public deleteProviderFromEnterprise(
    providerDocument: string
  ): Observable<string> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams().set('providerDocument', providerDocument);

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .delete<string>(
        `${environment.api}/enterprise-service/delete-provider-from-enterprise`,
        http
      )
      .pipe(
        map((res) => {
          try {
            return JSON.stringify(res);
          } catch (e) {
            return res;
          }
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }
}
