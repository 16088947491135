import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { AuditoriaService } from 'src/app/services/auditoria.service';
import { ProviderSelectFilterComponent } from '../provider-select-filter/provider-select-filter.component';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';

@Component({
  selector: 'app-seek-audit',
  templateUrl: './seek-audit.component.html',
  styleUrls: ['./seek-audit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})

export class SeekAuditComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'providerCompany.enterpriseCode',
    'providerCompany.document',
    'auditedCompany.corporateName',
    'auditedCompany.address.city',
    'auditingDate',
    'expiration',
    'score',
    'result',
    'status',
    'actions'
  ];

  formFilter!: FormGroup;
  @ViewChild('inputProvider') inputProvider!: ProviderSelectFilterComponent;

  tableActive: boolean = false;
  classProduct: string | null = '';
  tableData: any = [];
  auditList: any;

  loading: boolean = false;
  firstRender: boolean = true;

  pageIndex: number = 0;
  pageSize: number = 15;
  length: number = 0;

  private storageSession!: Storage;
  protected isUserProvider : boolean = false;
  protected user : any;

  protected providerDocument : any = "";

  constructor(
    private auditoriaService: AuditoriaService,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    this.storageSession = window.sessionStorage;
    this.formFilter = this.fb.group({
      year: ['', Validators.required],
      brand: [''],
      provider: [null],
      status: [null],
    });
  }

  ngOnInit(): void {

    const item = this.storageSession.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if(this.user.authorities.some((item: string) => item === "ROLE_PROVIDER")){
      this.isUserProvider = true;
    }
    this.checkFilters();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitForm() {
    this.formFilter.markAllAsTouched();
    if (this.formFilter.valid) {
      this.paginate();
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: 'Preencha os filtros',
        }
      });
    }
  }

  onClickCheck(checkbox: any, type: string) {
    checkbox[type] = !checkbox[type];
  }

  formatCnpj(value: string) {
    return formatCpfCnpj(value)
  }

  paginate(pageEvent?: PageEvent) {
    if (pageEvent) {
      this.pageIndex = pageEvent.pageIndex;
      this.pageSize = pageEvent.pageSize;
    } else {
      this.pageIndex = 0;
      this.pageSize = 15;
    }
    this.loading = true;
    this.classProduct = '';
    this.classProduct = localStorage.getItem('classProduct');
    if (this.classProduct === 'flv') {
      this.classProduct = 'FLV';
    }

    const options = {
      productType: !this.isUserProvider? this.classProduct : '',
      year: this.formFilter.get('year')?.value,
      brand: this.formFilter.get('brand')?.value,
      provider: this.formFilter.get('provider')?.value,
      status: this.formFilter.get('status')?.value,
      page: this.pageIndex,
      size: this.pageSize,
    }

    if(this.isUserProvider){
      this.auditoriaService.listAudit(options).subscribe({
        next: (res: any) => {
          this.tableData = res.content;
          if (this.tableData && this.tableData.length <= 0) {
            this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "right",
              verticalPosition: "top"
            });
          }
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;

          this.auditList = this.tableData;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.tableActive = true;
          this.loading = false;
          this.saveFilters();
        },
        error: (err) => {
          this.tableActive = true;
          this.tableData = [];
          this.loading = false;
        },
      });
    }else{
      this.auditoriaService.listAudit(options).subscribe({
        next: (res: any) => {
          this.tableData = res.content;
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;
          if (this.tableData && this.tableData.length <= 0) {
            this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "right",
              verticalPosition: "top"
            });
          }

          this.auditList = this.tableData;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.tableActive = true;
          this.loading = false;
          this.saveFilters();
        },
        error: (err) => {
          this.tableActive = true;
          this.tableData = [];
          this.loading = false;
        },
      });
    }
  };

  checkFilters() {
    const item = sessionStorage.getItem('filtro-qualidade-audit');
    const filters = item ? JSON.parse(item): null;

    if (filters) {
      this.formFilter.get('year')?.setValue(filters.year);

      if (filters.brand) {
        this.formFilter.get('brand')?.setValue(filters.brand);
      }
      if (filters.provider) {
        this.formFilter.get('provider')?.setValue(filters.provider);
      }

      if (filters.status) {
        this.formFilter.get('status')?.setValue(filters.status);
      }

      if (filters.providerName) {
        setTimeout(() => {
          this.inputProvider.setValue(filters.providerName);
        }, 500);
      }
      if ((filters.pageIndex || this.pageIndex === 0) && filters.pageSize) {
        this.paginate({ pageIndex: filters.pageIndex, pageSize: filters.pageSize } as PageEvent);
      }
    }
  }

  saveFilters() {
    sessionStorage.removeItem('filtro-qualidade-audit');

    const filter = {
      type: this.classProduct,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      year: this.formFilter.get('year')?.value,
      brand: this.formFilter.get('brand')?.value,
      provider: this.formFilter.get('provider')?.value,
      status: this.formFilter.get('status')?.value,
      providerName: this.inputProvider.inputProvider.value,
    }

    const filterJSON = JSON.stringify(filter);
    sessionStorage.setItem('filtro-qualidade-audit', filterJSON);
  }



  cleanFilters() {
    localStorage.removeItem('filtro-qualidade-audit');
    this.pageIndex = 0;
    this.pageSize = 15;
    this.inputProvider.cleanFilter();
    this.formFilter.get('year')?.setValue('');
    this.formFilter.get('brand')?.setValue('');
    this.formFilter.get('provider')?.setValue('');
    this.formFilter.get('status')?.setValue('');

    this.tableData = [];
    this.dataSource = new MatTableDataSource([] as Array <any>);
  }

  editAudit(item: any) {
    let url = window.location.pathname;
    let parts = url.split('/');
    let type = parts.pop();
    const options = ['FLV', 'Ovos', 'Suinos', 'Aves'];

    function capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (type && type !== 'flv') {
      type = capitalizeFirstLetter(type);
    }
    if (type && type === 'flv') {
      type = type.toUpperCase();
    }

    if(this.isUserProvider){
      this.router.navigate(['/minha-empresa/auditoria/nova-auditoria'], { queryParams: { type: type, id: item.id } });
    }else{
      this.router.navigate(['auditoria/nova-auditoria'], { queryParams: { type: type, id: item.id } });
    }

  }

  maskCpfCnpj(value: string): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(9)}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(0, 2)}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

  exportToExcel(json: any[], fileName: string): void {
    if(json.length > 0){
      this.loading = true;

      const options = {
        productType: !this.isUserProvider? this.classProduct : '',
        year: this.formFilter.get('year')?.value,
        brand: this.formFilter.get('brand')?.value,
        provider: !this.isUserProvider? this.formFilter.get('provider')?.value : this.providerDocument,
        status: this.formFilter.get('status')?.value,
      }

      this.auditoriaService.exportAudit(options).subscribe({
        next: (res: any) => {
          let response = res;
          this.dialog.open(ModalConfirmComponent, {
              data : {
                success: true,
                successText: response,
              }
          })
          this.loading = false;
        },
        error: (err) => {
          this.dialog.open(ModalConfirmComponent, {
            data : {
              error: true,
              errorText: 'Falha ao gerar arquivo para exportação!',
            }
          })
          this.loading = false;
        },
      });
    }
  }

  deleteAudit(item: any) {
    const dialogref = this.dialog.open(ModalConfirmComponent, {
      data: {
        text: 'Deseja deletar auditoria?',
        delete: true,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.loading = true;
        this.auditoriaService.deletarAuditoria(item.id).subscribe(res => {
          this.loading = false;
          const dialogref = this.dialog.open(ModalConfirmComponent, {
            data: {
              success: true,
              successText: 'Auditoria Apagada com sucesso!',
              delete: true,
            }
          });
          dialogref.afterClosed().subscribe(result => {
            this.paginate();
          });

        }, () => {
          this.loading = false;
          const dialogref = this.dialog.open(ModalConfirmComponent, {
            data: {
              error: true,
              errorText: 'Não foi possível apagar a Auditoria',
              delete: true,
            }
          });
          dialogref.afterClosed().subscribe(result => {
            this.paginate();
          });
        })
      }
    });
  }

  getStatusColor(status: string): string {
    status = this.capitalizarString(status);
    const statusColors: { [key: string]: string } = {
      'Em andamento': 'yellow',
      'Liberado': 'green',
      'Suspenso': 'red',
    };

    return statusColors[status] || '';
  }

  getPlaceName(element: any): string {
    let placeName = 'N/A';
    if (element.auditedCompany && element.auditedCompany.places && element.auditedCompany.places.length > 0 && element.auditedCompany.places[0] && element.auditedCompany.places[0].nickname) {
      placeName = element.auditedCompany.operationalType ? element.auditedCompany.operationalType : '';
    }
    return placeName;
  }

  changeProviderValue(event:any ){
    this.formFilter.get('provider')?.setValue(event);
  }

  public capitalizarString(string : string) {
    if (string == undefined || string.trim() === '') {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  public formatCpfCnpj(item: string) {
    return formatCpfCnpj(item);
  }

}
