import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuditoriaService {
  storage: Storage;
  modal = false;
  typeContent = '';
  typeModal = '';
  uploadFiles: any = [];
  token: any;
  item: any;
  httpOptions: any;

  constructor(private router: Router, private http: HttpClient) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};

    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    }
  }

  newAudit(form: FormGroup, process: any, type: string, auditedCompany: any, place: any) {

    const newAuditedCompany = {
      document: form.get('cnpj')?.value.replace(/\D/g, ''),
      address: {
        stateIdentifier: form.get('state')?.value,
        stateName: form.get('state')?.value,
        city: form.get('city')?.value,
        district: form.get('neighborhood')?.value,
        street: form.get('street')?.value,
        number: form.get('numberAddress')?.value,
        zipCode: form.get('postalCode')?.value
      },
      // operationalType: form.get('operationalType')?.value,
      outsourced: [],
      corporateName: form.get('nameProvider')?.value,
      economicGroup: form.get('economicGroup')?.value,
      documentType: "",
      enterpriseCode: form.get('codeProvider')?.value,
      places: [place],
    }

    const dateNow = new Date();
    const paths = [];
    paths.push(form.get('pathArchiveActionPlan')?.value)

    const reportPaths = [];
    reportPaths.push(form.get('reportPaths')?.value);
    const certificates: Array<any> = form.get('qualityCertificate')?.value && (form.get('qualityCertificate')?.value === true || form.get('qualityCertificate')?.value === 'true') ? [
      {
        name: 'Certificado de qualidade',
        description: form.get('describeCertificate')?.value,
        expiration: form.get('certificateValidity')?.value,
      }
    ] : [];

    if (form.valid) {
      let body = {
        auditingDate: form.get('auditingDate')?.value,
        auditedCompany: auditedCompany ? auditedCompany : newAuditedCompany,
        auditingCompany: {
          document: form.get('auditingCompany')?.value.replace(/\D/g, ''),
        },
        providerCompany: newAuditedCompany,
        type: 'Qualidade',
        process: process,
        exclusiveBrand: form.get('qualityCertificate')?.value == 'true' ? true : false,
        qualityCertificate: form.get('qualityCertificate')?.value,
        score: form.get('score')?.value,
        result: form.get('result')?.value,
        status: form.get('status')?.value,
        cultures: form.get('cultures')?.value,
        expiration: '',
        productionSystem: form.get('systemProduction')?.value,
        productEvaluation: '',
        auditor: '',
        operationalType: form.get('operationalType')?.value,
        actionPlan: {
          date: dateNow.toLocaleDateString(),
          name: "Plano de Ação",
          paths: paths,
          status: form.get('actionPlan')?.value,
        },
        description: '',
        certificates: certificates,
        features: [
          {
            name: 'subType',
            value: type,
            type: 'String',
            usage: 'Tipo de Auditoria de Qualidade',
          },
          {
            name: 'documentProvider',
            value: form.get('documentProvider')?.value,
            type: 'String',
            usage: 'Documento cadastrado pelo fornecedor',
          },
          {
            name: 'actionPlanProvider',
            value: form.get('actionPlanProvider')?.value,
            type: 'String',
            usage: 'Plano de ação cadastrado pelo fornecedor',
          }
        ],
        noteAnimal: form.get('noteAnimal') ? form.get('noteAnimal')?.value : null,
        reportFileName: form.get('reportFileName')?.value,
        reportPaths: reportPaths,
      };

      this.http
        .post(`${environment.api}/auditing-service/register`, body, this.httpOptions)
        .subscribe({
          next: (res: any) => {
            this.router.navigateByUrl('/auditoria');
            this.changeModal();
            this.typeContent = 'success';
          },
          error: (err) => {
            this.changeModal();
            this.typeContent = 'error';
          },
        });
    } else {
      form.markAllAsTouched();
    }
  }

  editAudit(data: any, process: any, type: string, id: string, auditedCompany: any, place: any) {
    const auditingDate = new Date(data.auditingDate);
    const certificates: Array<any> = data.qualityCertificate ? [
      {
        name: 'Certificado de qualidade',
        description: data.describeCertificate,
        expiration: data.certificateValidity,
      }
    ] : [];

    const newAuditedCompany = {
      document: data.cnpj.replace(/\D/g, ''),
      address: {
        stateIdentifier: "N/A",
        stateName: data.state,
        city: data.city,
        district: data.neighborhood,
        street: data.street,
        number: data.numberAddress,
        zipCode: data.postalCode
      },
      outsourced: [],
      corporateName: data.nameProvider,
      economicGroup: data.economicGroup,
      documentType: "",
      enterpriseCode: data.codeProvider,
      places: [
        place,
      ],
    }

    const dateNow = new Date();
    const paths = [];
    paths.push(data.pathArchiveActionPlan)

    const reportPaths = [];
    reportPaths.push(data.reportPaths);
    const body = {
      id,
      auditingDate: auditingDate.getTime(),
      auditedCompany: auditedCompany ? auditedCompany : newAuditedCompany,
      auditingCompany: {
        document: data.auditingCompany.replace(/\D/g, ''),
      },
      providerCompany: newAuditedCompany,
      type: 'Qualidade',
      process: process,
      exclusiveBrand: data.brand == 'true' ? true : false,
      qualityCertificate: data.qualityCertificate,
      score: data.score,
      result: data.result,
      status: data.status,
      cultures: data.cultures,
      expiration: '',
      productionSystem: data.systemProduction,
      productEvaluation: '',
      auditor: data.auditor,
      actionPlan: {
        date: dateNow.toLocaleDateString(),
        name: "Plano de Ação",
        paths: [data.pathArchiveActionPlan],
        status: data.actionPlan,
      },
      description: '',
      certificates: certificates,
      features: [
        {
          name: 'subType',
          value: type,
          type: 'String',
          usage: 'Tipo de Auditoria de Qualidade',
        },
        {
          name: 'documentProvider',
          value: data.documentProvider,
          type: 'String',
          usage: 'Documento cadastrado pelo fornecedor',
        },
        {
          name: 'actionPlanProvider',
          value: data.actionPlanProvider,
          type: 'String',
          usage: 'Plano de ação cadastrado pelo fornecedor',
        }
      ],
      noteAnimal: data.noteAnimal ? data.noteAnimal : null,
      reportFileName: data.reportFileName,
      reportPaths: reportPaths,
    };

    this.http
      .post(`${environment.api}/auditing-service/update`, body, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          this.router.navigateByUrl('/auditoria');
          this.changeModal();
          this.typeContent = 'success';
        },
        error: (err) => {
          this.changeModal();
          this.typeContent = 'error';
        },
      });
  }

  deletarAuditoria(hash: string) {
    return this.http.delete(`${environment.api}/auditing-service/delete?document=${hash}`, this.httpOptions);
  }

  changeModal() {
    this.modal = !this.modal;
  }

  onFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      this.uploadFiles = [];
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.uploadFiles.push(file);
      }
    }
  };

  submitFormFtp = () => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};
    let http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };

    const formData = new FormData();

    for (var i = 0; i < this.uploadFiles.length; i++) {
      let fileName = this.uploadFiles[i].name;

      if (fileName.includes(',') || fileName.includes(';')) {
        fileName = fileName.replace(/[,;]/g, ' ');
      }
      formData.append('file', this.uploadFiles[i], fileName);
    }


    return this.http.post(`${environment.api}/reader-service/auditingfilexlsx`, formData, {
      headers: new HttpHeaders({
        Authorization: token
      }),
    });
  };

  listAudit = (options: {
    productType: string | null;
    year: string | number;
    brand?: string;
    provider?: string;
    status?: string;
    page: number;
    size: number;
  }) => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('year', options.year)
      .set('type', 'Qualidade')
      .set('page', options.page)
      .set('size', options.size)

    if (isUserProvider == null) {
      params = params.set('productType', options.productType ? options.productType : 'FLV');
    }

    if (options.brand) {
      params = params.set('exclusiveBrand', options.brand);
    }

    if (options.provider) {
      params = params.set('providerCompany', options.provider);
    }

    if (options.status) {
      params = params.set('status', options.status);
    }

    const http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };
    return this.http.get(`${environment.api}/auditing-service/list-paginated`, http).pipe(
      res => res,
      error => error
    );
  };

  exportAudit = (options: {
    productType: string | null;
    year: string | number;
    brand?: string;
    provider?: string;
    status?: string;
  }) => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('year', options.year)
      .set('type', 'Qualidade')

    if (isUserProvider == null) {
      params = params.set('productType', options.productType ? options.productType : 'FLV');
    }

    if (options.brand) {
      params = params.set('exclusiveBrand', options.brand);
    }

    if (options.provider) {
      params = params.set('providerCompany', options.provider);
    }

    if (options.status) {
      params = params.set('status', options.status);
    }

    const http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };

    let httpRequest: any = http;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/qualityXLSX-service/auditing`, httpRequest).pipe(
      res => res,
      error => error
    );
  };

  listAuditByDocument = (subType: string = 'FLV', document: string) => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};
    let params = new HttpParams()
      .set('type', 'Qualidade')
      .set('productType', subType)
      .set('document', document);

    const http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };
    return this.http.get(`${environment.api}/auditing-service/find-by-provider`, http).pipe(
      res => res,
      error => error
    );
  };

  getAuditById(id: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');

    let http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };
    return this.http.get(`${environment.api}/auditing-service/list?id=${id}`, http).pipe(
      res => res,
      error => error
    )
  }

  socialAuditList = (options: {
    subType: string | null;
    year: string | number;
    brand?: string;
    provider?: string;
    status?: string;
    page: number;
    size: number;
  }) => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('type', 'Social')
      .set('year', options.year)
      .set('page', options.page)
      .set('size', options.size)

    if (isUserProvider == null) {
      params = params.set('productType', options.subType ? options.subType : 'FLV');
    }

    if (options.brand) {
      params = params.set('exclusiveBrand', options.brand);
    }

    if (options.provider) {
      params = params.set('providerCompany', options.provider);
    }

    if (options.status) {
      params = params.set('status', options.status);
    }

    let http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };
    return this.http.get(`${environment.api}/auditing-service/list-paginated`, http).pipe(
      res => res,
      error => error
    );
  }

  exportSocialAuditList = (options: {
    subType: string | null;
    year: string | number;
    brand?: string;
    provider?: string;
    status?: string;
  }) => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('type', 'Social')
      .set('year', options.year)

    if (isUserProvider == null) {
      params = params.set('productType', options.subType ? options.subType : 'FLV');
    }

    if (options.brand) {
      params = params.set('exclusiveBrand', options.brand);
    }

    if (options.provider) {
      params = params.set('providerCompany', options.provider);
    }

    if (options.status) {
      params = params.set('status', options.status);
    }

    let http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };

    let httpRequest: any = http;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/qualityXLSX-service/auditing`, httpRequest).pipe(
      res => res,
      error => error
    );
  }

  socialAuditListByDocument = (subType: string = 'FLV', document: string) => {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};
    let params = new HttpParams()
      .set('type', 'Social')
      .set('featureName', 'subType')
      .set('featureValue', subType)
      .set('document', document);

    const http = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };
    return this.http.get(`${environment.api}/auditing-service/find-by-provider`, http).pipe(
      res => res,
      error => error
    );
  };

  public adicionarAuditoriaSocial(auditoria: any, type: string, place: any, process: any) {
    const expirationDate = new Date(auditoria.auditingDate);
    const qualityCertificate = auditoria.brand;
    const result = auditoria.result;

    if (qualityCertificate === true && result === 'Aprovado') {
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    } else if (qualityCertificate === false && result === 'Aprovado') {
      expirationDate.setFullYear(expirationDate.getFullYear() + 2);
    } else if (qualityCertificate === true && result === 'Condicional') {
      expirationDate.setMonth(expirationDate.getMonth() + 6);
    } else if (qualityCertificate === false && result === 'Condicional') {
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    } else if (qualityCertificate === true && result === 'Reprovado') {
      expirationDate.setMonth(expirationDate.getMonth() + 3);
    } else if (qualityCertificate === false && result === 'Reprovado') {
      expirationDate.setMonth(expirationDate.getMonth() + 6);
    }

    const paths = [];
    paths.push(auditoria.pathArchiveActionPlan);

    const reportPaths = [];
    reportPaths.push(auditoria.reportPaths);

    let features = []

    if (auditoria.classification) {
      features.push({
        name: 'classification',
        type: 'String',
        value: auditoria.classification,
        usage: 'Classificação',
      });
    }

    if (auditoria.documentProvider) {
      features.push({
        name: 'documentProvider',
        type: 'String',
        value: auditoria.documentProvider,
        usage: 'Documento cadastrado pelo fornecedor',
      });
    }

    if (auditoria.actionPlanProvider) {
      features.push({
        name: 'actionPlanProvider',
        type: 'String',
        value: auditoria.actionPlanProvider,
        usage: 'Plano de Ação cadastrado pelo fornecedor',
      });
    }

    if (type) {
      features.push({
        name: 'subType',
        value: type,
        type: 'String',
        usage: 'Tipo de Auditoria Social',
      });
    }

    if (auditoria.status) {
      features.push({
        name: 'status',
        type: 'String',
        value: auditoria.status,
        usage: 'Status',
      });
    }

    if (auditoria.auditedLocal) {
      features.push({
        name: 'auditedLocal',
        type: 'String',
        value: auditoria.auditedLocal,
        usage: 'Local Auditado',
      });
    }

    if (auditoria.actionPlan) {
      features.push({
        name: 'actionPlan',
        type: 'String',
        value: auditoria.actionPlan,
        usage: 'Plano de Ação',
      });
    }

    const newAuditedCompany = {
      document: auditoria.cnpj.replace(/\D/g, ''),
      address: {
        stateIdentifier: "N/A",
        stateName: auditoria.state,
        city: auditoria.city,
        district: auditoria.neighborhood,
        street: auditoria.street,
        number: auditoria.numberAddress,
        zipCode: auditoria.postalCode
      },
      outsourced: [],
      corporateName: auditoria.nameProvider,
      documentType: "",
      enterpriseCode: auditoria.codeProvider,
      places: [place],
    };

    const dateNow = new Date();

    const novaAuditoriaSocial = {
      auditingDate: auditoria.auditingDate,
      auditedCompany: newAuditedCompany,
      auditingCompany: {
        document: auditoria.auditingCompany
      },
      providerCompany: newAuditedCompany,
      process: process,
      exclusiveBrand: auditoria.brand == 'true' ? true : false,
      qualityCertificate: false,
      score: auditoria.score,
      result: auditoria.result,
      status: auditoria.status,
      cultures: "",
      expiration: expirationDate,
      productionSystem: '',
      type: 'Social',
      features: features,
      productEvaluation: '',
      auditor: '',
      actionPlan: {
        date: dateNow.toLocaleDateString(),
        name: "Plano de Ação",
        paths: [auditoria.pathArchiveActionPlan],
        status: auditoria.actionPlan,
      },
      description: '',
      certificates: [],
      products: auditoria.products,
      reportFileName: auditoria.reportFileName,
      reportPaths: reportPaths,
    };

    return this.http.post(`${environment.api}/auditing-service/register`, novaAuditoriaSocial, this.httpOptions).pipe(
      res => res,
      error => error
    )
  }

  public editarAuditoriaSocial(auditoria: any, type: string, providerCompany: any, auditingCompany: any, place: any, process: any) {
    const auditingDate = new Date(auditoria.auditingDate);
    const expirationDate = new Date(auditoria.auditingDate);
    const qualityCertificate = auditoria.brand;
    const result = auditoria.result;

    const paths = [];
    paths.push(auditoria.pathArchiveActionPlan);

    const reportPaths = [];
    reportPaths.push(auditoria.reportPaths);

    if (qualityCertificate === true && result === 'Aprovado') {
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    } else if (qualityCertificate === false && result === 'Aprovado') {
      expirationDate.setFullYear(expirationDate.getFullYear() + 2);
    } else if (qualityCertificate === true && result === 'Condicional') {
      expirationDate.setMonth(expirationDate.getMonth() + 6);
    } else if (qualityCertificate === false && result === 'Condicional') {
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    } else if (qualityCertificate === true && result === 'Reprovado') {
      expirationDate.setMonth(expirationDate.getMonth() + 3);
    } else if (qualityCertificate === false && result === 'Reprovado') {
      expirationDate.setMonth(expirationDate.getMonth() + 6);
    }

    let features = []

    if (auditoria.classification) {
      features.push({
        name: 'classification',
        type: 'String',
        value: auditoria.classification,
        usage: 'Classificação',
      });
    }

    if (auditoria.documentProvider) {
      features.push({
        name: 'documentProvider',
        type: 'String',
        value: auditoria.documentProvider,
        usage: 'Documento cadastrado pelo fornecedor',
      });
    }

    if (auditoria.actionPlanProvider) {
      features.push({
        name: 'actionPlanProvider',
        type: 'String',
        value: auditoria.actionPlanProvider,
        usage: 'Plano de Ação cadastrado pelo fornecedor',
      });
    }

    if (type) {
      features.push({
        name: 'subType',
        type: 'String',
        value: type,
        usage: 'Tipo de Auditoria Social',
      });
    }

    if (auditoria.status) {
      features.push({
        name: 'status',
        type: 'String',
        value: auditoria.status,
        usage: 'Status',
      });
    }

    if (auditoria.actionPlan) {
      features.push({
        name: 'actionPlan',
        type: 'String',
        value: auditoria.actionPlan,
        usage: 'Plano de Ação',
      });
    }

    if (auditoria.auditedLocal) {
      features.push({
        name: 'auditedLocal',
        type: 'String',
        value: auditoria.auditedLocal,
        usage: 'Local Auditado',
      });
    }

    const dateNow = new Date();

    const editarAuditoriaSocial = {
      id: auditoria.id,
      auditingDate: auditingDate.getTime(),
      auditedCompany: providerCompany,
      auditingCompany: auditingCompany,
      providerCompany: providerCompany,
      process: process,
      exclusiveBrand: auditoria.brand == 'true' ? true : false,
      qualityCertificate: false,
      score: auditoria.score,
      result: auditoria.result,
      status: auditoria.status,
      cultures: "",
      expiration: expirationDate,
      productionSystem: '',
      type: 'Social',
      features: features,
      productEvaluation: '',
      auditor: '',
      actionPlan: {
        date: dateNow.toLocaleDateString(),
        name: "Plano de Ação",
        paths: [auditoria.pathArchiveActionPlan],
        status: auditoria.actionPlan,
      },
      description: '',
      certificates: [],
      products: auditoria.products,

      reportFileName: auditoria.reportFileName,
      reportPaths: reportPaths,
    };

    return this.http.post(`${environment.api}/auditing-service/update`, editarAuditoriaSocial, this.httpOptions).pipe(
      res => res,
      error => error
    )
  }

  public uploadFile(uploadFiles: any) {

    const formData = new FormData();
    for (var i = 0; i < uploadFiles.length; i++) {
      formData.append('file', uploadFiles[i]);
    }

    return this.http.post(`${environment.api}/reader-service/upload `, formData, this.httpOptions);
  }

  public regitrarAuditoriaSocialViaArquivo(uploadFiles: any) {
    const formData = new FormData();
    for (var i = 0; i < uploadFiles.length; i++) {
      formData.append('file', uploadFiles[i]);
    }

    return this.http.post(`${environment.api}/reader-service/auditingsocialfilexlsx`, formData, this.httpOptions);
  }

  public listProvidersWithoutQualityAuditing(customerId: any, productType: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerId', customerId)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (productType) {
      params = params.set('productType', productType);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/auditing-service/list-providers-without-quality-auditing`, http).pipe(
      res => res,
      error => error
    )
  }

  listBlockedSocialAuditing(customerId: any, productType: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerId', customerId)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (productType) {
      params = params.set('productType', productType);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/auditing-service/list-blocked-social-auditing`, http).pipe(
      res => res,
      error => error
    )
  }

  listPendingActionPlanSocialAuditing(customerId: any, productType: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerId', customerId)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (productType) {
      params = params.set('productType', productType);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/auditing-service/list-pending-action-plan-social-auditing`, http).pipe(
      res => res,
      error => error
    )
  }

  listPendingActionPlanQualityAuditing(customerId: any, productType: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerId', customerId)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (productType) {
      params = params.set('productType', productType);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/auditing-service/list-pending-action-plan-quality-auditing`, http).pipe(
      res => res,
      error => error
    )
  }

  public getFile(file: string) {
    let http = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Content-Type': 'application/json',
      }),
      responseType: 'text',
      params: {
        filePath: file,
      },
    };
    return this.http.get(`${environment.api}/reader-service/download?`,
      {
        headers: new HttpHeaders({
          Authorization: this.token,
          'Content-Type': 'application/json',
        }),
        responseType: 'blob',
        params: {
          filePath: file,
        },
      });
  }

  public getAuditingReports(startDate: string, endDate: string, auditingType: string, productType: string, optionsAuditing: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let optionsReports: any = [];

    optionsAuditing.auditingDate ? optionsReports.push("auditingDate") : null,
      optionsAuditing.providerCompanyDocument ? optionsReports.push("providerCompanyDocument") : null,
      optionsAuditing.auditedCompany ? optionsReports.push("auditedCompany") : null,
      optionsAuditing.auditingCompany ? optionsReports.push("auditingCompany") : null,
      optionsAuditing.process ? optionsReports.push("process") : null,
      optionsAuditing.actionPlan ? optionsReports.push("actionPlan") : null,
      optionsAuditing.expiration ? optionsReports.push("expiration") : null,
      optionsAuditing.type ? optionsReports.push("type") : null,
      optionsAuditing.exclusiveBrand ? optionsReports.push("exclusiveBrand") : null,
      optionsAuditing.qualityCertificate ? optionsReports.push("qualityCertificate") : null,
      optionsAuditing.year ? optionsReports.push("year") : null,
      optionsAuditing.result ? optionsReports.push("result") : null,
      optionsAuditing.productType ? optionsReports.push("productType") : null,
      optionsAuditing.providerCompanyDocument ? optionsReports.push("providerCompanyAddress") : null,
      optionsAuditing.providerCompanyDocument ? optionsReports.push("providerCompanyName") : null,
      optionsAuditing.providerCompanyDocument ? optionsReports.push("providerCompanyCode") : null,
      optionsAuditing.cultures ? optionsReports.push("cultures") : null,
      optionsAuditing.score ? optionsReports.push("score") : null

    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('auditingType', auditingType)
      .set('productType', productType)

    this.httpOptions.params = params;
    const httpOptions = this.httpOptions;
    httpOptions.responseType = 'arraybuffer';

    return this.http.post(`${environment.api}/auditing-service/auditingReports`, optionsReports, httpOptions).pipe(
      res => res,
      error => error
    );
  }
}
