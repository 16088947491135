import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-geomonitoring',
  templateUrl: './geomonitoring.component.html',
  styleUrls: ['./geomonitoring.component.scss']
})
export class GeomonitoringComponent implements OnInit {

  productSelected: string = "";


  constructor(
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  selectProductType(type: string) {
    this.productSelected = type;
  }

  back() {
    this.location.go("/sustentabilidade")
  }

}
