import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormsService } from 'src/app/services/forms.service';
import { OpenModalConfirmService } from 'src/app/services/open-modal-confirm.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  formTitle: string = '';
  questions: any[] = [];
  loading: boolean = false;

  constructor(
    private formService: FormsService,
    private openModalConfirmService: OpenModalConfirmService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.addQuestion();
  }

  addQuestion(): void {
    this.questions.push({
      questionText: '',
      type: 'TEXT',
      options: [],
      conditionalQuestions: [],
      code: `Q${String(this.questions.length + 1).padStart(4, '0')}`,
      parentId: null
    });
  }

  handleClickBack() {
    this.router.navigate(['/processos']);
  }

  removeQuestion(index: number): void {
    this.questions.splice(index, 1);
  }

  generateJson(): void {
    const jsonOutput = {
      title: this.formTitle,
      questions: this.questions.map((q: any) => this.mapQuestion(q))
    };
    this.openModalConfirmService.openModalConfirm({

    }).subscribe(result => {
      if (result.confirm) {
        this.loading = true;
        this.formService.registerForm(jsonOutput).subscribe(res => {
          this.loading = false;
          this.openModalConfirmService.openModalConfirm({
            success: true,
          }).subscribe(r => {
            this.handleClickBack();
          });
        }, error => {
          this.loading = false;
          this.openModalConfirmService.openModalConfirm({
            error: true,
          }).subscribe(r => {
            this.handleClickBack();
          });
        });
      }
    })
  }

  private mapQuestion(question: any): any {
    return {
      questionText: question.questionText,
      type: question.type,
      options: (question.options || []).map((opt: any) => this.mapOption(opt)),
      conditionalQuestions: (question.conditionalQuestions || []).map((cq: any) => this.mapQuestion(cq)),
      code: question.code,
      parentId: question.parentId
    };
  }

  private mapOption(option: any): any {
    return {
      value: option.optionText,
      conditionalQuestions: (option.conditionalOptions || []).map((cq: any) => this.mapQuestion(cq)),
      id: option.id,
      parentId: option.parentId
    };
  }
}
