import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-tabela-nutricional',
  templateUrl: './modal-tabela-nutricional.component.html',
  styleUrls: ['./modal-tabela-nutricional.component.scss']
})
export class ModalTabelaNutricionalComponent implements OnInit {

  formTable: FormGroup;

  constructor(public dialogRef: MatDialogRef<ModalTabelaNutricionalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.formTable = new FormGroup({
      nutriente: new FormControl('', Validators.required),
      quantidade: new FormControl('', Validators.required),
      quantidadePorPorcao: new FormControl('', Validators.required),
      vd: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    if (this.data.nutriente) {
      this.setDatas();
    }
  }

  setDatas() {
    this.formTable.get('nutriente')?.setValue(this.data.nutriente);
    this.formTable.get('quantidade')?.setValue(this.data.quantidade);
    this.formTable.get('quantidadePorPorcao')?.setValue(this.data.quantidadePorPorcao);
    this.formTable.get('vd')?.setValue(this.data.vd);
  }

  confirmModal() {
    const data = {
      nutriente: this.formTable.get("nutriente")?.value,
      quantidade: this.formTable.get("quantidade")?.value,
      quantidadePorPorcao: this.formTable.get("quantidadePorPorcao")?.value,
      vd: this.formTable.get("vd")?.value
    };


    this.dialogRef.close(data);
  }

  closeModal() {
    this.dialogRef.close();
  }

}
