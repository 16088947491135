import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'leaflet';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionProcessService {

  private token: any;
  private item: any;
  storage: Storage;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};
  }

  getResponseQuestionProcess(auditingId: any, processId: any) {
    let params = new HttpParams();

    if (auditingId) {
      params = params.set('auditingId', auditingId);
    }

    if (processId) {
      params = params.set('processId', processId);
    }

    let http = {};


    http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/auditing-service/find-by-id-and-process-id`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  updateQuestion(protocolId: any, criteriaCode: any, body: any) {
    const user = sessionStorage.getItem('user');
    let customerId: string | null = user ? JSON.parse(user).customerId : null;
    let params = new HttpParams();

    if (protocolId) {
      params = params.set('protocolId', protocolId);
    }

    if (criteriaCode) {
      params = params.set('criteriaCode', criteriaCode);
    }
    let http = {};


    http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.patch(`${environment.api}/protocol-service/update-param`, body, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  deleteQuestion(protocolId: any, criteriaCode: any): any {
    let params = new HttpParams();

    if (protocolId) {
      params = params.set('protocolId', protocolId);
    }

    if (criteriaCode) {
      params = params.set('criteriaCode', criteriaCode);
    }

    const headers = new HttpHeaders({
      Authorization: this.token,
    });

    return this.http.put(
      `${environment.api}/protocol-service/remove-param-from-process`,
      {}, // Corpo da requisição vazio
      { headers, params } // Configuração com headers e parâmetros
    );
  }

  deleteProcess(protocessId: any): any {
    let params = new HttpParams();

    if (protocessId) {
      params = params.set('id', protocessId);
    }

    let http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/inactivate-protocol`, http).pipe(
      (res) => res,
      (error) => error
    );
  }


  updateProcess(body: any) {
    const user = sessionStorage.getItem('user');
    let customerId: string | null = user ? JSON.parse(user).customerId : null;
    let params = new HttpParams();
    let http = {};


    http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.put(`${environment.api}/protocol-service/update-process?creationToken=${customerId}`, body, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  getQuestionProcessById(id?: string) {
    const user = sessionStorage.getItem('user');
    let customerId: string | null = user ? JSON.parse(user).customerId : null;
    let params = new HttpParams();
    let http = {};


    if (id) {
      params = params.set('id', id);
    }

    http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/process/list/filtered/${customerId}`, http).pipe(
      (res) => res,
      (error) => error
    );

  }

  getAllProcess(options: any): Observable<any> {
    const user = sessionStorage.getItem('user');
    let customerId: string | null = user ? JSON.parse(user).customerId : null;
    let params = new HttpParams();

    if (options?.name) {
      params = params.set('name', options?.name);
    }

    if (options?.registerDate) {
      params = params.set('createdAt', options?.registerDate);
    }

    if (options?.size) {
      params = params.set('size', options?.size);
    }

    if (options?.page) {
      params = params.set('page', options?.page);
    }

    params = params.set('onlyActive', true);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/process/list/filtered/${customerId}`, httpOptions).pipe(
      catchError((error: any) => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }


  sendQuestionProcess(processId?: string, auditingId?: string, response?: any) {
    let params = new HttpParams();


    if (processId) {
      params = params.set('processId', processId);
    }

    if (auditingId) {
      params = params.set('auditingId', auditingId);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params
    };

    return this.http.post(`${environment.api}/auditing-service/link-response-to-auditing`, response, httpOptions).pipe(
      (res) => res,
      (error) => error
    );

  }

  updateQuestionProcess(processId?: string, auditingId?: string, response?: any) {
    let params = new HttpParams();


    if (processId) {
      params = params.set('processId', processId);
    }

    if (auditingId) {
      params = params.set('auditingId', auditingId);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params
    };

    return this.http.post(`${environment.api}/auditing-service/update-response`, response, httpOptions).pipe(
      (res) => res,
      (error) => error
    );

  }

  getAnswersQuestionProcess(processId?: string, auditingId?: string) {
    let params = new HttpParams();


    if (processId) {
      params = params.set('processId', processId);
    }

    if (auditingId) {
      params = params.set('auditingId', auditingId);
    }


    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params
    };

    return this.http.get(`${environment.api}/auditing-service/find-by-id-and-process-id`, httpOptions).pipe(
      (res) => res,
      (error) => error
    );

  }

}
