import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-social-audit-modal',
  templateUrl: './social-audit-modal.component.html',
  styleUrls: ['./social-audit-modal.component.scss']
})
export class SocialAuditModalComponent implements OnInit {

  type: string = '';
  form: FormGroup = this.fb.group({
    type: ['', Validators.required],
  });

  formFile: FormGroup = this.fb.group({
    uploadFile: ['', Validators.required],
  });

  uploadFiles: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SocialAuditModalComponent>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  changeSelect(event: any) {
    this.type = event.target.value;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close({ confirm: true, type: this.type });
      return;
    }
    if (this.formFile.valid) {
      this.dialogRef.close({ confirm: true, file: this.uploadFiles });
      return;
    }
  }

  closeModal() {
    this.dialogRef.close({ confirm: false });
  }

  changeFile(event: any) {
    if (event.target.files.length > 0) {
      this.uploadFiles = [];
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        if (file.name.includes(',') || file.name.includes(';')) {
          const modifiedName = file.name.replace(/[,;]/g, ' ');
          const modifiedFile = new File([file], modifiedName, { type: file.type });
          this.uploadFiles.push(modifiedFile);
        } else {
          this.uploadFiles.push(file);
        }
      }
    }
  }

}
