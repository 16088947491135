import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { XlsXService } from 'src/app/services/xlsx.service';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-solicitacoes-respondidas',
  templateUrl: './solicitacoes-respondidas.component.html',
  styleUrls: ['./solicitacoes-respondidas.component.scss']
})
export class SolicitacoesRespondidasComponent implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatSort) sort!: MatSort;
  tableData: Array<any> = [];
  loading: boolean = false;
  response: any;
  page: number = 0;
  size: number = 5;
  length: number = 0;
  form!: FormGroup;
  displayedColumns: string[] = [
    'requestNumber',
    'requestDate',
    'producerName',
    'document',
    'propertyName',
    'car',
    'sendDate',
    'status',
    'actions',
  ];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.sort = this.sort;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private router: Router,
    private solicitacoesService: SolicitacoesService,
    private fb: FormBuilder,
    private xlsxService: XlsXService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      producer: [''],
      protocol: [''],
      car: [''],
      requestNumber: [''],
      status: [''],
      initialDate: [''],
      finalDate: ['']
    });
  }

  ngOnInit(): void {
    this.getRequestAnsweredList();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getRequestAnsweredList(pageEvent?: PageEvent): void {

    this.form.markAllAsTouched();
    if (this.form.valid) {
      if (pageEvent) {
        this.page = pageEvent.pageIndex;
        this.size = pageEvent.pageSize;
      }

      const options = {
        size: this.size,
        page: this.page,
        producerPlace: this.form.get('car')?.value ? this.form.get('car')?.value : '',
        startRequestDate: this.form.get('initialDate')?.value ? this.form.get('initialDate')?.value : '',
        endRequestDate: this.form.get('finalDate')?.value ? this.form.get('finalDate')?.value : '',
        producerName: this.form.get('producer')?.value,
        requestNumber: this.form.get('requestNumber')?.value,
        status: this.form.get('status')?.value
      }

      this.loading = true;
      this.solicitacoesService.listRequestAnsweredPaginated(options).subscribe((res: any) => {
        this.response = res;
        this.page = this.response.currentPage;
        this.length = this.response.totalItems;
        this.tableData = this.response.content.map((item: any) => {
          return {
            id: item.id,
            requestNumber: item.requestNumber || '-',
            requestDate: item.requestDate || '-',
            producerName: item.producer.corporate_name || item.producer.corporateName || '-',
            document: item.producer.document,
            propertyName: item.producer.place && item.producer.place.nickname ? item.producer.place.nickname : '-',
            car: item.producer.place && item.producer.place.code ? item.producer.place.code : '-',
            sendDate: item.sendDate || '-',
            status: item.status
          }
        });
        this.tableData = this.tableData.filter(item => item.status !== 'PENDING');
        this.dataSource = new MatTableDataSource(this.tableData);

        this.dataSource.sort = this.sort;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    }
  }

  viewRequest(element: any) {
    this.router.navigate(['/safe-gis/solicitacoes/ver-solicitacao'], { queryParams: { id: element.id } });
  }

  downloadLaudo(element: any) {
    this.router.navigate(['/safe-gis/solicitacoes/laudo-monitoramento'], { queryParams: { id: element.id } });
  }

  getStatusColor(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'green',
      'POST_ANALYSIS_ENABLED': 'green',
      'NOT_ENABLED': 'yellow',
      'POST_ANALYSIS_NOT_ENABLED': 'red',
      'ANALYSIS_IN_PROGRESS': 'yellow',
      'VALIDATING': 'yellow',
      'PENDING': 'red',
    };
    return statusColors[status] || '';
  }

  getStatusName(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'Habilitado',
      'POST_ANALYSIS_ENABLED': 'Habilitado AD',
      'NOT_ENABLED': 'Em Análise AD',
      'POST_ANALYSIS_NOT_ENABLED': 'Não Habilitado AD',
      'ANALYSIS_IN_PROGRESS': 'Analisando',
      'VALIDATING': 'Validando',
      'PENDING': 'Pendente',
    };
    return statusColors[status] || '';
  }

  maskCpfCnpj(value: string): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(9)}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(0, 2)}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

  handlePaginate(event: PageEvent): void {
    this.getRequestAnsweredList(event)
  }

  exportarSolicitacoesRespondidas(): void {
    this.loading = true;
    this.xlsxService.exportQuery().subscribe({
      next: (response) => {
        this.loading = false;
        this.dialog.open(ModalConfirmComponent, {
          data: {
            success: true,
            successText: 'Consulta enviada por email',
          }
        });
      },
      error: (error) => {
        this.loading = false;
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: true,
            errorText: 'Erro ao enviar consulta',
          }
        });
      }
    });
  }

}
