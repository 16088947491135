import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostalCodeService {

  constructor(private http: HttpClient) { }

  getAddresByPostalCode(postalCode: string) {
    const postalCodeFormated = postalCode.replace(".","").replace("-","");
    return this.http.get(`https://viacep.com.br/ws/${postalCodeFormated}/json/`,).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }
}
