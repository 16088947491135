<div class="overlay_map">
  <div class="body_modal">
    <ng-container *ngIf="this.data.block">
      <svg xmlns="http://www.w3.org/2000/svg" width="101" height="100" viewBox="0 0 101 100" fill="none">
        <rect x="0.5" width="100" height="100" rx="50" fill="#F43841"/>
        <path d="M56.8397 20L55.4946 61.2092H45.712L44.3261 20H56.8397ZM44 73.9674C44 72.2283 44.5978 70.788 45.7935 69.6467C47.0163 68.4783 48.6196 67.894 50.6033 67.894C52.6141 67.894 54.2174 68.4783 55.413 69.6467C56.6087 70.788 57.2065 72.2283 57.2065 73.9674C57.2065 75.6522 56.6087 77.0788 55.413 78.2473C54.2174 79.4158 52.6141 80 50.6033 80C48.6196 80 47.0163 79.4158 45.7935 78.2473C44.5978 77.0788 44 75.6522 44 73.9674Z" fill="white"/>
      </svg>
    </ng-container>
    <ng-container *ngIf="this.data.alert">
      <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="100" height="100" rx="50" fill="#FFAA00"/>
        <path d="M56.8397 20L55.4946 61.2092H45.712L44.3261 20H56.8397ZM44 73.9674C44 72.2283 44.5978 70.788 45.7935 69.6467C47.0163 68.4783 48.6196 67.894 50.6033 67.894C52.6141 67.894 54.2174 68.4783 55.413 69.6467C56.6087 70.788 57.2065 72.2283 57.2065 73.9674C57.2065 75.6522 56.6087 77.0788 55.413 78.2473C54.2174 79.4158 52.6141 80 50.6033 80C48.6196 80 47.0163 79.4158 45.7935 78.2473C44.5978 77.0788 44 75.6522 44 73.9674Z" fill="white"/>
        </svg>
        </ng-container>
    <h2>{{data.block ? 'Alerta de Bloqueio' : 'Alerta!'}}</h2>
    <p>{{data ? data.message : 'Ao selecionar essa opção, você será bloqueado de continuar o processo, deseja mesmo continuar?'}}</p>
  </div>

  <div class="buttons">
      <button class="button_cancel" (click)="closeModal()">
          <h1>Fechar</h1>
      </button>
  </div>
</div>
