<div class="form-group">
  <app-select-input
    #inputProduct
    label="Produto"
    [required]="required"
    [disabled]="disabled"
    (changeValue)="changeProductValue($event)"
    [loadMoreButton]="productsList.length < totalItems"
    (loadMore)="loadMoreProductOptionsList($event)"
    [list]="productOptionsList"
    (getSearchOptions)="getProductSearchOptions($event)"
  ></app-select-input>
</div>

<app-spinner *ngIf="loading"></app-spinner>
