<div class="header-area">
  <h1>Criador de Formulário</h1>
  <button class="button back" (click)="handleClickBack()">
    Voltar
  </button>
</div>
<hr />
<form>
  <div style="display: flex; flex-direction: row; gap: 12px; align-items: center;">
    <label for="form-title">Título do Formulário:</label>
    <input type="text" id="form-title" placeholder="Digite o título do formulário" name="title" [(ngModel)]="formTitle" style="flex-grow: 1;">
  </div>

  <div id="questions-container">
    <app-question
      *ngFor="let question of questions; let i = index"
      [question]="question"
      (remove)="removeQuestion(i)"
    ></app-question>
  </div>

  <div class="buttons-area">
    <button type="button" class="button" (click)="addQuestion()">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 16.75V9.25M8.75 9.25V1.75M8.75 9.25H16.25M8.75 9.25H1.25" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
      <span>Adicionar Pergunta</span>
    </button>
    <button type="button" class="button" (click)="generateJson()">Salvar Formulário</button>
  </div>
</form>

<app-spinner *ngIf="loading"></app-spinner>
