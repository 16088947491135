<!-- modal-content.component.html -->
<div class="my-custom-modal">
    <div class="header-modal">
        <p>Formulações - Insumo</p>
    </div>
    <div class="body-modal">
        <p class="msg-error">Preencha todos os dados antes de continuar!</p>

        <form [formGroup]="formTable" class="body-modal">
            <div class="form-area" style="margin-left:-10px;">
                <div style="width:380px;" class="form-group">
                    <app-select-input #inputProduct label="Produto" [required]="false"
                        (changeValue)="changeProductValue($event)" [list]="productOptionsList"
                        (getSearchOptions)="getProductSearchOptions($event)"></app-select-input>
                </div>
            </div>

            <div class="select" style="margin-left:-40px;margin-top:20px" tabindex="-1">
                <select style="width:380px;" formControlName="unidade" type="text" class="form-input select"
                    name="unidade" id="unidade">
                    <option>kg</option>
                    <option>Un</option>
                    <option>Cx</option>
                    <option>PC</option>
                    <option>GN</option>
                    <option>SC</option>
                    <option>BJ</option>
                </select>

                <label class="form-label" for="unidade" [class]="{ focus: formTable.get('unidade')?.value.length > 0 }">
                    Unidade
                </label>

            </div>

            <div style="margin-left:-40px" class="form-group" tabindex="-1">
                <input style="width:350px;" type="text" formControlName="quantidade" class="form-input" id="solicitante"
                    name="solicitante" />
                <label class="form-label"
                    [ngClass]="{ focus : (formTable.get('quantidade')?.value && formTable.get('quantidade')?.value.length > 0) }"
                    for="solicitante">
                    Quantidade
                </label>
            </div>
        </form>

    </div>

    <div class="buttons">
        <button [disabled]="!formTable.valid" class="button-export" id="first-button-export" (click)="confirmModal()">
            <h1 style="font-weight: 700;font-family: 'Roboto';font-style: normal;">Confirmar</h1>
        </button>

        <button class="button-clean" (click)="closeModal()">
            <h1>Cancelar</h1>
        </button>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>