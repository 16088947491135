import { Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FornecedoresService } from 'src/app/services/fornecedores.service';
import { NivelServicoService } from 'src/app/services/nivel-servico.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { ProviderSelectFilterComponent } from '../provider-select-filter/provider-select-filter.component';
import { ProductSelectFilterComponent } from '../product-select-filter/product-select-filter.component';
import { dateValidator } from 'src/app/validators/dateValidator';
import { dateNotBeforeValidator } from 'src/app/validators/dataBeforeValidator';
import { dateNotBefore } from 'src/app/validators/dateNotBeforeValidator';

@Component({
  selector: 'app-see-datas-level-service',
  templateUrl: './see-datas-level-service.component.html',
  styleUrls: ['./see-datas-level-service.component.scss'],
})
export class SeeDatasLevelServiceComponent implements OnInit {
  @ViewChild('inputProvider') inputProvider!: ProviderSelectFilterComponent;
  @ViewChild('inputProduct') inputProduct!: ProductSelectFilterComponent;

  private storage!: Storage;
  providerList: Array<any> = [];
  dataSource = new MatTableDataSource<any>([]);
  classProduct: string | null = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'receiptDate',
    'cd',
    'providerName',
    'document',
    'product',
    'plu',
    'invoiceNumber',
    'amountOrdered',
    'amountDelivered',
    'status',
    'efficiency',
  ];
  loading: boolean = false;

  tableActive: boolean = false;
  type!: string;
  tableData: Array<any> = [];

  form: FormGroup = this.fb.group({
    startCompletionDate: [
      '',
      [
        Validators.required,
        dateValidator,
        dateNotBeforeValidator(new Date(2020, 0, 1)),
      ],
    ],
    endCompletionDate: [
      '',
      [Validators.required, dateValidator, dateNotBefore('startDate')],
    ],
    providerDocument: [''],
    productHash: [''],
  });

  maxCompletionDate: any;
  minCompletionDate: any;

  pageIndex: number = 0;
  pageSize: number = 15;
  length: number = 0;

  protected isUserProvider: boolean = false;
  protected user: any;
  protected document: string = '';

  constructor(
    private fb: FormBuilder,
    private fornecedoresService: FornecedoresService,
    private nivelServicoService: NivelServicoService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    const maxDate = new Date();
    this.maxCompletionDate = maxDate.setDate(maxDate.getDate());
    this.storage = window.sessionStorage;
    this.storage = window.sessionStorage;
  }

  ngOnInit(): void {
    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if (
      this.user.authorities.some((item: string) => item === 'ROLE_PROVIDER')
    ) {
      this.isUserProvider = true;
    }
  }

  getProviderList(event: any) {
    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};
    if (user.customerId) {
      this.loading = true;
      this.fornecedoresService.listarFornecedores(user.customerId).subscribe(
        (res: any) => {
          if (res.length > 0 && res[0].outsourced) {
            this.providerList = res[0].outsourced;
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  }

  onChange() {
    Object.keys(this.form.controls).forEach((e) => {
      this.form.controls[e].valueChanges
        .pipe(
          debounceTime(500), // Waiting for 1 sec while you are typing
          distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
        )
        .subscribe((ea) => {
          let values;
          this.form.updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
          values = this.form.value;
          for (let value in values) {
            if (values[value] == '') {
              delete values[value];
            }
          }

          if (Object.keys(values).length > 0) {
            this.tableActive = true;
          } else {
            this.tableActive = false;
          }
        });
    });
  }

  submitForm() {
    this.pageIndex = 0;
    this.pageSize = 15;
    this.form.markAllAsTouched();

    this.classProduct = '';
    this.classProduct = localStorage.getItem('classProduct');

    const filter = {
      startCompletionDate: this.form.get('startCompletionDate')?.value,
      endCompletionDate: this.form.get('endCompletionDate')?.value,
      productType: this.classProduct,
      providerDocument: this.form.get('providerDocument')?.value
        ? this.form.get('providerDocument')?.value
        : '',
      productHash: this.form.get('productHash')?.value
        ? this.form.get('productHash')?.value
        : '',
      type: '',
      isUserProvider: false,
      page: this.pageIndex,
      size: this.pageSize,
    };

    if (this.isUserProvider) {
      const customerId = this.user.customerId;

      let listMovements: Array<any>;
      this.document = atob(customerId);

      const filter = {
        startCompletionDate: this.form.get('startCompletionDate')?.value,
        endCompletionDate: this.form.get('endCompletionDate')?.value,
        productType: this.classProduct,
        providerDocument: '',
        productHash: this.form.get('productHash')?.value,
        isUserProvider: true,
        page: this.pageIndex,
        size: this.pageSize,
      };
      this.loading = true;
      this.nivelServicoService.listarNivelServico(filter).subscribe(
        (res) => {
          let providerCompany = res.providerCompany;

          // if (providerCompany == undefined) {
          //   return;
          // }

          res.content.map((item: any) => {
            item.providerCompany = providerCompany;
          });

          listMovements = res.content;
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;

          if (listMovements) this.tableData = listMovements;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        },
        (error) => {
          listMovements = [];
          this.tableData = listMovements;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        }
      );

      return;
    } else {
      if (this.form.valid) {
        this.loading = true;
        this.nivelServicoService.listarNivelServico(filter).subscribe(
          (res) => {
            this.loading = false;
            if (res.content) this.tableData = res.content;
            if (this.tableData && this.tableData.length <= 0) {
              this._snackBar.open(
                'Nenhum registro encontrado para o filtro solicitado',
                undefined,
                {
                  duration: 2 * 1000,
                  panelClass: ['alert_error'],
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                }
              );
            }
            this.pageIndex = res.currentPage;
            this.length = res.totalItems;
            this.dataSource = new MatTableDataSource(this.tableData);
          },
          (error) => {
            this.loading = false;
            this.tableData = [];
            this.dataSource = new MatTableDataSource(this.tableData);
          }
        );
      } else {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: true,
            errorText: 'Preencha os filtros',
          },
        });
      }
    }
  }

  paginate(pageEvent: PageEvent): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.loading = true;

    this.classProduct = localStorage.getItem('classProduct');
    const filter = {
      startCompletionDate: this.form.get('startCompletionDate')?.value,
      endCompletionDate: this.form.get('endCompletionDate')?.value,
      productType: this.classProduct,
      providerDocument: this.form.get('providerDocument')?.value,
      productHash: this.form.get('productHash')?.value,
      type: '',
      isUserProvider: false,
      page: this.pageIndex,
      size: this.pageSize,
    };

    if (this.isUserProvider) {
      let listMovements: Array<any>;

      const filter = {
        startCompletionDate: this.form.get('startCompletionDate')?.value,
        endCompletionDate: this.form.get('endCompletionDate')?.value,
        productType: this.classProduct,
        productHash: this.form.get('productHash')?.value,
        isUserProvider: true,
        page: this.pageIndex,
        size: this.pageSize,
      };
      this.nivelServicoService.listarNivelServico(filter).subscribe({
        next: (res) => {
          this.loading = false;

          listMovements = res.content;

          if (listMovements) this.tableData = listMovements;
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;
          this.dataSource = new MatTableDataSource(this.tableData);
        },
        error: (error) => {
          this.loading = false;
          listMovements = [];
          this.tableData = listMovements;
          this.dataSource = new MatTableDataSource(this.tableData);
        },
      });

      return;
    } else {
      if (this.form.valid) {
        this.loading = true;
        this.nivelServicoService.listarNivelServico(filter).subscribe(
          (res) => {
            this.loading = false;
            if (res.content) this.tableData = res.content;
            this.pageIndex = res.currentPage;
            this.length = res.totalItems;
            this.dataSource = new MatTableDataSource(this.tableData);
          },
          (error) => {
            this.loading = false;
            this.tableData = [];
            this.dataSource = new MatTableDataSource(this.tableData);
          }
        );
      } else {
        if (this.form.valid) {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: true,
              errorText: 'Preencha o Fornecedor ou o Produto',
            },
          });
        } else {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: true,
              errorText: 'Preencha os filtros',
            },
          });
        }
      }
    }
  }

  checkStartCompletionDate() {
    const completionDate = this.form.get('startCompletionDate')?.value;
    const minDate = new Date(completionDate);
    this.minCompletionDate = minDate.setDate(minDate.getDate() + 1);
    this.form.get('endCompletionDate')?.setValue('');
  }

  exportToExcel(json: any[], fileName: string): void {
    if (json.length > 0) {
      this.form.markAllAsTouched();

      this.classProduct = '';
      this.classProduct = localStorage.getItem('classProduct');

      const filter = {
        startCompletionDate: this.form.get('startCompletionDate')?.value,
        endCompletionDate: this.form.get('endCompletionDate')?.value,
        productType: this.classProduct,
        providerDocument: this.form.get('providerDocument')?.value
          ? this.form.get('providerDocument')?.value
          : '',
        productHash: this.form.get('productHash')?.value
          ? this.form.get('productHash')?.value
          : '',
        type: '',
        isUserProvider: false,
        page: this.pageIndex,
        size: this.pageSize,
      };

      if (this.isUserProvider) {
        const customerId = this.user.customerId;

        let listMovements: Array<any>;
        this.document = atob(customerId);

        const filter = {
          startCompletionDate: this.form.get('startCompletionDate')?.value,
          endCompletionDate: this.form.get('endCompletionDate')?.value,
          productType: this.classProduct,
          providerDocument: this.document,
          productHash: this.form.get('productHash')?.value,
          isUserProvider: true,
          page: this.pageIndex,
          size: this.pageSize,
        };

        this.nivelServicoService.exportNivelServico(filter).subscribe(
          (res) => {
            let response = res;
            this.dialog.open(ModalConfirmComponent, {
              data: {
                success: true,
                successText: response,
              },
            });
            this.loading = false;
          },
          (error) => {
            this.dialog.open(ModalConfirmComponent, {
              data: {
                error: true,
                errorText: 'Falha ao gerar arquivo para exportação!',
              },
            });
            this.loading = false;
          }
        );

        return;
      } else {
        if (this.form.valid) {
          this.loading = true;
          this.nivelServicoService.exportNivelServico(filter).subscribe(
            (res) => {
              let response = res;
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  success: true,
                  successText: response,
                },
              });
              this.loading = false;
            },
            (error) => {
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  error: true,
                  errorText: 'Falha ao gerar arquivo para exportação!',
                },
              });
              this.loading = false;
            }
          );
        } else {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: true,
              errorText: 'Preencha os filtros',
            },
          });
        }
      }
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  cleanFilters() {
    this.form.get('startCompletionDate')?.setValue('');
    this.form.get('endCompletionDate')?.setValue('');
    this.form.get('providerDocument')?.setValue('');
    this.form.get('productHash')?.setValue('');

    this.inputProvider.cleanFilter();
    this.inputProduct.cleanFilter();

    this.tableData = [];
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  changeProductValue(event: any) {
    this.form.get('productHash')?.setValue(event);
  }

  changeProviderValue(event: any) {
    this.form.get('providerDocument')?.setValue(event);
  }

  maskCpfCnpj(value: string): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(
        9
      )}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(
        0,
        2
      )}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

  public formatCpfCnpj(item: string) {
    return formatCpfCnpj(item);
  }
}
