import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';

@Component({
  selector: 'app-pendentes-informacao',
  templateUrl: './pendentes-informacao.component.html',
  styleUrls: ['./pendentes-informacao.component.scss']
})
export class PendentesInformacaoComponent implements OnInit {
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  tableData: Array<any> = [];
  loading: boolean = false;

  displayedColumns: string[] = [
    'requestNumber',
    'requestDate',
    'producerName',
    'document',
    'propertyName',
    'car',
    'sendDate',
    'status',
    'actions',
  ];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private router: Router,
    private solicitacoesService: SolicitacoesService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.solicitacoesService.listPendingRequest().subscribe((res:any) => {
      let response = res.content as Array<any>;
      this.tableData = response.map(item => {
        return {
          id: item.id,
          requestNumber: item.requestNumber || '-',
          requestDate: item.requestDate || '-',
          producerName: item.producer.corporate_name || item.producer.corporateName || '-',
          document: item.producer.document,
          propertyName: item.producer.place && item.producer.place.nickname ? item.producer.place.nickname : '-',
          car: item.producer.place && item.producer.place.code ? item.producer.place.code : '-',
          sendDate: item.sendDate || '-',
          status: item.status,
          response: item.response ,
        }
      });
      this.tableData = this.tableData.filter(item => item.status === 'PENDING');
      this.dataSource = new MatTableDataSource(this.tableData);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  viewRequest(element: any) {
    if (element.response) {
      this.router.navigate(['/safe-gis/solicitacoes/ver-solicitacao'], { queryParams: { id: element.id, analiseDetalhada: true } });
    } else {
      this.router.navigate(['/safe-gis/solicitacoes/solicitacao-info'], { queryParams: { id: element.id } });
    }
  }

  getStatusColor(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'green',
      'POST_ANALYSIS_ENABLED': 'green',
      'NOT_ENABLED': 'yellow',
      'POST_ANALYSIS_NOT_ENABLED': 'red',
      'ANALYSIS_IN_PROGRESS': 'yellow',
      'VALIDATING': 'yellow',
      'PENDING': 'red',
    };
    return statusColors[status] || '';
  }

  getStatusName(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'Habilitado',
      'POST_ANALYSIS_ENABLED': 'Habilitado AD',
      'NOT_ENABLED': 'Em Análise AD',
      'POST_ANALYSIS_NOT_ENABLED': 'Não Habilitado AD',
      'ANALYSIS_IN_PROGRESS': 'Analisando',
      'VALIDATING': 'Validando',
      'PENDING': 'Pendente',
    };
    return statusColors[status] || '';
  }

  downloadLaudo(element: any) {
    this.router.navigate(['/safe-gis/solicitacoes/laudo-monitoramento'], { queryParams: { id: element.id } });
  }

  maskCpfCnpj(value: string): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(9)}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(0, 2)}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

}
