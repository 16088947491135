<div class="content">

  <a [routerLink]="['/auditoria']" routerLinkActive="active" class="item" *ngIf="!isCarrefour">
    <span class="icon">
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M46.875 44.6875L42.5 40.3125C43.2813 39.0625 43.75 37.5 43.75 35.9375C43.75 31.5625 40.3125 28.125 35.9375 28.125C31.5625 28.125 28.125 31.5625 28.125 35.9375C28.125 40.3125 31.5625 43.75 35.9375 43.75C37.5 43.75 39.0625 43.2813 40.3125 42.5L44.6875 46.875L46.875 44.6875ZM31.25 35.9375C31.25 33.2812 33.2812 31.25 35.9375 31.25C38.5938 31.25 40.625 33.2812 40.625 35.9375C40.625 38.5938 38.5938 40.625 35.9375 40.625C33.2812 40.625 31.25 38.5938 31.25 35.9375ZM12.5 25H28.125V28.125H12.5V25ZM12.5 15.625H31.25V18.75H12.5V15.625Z"
          fill="white" />
        <path
          d="M21.875 43.2812L13.75 38.9062C9.0625 36.5625 6.25 31.7188 6.25 26.5625V6.25H37.5V23.4375H40.625V6.25C40.625 4.53125 39.2188 3.125 37.5 3.125H6.25C4.53125 3.125 3.125 4.53125 3.125 6.25V26.5625C3.125 32.9688 6.5625 38.75 12.1875 41.7188L21.875 46.875V43.2812Z"
          fill="white" />
      </svg>
    </span>
    <span class="text">Auditorias</span></a>

  <a [routerLink]="['/analises/flv']" class="item" *ngIf="(!isCarrefour && !isAuditing) || isQuality">
    <span class="icon">
      <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.9165 2H2.08301V14.25H42.9165V2Z" stroke="white" stroke-width="3" stroke-linejoin="round" />
        <path d="M2.08301 38.7808L14.5127 26.2245L21.2288 32.6558L29.4394 24.4585L34.0128 28.9175" stroke="white"
          stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M42.9165 13.4048V39.9466M2.08301 13.4048V27.6965M11.2859 40.7918H42.9165M15.3539 8.12501H36.7915M8.20803 8.12195H9.22887"
          stroke="white" stroke-width="3" stroke-linecap="round" />
      </svg>
    </span>
    <span class="text">Análises</span></a>

  <a [routerLink]="[ isCarrefour ? '/rastreabilidade/bovinos' : '/rastreabilidade']" routerLinkActive="active" class="item" *ngIf="!isAuditing">
    <span class="icon">
      <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5 2C10.6961 2 1.9375 10.7586 1.9375 21.5625C1.9375 32.3664 10.6961 41.125 21.5 41.125C32.3039 41.125 41.0625 32.3664 41.0625 21.5625"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M21.5 11.7812C19.5655 11.7813 17.6744 12.3549 16.0658 13.4297C14.4573 14.5045 13.2036 16.0321 12.4633 17.8194C11.723 19.6067 11.5293 21.5734 11.9067 23.4707C12.2841 25.3681 13.2157 27.111 14.5836 28.4789C15.9515 29.8468 17.6944 30.7784 19.5918 31.1558C21.4892 31.5332 23.4558 31.3395 25.2431 30.5992C27.0304 29.8589 28.558 28.6052 29.6328 26.9967C30.7076 25.3882 31.2813 23.497 31.2813 21.5625M21.5 21.5625L28.3469 14.7091"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M28.3467 7.89701V14.7156H35.2207L41.0623 8.84688H34.2187V2L28.3467 7.89701Z" stroke="white"
          stroke-width="3" stroke-linejoin="round" />
      </svg>
    </span>
    <span class="text">Rastreabilidade</span></a>

  <a [routerLink]="[isCarrefour ? '/inspecoes/carrefour/bovinos' : '/inspecoes']" class="item" *ngIf="!isCarrefour && !isAuditing" >
    <span class="icon">
      <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M42.5 32.2105V17.1052H1.5V40.8421C1.5 41.4144 1.72735 41.9632 2.13203 42.3679C2.53672 42.7726 3.08559 43 3.65789 43H22"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M1.5 7.39471C1.5 6.8224 1.72735 6.27353 2.13203 5.86885C2.53672 5.46417 3.08559 5.23682 3.65789 5.23682H40.3421C40.9144 5.23682 41.4633 5.46417 41.868 5.86885C42.2727 6.27353 42.5 6.8224 42.5 7.39471V17.1052H1.5V7.39471Z"
          stroke="white" stroke-width="3" stroke-linejoin="round" />
        <path d="M13.3682 2V10.6316M30.6313 2V10.6316" stroke="white" stroke-width="3" stroke-linecap="round" />
        <path
          d="M28.474 38.6842C32.6452 38.6842 36.0267 35.3028 36.0267 31.1316C36.0267 26.9604 32.6452 23.579 28.474 23.579C24.3028 23.579 20.9214 26.9604 20.9214 31.1316C20.9214 35.3028 24.3028 38.6842 28.474 38.6842Z"
          stroke="white" stroke-width="3" />
        <path d="M34.9473 36.5264L41.421 41.9211" stroke="white" stroke-width="3" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </span>
    <span class="text">Inspeções</span></a>

  <a [routerLink]="['/nivel-servico']" class="item" *ngIf="!isCarrefour && !isAuditing">
    <span class="icon">
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.423 21.2036H12.1086V1.76717C12.1086 -0.589057 8.4581 -0.589057 8.4581 1.76717V21.2036H3.143C1.4308 21.2036 0 22.8262 0 24.7702C0 26.7155 1.4301 28.3388 3.143 28.3388H8.4581V40.2334C8.4581 42.5889 12.1086 42.5889 12.1086 40.2334V28.3388H17.423C19.1373 28.3388 20.5667 26.7162 20.5667 24.7702C20.5667 22.8255 19.1373 21.2036 17.423 21.2036ZM33.4173 37.7028H24.7604C22.0675 37.7028 22.0675 41.9995 24.7604 41.9995H33.4173C36.1109 41.9995 36.1109 37.7028 33.4173 37.7028ZM34.7298 30.1623H24.7604C22.0675 30.1623 22.0675 34.4597 24.7604 34.4597H34.7298C37.4234 34.4597 37.4234 30.1623 34.7298 30.1623ZM36.0423 22.6218H24.7604C22.0675 22.6218 22.0675 26.9185 24.7604 26.9185H36.0423C38.7359 26.9185 38.7359 22.6218 36.0423 22.6218ZM37.3548 15.0813H24.7604C22.0675 15.0813 22.0675 19.378 24.7604 19.378H37.3548C40.0484 19.378 40.0484 15.0813 37.3548 15.0813ZM37.3548 17.978H24.7604C24.2214 17.978 24.1409 17.509 24.1409 17.2304C24.1409 16.9511 24.2214 16.4821 24.7604 16.4821H37.3548C37.8945 16.4821 37.9743 16.9511 37.9743 17.2304C37.9743 17.509 37.8945 17.978 37.3548 17.978ZM38.6673 7.54154H24.7604C22.0675 7.54154 22.0675 11.8382 24.7604 11.8382H38.6673C41.3609 11.8382 41.3609 7.54154 38.6673 7.54154ZM38.6673 10.4382H24.7604C24.2214 10.4382 24.1409 9.96917 24.1409 9.69057C24.1409 9.41127 24.2214 8.94226 24.7604 8.94226H38.6673C39.207 8.94226 39.2868 9.41127 39.2868 9.69057C39.2868 9.96917 39.207 10.4382 38.6673 10.4382ZM39.9798 0.000349896H24.7604C22.0675 0.000349896 22.0675 4.297 24.7604 4.297H39.9798C42.6734 4.297 42.6734 0.000349896 39.9798 0.000349896ZM39.9798 2.89699H24.7604C24.2214 2.89699 24.1409 2.42798 24.1409 2.14868C24.1409 1.87007 24.2214 1.40107 24.7604 1.40107H39.9798C40.5195 1.40107 40.5993 1.87007 40.5993 2.14868C40.5993 2.42798 40.5195 2.89699 39.9798 2.89699Z"
          fill="white" />
      </svg>
    </span>
    <span class="text">Nível de Serviço</span></a>

  <a [routerLink]="['/sustentabilidade']" class="item" *ngIf="!isCarrefour && !isAuditing">
    <span class="icon">
      <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M46.9329 5.03571C46.9772 4.47729 46.9996 3.91733 47 3.35714V0H41.125C36.7665 0.000907272 32.5258 1.41478 29.0388 4.02958C25.5518 6.64438 23.0064 10.3192 21.7845 14.5029C19.9033 12.0762 17.4913 10.1125 14.7335 8.76245C11.9758 7.41237 8.94549 6.71175 5.875 6.71429H0V10.0714C0 15.4136 2.12219 20.5371 5.89971 24.3146C9.67723 28.0921 14.8006 30.2143 20.1429 30.2143H20.9821V44.4821C20.9821 45.1499 21.2474 45.7903 21.7196 46.2625C22.1918 46.7347 22.8322 47 23.5 47C24.1678 47 24.8082 46.7347 25.2804 46.2625C25.7526 45.7903 26.0179 45.1499 26.0179 44.4821V23.5H26.8571C31.9093 23.5007 36.7772 21.6029 40.4958 18.1829C44.2143 14.7628 46.5118 10.0703 46.9329 5.03571ZM26.1085 18.4643C26.5214 14.7708 28.2814 11.3591 31.0518 8.88169C33.8221 6.40428 37.4085 5.035 41.125 5.03571H41.8736C41.4607 8.72922 39.7008 12.1409 36.9304 14.6183C34.16 17.0957 30.5737 18.465 26.8571 18.4643H26.1085ZM5.875 11.75C9.59152 11.7493 13.1779 13.1186 15.9482 15.596C18.7186 18.0734 20.4786 21.4851 20.8915 25.1786H20.1429C16.4263 25.1793 12.84 23.81 10.0696 21.3326C7.29924 18.8552 5.5393 15.4435 5.12636 11.75H5.875Z"
          fill="white" />
      </svg>
    </span>
    <span class="text">Sustentabilidade</span></a>
</div>
