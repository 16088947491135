<div class="geomonitoring-content">
  <div class="row-buttons">
    <button class="button-export" id="second-button-export" style="margin-right: 8px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
            d="M20.4504 6.42891V6.38683L20.4203 6.35741L14.4096 0.479286L14.3804 0.450781H14.3397H6.99325C6.25893 0.450781 5.55425 0.736032 5.03434 1.24447C4.51436 1.75298 4.22182 2.44315 4.22182 3.16328V14.9195V15.0195H4.32182H5.65753H5.75753V14.9195V3.16328C5.75753 2.84409 5.88717 2.53751 6.11867 2.31112C6.35025 2.08465 6.66481 1.95703 6.99325 1.95703H14.2397V4.46953C14.2397 5.01644 14.4619 5.54049 14.8566 5.92651C15.2513 6.31247 15.7861 6.52891 16.3432 6.52891H18.9147V18.8383C18.9147 19.1575 18.785 19.4641 18.5535 19.6904C18.322 19.9169 18.0074 20.0445 17.679 20.0445H17.579V20.1445V21.4508V21.5508H17.679C18.4133 21.5508 19.118 21.2655 19.6379 20.7571C20.1578 20.2486 20.4504 19.5584 20.4504 18.8383V6.42891ZM6.46145 16.0298V15.9298H6.36145H5.16866H5.1113L5.08234 15.9794L4.04499 17.7531L2.99605 15.979L2.96702 15.9298H2.90997H1.66642H1.48481L1.58191 16.0833L3.18713 18.6203L1.56571 21.2017L1.46949 21.3548H1.65039H2.80044H2.85627L2.88557 21.3073L3.96307 19.5593L5.0367 21.3072L5.06598 21.3548H5.12191H6.33474H6.51804L6.41883 21.2007L4.77883 18.653L6.44533 16.0856L6.46145 16.0608V16.0312V16.0298ZM7.63573 21.3535H7.73573V21.2535V18.1314L8.86692 20.6402L8.89348 20.6991H8.95808H9.64731H9.7121L9.73858 20.64L10.8617 18.1322V21.2535V21.3535H10.9617H11.9167H12.0167V21.2535V16.0298V15.9298H11.9167H10.8481H10.7834L10.7569 15.9889L9.30868 19.214L7.85917 15.9889L7.83265 15.9298H7.76796H6.69138H6.59138V16.0298V21.2535V21.3535H6.69138H7.63439H7.63573ZM16.2923 20.3731V20.2731H16.1923H14.0269V16.0298V15.9298H13.9269H12.8704H12.7704V16.0298V21.2535V21.3535H12.8704H16.1923H16.2923V21.2535V20.3731Z"
            fill="white" stroke="white" stroke-width="0.2" />
      </svg>
      <p>Exportar</p>
    </button>
    <button class="button-export" >
      <svg width="28" height="28" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.053 3.68361H12.6706V2.17969L2.17969 3.79891V17.992L12.6706 19.7989V17.5698H19.053C19.2463 17.5796 19.4356 17.5125 19.5795 17.3831C19.7234 17.2537 19.8102 17.0725 19.821 16.8793V4.37351C19.8101 4.18038 19.7232 3.99941 19.5793 3.87014C19.4354 3.74087 19.2462 3.67381 19.053 3.68361ZM19.1538 16.994H12.6492L12.6385 15.8038H14.2054V14.4177H12.6265L12.6189 13.5986H14.2054V12.2125H12.607L12.5994 11.3935H14.2054V10.0074H12.5944V9.18832H14.2054V7.80222H12.5944V6.98316H14.2054V5.59706H12.5944V4.33697H19.1538V16.994Z"
              fill="white" />
          <path
              d="M15.0879 5.59375H17.8116V6.97985H15.0879V5.59375ZM15.0879 7.79954H17.8116V9.18565H15.0879V7.79954ZM15.0879 10.0053H17.8116V11.3914H15.0879V10.0053ZM15.0879 12.2111H17.8116V13.5972H15.0879V12.2111ZM15.0879 14.4169H17.8116V15.803H15.0879V14.4169Z"
              fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.91866 7.6342L6.27074 7.55671L7.12068 9.89355L8.12497 7.46031L9.47705 7.38281L7.83515 10.7006L9.47705 14.0267L8.04747 13.9303L7.08224 11.3949L6.11638 13.8339L4.80273 13.7179L6.32871 10.78L4.91866 7.6342Z"
              fill="#004877" />
      </svg>
      <p>Exportar</p>
    </button>
  </div>

  <form [formGroup]="form" >
    <div class="form-group">
      <input type="date" class="form-input" id="start" name="start"
        formControlName="start" />
      <label class="form-label" for="start"
        [ngClass]="{ focus: form.get('start')?.value }">
        Periodo de tempo: Início
      </label>
    </div>
    <div class="form-group">
      <input type="date" class="form-input" id="finish" name="finish"
        formControlName="finish" />
      <label class="form-label" for="finish"
        [ngClass]="{ focus: form.get('finish')?.value }">
        Periodo de tempo: Final
      </label>
    </div>
    <div class="form-group">
      <input type="text" class="form-input" id="sif" name="sif"
        formControlName="sif" />
      <label class="form-label" for="sif"
        [ngClass]="{ focus: form.get('sif')?.value }">
        SIF
      </label>
    </div>
    <div class="form-group">
      <input type="text" class="form-input" id="sicar" name="sicar"
        formControlName="sicar" />
      <label class="form-label" for="sicar"
        [ngClass]="{ focus: form.get('sicar')?.value }">
        SICAR
      </label>
    </div>
    <div class="form-group">
      <input type="text" class="form-input" id="document" name="document"
        formControlName="document" mask="000.000.000-00||00.000.000/0000-00" />
      <label class="form-label" for="document" [ngClass]="{ focus: form.get('document')?.value }">
        CPF/CNPJ
      </label>
    </div>
    <div class="form-group">
      <input type="text" class="form-input" id="provider_name" name="provider_name"
        formControlName="provider_name" />
      <label class="form-label" for="provider_name"
        [ngClass]="{ focus: form.get('provider_name')?.value }">
        Nome do Fornecedor
      </label>
    </div>
  </form>

  <div class="table-area" [ngStyle]="{ display: true ? 'inherit' : 'none' }">
    <mat-form-field appearance="standard" id="filtro">
      <mat-label>Pesquisar...</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Documento, Nome, Data..." #input>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="provider_code">
        <th mat-header-cell *matHeaderCellDef> Código do Fornecedor </th>
        <td mat-cell *matCellDef="let element"> {{ element.provider_company.enterprise_code}} </td>
      </ng-container>
      <ng-container matColumnDef="provider_name">
        <th mat-header-cell *matHeaderCellDef> Nome do Fornecedor </th>
        <td mat-cell *matCellDef="let element"> {{ 0 }} </td>
      </ng-container>
      <ng-container matColumnDef="sif">
        <th mat-header-cell *matHeaderCellDef> SIF </th>
        <td mat-cell *matCellDef="let element"> {{ 0 }} </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Endereço </th>
        <td mat-cell *matCellDef="let element"> {{ element.provider_company && element.provider_company.corporate_name ?
          element.provider_company.corporate_name : 'Erro'}} </td>
      </ng-container>
      <ng-container matColumnDef="outsourced">
        <th mat-header-cell *matHeaderCellDef> Terceiros </th>
        <td mat-cell *matCellDef="let element"> {{ 0 }} </td>
      </ng-container>

      <ng-container matColumnDef="local">
        <th mat-header-cell *matHeaderCellDef> Local de Entrega </th>
        <td mat-cell *matCellDef="let element"> {{ element.auditingDate | date:'dd/MM/yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="memr">
        <th mat-header-cell *matHeaderCellDef> ME/MR </th>
        <td mat-cell *matCellDef="let element"> {{ (element.expiration | date:'dd/MM/yyyy') || '-' }} </td>
      </ng-container>

      <ng-container matColumnDef="geolocation">
        <th mat-header-cell *matHeaderCellDef> Geolocalização </th>
        <td mat-cell *matCellDef="let element"> {{ element.score }} </td>
      </ng-container>

      <ng-container matColumnDef="coord">
        <th mat-header-cell *matHeaderCellDef> Coordenadas Espaciais </th>
        <td mat-cell *matCellDef="let element"> {{ element.result }} </td>
      </ng-container>

      <ng-container matColumnDef="analysis">
        <th mat-header-cell *matHeaderCellDef> Análise do Geomonitoramento do Fornecedor </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
          <span class="status">
            {{ element.status ? element.status : 'Sem Status' }}
          </span>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="double_check">
        <th mat-header-cell *matHeaderCellDef> Duplo Check </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
          <span class="status">
            {{ element.status ? element.status : 'Sem Status' }}
          </span>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status Geomonitoramento </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
          <span class="status">
            {{ element.status ? element.status : 'Sem Status' }}
          </span>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">Nenhum registro encontrado</td>
      </tr>
    </table>
  </div>
  <div class="paginacao">
    <mat-paginator id="paginator" [pageSizeOptions]="[5, 10, 25]" [length]="tableData.length" showFirstLastButtons
      [ngStyle]="{ display: true ? 'inherit' : 'none' }"></mat-paginator>
  </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>
