<div class="modal-table-process">
    <div class="header-modal">
        <h4>{{ subOptions? 'Subalternativas' : 'Alternativa'}}</h4>
        <hr />
    </div>
    <span *ngIf="!form.valid && submited" style="color:red;font-size: 12px;margin-left: 40px;">Preencha todos os
        campos</span>
    <form class="body-modal" [formGroup]="form">

        <div class="form-row input-form">
            <div class="select-container" *ngIf="subOptions" style="margin-bottom: 32px;" tabindex="-1">
                <input type="text" formControlName="question" class="form-input" id="name" name="name" />
                <label class="form-label" for="name" [ngClass]="{ focus : form.get('question') }">
                    Pergunta
                </label>
            </div>

            <div class="select-container" tabindex="-1">
                <input type="text" formControlName="name" class="form-input" id="name" name="name" />
                <label class="form-label" for="name" [ngClass]="{ focus : form.get('name') }">
                    Nome da Alternativa
                </label>
            </div>
        </div>

        <div class="info-process">
            <span class="utilizacao-label" style="margin-top: 10px;">Itens da alternativa:</span>

            <div class="utilizacao-container">
                <div>
                    <input type="radio" name="type" value="multiple" formControlName="type">
                    <label class="utilizacao-item">Permitir seleção multipla</label>
                </div>
                <div class="utilizacao-item">
                    <input type="radio" name="type" value="number" formControlName="type">
                    <label class="utilizacao-item">Numérico</label>
                </div>
                <div class="utilizacao-item">
                    <input type="radio" name="type" value="boolean" formControlName="type">
                    <label class="utilizacao-item">Booleano</label>
                </div>
                <div class="utilizacao-item">
                    <input type="radio" name="type" value="text" formControlName="type">
                    <label class="utilizacao-item">Caixa de texto</label>
                </div>
            </div>

            <div class="flex-container" *ngFor="let div of additionalDivs; let i = index">
                <div class="select-container" tabindex="-1"
                    *ngIf="form.get('type')?.value != 'number' && form.get('type')?.value != 'text'">
                    <input type="text" class="form-input" id="name" name="name" [formControlName]="'item_' + (i + 1)" />
                    <label class="form-label" for="name" [ngClass]="{ focus : form.get('item_' + (i + 1)) }">
                        Item
                    </label>
                </div>

                <div class="select-container" tabindex="-1"
                    *ngIf="!subOptions && form.get('type')?.value != 'number' && form.get('type')?.value != 'text'">
                    <input type="number" mask="0000000000" class="form-input" id="name" name="name"
                        [formControlName]="'order_' + (i + 1)" />
                    <label class="form-label" for="name" [ngClass]="{ focus : form.get('order_' + (i + 1)) }">
                        Ordem
                    </label>
                </div>

                <div class="select-container" tabindex="-1"
                    *ngIf="!subOptions && form.get('type')?.value != 'number'  && form.get('type')?.value != 'text'">
                    <input type="number" mask="0000000000" class="form-input" id="name" name="name"
                        [formControlName]="'points_' + (i + 1)" />
                    <label class="form-label" for="name" [ngClass]="{ focus : form.get('points_' + (i + 1)) }">
                        Pontos
                    </label>
                </div>

                <button type="button" class="actions-button button-delete" style="margin-left:30px"
                    *ngIf="i != 0  && additionalDivs.length == i+1 && form.get('type')?.value != 'number' && form.get('type')?.value != 'text'"
                    (click)="removeAdditionalDiv(i)">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25 16.75L16.75 1.25M16.75 16.75L1.25 1.25" stroke="white" stroke-width="2"
                            stroke-linecap="round" />
                    </svg>
                </button>

                <button type="button" class="actions-button button-edit" (click)="addAdditionalDiv()"
                    [ngStyle]="i === 0 ? { 'margin-left': '30px' } : {}"
                    *ngIf="form.get('type')?.value != 'number' && form.get('type')?.value != 'text' && additionalDivs.length == i+1">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.75 16.75V9.25M8.75 9.25V1.75M8.75 9.25H16.25M8.75 9.25H1.25" stroke="white"
                            stroke-width="2" stroke-linecap="round" />
                    </svg>
                </button>
            </div>
        </div>
    </form>


    <div class="buttons" (click)="closeModal()">
        <button class="button" type="button">
            <span>Confirmar</span>
        </button>

        <button class="button" type="button" (click)="closeModalCancel()">
            <span>Cancelar</span>
        </button>
    </div>
</div>