<div class="solicitacao-respondida-content">
  <div class="header-area">
    <h1>{{ analiseDetalhada ? "Análise Detalhada - Solicitação Respondida" : "Solicitação Respondida"}}</h1>
  </div>
  <form [formGroup]="form">
    <div class="body-area">
      <div class="producer">
        <h2>Dados do Produtor/Propriedade </h2>
        <div>
          <div class="row">
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="requestNumber" name="requestNumber"
                formControlName="requestNumber"/>
              <label class="form-label" for="requestNumber"
                [ngClass]="{ focus:  form.get('requestNumber')?.value }">
                Nº da Solicitação
              </label>
              <span class="alert" *ngIf="
                  form.get('requestNumber')?.errors &&
                  (form.get('requestNumber')?.touched ||
                    form.get('requestNumber')?.dirty)
                ">Campo obrigatório</span>
            </div>
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="producerName" name="producerName"
                formControlName="producerName"/>
              <label class="form-label" for="producerName"
                [ngClass]="{ focus:  form.get('producerName')?.value }">
                Nome do Produtor
              </label>
              <span class="alert" *ngIf="
                  form.get('producerName')?.errors &&
                  (form.get('producerName')?.touched ||
                    form.get('producerName')?.dirty)
                ">Campo obrigatório</span>
            </div>
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="producerDocument" name="producerDocument"
                formControlName="producerDocument" mask="000.000.000-00||00.000.000/0000-00"/>
              <label class="form-label" for="producerDocument"
                [ngClass]="{ focus:  form.get('producerDocument')?.value }">
                Documento do Proprietário
              </label>
              <span class="alert" *ngIf="
                  form.get('producerDocument')?.errors &&
                  (form.get('producerDocument')?.touched ||
                    form.get('producerDocument')?.dirty)
                ">Campo obrigatório</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="placeName" name="placeName"
                formControlName="placeName"/>
              <label class="form-label" for="placeName"
                [ngClass]="{ focus:  form.get('placeName')?.value }">
                Nome do Imóvel
              </label>
              <span class="alert" *ngIf="
                  form.get('placeName')?.errors &&
                  (form.get('placeName')?.touched ||
                    form.get('placeName')?.dirty)
                ">Campo obrigatório</span>
            </div>
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="state" name="state"
                formControlName="state"/>
              <label class="form-label" for="state"
                [ngClass]="{ focus:  form.get('state')?.value }">
                UF
              </label>
              <span class="alert" *ngIf="
                  form.get('state')?.errors &&
                  (form.get('state')?.touched ||
                    form.get('state')?.dirty)
                ">Campo obrigatório</span>
            </div>
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="city" name="city"
                formControlName="city"/>
              <label class="form-label" for="city"
                [ngClass]="{ focus:  form.get('city')?.value }">
                Município
              </label>
              <span class="alert" *ngIf="
                  form.get('city')?.errors &&
                  (form.get('city')?.touched ||
                    form.get('city')?.dirty)
                ">Campo obrigatório</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="car" name="car"
                formControlName="car"/>
              <label class="form-label" for="car"
                [ngClass]="{ focus:  form.get('car')?.value }">
                CAR
              </label>
              <span class="alert" *ngIf="
                  form.get('car')?.errors &&
                  (form.get('car')?.touched ||
                    form.get('car')?.dirty)
                ">Campo obrigatório</span>
            </div>
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="ccir" name="ccir"
                formControlName="ccir"/>
              <label class="form-label" for="ccir"
                [ngClass]="{ focus:  form.get('ccir')?.value }">
                CCIR
              </label>
              <span class="alert" *ngIf="
                  form.get('ccir')?.errors &&
                  (form.get('ccir')?.touched ||
                    form.get('ccir')?.dirty)
                ">Campo obrigatório</span>
            </div>
            <div class="form-group" tabindex="-1">
              <input type="text" class="form-input" id="statusCar" name="statusCar"
                formControlName="statusCar"/>
              <label class="form-label" for="statusCar"
                [ngClass]="{ focus:  form.get('statusCar')?.value }">
                Status CAR
              </label>
              <span class="alert" *ngIf="
                  form.get('statusCar')?.errors &&
                  (form.get('statusCar')?.touched ||
                    form.get('statusCar')?.dirty)
                ">Campo obrigatório</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="producer">
        <h2>Critérios do Protocolo de Monitoramento</h2>
        <div class="monitoring-area">
            <div class="radio-input" *ngIf="checkRequestOptions('slavery')">
              <span> Trabalho Escravo: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="slavery">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('ibamaEmbargo')">
              <span> Embargo Ibama <strong>(.xls)</strong>: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="ibamaXLSEmbargo">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('ibamaEmbargo')">
              <span> Embargo Ibama <strong>(.shp)</strong>: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="ibamaSHPEmbargo">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('deter')">
              <span> Deter Amazônia: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="deterAmazonia">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('deter')">
              <span> Deter Cerrado: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="deterCerrado">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('deter')">
              <span> Deter Pantanal: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="deterPantanal">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('publicForests')">
              <span> Florestas Públicas: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="publicForests">
                <mat-radio-button [value]="true">Sim</mat-radio-button >
                <mat-radio-button [value]="false">Não</mat-radio-button >
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('prodes')">
              <span> Prodes AMZ Legal: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="prodesAmazon">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('prodes')">
              <span> Prodes Cerrado: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="prodesCerrado">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('prodes')">
              <span> Prodes Mata Atlantica: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="prodesMataAtlantica">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('prodes')">
              <span> Prodes Mata Caatinga: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="prodesCaatinga">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('prodes')">
              <span> Prodes Pampa: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="prodesPampa">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('prodes')">
              <span> Prodes Pantanal: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="prodesPantanal">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('indigenousLand')">
              <span> Terra Indígena: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="indigenousLand">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('conservationUnit')">
              <span> Unidade de Conservação: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="conservationUnit">
                <mat-radio-button [value]="true">Sim</mat-radio-button>
                <mat-radio-button [value]="false">Não</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('archeologicalSites')">
              <span> Sítios Arqueológicos: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="archeologicalSites">
                <mat-radio-button [value]="true">Sim</mat-radio-button >
                <mat-radio-button [value]="false">Não</mat-radio-button >
              </mat-radio-group>
            </div>
            <div class="radio-input" *ngIf="checkRequestOptions('quilombolaAreas')">
              <span> Áreas Quilombolas: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="quilombolaAreas">
                <mat-radio-button [value]="true">Sim</mat-radio-button >
                <mat-radio-button [value]="false">Não</mat-radio-button >
              </mat-radio-group>
            </div>
            <div class="radio-input">
              <span> Resultado: </span>
              <mat-radio-group aria-label="Selecione uma opção" formControlName="result" [disabled]="true">
                <mat-radio-button [value]="true">Habilitado</mat-radio-button >
                <mat-radio-button [value]="false">Não Habilitado</mat-radio-button >
              </mat-radio-group>
            </div>
        </div>
      </div>
      <hr />
      <div class="interactions">
        <h2>Interações</h2>
        <div class="chat-area">
          <div class="messages-area" *ngIf="solicitacao && solicitacao.messages">
            <div [class]="user.sub === message.username ? 'message owner' : 'message'" *ngFor="let message of solicitacao.messages">
              <p>{{ message.text }}</p>
              <div *ngIf="message.attachments" class="assets">
                <div *ngFor="let item of message.attachments | keyvalue" class="asset">
                  <button type="button" (click)="downloadFileName(item.value)">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.4286 10H9.85716M3.4286 12.5714H12.4286M3.4286 15.1429H7.28574M16.2857 16.4286V7.42857L9.85716 1H3.4286C2.74662 1 2.09257 1.27092 1.61033 1.75315C1.12809 2.23539 0.857178 2.88944 0.857178 3.57143V16.4286C0.857178 17.1106 1.12809 17.7646 1.61033 18.2468C2.09257 18.7291 2.74662 19 3.4286 19H13.7143C14.3963 19 15.0503 18.7291 15.5326 18.2468C16.0148 17.7646 16.2857 17.1106 16.2857 16.4286Z" stroke="#2B81AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>{{ item.key }}</span>
                  </button>
                </div>
              </div>
              <span><strong>{{ message.username }} </strong> - {{ message.timestamp | date:'dd/MM/yyyy - HH:mm'}} </span>
            </div>
          </div>
        </div>
        <div class="input-area" onsubmit="submitMessage()" >
          <form [formGroup]="chatForm">
            <div class="text-area">
              <div class="form-group">
                <input type="text" class="form-input" id="text" name="text" formControlName="text"/>
                <label class="form-label" for="text" [ngClass]="{ focus:  chatForm.get('text')?.value }">
                  Nova Mensagem
                </label>
              </div>
              <button class="button" type="button" (click)="submitMessage()">
                Enviar
              </button>
            </div>
            <div class="assets">
              <div [formGroupName]="i" *ngFor="let attachment of getFormArray('attachments'); let i = index" class="asset">
                  <button type="button" class="attachment-button" (click)="downloadFileName(attachment.get('value')?.value)">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.4286 10H9.85716M3.4286 12.5714H12.4286M3.4286 15.1429H7.28574M16.2857 16.4286V7.42857L9.85716 1H3.4286C2.74662 1 2.09257 1.27092 1.61033 1.75315C1.12809 2.23539 0.857178 2.88944 0.857178 3.57143V16.4286C0.857178 17.1106 1.12809 17.7646 1.61033 18.2468C2.09257 18.7291 2.74662 19 3.4286 19H13.7143C14.3963 19 15.0503 18.7291 15.5326 18.2468C16.0148 17.7646 16.2857 17.1106 16.2857 16.4286Z" stroke="#2B81AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>{{ attachment.get('name')?.value }}</span>
                  </button>
                  <button class="button" type="button" (click)="removeIndexForm(i)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.6 2.8H8.4C8.4 2.4287 8.2525 2.0726 7.98995 1.81005C7.7274 1.5475 7.3713 1.4 7 1.4C6.6287 1.4 6.2726 1.5475 6.01005 1.81005C5.7475 2.0726 5.6 2.4287 5.6 2.8ZM4.2 2.8C4.2 2.05739 4.495 1.3452 5.0201 0.820101C5.5452 0.294999 6.25739 0 7 0C7.74261 0 8.4548 0.294999 8.9799 0.820101C9.505 1.3452 9.8 2.05739 9.8 2.8H13.3C13.4857 2.8 13.6637 2.87375 13.795 3.00503C13.9263 3.1363 14 3.31435 14 3.5C14 3.68565 13.9263 3.8637 13.795 3.99497C13.6637 4.12625 13.4857 4.2 13.3 4.2H12.6826L12.0624 11.438C12.0028 12.1369 11.683 12.788 11.1663 13.2624C10.6496 13.7369 9.97366 14.0001 9.2722 14H4.7278C4.02634 14.0001 3.35039 13.7369 2.8337 13.2624C2.31702 12.788 1.99722 12.1369 1.9376 11.438L1.3174 4.2H0.7C0.514348 4.2 0.336301 4.12625 0.205025 3.99497C0.0737498 3.8637 0 3.68565 0 3.5C0 3.31435 0.0737498 3.1363 0.205025 3.00503C0.336301 2.87375 0.514348 2.8 0.7 2.8H4.2ZM9.1 7C9.1 6.81435 9.02625 6.6363 8.89497 6.50503C8.7637 6.37375 8.58565 6.3 8.4 6.3C8.21435 6.3 8.0363 6.37375 7.90503 6.50503C7.77375 6.6363 7.7 6.81435 7.7 7V9.8C7.7 9.98565 7.77375 10.1637 7.90503 10.295C8.0363 10.4263 8.21435 10.5 8.4 10.5C8.58565 10.5 8.7637 10.4263 8.89497 10.295C9.02625 10.1637 9.1 9.98565 9.1 9.8V7ZM5.6 6.3C5.41435 6.3 5.2363 6.37375 5.10503 6.50503C4.97375 6.6363 4.9 6.81435 4.9 7V9.8C4.9 9.98565 4.97375 10.1637 5.10503 10.295C5.2363 10.4263 5.41435 10.5 5.6 10.5C5.78565 10.5 5.9637 10.4263 6.09497 10.295C6.22625 10.1637 6.3 9.98565 6.3 9.8V7C6.3 6.81435 6.22625 6.6363 6.09497 6.50503C5.9637 6.37375 5.78565 6.3 5.6 6.3Z" fill="white"/>
                    </svg>
                  </button>
              </div>
              <button type="button" class="button" (click)="openModalAssets()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.49984 15.3333C1.99567 15.3333 1.56392 15.1536 1.20459 14.7943C0.845255 14.435 0.665894 14.0035 0.666505 13.5V10.75H2.49984V13.5H13.4998V10.75H15.3332V13.5C15.3332 14.0041 15.1535 14.4359 14.7942 14.7952C14.4348 15.1545 14.0034 15.3339 13.4998 15.3333H2.49984ZM7.08317 11.6666V4.19579L4.69984 6.57913L3.41651 5.24996L7.99984 0.666626L12.5832 5.24996L11.2998 6.57913L8.9165 4.19579V11.6666H7.08317Z" fill="white"/>
                </svg>
                <span> Upload Arquivo</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <hr />
      <div class="producer">
        <h2>Resultados</h2>
        <div class="carrossel" *ngIf="solicitacao && solicitacao.response.images">
          <app-carrossel [imagens]="imageList"></app-carrossel>
        </div>
        <div>
          <div class="row">
            <div class="analyse-date">
              <span>Data Análise:</span>
              <div class="form-group" tabindex="-1">
                <input type="date" class="form-input" id="sendDate" name="sendDate" formControlName="sendDate" style="width: fit-content; text-align: center;" />
                <!-- <label class="form-label" for="sendDate"
                  [ngClass]="{ focus:  form.get('sendDate')?.value }">
                  Data Análise
                </label>
                <span class="alert" *ngIf="
                    form.get('sendDate')?.errors &&
                    (form.get('sendDate')?.touched ||
                      form.get('sendDate')?.dirty)
                  ">Campo obrigatório</span> -->
              </div>
            </div>
            <div class="analyses-status">
              <span>Status Análise:</span>
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="getStatusColor(form.get('status')?.value) === 'green' && !analiseDetalhada">
                <path d="M5.225 11.5114L0 6.28644L1.30625 4.98019L5.225 8.89894L13.6354 0.488525L14.9417 1.79478L5.225 11.5114Z" fill="#00D68F"/>
                </svg>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="getStatusColor(form.get('status')?.value) === 'red' && !analiseDetalhada">
                  <path d="M14.1385 12.6801C14.3322 12.8739 14.4411 13.1366 14.4411 13.4106C14.4411 13.6846 14.3322 13.9474 14.1385 14.1411C13.9448 14.3348 13.682 14.4437 13.408 14.4437C13.1341 14.4437 12.8713 14.3348 12.6776 14.1411L7.2214 8.6832L1.76351 14.1394C1.56977 14.3331 1.30702 14.4419 1.03304 14.4419C0.75906 14.4419 0.496303 14.3331 0.30257 14.1394C0.108838 13.9456 2.88684e-09 13.6829 0 13.4089C-2.88684e-09 13.1349 0.108838 12.8722 0.30257 12.6784L5.76046 7.22226L0.304289 1.76437C0.110556 1.57063 0.00171879 1.30788 0.00171879 1.0339C0.00171879 0.759919 0.110556 0.497162 0.304289 0.303429C0.498021 0.109697 0.760778 0.000859234 1.03476 0.000859231C1.30874 0.000859228 1.57149 0.109697 1.76523 0.303429L7.2214 5.76132L12.6793 0.30257C12.873 0.108838 13.1358 -4.56449e-09 13.4098 0C13.6837 4.56449e-09 13.9465 0.108838 14.1402 0.30257C14.334 0.496302 14.4428 0.75906 14.4428 1.03304C14.4428 1.30702 14.334 1.56978 14.1402 1.76351L8.68234 7.22226L14.1385 12.6801Z" fill="#F43841"/>
                </svg>
              <span class="status" [ngClass]="getStatusColor(form.get('status')?.value)" *ngIf="!analiseDetalhada">
                {{ getStatusName(form.get('status')?.value) }}
              </span>

              <div class="select-container" tabindex="-1" *ngIf="isAdmin && analiseDetalhada">
                <select
                  type="text"
                  class="form-input select"
                  id="status"
                  name="status"
                  formControlName="status"
                >
                  <!-- <option value="ENABLED">Habilitado</option> -->
                  <option value="POST_ANALYSIS_ENABLED">Habilitado AD</option>
                  <option value="NOT_ENABLED">Em Análise AD</option>
                  <option value="POST_ANALYSIS_NOT_ENABLED">Não Habilitado AD</option>
                  <!-- <option value="ANALYSIS_IN_PROGRESS">Analisando</option> -->
                </select>

                <!-- <label class="form-label" for="status"
                  [ngClass]="{ focus: form.get('status')?.value && form.get('status')?.value.length > 0 }">
                  Status
                </label> -->
                <span class="alert" *ngIf="
                    form.get('status')?.errors &&
                    (form.get('status')?.touched ||
                      form.get('status')?.dirty)
                  ">Campo obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="upload-arquivo" *ngIf="(form.get('ccirFilePath')?.value)">
        <div class="file-area">
          <button type="button" class="button" (click)="downloadFileName('ccirFilePath')">
            <span>CCIR</span>
          </button>
          <!-- <div class="uploaded-file">
            <button type="button" (click)="downloadFileName('ccirFilePath')"> {{ getFileName(form.get('ccirFilePath')?.value) }} </button>
          </div>
          <span style="font-size: 12px; color: red;" *ngIf="form.get('ccirFilePath')?.errors && (form.get('ccirFilePath')?.touched || form.get('ccirFilePath')?.dirty)">Campo obrigatório</span> -->
        </div>
        <div class="file-area" *ngIf="(form.get('producerPropertyFilePath')?.value)">
          <button type="button" class="button" (click)="downloadFileName('producerPropertyFilePath')">
            <span>Vinculo produtor x propriedade</span>
          </button>
          <!-- <div class="uploaded-file">
            <button type="button" (click)="downloadFileName('producerPropertyFilePath')">{{ getFileName(form.get('producerPropertyFilePath')?.value) }} </button>
          </div>
          <span style="font-size: 12px; color: red;" *ngIf="form.get('producerPropertyFilePath')?.errors && (form.get('producerPropertyFilePath')?.touched || form.get('producerPropertyFilePath')?.dirty)">Campo obrigatório</span> -->
        </div>
        <div class="file-area">
          <button type="button" class="button" (click)="downloadFileName('carFilePath')">
            <span>CAR</span>
          </button>
          <!-- <div class="uploaded-file">
            <button type="button" (click)="downloadFileName('carFilePath')"> {{ getFileName(form.get('carFilePath')?.value) }} </button>
          </div>
          <span style="font-size: 12px; color: red;" *ngIf="form.get('carFilePath')?.errors && (form.get('carFilePath')?.touched || form.get('carFilePath')?.dirty)">Campo obrigatório</span> -->
        </div>
        <div class="file-area" *ngIf="(form.get('producerDocumentPath')?.value)">
          <button type="button" class="button" (click)="downloadFileName('producerDocumentPath')">
            <span>Documento Proprietário</span>
          </button>
          <!-- <div class="uploaded-file">
            <button type="button" (click)="downloadFileName('producerDocumentPath')"> {{ getFileName(form.get('producerDocumentPath')?.value) }} </button>
          </div>
          <span style="font-size: 12px; color: red;" *ngIf="form.get('producerDocumentPath')?.errors && (form.get('producerDocumentPath')?.touched || form.get('producerDocumentPath')?.dirty)">Campo obrigatório</span> -->
        </div>
        <div class="file-area" *ngIf="(form.get('kmlPath')?.value)">
          <button type="button" class="button" (click)="downloadFileName('kmlPath')">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00531 4.69334C6.42915 5.07407 6.89609 4.81187 7.04335 4.65742L8.76743 2.80403V13.479C8.76743 13.8848 9.09429 14.2117 9.50017 14.2117C9.90605 14.2117 10.2329 13.8848 10.2329 13.479V2.80044L11.957 4.65383C12.2336 4.95195 12.6969 4.96632 12.995 4.68975C13.2931 4.41318 13.3075 3.94983 13.0309 3.65171L10.0354 0.433421C9.89527 0.286155 9.70131 0.199951 9.50017 0.199951C9.29543 0.199951 9.10147 0.286155 8.96499 0.433421L5.96939 3.65171C5.69282 3.95342 5.7036 4.42395 6.00531 4.69334Z" fill="white"/>
              <path d="M17.5673 11.8411C17.1614 11.8411 16.8345 12.1679 16.8345 12.5738V16.338H2.16548V12.5738C2.16548 12.1679 1.83862 11.8411 1.43275 11.8411C1.02687 11.8411 0.700012 12.1679 0.700012 12.5738V17.0708C0.700012 17.4767 1.02687 17.8035 1.43275 17.8035H17.5673C17.9732 17.8035 18.3 17.4767 18.3 17.0708V12.5738C18.3 12.1679 17.9732 11.8411 17.5673 11.8411Z" fill="white"/>
            </svg>
            <span>Arquivo KML </span>
          </button>
          <!-- <div class="uploaded-file">
            <button type="button" (click)="downloadFileName('kmlPath')"> {{ getFileName(form.get('kmlPath')?.value) }} </button>
          </div>
          <span style="font-size: 12px; color: red;" *ngIf="form.get('kmlPath')?.errors && (form.get('kmlPath')?.touched || form.get('kmlPath')?.dirty)">Campo obrigatório</span> -->
        </div>
      </div>
      <div class="buttons">
        <button class="button button-upload" type="button" (click)="downloadFile('laudo')" *ngIf="!analiseDetalhada && this.form.get('reportFile')?.value">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00492 11.6724L3.42055 7.08805L4.70417 5.75858L7.08805 8.14245V0.669922H8.9218V8.14245L11.3057 5.75858L12.5893 7.08805L8.00492 11.6724ZM2.50367 15.3399C1.99939 15.3399 1.56754 15.1602 1.20813 14.8008C0.848714 14.4414 0.669312 14.0098 0.669923 13.5062V10.7555H2.50367V13.5062H13.5062V10.7555H15.3399V13.5062C15.3399 14.0105 15.1602 14.4423 14.8008 14.8017C14.4414 15.1611 14.0098 15.3405 13.5062 15.3399H2.50367Z" fill="white"/>
          </svg>
          <span> Download Laudo </span>
        </button>
        <button class="button button-upload" type="button" (click)="uploadFile()" *ngIf="isAdmin && analiseDetalhada">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49984 15.3333C1.99567 15.3333 1.56392 15.1536 1.20459 14.7943C0.845255 14.435 0.665894 14.0035 0.666505 13.5V10.75H2.49984V13.5H13.4998V10.75H15.3332V13.5C15.3332 14.0041 15.1535 14.4359 14.7942 14.7952C14.4348 15.1545 14.0034 15.3339 13.4998 15.3333H2.49984ZM7.08317 11.6666V4.19579L4.69984 6.57913L3.41651 5.24996L7.99984 0.666626L12.5832 5.24996L11.2998 6.57913L8.9165 4.19579V11.6666H7.08317Z" fill="white"/>
          </svg>
          <span> Upload Laudo </span>
        </button>
        <!-- <button class="button button-upload" type="button" *ngIf="isAdmin && analiseDetalhada">
          <span> Solicitar Análise de Especialista </span>
        </button> -->
        <button class="button" (click)="back()">Voltar</button>
        <button class="button button-upload" (click)="submitForm()" *ngIf="isAdmin && analiseDetalhada"> Salvar </button>
      </div>
    </div>
  </form>
</div>

<app-spinner *ngIf="loading"></app-spinner>
