<div class="select-container" tabindex="-1">
  <div class="open-options" (click)="openOptions()">
    <input
      class="form-input select"
      name=""
      [placeholder]="placeholder"
      type="text"
      [(ngModel)]="value"
      (change)="filterList($event)"
      (blur)="filterList($event)"
      (keyup)="filterList($event)"
      (focus)="filterList($event)"
      [disabled]="disabled"
      autocomplete="off"
    >
    <label class="form-label" for="" [ngClass]="{ focus: value  }"> {{ label }} </label>
    <span class="alert" *ngIf="!valueIsValid() && required && change"> Campo obrigatório </span>
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow" [ngClass]="{ rotate : optionsShow }">
      <path d="M9 1L5 5L1 1" stroke="black" stroke-width="2"/>
    </svg>
  </div>
  <div *ngIf="optionsShow && !disabled" #optionsListElement class="options">
    <div class="option" style="background-color: #CCC;" (click)="selectOption({name: '', value: ''})" *ngIf="optionsList.length > 0 && value != ''">
      Remover Filtro
    </div>
    <div *ngFor="let option of optionsList" class="option" (click)="selectOption(option)">
      {{ option.name }}
    </div>
    <div class="option" *ngIf="optionsList.length === 0" (click)="toggleOptions()">
      Nenhum Registro Encontrado
    </div>
    <div class="load-more" *ngIf="loadMoreButton && (optionsList.length !== 0)">
      <button type="button" (click)="loadMoreOptions()">
        <span>Carregar Mais </span>
      </button>
    </div>
  </div>
</div>
