import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsService } from 'src/app/services/forms.service';
import { OpenModalConfirmService } from 'src/app/services/open-modal-confirm.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DynamicFormComponent implements OnInit {

  formJson: any ;
  loading: boolean = false;

  constructor(
    private formsService: FormsService,
    private activeRouter: ActivatedRoute,
    private openModalConfirmService: OpenModalConfirmService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const hash = this.activeRouter.snapshot.queryParamMap.get('hash');
    if (hash) {
      this.loading = true;
      this.formsService.getFormById(hash).subscribe(res => {
        this.loading = false;
        this.formJson = res;
        this.getInfo();
      }, error => {
        this.loading = false;
      });
    }
  }

  handleClickBack() {
    this.router.navigate(['/processos']);
  }

  getInfo() {
    const formElement = document.getElementById('formulario-dinamico');
    if (formElement) {
      this.renderForm(this.formJson, formElement);
      formElement.addEventListener('submit', (event) => this.onSubmit(event));
    }
  }

  renderForm(json: any, formElement: HTMLElement): void {
    json.questions.forEach((question: any) => {
      const questionContainer = document.createElement('div');
      questionContainer.className = 'question-container';

      const questionLabel = document.createElement('label');
      questionLabel.className = 'question-title';
      questionLabel.textContent = question.questionText;
      questionContainer.appendChild(questionLabel);

      if (question.type === 'TEXT') {
        const input = document.createElement('input');
        input.type = 'text';
        input.name = question.code;
        questionContainer.appendChild(input);
      }
      else if (question.type === 'CHECKBOX' || question.type === 'MULTIPLE_RESPONSES') {
        question.options.forEach((option: any) => {
          const optionContainer = document.createElement('div');
          optionContainer.className = 'option-container';

          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.name = `${question.code}_${option.value}`;
          checkbox.value = option.value;
          checkbox.id = `${question.code}_${option.value}`;

          const label = document.createElement('label');
          label.textContent = option.value;
          label.setAttribute('for', `${question.code}_${option.value}`);

          optionContainer.appendChild(checkbox);
          optionContainer.appendChild(label);
          questionContainer.appendChild(optionContainer);

          if (option.conditionalQuestions && option.conditionalQuestions.length > 0) {
            const conditionalContainer = document.createElement('div');
            conditionalContainer.className = 'conditional-container';
            conditionalContainer.style.display = 'none';
            optionContainer.appendChild(conditionalContainer);

            checkbox.addEventListener('change', function () {
              conditionalContainer.style.display = checkbox.checked ? 'block' : 'none';
            });

            this.renderConditionalQuestions(option.conditionalQuestions, conditionalContainer, `${question.code}_${option.value}`);
          }
        });
      }
      else if (question.type === 'LIST_OF_VALUES') {
        const addButton = document.createElement('button');
        addButton.type = 'button';
        addButton.textContent = 'Adicionar';
        questionContainer.appendChild(addButton);

        const listContainer = document.createElement('div');
        questionContainer.appendChild(listContainer);

        addButton.addEventListener('click', function () {
          const input = document.createElement('input');
          input.type = 'text';
          input.name = question.code + '[]';
          listContainer.appendChild(input);
        });
      }

      formElement.appendChild(questionContainer);
    });
  }

  renderConditionalQuestions(conditionalQuestions: any, container: HTMLElement, parentCode: string): void {
    conditionalQuestions.forEach((question: any) => {
      const questionContainer = document.createElement('div');
      questionContainer.className = 'question-container';

      const questionLabel = document.createElement('label');
      questionLabel.className = 'question-title';
      questionLabel.textContent = question.questionText;
      questionContainer.appendChild(questionLabel);

      if (question.type === 'TEXT') {
        const input = document.createElement('input');
        input.type = 'text';
        input.name = `${parentCode}_${question.code}`;
        questionContainer.appendChild(input);
      } else if (question.type === 'MULTIPLE_CHOICE') {
        question.options.forEach((option: any) => {
          const optionContainer = document.createElement('div');
          optionContainer.className = 'option-container';

          const radio = document.createElement('input');
          radio.type = 'radio';
          radio.name = `${parentCode}_${question.questionText}`;
          radio.value = option.value;
          radio.id = `${parentCode}_${option.value}`;

          const label = document.createElement('label');
          label.textContent = option.value;
          label.setAttribute('for', `${parentCode}_${option.value}`);

          optionContainer.appendChild(radio);
          optionContainer.appendChild(label);
          questionContainer.appendChild(optionContainer);

          if (option.conditionalQuestions && option.conditionalQuestions.length > 0) {
            const conditionalContainer = document.createElement('div');
            conditionalContainer.className = 'conditional-container';
            conditionalContainer.style.display = 'none';
            optionContainer.appendChild(conditionalContainer);

            radio.addEventListener('change', function () {
              conditionalContainer.style.display = radio.checked ? 'block' : 'none';
            });

            this.renderConditionalQuestions(option.conditionalQuestions, conditionalContainer, `${parentCode}_${option.value}`);
          }
        });
      }

      container.appendChild(questionContainer);
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    const formElement = event.target as HTMLFormElement;
    const formData = new FormData(formElement);
    const formValues: any = {};

    formData.forEach((value, key) => {
      if (formValues[key]) {
        if (Array.isArray(formValues[key])) {
          formValues[key].push(value);
        } else {
          formValues[key] = [formValues[key], value];
        }
      } else {
        formValues[key] = value;
      }
    });



    const hash = this.activeRouter.snapshot.queryParamMap.get('hash');
    this.openModalConfirmService.openModalConfirm({}).subscribe(result => {
      if (result.confirm) {
        this.formsService.submitResponse(hash as string, formValues).subscribe(res => {
          this.openModalConfirmService.openModalConfirm({
            success: true,
          }).subscribe({
            next: r => this.handleClickBack(),
            error: e => this.handleClickBack()
          });
        }, e => {
          this.openModalConfirmService.openModalConfirm({
            error: true,
          })
        });
      }
    });
  }
}
