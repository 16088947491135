<div class="form-group" [ngStyle]="{ display: isUserProvider ? 'none' : '' }">
  <app-select-input
    #inputStoreCD
    [label]="!isCarrefour ? operationalType === 'STORE' ? 'Loja' : 'Centro de Distribuição' : 'Local de Entrega'"
    [required]="required"
    (changeValue)="changeStoreCDValue($event)"
    [list]="storeCDOptionsList"
    [loadMoreButton]="storeCDsList.length < totalItems"
    (loadMore)="loadMoreOptionsList($event)"
    (getSearchOptions)="getStoreCDSearchOptions($event)"
    [disabled]="disabled"
  ></app-select-input>
</div>

<app-spinner *ngIf="loading"></app-spinner>
