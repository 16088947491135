import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

  constructor() { }

  @Input() percentageApproved: any;
  @Input() percentageRestriction: any;
  @Input() percentageReturned: any;
  @Input() percentageReturnedWithRestriction: any;
  @Input() percentageReturnedWithEvaluate: any;
  @Input() percentageReceiveWith: any;
  @Input() percentageReturnedRecWith: any;

  async ngOnInit() {
    await this.loadGoogleCharts();
    this.drawChart();
  }

  loadGoogleCharts(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      google.charts.load("current", { packages: ["corechart"] });
      google.charts.setOnLoadCallback(() => {
        resolve();
      });
    });
  }

  drawChart() {

    const legend = {
      position: 'right',
      alignment: 'center',
      maxLines: 2,
      textStyle: {
        fontSize: 12
      }
    };

    const colors = ['#00D68F', '#FFAA00', '#F43841', 'rgb(126, 16, 16)', 'rgb(173, 173, 10)', 'blue', 'black'];

    var data = google.visualization.arrayToDataTable([
      ['Status', 'Quantidade'],
      ['Aprovado', this.percentageApproved],
      ['Recebido com Restrição', this.percentageRestriction],
      ['Devolvido', this.percentageReturned],
      ['Devolvido/restrição', this.percentageReturnedWithRestriction],
      ['Avaliar', this.percentageReturnedWithEvaluate],
      ['Recebimento Comercial', this.percentageReceiveWith],
      ['Devolvido / Recebimento Comercial', this.percentageReturnedRecWith]
    ]);

    let options: any = {
      pieSliceTextStyle: {
        color: "black",
      },
      pieHole: 0.6,
      width: 600,
      height: 350,
      colors: colors,
      responsive: true,
      legend: legend,
      pieSliceText: 'percentage',
      series: {
        0: {
          marker: {
            shape: 'square'
          }
        },
      },
      chartArea: {
        height: "90%",
        width: "90%",
      }
    };

    let graph: any = document.getElementById('chart_div');
    var chart = new google.visualization.PieChart(graph);
    chart.draw(data, options);
  }
}
