import { FornecedoresService } from 'src/app/services/fornecedores.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoService } from 'src/app/services/produto.service';
import { ProductsListInfoComponent } from './products-list-info/products-list-info.component';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit, OnChanges {

  @Input() provider: any;
  productsList: any;
  providerData: any;
  loading: boolean = true;

  constructor(
    private fornecedoresService: FornecedoresService,
    private produtoService: ProdutoService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.fornecedoresService.getStatusProvider(this.provider.document).subscribe(
      res => {
        this.providerData = res;
        this.loading = false;
      }, error => {
        this.providerData = {};
        this.loading = false;
      }
    )
    this.loading = true;
    this.produtoService.listarProdutosPorDocumento(this.provider.document).subscribe(
      res => {
        this.productsList = res;
        this.loading = false;
      }, error => {
        this.loading = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    this.fornecedoresService.getStatusProvider(this.provider.document).subscribe(
      res => {
        this.providerData = res;
        this.loading = false;
      }, error => {
        this.providerData = {};
        this.loading = false;
      }
    )
    this.loading = true;
    this.produtoService.listarProdutosPorDocumento(this.provider.document).subscribe(
      res => {
        this.productsList = res;
        this.loading = false;
      }, error => {
        this.loading = false;
      }
    )
  }

  showProducts() {
    this.dialog.open(ProductsListInfoComponent, {
      data: {
        productsList: this.productsList,
      },
    });
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      })
    }
    return returnFeature.value;
  }

  getOperationType() {
    if (this.provider.operationalType) {
      return this.provider.operationalType
    }
    return '-'
  }

  public capitalizarString(string : string) {
    if (string == undefined || string.trim() === '') {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

}
