import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dateNotBeforeValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null; // Don't validate empty value
    }

    const inputDate = new Date(control.value);
    if (isNaN(inputDate.getTime())) {
      return { 'invalidDate': { value: control.value } }; // Invalid date
    }

    return inputDate >= minDate ? null : { 'invalidDate': { value: control.value } };
  };
}
