<div class="select-type-content" *ngIf="data.selectType">
  <div class="header">
    <h4>Selecione o Tipo de Auditoria Social </h4>
  </div>
  <div class="form-container">
    <form [formGroup]="form" action="">
      <div class="select-container" tabindex="-1">
        <select formControlName="type"   class="form-input select" id="type" #type2 name="type" (change)="changeSelect($event)">
          <option value="" disabled selected>Selecione</option>
          <option value="Bovinos"> Bovinos</option>
          <option value="Suinos"> Suínos</option>
          <option value="FLV"> FLV</option>
          <option value="Pescado"> Pescado</option>
          <option value="Aves"> Aves</option>
          <option value="Ovos"> Ovos</option>
          <option value="Nao Alimentos"> Não Alimentos</option>
        </select>

        <label class="form-label" for="type"
          [ngClass]="{ focus: (form.get('type')?.value && form.get('type')?.value.length > 0)}">
          Selecione o Tipo
        </label>
        <span class="alert" *ngIf="
            form.get('type')?.errors &&
            (form.get('type')?.touched ||
              form.get('type')?.dirty)
          ">Campo obrigatório
        </span>
      </div>
    </form>
  </div>

  <div class="buttons-area">
    <button type="button" (click)="submit()">Confirmar</button>
    <button type="button" (click)="closeModal()">Cancelar</button>
  </div>
</div>


<div class="select-type-content" *ngIf="data.selectFile">
  <div class="header">
    <h4>Inserir Auditoria Social via Arquivo </h4>
  </div>
  <div class="form-container file-area">
    <form [formGroup]="formFile" action="">
      <input type="file" name="uploadFile" id="uploadFile" class="file" formControlName="uploadFile" (change)="changeFile($event)"/>
    </form>
  </div>

  <div class="buttons-area">
    <button type="button" (click)="submit()">Confirmar</button>
    <button type="button" (click)="closeModal()">Cancelar</button>
  </div>
</div>
