import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProviderProduct } from '../interfaces/reception.interface';

@Injectable({
  providedIn: 'root',
})
export class ProdutoService {
  storage: Storage;
  productSelected: any;

  constructor(private router: Router, private http: HttpClient) {
    this.storage = window.sessionStorage;
  }

  saveProductSelect(product: any) {
    localStorage.setItem('objProduct', JSON.stringify(product));
  }

  getProductSelect() {
    const data = localStorage.getItem('objProduct');
    return data ? JSON.parse(data) : null;
  }

  public listProducts(options: {
    productEan?: string;
    productPlu?: string;
    productName?: string;
    productBatch?: string;
    productCode?: string;
    id?: string;
    providerDocument?: string;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams();

    if (options.productEan) {
      params = params.set('productEan', options.productEan.trim());
    }

    if (options.productPlu) {
      params = params.set('productPlu', options.productPlu.trim());
    }

    if (options.productName) {
      params = params.set('productName', options.productName.trim());
    }

    if (options.productBatch) {
      params = params.set('productBatch', options.productBatch.trim());
    }

    if (options.productCode) {
      params = params.set('productCode', options.productCode.trim());
    }

    if (options.id) {
      params = params.set('id', options.id.trim());
    }

    if (options.providerDocument) {
      params = params.set('providerDocument', options.providerDocument.trim());
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/product-service/list`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  updateProductForProvider(body: any, economicGroup: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    const params = new HttpParams().set('economicGroup', economicGroup);

    return this.http.put<Object>(
      `${environment.api}/enterprise-product-service/update`,
      body,
      { headers, params }
    );
  }

  registerProduct(body: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };

    return this.http.post<Object>(
      `${environment.api}/product-service/register`,
      body,
      httpOptions
    );
  }

  registerProductForProvider(body: any, document: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    const params = new HttpParams().set('document', document);

    return this.http.post<Object>(
      `${environment.api}/enterprise-product-service/register`,
      body,
      { headers, params }
    );
  }

  updateProduct(body: any, blocked: boolean): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    const params = new HttpParams()
      .set('document', 123)
      .set('blocked', blocked);

    return this.http.put(`${environment.api}/product-service/update`, body, {
      headers,
      params,
    });
  }

  getTechnicalList(hash: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    const params = new HttpParams().set('hash', hash);

    return this.http.get(
      `${environment.api}/datasheet-service/find-by-productHash`,
      { headers, params }
    );
  }

  deleteDatasheet(hash: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    return this.http.delete(
      `${environment.api}/datasheet-service/delete/${hash}`,
      { headers }
    );
  }

  getProductByName(name: string, document?: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    let params = new HttpParams().set('productName', name);

    if (document) {
      params = params.set('providerDocument', document);
    }

    return this.http.get(`${environment.api}/product-service/find-by-name`, {
      headers,
      params,
    });
  }

  getProductByNameAndEconomicGroup(name: string, document: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    const params = new HttpParams()
      .set('productName', name)
      .set('document', document);

    return this.http.get(
      `${environment.api}/enterprise-product-service/find-by-document-and-product-name`,
      { headers, params }
    );
  }

  getProductByNamePaginated(
    name: string,
    page: number = 0,
    size: number = 10,
    documentProvider: string = ''
  ) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    let params = new HttpParams()
      .set('productName', name)
      .set('page', page)
      .set('size', size);

    if (documentProvider) {
      params = params.set('documentProvider', documentProvider);
    }

    return this.http.get(
      `${environment.api}/product-service/find-by-name/paginated`,
      { headers, params }
    );
  }

  getProductByNameAndProvider(
    name: string,
    documentProvider: string = '',
    page?: number,
    size?: number
  ): Observable<ProviderProduct> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    const headers = new HttpHeaders({
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    });

    let params = new HttpParams();

    if (page && size) {
      params = new HttpParams()
        .set('productName', name)
        .set('page', page)
        .set('size', size);
    } else {
      params = new HttpParams().set('productName', name);
    }

    if (documentProvider) {
      params = params.set('documentProvider', documentProvider);
    }

    return this.http.get<ProviderProduct>(
      `${environment.api}/product-service/find-by-name/paginated`,
      { headers, params }
    );
  }

  getLastMovement(document: any, product: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };

    return this.http.post<Object>(
      `${environment.api}/movement-service/last-movement-by-product-provider?provider=${document}`,
      product,
      httpOptions
    );
  }


  public listProductsByEan(ean: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          productEan: ean,
        },
      };
    }

    return this.http.get(`${environment.api}/product-service/list`, http).pipe(
      (res) => res,
      (error) => error
    );
  }


  public listLevelServiceProduct(type: string, document: string, date: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          type: type,
          providerDocument: document.replace(/[^a-z0-9]/gi, ''),
          completionDate: date,
        },
      };
    }

    return this.http
      .get(`${environment.api}/quality-service/provider-service-level`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public listProductsByPlu(plu: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          productPlu: plu,
        },
      };
    }

    return this.http.get(`${environment.api}/product-service/list`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  getSumary(productType: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          productType: productType,
        },
      };
    }

    return this.http
      .get(`${environment.api}/summary-view/findByProductType`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public listProductsByCode(code: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          productCode: code,
        },
      };
    }

    return this.http.get(`${environment.api}/product-service/list`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  public findProductsFilter(filters: {
    hash?: string;
    code?: string;
    plu?: string;
    name?: string;
    ean?: string;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }
    return this.http
      .post(`${environment.api}/product-service/find`, filters, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public listProductsByName(name: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          productName: name,
        },
      };
    }
    return this.http.get(`${environment.api}/product-service/list`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  public inserirFornecedor(listaProdutos: any, enterprise: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const newProductList = {
      productList: listaProdutos,
      enterprise: {
        document:
          enterprise && enterprise.document
            ? enterprise.document.replace(/\D/g, '')
            : '',
      },
    };

    return this.http
      .post(
        `${environment.api}/product-service/add-provider-productlist`,
        newProductList,
        {
          headers: new HttpHeaders({
            Authorization: token,
          }),
        }
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public removerFornecedor(listaProdutos: any, enterprise: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const newProductList = {
      productList: listaProdutos,
      enterprise: {
        document:
          enterprise && enterprise.document
            ? enterprise.document.replace(/\D/g, '')
            : '',
      },
    };

    return this.http
      .post(
        `${environment.api}/product-service/remove-provider-productlist`,
        newProductList,
        {
          headers: new HttpHeaders({
            Authorization: token,
          }),
        }
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public sendTechnicalList(body: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }

    return this.http
      .post(`${environment.api}/datasheet-service/create`, body, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public listarProdutosPorDocumento(documento: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          document: documento,
        },
      };
    }

    return this.http
      .get(`${environment.api}/product-service/products-by-provider`, http)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          throw error;
        })
      );
  }

  public findProduct(body: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }

    return this.http
      .post(`${environment.api}/product-service/find`, body, http)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          throw error;
        })
      );
  }


  public findAnalysisByProductHashAndProviderDocument(productHash: string, providerDocument: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params: {
          productHash,
          providerDocument,
        },
      };
    }

    return this.http.get(`${environment.api}/analysis-service/find-analysis-by-product-hash-and-provider-document`, http)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public lastMovementByProductAndProvider(options: {
    productEan?: string;
    productPlu?: string;
    provider?: string;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams();

    if (options.productEan) {
      params = params.set('productEan', options.productEan.trim());
    }
    if (options.productPlu) {
      params = params.set('productPlu', options.productPlu.trim());
    }
    if (options.provider) {
      params = params.set('provider', options.provider.trim());
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/movement-service/last-movement-by-product-and-provider`, http)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  findByEconomicGroupAndProductHash(productHash: string, economicGroup: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams();

    if (productHash) {
      params = params.set('productHash', productHash);
    }
    if (economicGroup) {
      params = params.set('economicGroup', economicGroup);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/enterprise-product-service/find-by-economic-group-and-product-hash`, http)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

}
