import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';

@Component({
  selector: 'app-modal-origins',
  templateUrl: './modal-origins.component.html',
  styleUrls: ['./modal-origins.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalOriginsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  protected array: Array<any> = [];
  protected dataSource = new MatTableDataSource<any>([]);
  protected displayedColumns: string[] = [
    'status',
    'car',
    'document',
    'fantasyName'
  ];
  protected tableData: any;

  constructor(private dialogRef: MatDialogRef<ModalOriginsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.origins);
    if (this.data.productType === 'FLV') {
      this.displayedColumns.push('identifier');
      this.displayedColumns.push('product');
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  closeModal() {
    this.dialogRef.close();
  }

  formatCpfCnpj(item: string) {
    return formatCpfCnpj(item);
  }

}
