import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialAuditComponent } from './social-audit.component';
import { SocialAuditRoutingModule } from './social-audit-routing.module';
import { SocialAuditModalComponent } from './social-audit-modal/social-audit-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  declarations: [
    SocialAuditComponent,
    SocialAuditModalComponent,
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    SocialAuditRoutingModule,
    ReactiveFormsModule,
  ]
})
export class SocialAuditModule { }
