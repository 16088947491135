import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { dateValidator } from 'src/app/validators/dateValidator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TraceabilityService } from 'src/app/services/traceability.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { CompanyService } from 'src/app/services/company.service';
import { ProviderSelectFilterComponent } from '../provider-select-filter/provider-select-filter.component';
import { ModalOriginsComponent } from '../modal-origins/modal-origins.component';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ProductSelectFilterComponent } from '../product-select-filter/product-select-filter.component';
import { StoreCDSelectFilterComponent } from '../store-cd-select-filter/store-cd-select-filter.component';
import { UserService } from 'src/app/services/user.service';
import { dateNotBeforeValidator } from 'src/app/validators/dataBeforeValidator';
import { GpaReceivementModel } from 'src/app/interfaces/gpa-receivement.interface';
import { dateNotBefore } from 'src/app/validators/dateNotBeforeValidator';

@Component({
  selector: 'app-search-traceability',
  templateUrl: './search-traceability.component.html',
  styleUrls: ['./search-traceability.component.scss'],
})
export class SearchTraceabilityComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  loading: boolean = false;
  beforeProductList: any;
  showOptions: boolean = false;
  economicGroup: string = "";
  storage: Storage;
  sif: any;
  car: any;
  identifier: any;
  invoiceNumber: any;
  productSwitched: any;
  tableActive: boolean = false;
  typeProduct: string = "";
  formTraceability: FormGroup;
  traceabilitys: any;
  dataSource = new MatTableDataSource<any>();
  tableData: GpaReceivementModel[] = [];
  products: any;
  productHashSelected: string = "";

  @ViewChild('inputProvider') inputProvider!: ProviderSelectFilterComponent;
  @ViewChild('inputProduct') inputProduct!: ProductSelectFilterComponent;
  @ViewChild('inputStoreCD') inputStoreCD!: StoreCDSelectFilterComponent;

  minCompletionDate: any;
  maxCompletionDate: any;

  openOptions: any;

  pageIndex: number = 0;
  pageSize: number = 15;
  length: number = 0;
  displayedColumns: string[] = [
    'completionDate',
    'datasProvider',
    'origin',
    'datasProduct',
    'blocked',
    'fiscalNote',
    'productionDate',
    'status'
  ];
  isCarrefour: boolean = false;
  buOptions: any = [
    {
      name: 'Atacadão',
      value: 'NzUzMTUzMzM='
    },
    {
      name: 'Carrefour',
      value: 'NDUuNTQzLjkxNQ=='
    },
    {
      name: "Sam's Club",
      value: 'MDAuMDYzLjk2MA=='
    }
  ];

  protected isUserProvider: boolean = false;
  protected document: any = null;
  protected providerDocument: any = null;

  constructor(private route: ActivatedRoute,
    private traceabilityService: TraceabilityService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private productService: ProdutoService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private userService: UserService,
  ) {
    const maxDate = new Date();
    this.maxCompletionDate = maxDate.setDate(maxDate.getDate());
    this.storage = window.sessionStorage;

    const minDate = new Date(2020, 0, 1);
    this.formTraceability = this.fb.group({
      startDate: ['', [Validators.required, dateValidator, dateNotBeforeValidator(minDate)]],
      endDate: ['', [Validators.required, dateValidator, dateNotBefore('startDate')]],
      product: [''],
      fiscalNote: [''],
      identifier: [''],
      car: [''],
      sif: [''],
      localType: [''],
      storeCD: [''],
      provider: [''],
      carrefourLocal: [''],
    });
  }

  ngOnInit(): void {

    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      this.isUserProvider = true;

      const customerId = user.customerId;
      this.providerDocument = atob(customerId);
    }

    if (user.authorities.some((item: string) => item.includes("CARREFOUR"))) {
      this.isCarrefour = true;
      this.formTraceability.get('localType')?.setValidators(null);
      this.formTraceability.get('carrefourLocal')?.setValidators(Validators.required);
      this.displayedColumns.push('quantity');
    } else {
      this.displayedColumns.push('localDelivery');
      this.isCarrefour = false;
    }

    this.economicGroup = this.userService.getEconomicGroup();

    this.typeProduct = this.route.snapshot.url.map(segment => segment.path).join('/');
    if (this.typeProduct == 'flv')
      this.typeProduct = 'FLV';
    else if (this.typeProduct == 'bovinos')
      this.typeProduct = 'BOVINOS'
    else if (this.typeProduct == 'suinos')
      this.typeProduct = 'SUINOS'
    else if (this.typeProduct == 'pescado')
      this.typeProduct = 'Pescado'
    else if (this.typeProduct == 'aves')
      this.typeProduct = 'AVES'
    else if (this.typeProduct == 'ovos')
      this.typeProduct = 'OVOS'
    else if (this.typeProduct == 'mercearia')
      this.typeProduct = 'Mercearia'
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {

    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('.input-product') && this.openOptions) {
      this.openOptions = false;
    }
  }


  getDataSourceLength(): number {
    if (this.dataSource && this.dataSource.data) {
      return this.dataSource.data.length;
    }
    return 0;
  }

  changeProviderValue(event: any) {
    this.formTraceability.get('provider')?.setValue(event);
  }


  getProducts() {
    this.loading = true;
    this.productService.getProductByName(this.formTraceability.get('product')?.value).subscribe(r => {
      this.products = r;

      this.openOptions = true;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  cleanFilters() {
    this.formTraceability.get('startDate')?.setValue("");
    this.formTraceability.get('endDate')?.setValue("");
    this.formTraceability.get('localType')?.setValue("");
    this.formTraceability.get('storeCD')?.setValue("");
    this.formTraceability.get('sif')?.setValue("");
    this.formTraceability.get('car')?.setValue("");
    this.formTraceability.get('identifier')?.setValue("");
    this.formTraceability.get('fiscalNote')?.setValue("");
    this.formTraceability.get('product')?.setValue("");
    this.formTraceability.get('identifier')?.setValue("");
    this.formTraceability.get('provider')?.setValue("");
    this.formTraceability.get('invoiceNumber')?.setValue("");

    this.inputProvider.cleanFilter();
    this.inputStoreCD.cleanFilter();
    this.inputProduct.cleanFilter();

    this.tableActive = false;
  }

  changeProductValue(productHash: any) {
    this.productHashSelected = productHash;
    this.formTraceability.get('product')?.setValue(productHash);
    this.openOptions = false;
  }

  openModal(item: any): void {
    this.dialog.open(ModalOriginsComponent, {
      data: {
        origins: item.origins,
        productType: this.typeProduct.toUpperCase(),
      }
    });
  }

  checkStartDate() {
    const completionDate = this.formTraceability.get('startDate')?.value;
    const minDate = new Date(completionDate);
    this.minCompletionDate = minDate.setDate(minDate.getDate() + 1);
    this.formTraceability.get('endDate')?.setValue('');
  }

  getTableData(pageEvent?: PageEvent): void {
    this.formTraceability.markAllAsTouched();
    if (!this.isCarrefour) {
      this.inputStoreCD.handleChanged();
    }
    if (this.formTraceability.valid) {

      if (pageEvent) {
        this.pageIndex = pageEvent.pageIndex;
        this.pageSize = pageEvent.pageSize;
      } else {
        this.pageIndex = 0;
        this.pageSize = 15;
      }
      if (this.formTraceability.get('startDate')?.value && this.formTraceability.get('endDate')?.value) {
        const userString = this.storage.getItem('user');
        let customerID;
        if (userString !== null) {
          const userJSON = JSON.parse(userString);
          customerID = userJSON.customerId;
        }

        const economicGroup: any = this.isCarrefour ? this.formTraceability.get('carrefourLocal')?.value : this.storage.getItem('economicGroup');
        if (economicGroup) {
          this.economicGroup = economicGroup;
          this.getInfoTable();
        } else {
          this.loading = true;
          this.companyService.getEnterpriseByCustomerId(customerID).subscribe(r => {
            let enterprise: any = r;
            this.economicGroup = enterprise[0].economicGroup;
            if (this.isUserProvider) {
              this.document = enterprise[0]?.document;
            }
            this.storage.setItem('economicGroup', this.economicGroup);
            this.getInfoTable();
          }, error => {
            this.loading = false;
          });
        }
      }
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: 'Preencha os filtros',
        }
      });
    }
  }

  getInfoTable() {
    let options: any;

    if (this.isCarrefour) {
      options = {
        providerDocument: this.isUserProvider? '' : this.formTraceability.get('provider')?.value,
        productHash: this.formTraceability.get('product')?.value,
        startDate: this.formTraceability.get('startDate')?.value,
        endDate: this.formTraceability.get('endDate')?.value,
        productType: this.typeProduct.toUpperCase(),
        operationalType: this.formTraceability.get('localType')?.value,
        carrefour: this.isCarrefour,
        customerDocument: this.formatCnpj(this.formTraceability.get('storeCD')?.value),
        economicGroup: this.formTraceability.get('storeCD')?.value,
        invoiceNumber: this.formTraceability.get('fiscalNote')?.value ? this.formTraceability.get('fiscalNote')?.value + ';' : '',
        identifier: this.formTraceability.get('identifier')?.value,
        sif: this.formTraceability.get('sif')?.value,
        car: this.formTraceability.get('car')?.value,
        page: this.pageIndex,
        size: this.pageSize,
      }


    } else {
      options = {
        providerDocument: this.isUserProvider ? '' : this.formTraceability.get('provider')?.value,
        productHash: this.formTraceability.get('product')?.value,
        documentDelivery: this.formTraceability.get('storeCD')?.value,
        startDate: this.formTraceability.get('startDate')?.value,
        endDate: this.formTraceability.get('endDate')?.value,
        productType: this.typeProduct,
        operationalType: this.formTraceability.get('localType')?.value,
        economicGroup: this.isUserProvider ? "NDc1MDg0MTE=" : this.economicGroup,
        invoiceNumber: this.formTraceability.get('fiscalNote')?.value ? this.formTraceability.get('fiscalNote')?.value + ';' : '',
        identifier: this.formTraceability.get('identifier')?.value,
        sif: this.formTraceability.get('sif')?.value,
        car: this.formTraceability.get('car')?.value,
        page: this.pageIndex,
        size: this.pageSize,
      }
    }

    this.loading = true;
    this.traceabilityService.getListPaginatedCahe(options).subscribe((res: any) => {
      this.loading = false;
      this.tableData = res.content;
      if (this.tableData && this.tableData.length <= 0) {
        this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
          duration: 2 * 1000,
          panelClass: ['alert_error'],
          horizontalPosition: "right",
          verticalPosition: "top"
        });
      }
      this.pageIndex = res.currentPage;
      this.length = res.totalItems;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.tableActive = true;
    }, error => {
      this.tableData = [];
      this.tableActive = true;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.loading = false;
      this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
        duration: 2 * 1000,
        panelClass: ['alert_error'],
        horizontalPosition: "right",
        verticalPosition: "top"
      });
    });
  }

  changeStoreCDValue(event: any) {
    this.formTraceability.get('storeCD')?.setValue(event);
  }

  exportToExcel(fileName: string): void {
    const exportData: any[] = [];
    if (this.tableData.length > 0) {
      let options: any;

      if (this.isCarrefour) {

        options = {
          providerDocument: this.formTraceability.get('provider')?.value,
          productHash: this.formTraceability.get('product')?.value ? this.formTraceability.get('product')?.value : '',
          startDate: this.formTraceability.get('startDate')?.value,
          endDate: this.formTraceability.get('endDate')?.value,
          productType: this.typeProduct.toUpperCase(),
          carrefour: this.isCarrefour,
          customerDocument: this.formatCnpj(this.formTraceability.get('storeCD')?.value),
          economicGroup: this.formTraceability.get('storeCD')?.value,
          invoiceNumber: this.formTraceability.get('fiscalNote')?.value ? this.formTraceability.get('fiscalNote')?.value + ';' : '',
          identifier: this.formTraceability.get('identifier')?.value,
          sif: this.formTraceability.get('sif')?.value,
          car: this.formTraceability.get('car')?.value,
          page: this.pageIndex,
          size: this.pageSize,
        }

      } else {
        options = {
          providerDocument: this.isUserProvider ? this.providerDocument : this.formTraceability.get('provider')?.value,
          productHash: this.formTraceability.get('product')?.value ? this.productHashSelected : '',
          documentDelivery: this.formTraceability.get('storeCD')?.value,
          startDate: this.formTraceability.get('startDate')?.value,
          endDate: this.formTraceability.get('endDate')?.value,
          productType: this.typeProduct,
          economicGroup: this.isUserProvider ? "NDc1MDg0MTE=" : this.economicGroup,
          invoiceNumber: this.formTraceability.get('fiscalNote')?.value ? this.formTraceability.get('fiscalNote')?.value + ';' : '',
          identifier: this.formTraceability.get('identifier')?.value,
          sif: this.formTraceability.get('sif')?.value,
          car: this.formTraceability.get('car')?.value,
          page: this.pageIndex,
          size: this.pageSize,
        }
      }

      this.loading = true;
      this.traceabilityService.exportListPaginated(options).subscribe((res: any) => {
        let response = res;
        this.dialog.open(ModalConfirmComponent, {
          data: {
            success: true,
            successText: response,
          }
        })
        this.loading = false;
      }, error => {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: true,
            errorText: 'Falha ao gerar arquivo para exportação!',
          }
        })
        this.loading = false;
      });
    }
  }

  getStoreCDSearchOptions(event: any) {
    // this.inputStoreCD.getStoreCDSearchOptions('');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  formatCnpj(cnpj: any): any {
    cnpj = cnpj.replace(/\D/g, '');

    if (cnpj.length <= 14) {
      cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }
    return cnpj
  }

}
