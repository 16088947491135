import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private storage: Storage;

  constructor(private router: Router, private authService: AuthService) {
    this.storage = window.localStorage;
  }

  get(key: string): any {
    if (this.storage) {
      let obj = this.storage.getItem(key);
      return JSON.parse(obj!);
    }
    return null;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.auth()) {

      let user : any = sessionStorage.getItem('user');

      if(user == undefined){
        return false;
      }

      user = JSON.parse(user);

      if((user.authorities.some((item: string) => item === "ROLE_GPA_GEOMONITORING_ADMIN"))){
        return true;
      }

      if((user.authorities.some((item: string) => item === "ROLE_GEOMONITORING_CLIENT_LEGACY" || item === "ROLE_GEOMONITORING_CLIENT" || item === "ROLE_GEOMONITORING_ADMIN")) && !state.url.includes("safe-gis")){
        this.router.navigate(['/safe-gis']);
        return true;
      }

      if((user.authorities.some((item: string) => item === "ROLE_PROVIDER")) && !state.url.includes("minha-empresa")){
        this.router.navigate(['/minha-empresa/home']);
        return true;
      }

      if(!user.authorities.some((item: string) => item === "ROLE_PROVIDER" || item === "ROLE_GEOMONITORING_CLIENT_LEGACY" || item === "ROLE_GEOMONITORING_CLIENT" || item === "ROLE_GEOMONITORING_ADMIN") && (state.url.includes("safe-gis") || state.url.includes("minha-empresa"))){
        this.router.navigate(['/home']);
        return true;
      }

      return true;
    }
    this.router.navigateByUrl('login');
    return false;
  }
}
