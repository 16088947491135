import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isCarrefour:boolean = false;
  isAuditing: boolean = false;
  isQuality: boolean = false;
  constructor() { }

  ngOnInit(): void {
    let user: any = sessionStorage.getItem('user');
    user = JSON.parse(user);

    if (user.authorities.some((item: string) => item.includes("CARREFOUR"))) {
      this.isCarrefour = true;
    } else {
      this.isCarrefour = false;
    }

    if (user.authorities.some((item: string) => item === "ROLE_QUALITY" || item === "ROLE_QUALITY_AUDITING")) {
      this.isAuditing = true;
    }

    if (user.authorities.some((item: string) => item === "ROLE_QUALITY")) {
      this.isQuality = true;
    }
  }

}
