import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalAlternativasComponent } from '../modal-alternativas/modal-alternativas.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/services/company.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { QuestionProcessService } from 'src/app/services/question-process.service';

export interface SubOption {
  index: number;
  nameOptionRoot: string;
  title?: any;
  subOptions?: any[];
}

interface SubOptions {
  name: string;
  type: string;
  item_1?: string;
  order_1?: string;
  points_1?: string;
  item_2?: string;
  order_2?: string;
  points_2?: string;
  item_3?: string;
  order_3?: string;
  points_3?: string;
}

interface OriginalObject {
  index: number;
  nameOptionRoot: string;
  title: string;
  subOptions: SubOptions | string[];
}

interface TransformedObject {
  value: string;
  name: string;
  type: string;
  criteriaParams: TransformedObject[];
  description: string
}

@Component({
  selector: 'app-accordion-perguntas',
  templateUrl: './accordion-perguntas.component.html',
  styleUrls: ['./accordion-perguntas.component.scss']
})
export class AccordionPerguntasComponent implements OnInit {
  @Output() accordionOpen = new EventEmitter<boolean>();
  @Input() nQuestion: number = 0;
  @Input() hash: any = "";
  @Input() last: boolean = false;
  @Input() openIndexReceveid: boolean = false;
  @Output() toggle = new EventEmitter<number>();
  @Output() remove = new EventEmitter<number>();
  @Input() isOpen: boolean = false;
  @Input() questionCreated: any = "";
  @Output() processCreated = new EventEmitter<number>();
  currentIndex: number = -1;
  submited: boolean = false;
  formAlternativa: any;
  form: FormGroup;
  titleSubOptionCount: number = 0;
  subOptions: SubOption[] = [];
  selectedGravidades: string[] = [];
  firstSubQuestion: string = "";
  mainOptionForSuboptions: string = "";
  subOptionSelected: string = "";
  selectedIndexSuboptionsArray: number[] = [];
  @ViewChildren('radioInputSubOption') radioInputs!: QueryList<ElementRef>;

  gravidades = [
    { id: 'leve', name: 'leve', label: 'Leve' },
    { id: 'media', name: 'media', label: 'Média' },
    { id: 'grave', name: 'grave', label: 'Grave' },
    { id: 'gravissimo', name: 'gravissimo', label: 'Gravíssimo' }
  ];

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private questionProcessService: QuestionProcessService
  ) {
    this.form = this.formBuilder.group({
      mainOptionForSuboptions: [''],
      tituloCaixaTextos: [''],
      pergunta: ['', Validators.required],
      descricao: [''],
      pesoPergunta: [''],
      impactoFinanceiro: [''],
      required: [false],
      alternativaEscolhida: [''],
      naoConformidade: [''],
      gravidadeNaoConformidade: ['leve'],
      permissaoClassificacaoNaoConformidade: [false],
      alerta: [''],
      limiteMaximoAlerta: [''],
      mensagemModalAlerta: [''],
      gerarFormulaMaximo: [false],
      bloqueioContinuacao: [''],
      limiteMaximoBloqueio: [''],
      mensagemModalBloqueio: [''],
      gerarFormulaBloqueio: [false],
      fotos: [],
      fotosObrigatorio: [false],
      anexos: [],
      ordemPergunta: [''],
      caixaTextos: [],
      anexosObrigatorio: [false],
      caixaTextosObrigatorio: ['']
    });

  }

  ngOnInit(): void {

    if (this.questionCreated) {
      this.formAlternativa = this.questionCreated;

      this.form.patchValue({
        pergunta: this.questionCreated.name,
        descricao: this.questionCreated.description,
      });

      const alternativaList = this.questionCreated.criteriaParams.find((param: any) => param.description === 'ALTERNATIVAS');

      if (alternativaList) {
        alternativaList.criteriaParams.forEach((option: any, index: any) => {
          const itemKey = `item_${index + 1}`;
          const orderKey = `order_${index + 1}`;
          const pointsKey = `points_${index + 1}`;

          this.formAlternativa[itemKey] = option.name;

          const orderParam = option.criteriaParams.find((param: any) => param.name === 'ORDEM');
          if (orderParam) {
            this.formAlternativa[orderKey] = orderParam.value;
          }

          const pointsParam = option.criteriaParams.find((param: any) => param.name === 'PONTOS');
          if (pointsParam) {
            this.formAlternativa[pointsKey] = pointsParam.value;
          }

        });
      }

      this.formAlternativa = {
        name: this.form.value.pergunta,
        type: '',
        question: this.form.value.pergunta
      };

      const typeMapping: any = {
        'MULTIPLE_CHOICE': 'multiple',
        'INPUT_NUMBER': 'number',
        'INPUT_TEXT': 'text',
        'BOOLEAN': 'boolean'
      };

      const alternativaPrincipal = this.questionCreated.criteriaParams.find((param: any) => param.description === 'ALTERNATIVAS');

      if (alternativaPrincipal) {
        this.formAlternativa.type = typeMapping[alternativaPrincipal.type] || 'text';

        alternativaPrincipal.criteriaParams.forEach((option: any, index: any) => {
          const itemKey = `item_${index + 1}`;
          const orderKey = `order_${index + 1}`;
          const pointsKey = `points_${index + 1}`;

          this.formAlternativa[itemKey] = option.name;

          const orderParam = option.criteriaParams.find((param: any) => param.name === 'ORDEM');
          if (orderParam) {
            this.formAlternativa[orderKey] = orderParam.value;
          }

          const pointsParam = option.criteriaParams.find((param: any) => param.name === 'PONTOS');
          if (pointsParam) {
            this.formAlternativa[pointsKey] = pointsParam.value;
          }
        });
      }



      const pesoParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'PESO');
      if (pesoParam) {
        this.form.patchValue({
          pesoPergunta: pesoParam.value
        });
      }

      const ordemParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'ORDEM');
      if (ordemParam) {
        this.form.patchValue({
          ordemPergunta: ordemParam.value
        });
      }

      const impactoFinanceiroParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'IMPACTO_FINANCEIRO');
      if (impactoFinanceiroParam) {
        this.form.patchValue({
          impactoFinanceiro: impactoFinanceiroParam.value
        });
      }

      const requiredParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'OBRIGATORIA');
      if (requiredParam) {
        this.form.patchValue({
          required: requiredParam.value
        });
      }

      const limiteMaximoAlertaParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'LIMITE_MAXIMO_ALERTA');

      if (limiteMaximoAlertaParam) {
        this.form.patchValue({
          limiteMaximoAlerta: limiteMaximoAlertaParam.value
        });

        this.form.patchValue({
          mensagemModalAlerta: limiteMaximoAlertaParam.criteriaParams.find((param: any) => param.name === 'MENSAGEM')?.value
        });
      }

      const limiteMaximoBloqueioParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'LIMITE_MAXIMO_BLOQUEIO');

      if (limiteMaximoBloqueioParam) {
        this.form.patchValue({
          limiteMaximoBloqueio: limiteMaximoBloqueioParam.value
        });

        this.form.patchValue({
          mensagemModalBloqueio: limiteMaximoBloqueioParam.criteriaParams.find((param: any) => param.name === 'MENSAGEM')?.value
        });
      }


      const fotosParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'FOTOS');
      if (fotosParam) {
        this.form.get('fotosObrigatorio')?.setValue(true);
      }

      const anexosParam = this.questionCreated.criteriaParams.find((param: any) => param.name === 'ANEXOS');
      if (anexosParam) {
        this.form.get('anexosObrigatorio')?.setValue(true);
      }


      const alternativa = this.questionCreated.criteriaParams.find((param: any) => param.description === 'ALTERNATIVAS');
      if (alternativa) {
        alternativa.criteriaParams.forEach((option: any) => {
          let fieldName = '';

          Object.entries(this.formAlternativa).forEach(([key, value]) => {
            if (value === option.name) {
              fieldName = key;
            }
          })

          const naoConformidade = option.criteriaParams.find((param: any) => param.name === 'NÃO CONFORMIDADE');
          if (naoConformidade) {
            const todasAsGravidades = ["leve", "media", "grave", "gravissimo"];

            this.form.patchValue({
              naoConformidade: fieldName,
            });

            const gravidadesPresentes = naoConformidade.criteriaParams.map((param: any) => param.name);
            const gravidadesAusentes = todasAsGravidades.filter(gravidade => !gravidadesPresentes.includes(gravidade));

            if (gravidadesAusentes && gravidadesPresentes.length > 1) {
              this.form.get("permissaoClassificacaoNaoConformidade")?.setValue(true);
              this.selectedGravidades.push(...gravidadesAusentes);
            }

            else if (gravidadesPresentes.length == 1) {
              this.form.get('gravidadeNaoConformidade')?.setValue(gravidadesPresentes[0])
            }
          }


          const boxTextParam = option.criteriaParams.find((param: any) => param.name === 'CAIXA_TEXTOS');
          if (boxTextParam) {

            this.form.patchValue({
              caixaTextos: fieldName,
              tituloCaixaTextos: boxTextParam.criteriaParams.find((param: any) => param.name === 'TITULO_CAIXA_TEXTOS')?.value
            });
          }

          const subOptionMain = option.criteriaParams.find((param: any) => param.description === 'SUB_ALTERNATIVAS');
          if (subOptionMain) {

            this.mainOptionForSuboptions = fieldName;
            this.processSubOptionsObject(subOptionMain, this.formAlternativa[this.mainOptionForSuboptions], '')
          }


          const alertParam = option.criteriaParams.find((param: any) => param.name === 'ALERTA');
          if (alertParam) {
            this.form.patchValue({
              alerta: fieldName,
              mensagemModalAlerta: alertParam.criteriaParams.find((param: any) => param.name === 'MENSAGEM')?.value
            });
          }

          const bloqueioParam = option.criteriaParams.find((param: any) => param.name === 'BLOQUEIO');
          if (bloqueioParam) {
            this.form.patchValue({
              bloqueioContinuacao: fieldName,
              mensagemModalBloqueio: bloqueioParam.criteriaParams.find((param: any) => param.name === 'MENSAGEM')?.value
            });
          }


          const fotosParam = option.criteriaParams.find((param: any) => param.name === 'FOTOS');
          if (fotosParam) {
            this.form.patchValue({
              fotos: fieldName
            });
          }

          const anexosParam = option.criteriaParams.find((param: any) => param.name === 'ANEXOS');
          if (anexosParam) {
            this.form.patchValue({
              anexos: fieldName
            });
          }

        });
      }
    }
  }


  processSubOptionsObject(backendObject: any, parentNameOptionRoot: string, parentTitle: string): void {
    const processedSubOptions: any[] = [];
    this.currentIndex = -1;

    const processObject = (obj: any, parentName: string, parentTitle: string, isLast: boolean): void => {
      const translatedType = this.translateType(this.getFirstCriteriaParamType(obj));

      const subOption: any = {
        index: this.currentIndex,
        nameOptionRoot: parentName,
        title: parentTitle,
        subOptions: {
          name: this.getFirstCriteriaParamName(obj),
          type: translatedType,
          question: this.getFirstCriteriaParamName(obj),
        },
      };

      obj.criteriaParams.forEach((param: any, index: number) => {
        subOption.subOptions[`item_${index + 1}`] = param.value;

        const orderParam = param.criteriaParams.find((p: any) => p.name === 'ORDEM');
        if (orderParam) {
          subOption.subOptions[`order_${index + 1}`] = orderParam.value;
        } else {
          subOption.subOptions[`order_${index + 1}`] = "";
        }

        const pointsParam = param.criteriaParams.find((p: any) => p.name === 'PONTOS');
        if (pointsParam) {
          subOption.subOptions[`points_${index + 1}`] = pointsParam.value;
        } else {
          subOption.subOptions[`points_${index + 1}`] = "";
        }

        const isLastParam = (index === obj.criteriaParams.length - 1 && param.criteriaParams.length === 0);
        if (param.criteriaParams.length > 0) {
          this.currentIndex++;
          processObject(param, param.value, param.name, isLastParam);
        }
      });

      processedSubOptions.push(subOption);


      if (isLast) {

        this.subOptions = this.sortObjectsByIndex(processedSubOptions);
      }
    };

    processObject(backendObject, parentNameOptionRoot, parentTitle, true);
  }

  getFirstCriteriaParamName(obj: any): string {
    if (obj.criteriaParams.length > 0) {
      return obj.criteriaParams[0].name;
    }
    return "";
  }

  getFirstCriteriaParamType(obj: any): string {
    if (obj.criteriaParams.length > 0) {
      return obj.criteriaParams[0].type;
    }
    return "";
  }

  translateType(type: string): string {
    const typeMapping: { [key: string]: string } = {
      'MULTIPLE_CHOICE': 'multiple',
      'INPUT_NUMBER': 'number',
      'INPUT_TEXT': 'text',
      'BOOLEAN': 'boolean'
    };
    return typeMapping[type] || type;
  }

  sortObjectsByIndex(arr: any): any {
    return arr.sort((a: any, b: any) => a.index - b.index);
  }

  getOptionRoot(targetIndex: number) {
    const foundObject = this.subOptions.find(obj => obj.index === targetIndex);
    return foundObject ? foundObject.nameOptionRoot : "";
  }

  getQuestion(data: any): string {

    if (data && data.subOptions && data.subOptions.question) {
      return data.subOptions.question;
    }
    return '';
  }

  onCheckboxChangeGravidade(event: any, value: string) {
    if (event.target.checked) {
      this.selectedGravidades.push(value);
    } else {
      const index = this.selectedGravidades.indexOf(value);
      if (index !== -1) {
        this.selectedGravidades.splice(index, 1);
      }
    }

  }

  isGravidadeSelected(label: string): boolean {
    const gravidadeName = this.mapLabelToName(label);
    return this.selectedGravidades.includes(gravidadeName);
  }

  mapLabelToName(label: string): string {
    const gravidade = this.gravidades.find(g => g.label.toLowerCase() === label.toLowerCase());
    return gravidade ? gravidade.name : '';
  }

  onCheckboxChange(event: any, item: string, nameForm: string) {
    if (event.target.checked) {
      this.form.get(nameForm)?.setValue(`${item}`);
    } else {
      this.form.get(nameForm)?.setValue('');
    }
  }

  transformSubOptions(subOptions: any, title: string): any[] {
    const criteriaParams: any[] = [];

    for (let i = 1; i <= 3; i++) {
      const itemKey = `item_${i}` as keyof SubOptions;

      if (subOptions[itemKey]) {
        criteriaParams.push({
          name: subOptions?.question,
          description: "SUB_ALTERNATIVAS",
          value: subOptions[itemKey],
          type: subOptions?.type == 'multiple' ? 'MULTIPLE_CHOICE' : subOptions.type == 'number' ? 'INPUT_NUMBER' : subOptions.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
          criteriaParams: []
        });
      }
    }

    return criteriaParams;
  }

  removeDuplicates(criteriaParams: any[]): any[] {
    const uniqueValues = new Map<string, any>();

    for (const obj of criteriaParams) {
      const existing = uniqueValues.get(obj.value);

      if (!existing) {
        uniqueValues.set(obj.value, obj);
      } else if ((!existing.criteriaParams || existing.criteriaParams.length === 0) && obj.criteriaParams && obj.criteriaParams.length > 0) {
        uniqueValues.set(obj.value, obj);
      }
    }

    return Array.from(uniqueValues.values());
  }



  transformObject(original: any[], index: number = 0): any | null {
    if (index >= original.length) return null;

    const current = original[index];
    let criteriaParams: any[] = [];

    if (index == 0) {
      this.firstSubQuestion = current.subOptions?.question;
    }

    if (typeof current.subOptions === 'object' && 'type' in current.subOptions) {
      criteriaParams = this.transformSubOptions(current.subOptions, current.title);
    }

    const nextTransformed = this.transformObject(original, index + 1);

    if (nextTransformed) {
      criteriaParams.push(nextTransformed);
    }

    criteriaParams = this.removeDuplicates(criteriaParams);

    return {
      name: index > 0 ? original[index - 1].subOptions?.question : '',
      description: "SUB_ALTERNATIVAS",
      value: current.nameOptionRoot,
      type: index > 0 ? original[index - 1].subOptions?.type == 'multiple' ? 'MULTIPLE_CHOICE' : original[index - 1].subOptions?.type == 'number' ? 'INPUT_NUMBER' : original[index - 1].subOptions?.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT' || '' : '',
      criteriaParams: criteriaParams
    };
  }


  createJSON() {
    let options = this.formKeys;

    let alte: any[] = [];
    let transformedData: any;
    let objectCriteriaParams: any;

    if (this.subOptions) {
      transformedData = this.transformObject(this.subOptions);
    }

    if (this.formAlternativa.type == 'multiple' || this.formAlternativa.type == 'boolean') {
      options.forEach((option: any) => {
        let criteriaParams: any[] = [];

        if (this.form.get('naoConformidade')?.value == option) {
          criteriaParams.push({
            "name": "NÃO CONFORMIDADE",
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "criteriaParams": this.getGravidadeCriteriaParams()
          });
        }

        if (this.form.get('alerta')?.value == option) {
          criteriaParams.push({
            "name": "ALERTA",
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "criteriaParams": [
              {
                "name": "MENSAGEM",
                "value": this.form.get('mensagemModalAlerta')?.value,
                "type": "DEFAULT",
                "criteriaParams": []
              }
            ]
          });
        }

        if (this.form.get('bloqueioContinuacao')?.value == option) {
          criteriaParams.push({
            "name": "BLOQUEIO",
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "criteriaParams": [
              {
                "name": "MENSAGEM",
                "value": this.form.get('mensagemModalBloqueio')?.value,
                "type": "DEFAULT",
                "criteriaParams": []
              }
            ]
          });
        }

        if (this.form.get('fotos')?.value == option) {
          const fotosObrigatorioValue = true;

          let criteriaParamsEntry: any = {
            "name": "FOTOS",
            "type": 'IMAGE_FILE',
            "criteriaParams": []
          };

          if (fotosObrigatorioValue) {
            criteriaParamsEntry.criteriaParams.push({
              "name": "OBRIGATORIO",
              "value": fotosObrigatorioValue,
              "type": "DEFAULT",
              "criteriaParams": []
            });
          }

          criteriaParams.push(criteriaParamsEntry);
        }

        if (this.form.get('anexos')?.value == option) {
          let criteria: any = {
            "name": "ANEXOS",
            "type": 'TEXT_FILE',
            "criteriaParams": [
            ]
          }

          let obrigatorioCriteria: any = {
            "name": "OBRIGATORIO",
            "value": this.form.get('anexosObrigatorio')?.value,
            "type": "DEFAULT",
            "criteriaParams": []
          };

          criteria['criteriaParams'].push(obrigatorioCriteria);


          criteriaParams.push(criteria);
        }

        if (this.form.get('caixaTextos')?.value == option) {
          let caixaTextosCriteriaParams: any[] = [];

          if (this.form.get('caixaTextosObrigatorio')?.value) {
            caixaTextosCriteriaParams.push({
              "name": "OBRIGATORIO",
              "value": true,
              "type": "DEFAULT",
              "criteriaParams": []
            });
          }

          if (this.form.get('tituloCaixaTextos')?.value != '') {
            caixaTextosCriteriaParams.push({
              "name": "TITULO_CAIXA_TEXTOS",
              "value": this.form.get('tituloCaixaTextos')?.value,
              "type": "TITLE",
              "criteriaParams": []
            });
          }

          criteriaParams.push({
            "name": "CAIXA_TEXTOS",
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "criteriaParams": caixaTextosCriteriaParams
          });
        }

        if (this.findValueByItemName(option, 'order') != null) {
          criteriaParams.push({
            "name": "ORDEM",
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "value": this.findValueByItemName(option, 'order')
          });
        }

        if (this.findValueByItemName(option, 'points') != null) {
          criteriaParams.push({
            "name": "PONTOS",
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "value": this.findValueByItemName(option, 'points')
          });
        }

        if (transformedData && transformedData.value == this.formAlternativa[option]) {
          criteriaParams.push({
            "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
            "name": this.firstSubQuestion,
            "description": "SUB_ALTERNATIVAS",
            "criteriaParams": transformedData.criteriaParams
          });
        }

        alte.push({
          "name": this.formAlternativa[option],
          "value": "false",
          "criteriaParams": criteriaParams
        });
      });

      objectCriteriaParams = this.removerPropriedadesNull(alte);
    }

    let json: any = {
      "name": this.form.get('pergunta')?.value,
      "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
      "description": this.form.get('descricao')?.value,
      "criteriaParams": [
        {
          "name": this.formAlternativa?.name,
          "value": "1",
          "description": "ALTERNATIVAS",
          "type": this.formAlternativa.type == 'multiple' ? 'MULTIPLE_CHOICE' : this.formAlternativa.type == 'number' ? 'INPUT_NUMBER' : this.formAlternativa.type == 'boolean' ? 'BOOLEAN' : 'INPUT_TEXT',
          "criteriaParams": objectCriteriaParams
        }
      ]
    };

    if (this.form.get('ordemPergunta')?.value) {
      json.criteriaParams.push({
        "name": "ORDEM",
        "value": this.form.get('ordemPergunta')?.value,
        "type": "DEFAULT",
        "criteriaParams": []
      })
    }

    if (this.form.get('pesoPergunta')?.value) {
      json.criteriaParams.push({
        "name": "PESO",
        "value": this.form.get('pesoPergunta')?.value,
        "type": "DEFAULT",
        "criteriaParams": []
      },)
    }

    if (this.form.get('impactoFinanceiro')?.value) {
      json.criteriaParams.push({
        "name": "IMPACTO_FINANCEIRO",
        "value": this.form.get('impactoFinanceiro')?.value,
        "type": "DEFAULT",
        "criteriaParams": []
      })
    }

    if (this.form.get('required')?.value) {
      json.criteriaParams.push({
        "name": "OBRIGATORIA",
        "value": this.form.get('required')?.value,
        "type": "DEFAULT",
        "criteriaParams": []
      })
    }

    if (this.form.get('anexosObrigatorio')?.value && this.formAlternativa.type != 'multiple' && this.formAlternativa.type != 'boolean') {
      json.criteriaParams.push({
        "name": "ANEXOS",
        "type": 'TEXT_FILE',
        "criteriaParams": [
          {
            "name": "OBRIGATORIO",
            "value": true,
            "type": "DEFAULT",
            "criteriaParams": []
          }
        ]
      });
    }

    if (this.form.get('fotosObrigatorio')?.value && this.formAlternativa.type != 'multiple' && this.formAlternativa.type != 'boolean') {
      json.criteriaParams.push({
        "name": "FOTOS",
        "type": 'IMAGE_FILE',
        "criteriaParams": [
          {
            "name": "OBRIGATORIO",
            "value": true,
            "type": "DEFAULT",
            "criteriaParams": []
          }
        ]
      });
    }

    if (this.form.get('limiteMaximoAlerta')?.value && this.formAlternativa.type == 'number') {
      json.criteriaParams.push({
        "name": "LIMITE_MAXIMO_ALERTA",
        "type": 'INPUT_NUMBER',
        "value": this.form.get('limiteMaximoAlerta')?.value,
        "criteriaParams": [
          {
            "name": "MENSAGEM",
            "value": this.form.get('mensagemModalAlerta')?.value,
            "type": "DEFAULT",
            "criteriaParams": []
          }
        ]
      });
    }

    if (this.form.get('limiteMaximoBloqueio')?.value && this.formAlternativa.type == 'number') {
      json.criteriaParams.push({
        "name": "LIMITE_MAXIMO_BLOQUEIO",
        "type": 'INPUT_NUMBER',
        "value": this.form.get('limiteMaximoBloqueio')?.value,
        "criteriaParams": [
          {
            "name": "MENSAGEM",
            "value": this.form.get('mensagemModalBloqueio')?.value,
            "type": "DEFAULT",
            "criteriaParams": []
          }
        ]
      });
    }


    if (this.form.get('pergunta')?.value && !this.questionCreated) {
      let dialogref = this.dialog.open(ModalConfirmComponent, {
        data: {
          text: 'Deseja salvar a pergunta?',
          default: true
        },
      });
      dialogref.afterClosed().subscribe(result => {
        if (result.confirm == true) {
          this.companyService.createNewQuestionsProcess(json, this.hash).subscribe(r => {
            this.emitProcess(r);
            this.submited = true;
            this.dialog.open(ModalConfirmComponent, {
              data: {
                success: true,
                successText: 'Perguntas salvas com sucesso!',
              },
            });
          }, error => {
            this.dialog.open(ModalConfirmComponent, {
              data: {
                success: false
              },
            });
          })
        }
      })
    }
    else if (this.form.get('pergunta')?.value && this.questionCreated) {
      let dialogref = this.dialog.open(ModalConfirmComponent, {
        data: {
          text: 'Deseja editar a pergunta?',
          default: true
        },
      });
      dialogref.afterClosed().subscribe(result => {
        if (result.confirm == true) {
          this.questionProcessService.updateQuestion(this.hash, this.questionCreated.criteriaCode, json).subscribe((r: any) => {
            this.emitProcess(r);
            this.dialog.open(ModalConfirmComponent, {
              data: {
                success: true,
                successText: 'Perguntas salvas com sucesso!',
              },
            });
          }, (error: any) => {
            this.dialog.open(ModalConfirmComponent, {
              data: {
                success: false
              },
            });
          })
        }
      })
    }
  }

  emitProcess(process: any): void {
    this.processCreated.emit(process)
  }

  getGravidadeCriteriaParams() {
    let gravidade = this.form.get('gravidadeNaoConformidade')?.value;
    if (this.form.get('permissaoClassificacaoNaoConformidade')?.value === false) {
      switch (gravidade) {
        case 'leve':
          return [{ "name": "leve" }];
        case 'media':
          return [{ "name": "media" }];
        case 'grave':
          return [{ "name": "grave" }];
        case 'gravissima':
          return [{ "name": "gravissima" }];
        default:
          return [];
      }
    } else if (this.form.get('permissaoClassificacaoNaoConformidade')?.value === true) {
      return this.gravidades.filter(gravidade => !this.selectedGravidades.includes(gravidade.name));
    } else {
      switch (gravidade) {
        case 'leve':
          return [{ "name": "leve" }];
        case 'media':
          return [{ "name": "media" }];
        case 'grave':
          return [{ "name": "grave" }];
        case 'gravissima':
          return [{ "name": "gravissima" }];
        default:
          return [];
      }
    }
  }

  removerPropriedadesNull(obj: any): any {
    if (Array.isArray(obj)) {
      return obj
        .map(v => (v && typeof v === 'object') ? this.removerPropriedadesNull(v) : v)
        .filter(v => v != null);
    } else if (obj && typeof obj === 'object') {
      return Object.entries(obj)
        .reduce((acc, [key, value]) => {
          if (value && typeof value === 'object') {
            value = this.removerPropriedadesNull(value);
          }
          if (value != null) {
            acc[key] = value;
          }
          return acc;
        }, {} as { [key: string]: any });
    }
    return obj;
  }



  findValueByItemName(itemName: string, nameInput: string): any | null {
    const number = itemName.match(/\d+$/)?.[0];
    if (!number) {
      return null;
    }
    const orderPropertyName = `${nameInput}_${number}`;
    if (this.formAlternativa[orderPropertyName]) {

      return this.formAlternativa[orderPropertyName];
    } else {
      return null;
    }
  }


  addSubOption(nameRoot: string, index: number) {
    this.subOptions.push({
      index: index,
      nameOptionRoot: nameRoot,
      title: "",
      subOptions: ['']
    });
  }

  deleteQuestion() {
    let dialogref = this.dialog.open(ModalConfirmComponent, {
      data: {
        text: 'Deseja excluir a pergunta?',
        default: true
      },
    });

    dialogref.afterClosed().subscribe(result => {
      if (result.confirm == true) {
        if (this.questionCreated || this.submited) {
          this.questionProcessService.deleteQuestion(this.hash, this.questionCreated.criteriaCode).subscribe((r: any) => {
            this.remove.emit(this.nQuestion);
          })
        }
        else if (this.nQuestion != 0) {
          this.remove.emit(this.nQuestion);
        }
      }
    })

  }

  addNewTitleSubOptionName() {
    let newIndex;

    if (this.titleSubOptionCount == 0) {
      const newTitle = `titleSubOption${this.titleSubOptionCount}`;
      this.form.addControl(newTitle, new FormControl(''));
      newIndex = this.titleSubOptionCount + 1;
      this.form.addControl(newTitle, new FormControl(''));
    } else {
      newIndex = this.titleSubOptionCount + 1;
    }

    const nextTitle = `titleSubOption${newIndex}`;
    this.form.addControl(nextTitle, new FormControl(''));

    this.titleSubOptionCount = newIndex;
  }

  toggleOpen(): void {
    if (this.isOpen) {
      this.toggle.emit(-1);
    }
    else {
      this.toggle.emit(this.nQuestion);
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(ModalAlternativasComponent, {
      data: {
        options: this.formAlternativa ? this.formAlternativa : ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.formAlternativa = result.value;

      }
    });
  }

  clearField(fieldName: string): void {
    this.form.get(fieldName)?.setValue('');
    this.form.get(`${fieldName}Obrigatorio`)?.setValue(false);
  }

  clearSubOptions() {
    this.mainOptionForSuboptions = "";
    this.subOptions.length = 0;
    this.radioInputs.forEach((radio) => {
      radio.nativeElement.checked = false;
    });
  }

  setOptionSelected(optionSelected: string) {
    this.mainOptionForSuboptions = optionSelected;
  }

  setSuboptionSelected(item: string, index: number) {

    this.selectedIndexSuboptionsArray.push(index);
    this.subOptionSelected = item;
  }

  getSuboptionSelected() {
    return this.subOptionSelected;
  }

  openModalSubOptions(index: number, item: any) {
    let itens: any = "", edit: boolean = false;
    if (this.subOptions.length > 0) {
      this.subOptions.forEach(op => {
        if (op.index == index) {
          itens = op.subOptions;
          edit = true;
        }
      })
    }

    const dialogRef = this.dialog.open(ModalAlternativasComponent, {
      data: {
        subOptions: true,
        options: itens
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      result.value.type == 'number' || result.value.type == 'text' ? result.value.item_1 = "itemNumberText" : '';

      if (result && !edit) {
        this.subOptions.push({
          index: index,
          nameOptionRoot: item,
          title: result.value.name,
          subOptions: result.value
        });

      }
      else if (result && edit) {
        this.subOptions.forEach(op => {
          if (op.index == index) {
            op.title = result.value.name;
            op.subOptions = result.value;
          }
        })

        const modifiedIndex = this.subOptions.findIndex(op => op.index === index);
        if (modifiedIndex !== -1) {
          this.subOptions.splice(modifiedIndex + 1);
        }
      }
    });
  }

  get formKeys(): string[] {
    const itemKeys = Object.keys(this.formAlternativa).filter(key => key.startsWith('item_'));


    const hasOrder = itemKeys.some(key => this.formAlternativa[`order_${key.split('_')[1]}`]);

    if (!hasOrder) {
      return itemKeys;
    }

    return itemKeys.sort((a, b) => {
      const orderA = this.formAlternativa[`order_${a.split('_')[1]}`] || Number.MAX_SAFE_INTEGER;
      const orderB = this.formAlternativa[`order_${b.split('_')[1]}`] || Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }

  filterItemsStartingWithItem(index: number): string[] {
    const subOptions = this.subOptions[index]?.subOptions;
    if (!subOptions) return [];

    return Object.keys(subOptions)
      .filter(key => key.startsWith('item_'))
      .map((key: any) => subOptions[key]);
  }

  checkIfSingleItemAndEquals(i: any): boolean {
    const result = this.filterItemsStartingWithItem(i);
    if (Array.isArray(result) && result.length === 1 && result[0] === 'itemNumberText') {
      return false;
    }
    return true;
  }
}
