<link rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
<link rel="stylesheet" href="https://unpkg.com/leaflet-editable/doc/style.css" />

<script src="https://unpkg.com/leaflet/dist/leaflet.js"></script>
<link rel="stylesheet" href="https://unpkg.com/leaflet-draw@1.0.4/dist/leaflet.draw.css" />
<script src="https://unpkg.com/leaflet-providers@1.12.0/leaflet-providers.js"></script>
<div class="mapa-sobreposicao-container">
  <div class="header">
    <h2>Mapa de Sobreposição</h2>
  </div>
  <form [formGroup]="formFilter">
    <div class="filter-area" *ngIf="initialRequestList">
      <div class="filter">
        <app-select-input #inputProvider label="Produtor" [required]="false" (changeValue)="changeProducerValue($event)"
          [list]="producerOptionsList" (getSearchOptions)="getProducerSearchOptions($event)"></app-select-input>
      </div>
      <div class="filter">
        <app-select-input #inputRequest label="Inspeção" [required]="false" (changeValue)="changeRequestValue($event)"
          [list]="requestOptionsList" (getSearchOptions)="getRequestSearchOptions($event)"></app-select-input>
      </div>
      <button class="button_clear" type="submit" (click)="removeEditedLayers()" *ngIf="isAdmin" >Remover edições</button>
    </div>

    <div id="map" class="map-area" #mapArea></div>
    <div class="overlap-warn" *ngIf="renderOverlapAreas && validateEditing()">
      <label>A área resumida atual excede os limites da propriedade</label>
    </div>
    <button class="button_save" type="submit"  *ngIf="isAdmin"  (click)="extractEditableLayers()">Validar</button>
  
    <div class="overlap-title" *ngIf="isAdmin" >
      <h3>Sobreposições</h3>
    </div>
    
    <div *ngIf="renderOverlapAreas && isAdmin" >
      <div
        *ngFor="let attribute of ['property','archeologicalSites', 'conservationUnit', 'deterAmazon', 'deterCerrado', 'deterPantanal', 'ibamaEmbargo', 'indigenousLand', 'prodesAmazon', 'prodesAtlanticForest', 'prodesCaatinga', 'prodesCerrado', 'prodesPampa', 'prodesPantanal', 'publicForests', 'quilombolaAreas']">
        <div class="overlap-info" *ngIf="renderOverlap(attribute)">
          <label [textContent]="getTitle(attribute) + ': '"></label>
          <span>{{ renderOverlapArea(attribute) | number: '1.4-4' }} ha</span>
        </div>
      </div>

    </div>
  </form>
</div>