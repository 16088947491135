import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { OpenModalConfirmService } from 'src/app/services/open-modal-confirm.service';
import { iModalConfirm } from '../modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private storage!: Storage;
  public user: any;

  constructor(
    private authService:AuthService,
    private router: Router,
    private modalConfirmService: OpenModalConfirmService,
  ) {
    this.storage = window.sessionStorage
  }

  ngOnInit(): void {
    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};
  }
  logout(){
    const data: iModalConfirm = {
      type: 'exit',
      text: 'Deseja Sair?'
    }
    this.modalConfirmService.openModalConfirm(data).subscribe(result => {
      if (result.confirm) {
        this.authService.logout();
      }
    });
  }
}
