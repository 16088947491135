import { HttpParams } from "@angular/common/http";
import { ExportXlsxOptions } from "../../pages/integracao/interfaces/ExportXlsxOptions";

const addTypeOfExportXlsx = (isDate: boolean) => {
  if (isDate) {
    return 'DADOS_NAO_PROCESSADOS';
  }

  return 'DIVERGENCIA_DE_DADOS';
}

const buildExportXlsxParams = (options: ExportXlsxOptions): HttpParams => {
  let params = new HttpParams();

  if (options.startDate && options.endDate) {
    params = params.set('startDate', options.startDate);
    params = params.set('endDate', options.endDate);
  }

  if (options.type) {
    params = params.set('type', options.type);
  }

  if (options.provider) {
    params = params.set('providerDocument', options.provider);
  }

  if (options.productType){
    params = params.set('productType', options.productType);
  }

  return params;
}

const isUserProvider = (userItem: string | null): boolean =>  {
  const user = userItem ? JSON.parse(userItem) : {};
  return user.authorities?.some((item: string) => item === "ROLE_PROVIDER");
}

export {addTypeOfExportXlsx, buildExportXlsxParams, isUserProvider}
