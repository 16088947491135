import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RankingService } from 'src/app/services/ranking.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { dateValidator } from 'src/app/validators/dateValidator';
import { ProductSelectFilterComponent } from '../product-select-filter/product-select-filter.component';
import { ProviderSelectFilterComponent } from '../provider-select-filter/provider-select-filter.component';
import { StoreCDSelectFilterComponent } from '../store-cd-select-filter/store-cd-select-filter.component';
import { dateNotBeforeValidator } from 'src/app/validators/dataBeforeValidator';
import { dateNotBefore } from 'src/app/validators/dateNotBeforeValidator';

@Component({
  selector: 'app-seek-ranking',
  templateUrl: './seek-ranking.component.html',
  styleUrls: ['./seek-ranking.component.scss']
})
export class SeekRankingComponent implements OnInit {

  @ViewChild('inputProduct') inputProduct!: ProductSelectFilterComponent;
  @ViewChild('inputProvider') inputProvider!: ProviderSelectFilterComponent;
  @ViewChild('inputStoreCD') inputStoreCD!: StoreCDSelectFilterComponent;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource = new MatTableDataSource([]);

  protected formFilter!: FormGroup;
  protected tableActive : boolean = false;
  protected storage : Storage;

  protected isBovinos : boolean = false;
  protected isFlv : boolean = false;
  protected loading : boolean = false;

  protected tableData : any = [];
  public linkClass: string = '';
  protected economicGroup: string = "";

  minCompletionDate: any;
  maxCompletionDate: any;

  protected pageIndex: number = 0;
  protected pageSize: number = 15;
  protected length: number = 0;

  protected index = 0;

  protected displayedColumns: string[] = [
    'position',
    'provider',
    'blocked',
    'geomonitoring',
    'tracking',
    'inspection',
    'levelService',
    'volume',
    'share',
    'score',
  ];

  protected displayedColumns2: string[] = [
    'position',
    'provider',
    'social',
    'socialResult',
    'audit',
    'auditResult',
    'residue',
    'tracking',
    'levelService',
    'inspection',
    'amount',
    'score',
  ];

  constructor(
    private rankingService : RankingService,
    private router : Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    const maxDate = new Date();
    this.maxCompletionDate = maxDate.setDate(maxDate.getDate());
    this.storage = window.sessionStorage;
    const minDate = new Date(2020, 0, 1);
    this.formFilter = this.formBuilder.group({
      startDate: ['', [Validators.required, dateValidator, dateNotBeforeValidator(minDate)]],
      endDate: ['', [Validators.required, dateValidator, dateNotBefore('startDate')]],
      product: [''],
      provider: [''],
      localType: [''],
      storeCD: ['']
    });
  }

  ngOnInit(): void {

    let linkClass = this.router.url.split('/');

    if(linkClass[2] == "bovinos"){
      this.isBovinos = true;
    }else{
      this.isFlv = true;
    }

    const economicGroup: any = this.storage.getItem('economicGroup');

    if(economicGroup){
      this.economicGroup = economicGroup;
    }

  }

  protected onChanged(){

    this.formFilter.markAllAsTouched();

    if(!this.formFilter.valid){
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: 'Preencha os filtros',
        }
      });
      return;
    }

    this.loading = true;
    this.dataSource = new MatTableDataSource([]);

    if(this.isBovinos){

      let options = {
        startDate: this.formFilter.get("startDate")?.value,
        endDate: this.formFilter.get("endDate")?.value,
        provider: this.formFilter.get("provider")?.value,
        product: this.formFilter.get("product")?.value,
        storeCD: this.formFilter.get('storeCD')?.value,
        productType: 'Bovinos',
        page: this.pageIndex,
        size: this.pageSize,
      }

      this.rankingService.listRankingBovinos(options).subscribe({
        next: (res: any) => {
          this.index = 0;
          this.tableData = res.content;
          if (this.tableData && this.tableData.length <= 0) {
            this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "right",
              verticalPosition: "top"
            });
          }
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;

          this.tableData.map((item : any) => {
            this.index = this.index + 1;
            item.index = this.index;
          });

          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        },
        error: (err) => {
          this.index = 0;
          this.tableData = [];
          this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
            duration: 2 * 1000,
            panelClass: ['alert_error'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        },
      });
    }else{

      let options = {
        startDate: this.formFilter.get("startDate")?.value,
        endDate: this.formFilter.get("endDate")?.value,
        provider: this.formFilter.get("provider")?.value,
        product: this.formFilter.get("product")?.value,
        storeCD: this.formFilter.get('storeCD')?.value,
        productType: 'FLV',
        page: this.pageIndex,
        size: this.pageSize,
      }

      this.rankingService.listRankingFlv(options).subscribe({
        next: (res: any) => {
          this.index = 0;
          this.tableData = res.content;
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;
          if (this.tableData && this.tableData.length <= 0) {
            this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "right",
              verticalPosition: "top"
            });
          }

          this.tableData.map((item : any) => {
            this.index = this.index + 1;
            item.index = this.index;
          });

          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        },
        error: (err) => {
          this.index = 0;
          this.tableData = [];
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        },
      });
    }
  }

  paginate(pageEvent: PageEvent): void {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.loading = true;

    if(this.isBovinos){
      let startIndex = this.pageIndex * this.pageSize;
      let endIndex = (this.pageIndex+1) * this.pageSize;

      let dataPaginada = this.tableData.slice(startIndex, endIndex);
      this.dataSource = new MatTableDataSource(dataPaginada);
      this.loading = false;
    }else{
      let startIndex = this.pageIndex * this.pageSize;
      let endIndex = (this.pageIndex+1) * this.pageSize;

      let dataPaginada = this.tableData.slice(startIndex, endIndex);
      this.dataSource = new MatTableDataSource(dataPaginada);
      this.loading = false;
    }

    // if(this.isBovinos){

    //   let options = {
    //     startDate: this.formFilter.get("startDate")?.value,
    //     endDate: this.formFilter.get("endDate")?.value,
    //     provider: this.formFilter.get("provider")?.value,
    //     product: this.formFilter.get("product")?.value,
    //     storeCD: this.formFilter.get('storeCD')?.value,
    //     productType: 'bovinos',
    //     page: this.pageIndex,
    //     size: this.pageSize,
    //   }

    //   this.rankingService.listRankingBovinos(options).subscribe({
    //     next: (res: any) => {
    //       this.tableData = res.content;
    //       this.pageIndex = res.currentPage;
    //       this.length = res.totalItems;

    //       this.tableData.map((item : any) => {
    //         if(!item.index){
    //           this.index = this.index + 1;
    //           item.index = this.index;
    //         }
    //       });

    //       this.dataSource = new MatTableDataSource(this.tableData);
    //       this.dataSource.paginator = this.paginator;
    //     },
    //     error: (err) => {
    //       this.tableData = [];
    //       this.dataSource = new MatTableDataSource(this.tableData);
    //       this.dataSource.paginator = this.paginator;
    //     },
    //   });
    // }else{

    //   let options = {
    //     startDate: this.formFilter.get("startDate")?.value,
    //     endDate: this.formFilter.get("endDate")?.value,
    //     provider: this.formFilter.get("provider")?.value,
    //     product: this.formFilter.get("product")?.value,
    //     storeCD: this.formFilter.get('storeCD')?.value,
    //     productType: 'FLV',
    //     page: this.pageIndex,
    //     size: this.pageSize,
    //   }

    //   this.rankingService.listRankingFlv(options).subscribe({
    //     next: (res: any) => {
    //       this.tableData = res.content;
    //       this.pageIndex = res.currentPage;
    //       this.length = res.totalItems;

    //       this.tableData.map((item : any) => {
    //         if(!item.index){
    //           this.index = this.index + 1;
    //           item.index = this.index;
    //         }
    //       });

    //       this.dataSource = new MatTableDataSource(this.tableData);
    //       this.dataSource.paginator = this.paginator;
    //     },
    //     error: (err) => {
    //       this.tableData = [];
    //       this.dataSource = new MatTableDataSource(this.tableData);
    //       this.dataSource.paginator = this.paginator;
    //     },
    //   });
    // }

  }

  protected changeProductValue(event:any) : void{
    this.formFilter.get('product')?.setValue(event);
  }

  protected changeProviderValue(event:any ){
    this.formFilter.get('provider')?.setValue(event);
  }

  protected changeStoreCDValue(event: any) {
    this.formFilter.get('storeCD')?.setValue(event);
  }

  protected cleanFilters() {
    this.formFilter.get("startDate")?.setValue("");
    this.formFilter.get("endDate")?.setValue("");
    this.formFilter.get("product")?.setValue("");
    this.formFilter.get("provider")?.setValue("");
    this.formFilter.get("localType")?.setValue("");
    this.formFilter.get("storeCD")?.setValue("");

    this.inputProvider.cleanFilter();
    this.inputProduct.cleanFilter();
    this.inputStoreCD.cleanFilter();

  }

  protected exportToExcel(dados : any[], fileName : string): void {
    if(dados.length == 0){
      return;
    }

    if(this.isBovinos){

      let options = {
        startDate: this.formFilter.get("startDate")?.value,
        endDate: this.formFilter.get("endDate")?.value,
        provider: this.formFilter.get("provider")?.value,
        product: this.formFilter.get("product")?.value,
        storeCD: this.formFilter.get('storeCD')?.value,
        productType: 'BOVINOS',
      }

      this.rankingService.exportRankingBovinos(options).subscribe({
        next: (res: any) => {
          let response = res;
          this.dialog.open(ModalConfirmComponent, {
              data : {
                success: true,
                successText: response,
              }
          })
          this.loading = false;
        },
        error: (err) => {
          this.dialog.open(ModalConfirmComponent, {
            data : {
              error: true,
              errorText: 'Falha ao gerar arquivo para exportação!',
            }
          })
          this.loading = false;
        },
      });
    }else{

      let options = {
        startDate: this.formFilter.get("startDate")?.value,
        endDate: this.formFilter.get("endDate")?.value,
        provider: this.formFilter.get("provider")?.value,
        product: this.formFilter.get("product")?.value,
        storeCD: this.formFilter.get('storeCD')?.value,
        productType: 'FLV',
      }

      this.rankingService.exportRankingFlv(options).subscribe({
        next: (res: any) => {
          let response = res;
          this.dialog.open(ModalConfirmComponent, {
              data : {
                success: true,
                successText: response,
              }
          })
          this.loading = false;
        },
        error: (err) => {
          this.dialog.open(ModalConfirmComponent, {
            data : {
              error: true,
              errorText: 'Falha ao gerar arquivo para exportação!',
            }
          })
          this.loading = false;
        },
      });
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  protected back() : void{
    this.location.back();
  }

  protected checkStartCompletionDate() {
    const completionDate = this.formFilter.get('startDate')?.value;
    const minDate = new Date(completionDate);
    this.minCompletionDate = minDate.setDate(minDate.getDate() + 1);
    this.formFilter.get('endDate')?.setValue('');
  }

  public capitalizarString(string : string) {
    if (string == undefined || string.trim() === '') {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

}
