<!-- modal-content.component.html -->
<div class="my-custom-modal">
    <div class="header-modal">
        <p>Tabela nutricional</p>
    </div>
    <div class="body-modal">
        <p class="msg-error">Preencha todos os dados antes de continuar!</p>

        <form [formGroup]="formTable" class="body-modal">
            <div class="form-group" tabindex="-1">
                <input type="text" style="width:80%" formControlName="nutriente" class="form-input" id="solicitante"
                    name="solicitante" />
                <label class="form-label" for="solicitante"
                    [ngClass]="{ focus : (formTable.get('nutriente')?.value && formTable.get('nutriente')?.value.length > 0) }">
                    Nutriente
                </label>
            </div>

            <div class="form-group" tabindex="-1">
                <input type="text" style="width:80%" formControlName="quantidade" class="form-input" id="solicitante"
                    name="solicitante" />
                <label class="form-label" for="solicitante"
                    [ngClass]="{ focus : (formTable.get('quantidade')?.value && formTable.get('quantidade')?.value.length > 0) }">
                    Quantidade
                </label>
            </div>

            <div class="form-group" tabindex="-1">
                <input type="text" style="width:80%" formControlName="quantidadePorPorcao" class="form-input"
                    id="solicitante" name="solicitante" />
                <label class="form-label"
                    [ngClass]="{ focus : (formTable.get('quantidadePorPorcao')?.value && formTable.get('quantidadePorPorcao')?.value.length > 0) }"
                    for="solicitante">
                    Quantidade por porção
                </label>
            </div>

            <div class="form-group" tabindex="-1">
                <input style="width:80%" type="text" formControlName="vd" class="form-input" id="solicitante"
                    name="solicitante" />
                <label [ngClass]="{ focus : (formTable.get('vd')?.value && formTable.get('vd')?.value.length > 0) }"
                    class="form-label" for="solicitante">
                    %VD(*)
                </label>
            </div>
        </form>

    </div>

    <div class="buttons">
        <button class="button-export" id="first-button-export" (click)="confirmModal()">
            <h1 style="font-weight: 700;font-family: 'Roboto';font-style: normal;">Confirmar</h1>
        </button>

        <button class="button-clean" (click)="closeModal()">
            <h1>Cancelar</h1>
        </button>
    </div>
</div>