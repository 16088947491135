import { FormControl } from "@angular/forms";

export function dateValidator(control: FormControl): { [key: string]: any } | null {
  const value = control.value;
  if (!value) return null;


  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  if (!datePattern.test(value)) {
    return { invalidDate: true };
  }

  const date = new Date(value);
  const maxDate = new Date();

  if (isNaN(date.getTime())) {
    return { invalidDate: true };
  }

  if (maxDate < date) {
    return { invalidDate: true }
  }

  return null;
}
