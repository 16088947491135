import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  @Input() question: any;
  @Output() remove = new EventEmitter<void>();
  showOptions = false;

  onTypeChange(event: any) {
    this.question.type = event.target.value;
    this.showOptions = this.question.type !== 'TEXT';
  }

  addOption() {
    const optionId = `O${String(this.question.options.length + 1).padStart(4, '0')}`;
    this.question.options.push({
      optionText: '',
      conditionalOptions: [],
      conditionalQuestions: [],
      id: optionId,
      parentId: this.question.code
    });
  }

  removeOption(index: number) {
    this.question.options.splice(index, 1);
  }
}
