import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-block-alert-question-process',
  templateUrl: './modal-block-alert-question-process.component.html',
  styleUrls: ['./modal-block-alert-question-process.component.scss']
})
export class ModalBlockAlertQuestionProcessComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalBlockAlertQuestionProcessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }

  closeModal() {
    this.dialogRef.close();
  }

}

