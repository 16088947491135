import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SocialAuditComponent } from './social-audit.component';
import { SeeDatasSutentabiltyComponent } from 'src/app/components/see-datas-sutentabilty/see-datas-sutentabilty.component';

const routes: Routes = [{
  path: '',
  component: SocialAuditComponent,
  children: [
    { path: 'bovinos', component: SeeDatasSutentabiltyComponent },
    { path: 'suinos', component: SeeDatasSutentabiltyComponent },
    { path: 'flv', component: SeeDatasSutentabiltyComponent },
    { path: 'pescado', component: SeeDatasSutentabiltyComponent },
    { path: 'aves', component: SeeDatasSutentabiltyComponent },
    { path: 'ovos', component: SeeDatasSutentabiltyComponent },
    { path: 'mercearia', component: SeeDatasSutentabiltyComponent },
    { path: 'todos', component: SeeDatasSutentabiltyComponent },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SocialAuditRoutingModule { }
