import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalAlternativasComponent } from '../modal-alternativas/modal-alternativas.component';

@Component({
  selector: 'app-modal-tabela-processo',
  templateUrl: './modal-tabela-processo.component.html',
  styleUrls: ['./modal-tabela-processo.component.scss']
})
export class ModalTabelaProcessoComponent implements OnInit, OnDestroy {

  alternativas: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public dataModal: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalAlternativasComponent>) { }
  typeModal: string = "";

  ngOnInit(): void {

    this.typeModal = this.dataModal.type;
  }

  ngOnDestroy(): void {

    localStorage.setItem('al', `${this.alternativas}`);
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalAlternativasComponent, {

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alternativas.push(result.value);

      }
    });
  }

  closeModal() {
    this.dialogRef.close(this.alternativas);
  }
}
