import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ModalClientRequestComponent } from './modal-client-request/modal-client-request.component';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitacoes',
  templateUrl: './solicitacoes.component.html',
  styleUrls: ['./solicitacoes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SolicitacoesComponent implements OnInit, AfterViewInit {

  protected dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  protected tableData: Array<any> = [];
  loading: boolean = false;

  protected formFilterRequest : FormGroup = new FormGroup({
    corporateName : new FormControl(''),
    document : new FormControl(''),
    status : new FormControl('')
  });

  displayedColumns: string[] = [
    'requestNumber',
    'requestDate',
    'corporateName',
    'document',
    'nickname',
    'code',
    'status',
    'actions'
  ];

  constructor(
    private dialog : MatDialog,
    private solicitacoesService : SolicitacoesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.listSolicitacoes();
  }

  listSolicitacoes() {
    this.solicitacoesService.listNewRequest().subscribe({
      next: (res: any) => {
        this.loading = false;
        this.tableData = res;
        this.tableData = this.tableData.filter(item => item.status !== 'PENDING');
        this.dataSource = new MatTableDataSource(this.tableData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error: (err) => {
        this.loading = false;
        this.tableData = [];
        this.dataSource = new MatTableDataSource();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  protected openModal() : void {
    const dialogref = this.dialog.open(ModalClientRequestComponent);

    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.listSolicitacoes();
      }
    });
  }

  protected filter() : void {

    let json = {
      "corporateName" : this.formFilterRequest.get('corporateName')?.value,
      "document" : this.formFilterRequest.get('document')?.value,
      "status" : this.formFilterRequest.get('status')?.value,
    }

    this.solicitacoesService.listNewRequest().subscribe({
      next: (res: any) => {
        this.tableData = res;
        this.dataSource = new MatTableDataSource(res);
      },
      error: (err) => {
        this.tableData = [];
        this.dataSource = new MatTableDataSource();
      },
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  protected applyFilter(event : Event) : void {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  maskCpfCnpj(value: string): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(9)}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(0, 2)}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

  getStatusColor(status: string): string {
    return this.solicitacoesService.getStatusColor(status);
  }

  getStatusName(status: string): string {
    return this.solicitacoesService.getStatusName(status);
  }

  viewRequest(element: any) {
    this.router.navigate(['/safe-gis/solicitacoes/solicitacao-info'],  { queryParams: { id: element.id } });
    // const dialogref = this.dialog.open(ModalClientRequestComponent, {
    //   data: {
    //     element: element,
    //     view: true,
    //   },
    // });
  }
}
