import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-devolucao',
  templateUrl: './modal-devolucao.component.html',
  styleUrls: ['./modal-devolucao.component.scss']
})
export class ModalDevolucaoComponent implements OnInit {

  formReturn: FormGroup;

  constructor(public dialogRef: MatDialogRef<ModalDevolucaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formReturn = new FormGroup({
      name: new FormControl('', Validators.required),
      describe: new FormControl('', Validators.required),
      recomendation: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
      limit: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    if (this.data.name) {
      this.setDatas();
    }
  }

  setDatas() {
    this.formReturn.get('name')?.setValue(this.data.name);
    this.formReturn.get('describe')?.setValue(this.data.describe);
    this.formReturn.get('limit')?.setValue(this.data.limit);
    this.formReturn.get('recomendation')?.setValue(this.data.recomendation);
    this.formReturn.get('category')?.setValue(this.data.category);
  }

  confirmModal() {
    this.formReturn.markAllAsTouched();
    if (this.formReturn.valid) {
      const data = {
        name: this.formReturn.get("name")?.value,
        describe: this.formReturn.get("describe")?.value,
        limit: this.formReturn.get("limit")?.value,
        category: this.formReturn.get("category")?.value,
        recomendation: this.formReturn.get("recomendation")?.value,
      };
      this.dialogRef.close(data);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
