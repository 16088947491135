import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() list!: Array<{ name: string, value: string }>;
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() initialValue: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() loadMoreButton: boolean = false;

  @Output() changeValue = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() getSearchOptions = new EventEmitter();

  optionsSelected: string = '';
  searchWord: string = '';

  value: string = '';
  optionsShow: boolean = false;
  optionsList: Array<{ name: string, value: string }> = [];
  change: boolean = false;
  changeInput: boolean = true;

  @ViewChild('optionsListElement') optionsListElement!: ElementRef;
  page: number = 0;

  constructor(private elementRef: ElementRef) {
    this.optionsList = this.list;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }

  ngAfterViewInit(): void {
    this.optionsList = this.list;
  }

  setInitialValue(value: string) {
    this.value = value;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.optionsShow) {
        this.toggleOptions();
      }
    }
  }

  toggleOptions() {
    this.optionsShow = !this.optionsShow;
  }

  openOptions() {
    this.toggleOptions();
    this.change = true;
  }

  changeOptionsFilter(optionsList: any) {
    this.optionsList = optionsList;
  }

  valueIsValid(): boolean {
    return this.value !== '' && this.optionsList.some(item => item.name === this.value);
  }

  filterList(event: any) {
    this.optionsShow = true;
    if (event.type === 'change') {
      this.changeInput = true;
    }
    if (event.code === 'Enter' && this.changeInput) {
      this.searchWord = this.value;
      this.page = 0;
      this.getSearchOptions.emit(this.searchWord);
      this.changeInput = false;
    }

    if (event.target.value === '') {
      this.changeValue.emit('');
    }

    if (event.type === 'focus' && this.changeInput) {
      this.searchWord = this.value;
      this.page = 0;
      this.getSearchOptions.emit(this.searchWord);
      this.changeInput = false;
    }
  }

  selectOption(option: { name: string, value: string }) {
    this.value = option.name;
    this.optionsSelected = option.value;
    this.changeValue.emit(this.optionsSelected);
    this.optionsShow = false;
  }

  loadMoreOptions() {
    this.value = this.searchWord;
    this.loadMore.emit(this.page);
    const children = this.optionsListElement.nativeElement.children;
    if (children.length >= 10) {
      const antepenultimateChild = children[children.length - 10];
      antepenultimateChild.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setValue(value: string) {
    this.value = value;
  }

}
