import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent {
  @Input() option: any;
  @Output() remove = new EventEmitter<void>();
  showConditional = false;

  addConditional() {
    this.showConditional = true;
    
    // Inicializar conditionalOptions caso não esteja definida
    if (!this.option.conditionalOptions) {
      this.option.conditionalOptions = [];
    }

    const conditionalId = `Q${String(this.option.conditionalOptions.length + 1).padStart(4, '0')}`;
    this.option.conditionalOptions.push({
      questionText: '',
      type: 'TEXT',  // O tipo padrão pode ser ajustado conforme a necessidade
      options: [],
      conditionalQuestions: [],
      code: conditionalId,
      parentId: this.option.id
    });
  }

  addConditionalOption() {
    if (!this.option.conditionalOptions) {
      this.option.conditionalOptions = [];
    }

    const optionId = `O${String(this.option.conditionalOptions.length + 1).padStart(4, '0')}`;
    this.option.conditionalOptions.push({
      optionText: '',
      conditionalOptions: [],
      id: optionId,
      parentId: this.option.id
    });
  }

  removeConditionalOption(index: number) {
    this.option.conditionalOptions.splice(index, 1);
  }
}
