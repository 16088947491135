import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitacoesComponent } from './solicitacoes.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { SolicitacoesRoutingModule } from './solicitacoes-routing.module';
import { SolicitacoesRespondidasComponent } from './solicitacoes-respondidas/solicitacoes-respondidas.component';
import { SolicitacaoRespondidaComponent } from './solicitacao-respondida/solicitacao-respondida.component';
import { ModalClientRequestComponent } from './modal-client-request/modal-client-request.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AnaliseDetalhadaComponent } from './analise-detalhada/analise-detalhada.component';
import { UploadLaudoComponent } from './solicitacao-respondida/upload-laudo/upload-laudo.component';
import { PendentesInformacaoComponent } from './pendentes-informacao/pendentes-informacao.component';
import { SolicitacaoInfoComponent } from './solicitacao-info/solicitacao-info.component';
import { LaudoMonitoramentoComponent } from './laudo-monitoramento/laudo-monitoramento.component';
import { HttpClientModule } from '@angular/common/http';
import { MapaSobreposicaoComponent } from './mapa-sobreposicao/mapa-sobreposicao.component';
import { ResultadoAnaliseComponent } from './laudo-monitoramento/resultado-analise/resultado-analise.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    SolicitacoesComponent,
    SolicitacoesRespondidasComponent,
    SolicitacaoRespondidaComponent,
    ModalClientRequestComponent,
    AnaliseDetalhadaComponent,
    UploadLaudoComponent,
    PendentesInformacaoComponent,
    SolicitacaoInfoComponent,
    LaudoMonitoramentoComponent,
    ResultadoAnaliseComponent,
    MapaSobreposicaoComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    SolicitacoesRoutingModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot(maskConfig),
    HttpClientModule,
  ]
})
export class SolicitacoesModule { }
