import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function dateNotBefore(minDateControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control.parent;
    if (!formGroup) {
      return null;
    }

    const minDateControl = formGroup.get(minDateControlName);
    if (!minDateControl) {
      return null;
    }

    const minDate = new Date(minDateControl.value);
    const currentDate = new Date(control.value);

    return currentDate < minDate ? { invalidDate: true } : null;
  };
}
