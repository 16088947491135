import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeomonitoringRoutingModule } from './geomonitoring-routing.module';
import { GeomonitoringComponent } from './geomonitoring.component';


@NgModule({
  declarations: [
    GeomonitoringComponent
  ],
  imports: [
    CommonModule,
    GeomonitoringRoutingModule
  ]
})
export class GeomonitoringModule { }
