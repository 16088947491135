<div class="table-area">
  <table>
    <tr>
      <th>
        Nome Fornecedor
      </th>
      <td>
        {{ provider.corporateName }}
      </td>
    </tr>
    <tr>
      <th>
        Endereço do Fornecedor/Origem
      </th>
      <td>
        {{ provider.address.street +  ', ' + provider.address.number + ', ' + provider.address.city }}
      </td>
    </tr>
    <tr>
      <th>
        Terceiros
      </th>
      <td>
        {{ provider.outsourced ? provider.outsourced.length : 'Nenhum terceiro Registrado' }}
      </td>
    </tr>
    <tr>
      <th>
        Status da Auditoria de Qualidade
      </th>
      <td>
        {{ providerData?.auditoria_quality? capitalizarString(providerData.auditoria_quality.status) : "-" }}
      </td>
    </tr>
    <tr>
      <th>
        Status da Auditoria Social
      </th>
      <td>
        {{ providerData?.auditoria_social? capitalizarString(providerData.auditoria_social.status) : "-" }}
      </td>
    </tr>
    <tr>
      <th>
        Status da Rastreabilidade
      </th>
      <td>
        {{ providerData?.assessmentResult? capitalizarString(providerData?.assessmentResult) : "-" }}
      </td>
    </tr>
    <tr>
      <th>
        Data da Última Entrada
      </th>
      <td>
        {{ providerData?.completionDate? (providerData?.completionDate | date:'dd/MM/yyyy') : "-" }}
      </td>
    </tr>
    <tr>
      <th>
        Local de Entrega
      </th>
      <td>
        {{ providerData?.customer? providerData?.customer.corporateName : "-" }}
      </td>
    </tr>
    <tr>
      <th>
        Status do Fornecedor
      </th>
      <td>
        {{ provider.blocked && provider.blocked === false ? 'Bloqueado' : 'Ativo' }}
      </td>
    </tr>
    <tr>
      <th>
        Tipo
      </th>
      <td>
        {{ getOperationType() }}
      </td>
    </tr>
    <tr>
      <th>
        ME/MR
      </th>
      <td>
        -
      </td>
    </tr>
    <tr>
      <th>
        Lista de Produtos
      </th>
      <td>
        {{ productsList && productsList.length > 0 ? productsList.length + " produto (s) cadastrados" : "Nenhum produto cadastrado" }}
        <button mat-button *ngIf="productsList && productsList.length" (click)="showProducts()">
          ver mais
        </button>
      </td>
    </tr>
  </table>
</div>

<app-spinner *ngIf="loading"></app-spinner>
