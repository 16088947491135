import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NivelServicoService {
  token: any;
  item: any;
  httpOtions: any;
  storage: Storage;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};

    this.httpOtions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    }
  }

  listarNivelServico(filters: {
    startCompletionDate: string;
    endCompletionDate: string;
    productType : string | null;
    providerDocument?: string;
    productHash?: string;
    isUserProvider?: boolean;
    page: number;
    size: number;
  }) {

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if(user.authorities.some((item: string) => item === "ROLE_PROVIDER")){
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('startCompletionDate', filters.startCompletionDate)
      .set('endCompletionDate', filters.endCompletionDate)
      .set('page', filters.page)
      .set('size', filters.size)
      .set('type', 'RECEIVEMENT');

    if(isUserProvider == null){
      params = params.set('productTypeValue', filters.productType ? filters.productType : 'FLV');
    }

    if (filters.providerDocument) {
      params = params.set('providerDocument', filters.providerDocument);
    }

    if (filters.productHash) {
      params = params.set('productHash', filters.productHash);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    }

    return this.http.get(`${environment.api}/quality-service/provider-service-level`, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );

  }

  exportNivelServico(filters: {
    startCompletionDate: string;
    endCompletionDate: string;
    productType : string | null;
    providerDocument?: string;
    productHash?: string;
    isUserProvider?: boolean;
    page: number;
    size: number;
  }) {

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if(user.authorities.some((item: string) => item === "ROLE_PROVIDER")){
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('startCompletionDate', filters.startCompletionDate)
      .set('endCompletionDate', filters.endCompletionDate)
      .set('type', 'RECEIVEMENT');

    if(isUserProvider == null){
      params = params.set('productTypeValue', filters.productType ? filters.productType : 'FLV');
    }

    if (filters.providerDocument) {
      params = params.set('providerDocument', filters.providerDocument);
    }

    if (filters.productHash) {
      params = params.set('productHash', filters.productHash);
    }
    const httpOptions : any = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    }

    httpOptions.responseType = 'text'

    return this.http.get(`${environment.api}/qualityXLSX-service/provider-service-level`, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );

  }

}
