<div class="top_navbar">
  <div>
    <img src="../../../assets/images/logoSafe.png" alt="logo" class="logo" />
    <span class="logo-span">Safe Trace</span>
  </div>

  <div class="icon">
    <span>
      <svg
        width="21"
        height="25"
        viewBox="0 0 21 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.00214 3.40909V4.06678C3.47177 5.44555 1.00527 9.97419 1.09478 13.899L1.09475 16.0789C-0.712519 18.2917 -0.606183 21.5909 3.90272 21.5909H7.00214C7.00214 22.4951 7.37211 23.3622 8.03064 24.0015C8.68918 24.6408 9.58235 25 10.5137 25C11.445 25 12.3381 24.6408 12.9967 24.0015C13.6552 23.3622 14.0252 22.4951 14.0252 21.5909H17.1331C21.6334 21.5909 21.6987 18.2848 19.8916 16.072L19.9419 13.9021C20.0329 9.97264 17.5616 5.43992 14.0252 4.06311V3.40909C14.0252 2.50494 13.6552 1.63783 12.9967 0.9985C12.3381 0.359171 11.445 0 10.5137 0C9.58235 0 8.68918 0.359171 8.03064 0.998499C7.37211 1.63783 7.00214 2.50494 7.00214 3.40909ZM11.6842 3.40909C11.6842 3.43217 11.6834 3.45517 11.682 3.47807C11.3008 3.43254 10.9124 3.40909 10.5184 3.40909C10.1211 3.40909 9.72959 3.43293 9.34538 3.47919C9.3439 3.45592 9.34315 3.43254 9.34315 3.40909C9.34315 3.10771 9.46647 2.81867 9.68599 2.60556C9.9055 2.39245 10.2032 2.27273 10.5137 2.27273C10.8241 2.27273 11.1218 2.39245 11.3413 2.60556C11.5608 2.81867 11.6842 3.10771 11.6842 3.40909ZM9.34315 21.5909C9.34315 21.8923 9.46647 22.1813 9.68599 22.3944C9.9055 22.6075 10.2032 22.7273 10.5137 22.7273C10.8241 22.7273 11.1218 22.6075 11.3413 22.3944C11.5608 22.1813 11.6842 21.8923 11.6842 21.5909H9.34315ZM17.1331 19.3182C18.1331 19.3182 18.6726 18.1794 18.0218 17.4423C17.7064 17.085 17.5373 16.6219 17.5483 16.1455L17.6014 13.851C17.6908 9.9927 14.4936 5.68182 10.5184 5.68182C6.5441 5.68182 3.34725 9.99126 3.43521 13.8486L3.4876 16.1458C3.49846 16.6222 3.32936 17.0851 3.01401 17.4423C2.3632 18.1794 2.9027 19.3182 3.90272 19.3182H17.1331Z"
          fill="#99CA3C"
        />
      </svg>
    </span>
  </div>
  <div class="profile">
    <span class="profile-name">{{ user ? user.sub : "Usuário" }}</span>
    <span class="profile-type">Qualidade</span>
  </div>

  <button class="logout"(click)="logout()">
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8334 18.5001H16.1876M27.7501 23.1251L32.3751 18.5001L27.7501 13.8751M20.0417 10.7917V9.25008C20.0417 8.43233 19.7169 7.64807 19.1387 7.06984C18.5604 6.4916 17.7762 6.16675 16.9584 6.16675H9.25008C8.43233 6.16675 7.64807 6.4916 7.06984 7.06984C6.4916 7.64807 6.16675 8.43233 6.16675 9.25008V27.7501C6.16675 28.5678 6.4916 29.3521 7.06984 29.9303C7.64807 30.5086 8.43233 30.8334 9.25008 30.8334H16.9584C17.7762 30.8334 18.5604 30.5086 19.1387 29.9303C19.7169 29.3521 20.0417 28.5678 20.0417 27.7501V26.2084"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span>Sair</span>
  </button>
</div>
<hr class="top_hr" />
