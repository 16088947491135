import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { ProdutoService } from 'src/app/services/produto.service';

@Component({
  selector: 'app-product-select-filter',
  templateUrl: './product-select-filter.component.html',
  styleUrls: ['./product-select-filter.component.scss']
})
export class ProductSelectFilterComponent {
  @Input() isUserProvider: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() documentProvider: string = '';
  @Input() byName: boolean = false;
  @ViewChild('inputProduct') inputProduct!: SelectInputComponent;
  @Output() handleChangeProductValue  = new EventEmitter();
  productOptionsList: Array<{ name: string, value: string }> = [];
  productsList: any[] = [];
  loading: boolean = false;
  totalItems: number = 0;

  constructor(
    private produtoService: ProdutoService,
  ) { }

  changeProductValue(event:any ){
    this.handleChangeProductValue.emit(event);
  }

  loadMoreProductOptionsList(event: any) {
    this.loading = true;
    this.inputProduct.loadMoreButton = true;
    this.produtoService.getProductByNamePaginated(this.inputProduct.searchWord, this.inputProduct.page, 10, this.documentProvider).subscribe((res: any) => {
      this.totalItems = res.totalItems;
      if (this.inputProduct.page === 0) {
        this.productsList = res.content;
      } else {
        res.content.map((item: any) => {
          if (!this.productsList.some((element: any) => element === item)) {
            this.productsList.push(item);
          }
        });
      }
      if (res.totalItems === this.productsList.length) {
        this.inputProduct.loadMoreButton = false;
      }
      this.inputProduct.page++;
      this.getProductList();
      this.inputProduct.changeOptionsFilter(this.productOptionsList);
      this.inputProduct.optionsShow = true;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  setValue(value: string) {
    this.inputProduct.value = value;
    this.inputProduct.change = false;
  }

  getProductSearchOptions(event: any) {
    if (event && event.length > 0) {
      this.loading = true;
      this.produtoService.getProductByNamePaginated(event, this.inputProduct.page, 10, this.documentProvider).subscribe((res: any) => {
        this.productsList = res.content;
        this.totalItems = res.totalItems;
        this.getProductList();
        this.inputProduct.changeOptionsFilter(this.productOptionsList);
        this.inputProduct.optionsShow = true;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    }
  }

  public getProductList():void {
    this.productOptionsList = [];
    if (this.productsList.length > 0) {
      this.productOptionsList = this.productsList.map((item: any) => {
        let option = {
          name: `${item.name} ${item.ean ? '- EAN: ' + item.ean : ''} ${item.plu ? '- PLU: ' + item.plu : ''} ${item.code ? '- Código: ' + item.code : ''}`, value: item.hash
        }
        if (this.byName) {
          option = {
            name: `${item.name} ${item.ean ? '- EAN: ' + item.ean : ''} ${item.plu ? '- PLU: ' + item.plu : ''} ${item.code ? '- Código: ' + item.code : ''}`, value: item.name
          }
        }
        return option;
      });
      this.inputProduct.changeOptionsFilter(this.productOptionsList);
    }
  }

  handleChanged() {
    this.inputProduct.change = true;
  }

  cleanFilter() {
    this.inputProduct.value = '';
  }
}
