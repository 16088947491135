import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArquivosService {
  storage: Storage;
  uploadFiles: any = [];
  token: any;
  item: any;
  httpOtions: any;

  constructor(
    private http: HttpClient
  ) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};

    this.httpOtions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      responseType: 'text' as 'json'
    }
  }


  public uploadFile(uploadFiles: any) {
    const formData = new FormData();
    for (var i = 0; i < uploadFiles.length; i++) {
      formData.append('file', uploadFiles[i]);
    }
    return this.http.post(`${environment.api}/reader-service/upload`, formData, this.httpOtions);
  }

  public upload(file: any) {

    return this.http.post(`${environment.api}/files`, file, this.httpOtions);
  }

  public deletefile(id: any) {
    return this.http.delete(`${environment.api}/files/${id}`, this.httpOtions);
  }

  public getFile(file: string): Observable<Blob> {
    return this.http.get(`${environment.api}/reader-service/download`,
      {
        headers: new HttpHeaders({
          Authorization: this.token,
          'Content-Type': 'application/json',
        }),
        responseType: 'blob',
        params: {
          filePath: file,
        },
      });
  }

  getFiles(type: any, customerId: any, page: any, size: any, fileNameRegex: any) {

    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('typeRegex', type)
      .set('whiteList', customerId)
      .set('page', page)
      .set('size', size)
      .set('fileNameRegex', fileNameRegex)

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/files/filtered`, http).pipe(
      res => res,
      error => error
    )
  }


  getFileDownload(filePath: any): Observable<any> {

    return this.http.get(`${environment.api}/reader-service/download`,
      {
        headers: new HttpHeaders({
          Authorization: this.token,
          'Content-Type': 'application/json',
        }),
        responseType: 'blob',
        params: {
          filePath: filePath,
        },
      });


  }

  updateFiles(id: any, file: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }

    return this.http.put(`${environment.api}/files/${id}`, file, http).pipe(
      res => res,
      error => error
    )
  }
}
