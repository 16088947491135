<div class="modal-devolucao">
  <div class="header-modal">
    <p>Cadastrar motivo da devolução</p>
  </div>
  <form [formGroup]="formReturn" class="body-modal">
    <div class="flex-area">
      <div class="form-group" tabindex="-1">
        <input type="text" formControlName="name" class="form-input" id="name" name="name" />
        <label [ngClass]="{ focus : formReturn.get('name')?.value }" class="form-label" for="name">
          Nome do defeito
        </label>
        <span class="alert" *ngIf="formReturn.get('name')?.errors && (formReturn.get('name')?.touched ||formReturn.get('name')?.dirty)">
          Campo obrigatório
        </span>
      </div>

      <div class="select-container" tabindex="-1" style="margin-top:20px">
        <select type="text" formControlName="category" class="form-input" name="category" id="category">
          <option> % Podridão</option>
          <option>% Danos graves</option>
          <option>% Danos leves</option>
          <option>% Calibre</option>
          <option>% Maturação </option>
          <option>% Umidade </option>
          <option>% Peso </option>
          <option>% Rotulagem </option>
          <option>% Embalagem </option>
          <option>% Toalete </option>
          <option>% Sujidade </option>
          <option>% Danos graves 2</option>
          <option>% Danos graves 3</option>
          <option>% Brotamento interno</option>
        </select>

        <label [ngClass]="{ focus : formReturn.get('category')?.value }" class="form-label" for="category">
          Categoria de defeitos
        </label>

        <span class="alert" *ngIf="formReturn.get('category')?.errors && (formReturn.get('category')?.touched ||formReturn.get('category')?.dirty)">
          Campo obrigatório
        </span>
      </div>

      <div class="form-group" tabindex="-1">
        <input type="text" formControlName="limit" class="form-input" id="name" name="limit" />
        <label
            [ngClass]="{ focus : (formReturn.get('limit')?.value && formReturn.get('limit')?.value.length > 0) }"
            class="form-label" for="name">
            Limite (%)
        </label>
        <span class="alert" *ngIf="formReturn.get('limit')?.errors && (formReturn.get('limit')?.touched ||formReturn.get('limit')?.dirty)">
          Campo obrigatório
        </span>
      </div>
      <div class="form-group" tabindex="-1">
        <input type="text" formControlName="recomendation" class="form-input" id="name" name="recomendation" />
        <label [ngClass]="{ focus : formReturn.get('recomendation')?.value }" class="form-label" for="recomendation">
          Recomendação de armazenagem
        </label>
        <span class="alert" *ngIf="formReturn.get('recomendation')?.errors && (formReturn.get('recomendation')?.touched ||formReturn.get('recomendation')?.dirty)">
          Campo obrigatório
        </span>
      </div>
    </div>
    <div class="input-maior">
      <div class="form-group" tabindex="-1">
        <textarea formControlName="describe" class="form-input" id="describe" name="describe"></textarea>
        <label [ngClass]="{ focus : formReturn.get('describe')?.value }" class="form-label" for="describe">
          Descrição do problema
        </label>
        <span class="alert" *ngIf="formReturn.get('describe')?.errors && (formReturn.get('describe')?.touched ||formReturn.get('describe')?.dirty)">
          Campo obrigatório
        </span>
      </div>
    </div>
  </form>
  <div class="buttons">
    <button class="button" type="button" (click)="confirmModal()">
      <span>Confirmar</span>
    </button>

    <button class="button" type="button" style="background: #CCC;" (click)="closeModal()">
      <span>Cancelar</span>
    </button>
  </div>
</div>
