<div class="my-custom-modal">
    <div class="close-icon">
        <span style="color: white;" (click)="closeModal()">X</span>
    </div>

    <!-- <div class="body-modal">
        <table>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>CAR</th>
                    <th>Documento</th>
                    <th>Nome Fantasia</th>
                </tr>
            </thead>
            <tbody *ngIf="data.treeReceivement && data.treeReceivement.tree.root.children.length > 0 ">
                <tr *ngFor="let child of data.treeReceivement.tree.root.children">
                    <td class="center">
                      <span [ngClass]="child.data.provider.places[0].blocked ? 'status-red': 'status-green'"></span>
                    </td>
                    <td>{{ child.data.provider.places[0].ruralEnvironmentalRegistry ? child.data.provider.places[0].ruralEnvironmentalRegistry : '-' }}</td>
                    <td>{{ formatCpfCnpj(child.data.provider.document) }}</td>
                    <td>{{ child.data.provider.corporateName }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="data.origins &&data.origins.length > 0">
                <tr *ngFor="let child of data.origins">
                    <td class="center">
                      <span [ngClass]="child.places[0].blocked ? 'status-red': 'status-green'"></span>
                    </td>
                    <td>{{ child.places[0].ruralEnvironmentalRegistry ? child.places[0].ruralEnvironmentalRegistry : '-' }}</td>
                    <td>{{ formatCpfCnpj(child.document) }}</td>
                    <td>{{ child.corporateName }}</td>
                </tr>
            </tbody>
        </table>
        <div class="legend">
          <span class="status-red"></span>Bloqueado
          <span class="status-green"></span>Liberado
        </div>
    </div> -->

    <div class="body-modal">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-table">

            <ng-container matColumnDef="status" class="column-table">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let child">
                    <span
                        [ngClass]="child?.outsourced?.places[0]?.blocked ? child?.outsourced?.places[0]?.blocked ? 'status-red': 'status-green' : child?.outsourced?.places[0]?.blocked ? 'status-red': 'status-green'"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="car" class="column-table">
                <th mat-header-cell *matHeaderCellDef>CAR</th>
                <td mat-cell *matCellDef="let child">
                    {{ child?.outsourced?.places[0]?.ruralEnvironmentalRegistry || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="document" class="column-table">
                <th mat-header-cell *matHeaderCellDef>Documento</th>
                <td mat-cell *matCellDef="let child">
                    {{ child.outsourced?.document || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="fantasyName" class="column-table">
                <th mat-header-cell *matHeaderCellDef>Nome Fantasia</th>
                <td mat-cell *matCellDef="let child">
                    {{ child.outsourced?.corporateName || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="product" class="column-table" *ngIf="data.productType === 'FLV'">
              <th mat-header-cell *matHeaderCellDef>Produto</th>
              <td mat-cell *matCellDef="let child">
                  {{ child.product?.name || '-' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="identifier" class="column-table" *ngIf="data.productType === 'FLV'">
              <th mat-header-cell *matHeaderCellDef>Identificador</th>
              <td mat-cell *matCellDef="let child">
                  {{ child.product?.lots?.identifier || '-' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:  displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">Nenhum registro encontrado</td>
            </tr>
        </table>
    </div>

    <div class="paginacao">
        <mat-paginator id="paginator" [pageSizeOptions]="[10, 15]" [length]="data.origins.length"
            showFirstLastButtons></mat-paginator>
    </div>

</div>
