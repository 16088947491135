<div class="select-type-content" *ngIf="data.selectFile">
  <div class="header">
    <h4> {{ data.title ? data.title : 'Inserir o Plano de Ação'}} </h4>
  </div>
  <div class="archives-area" *ngIf="uploadFiles.length > 1">
    <ul>
      <li *ngFor="let archive of uploadFiles; let i = index" class="item-list">
        <span>{{ archive.name }}</span>
        <button type="button" class="button btn-delete" (click)="deleteFile(i)">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z" fill="white"/>
          </svg>
        </button>
      </li>
    </ul>

  </div>
  <div class="form-container file-area">
    <form [formGroup]="formFile" action="">
      <input type="file" name="uploadFile" id="uploadFile" class="file" formControlName="uploadFile" (change)="changeFile($event)"/>
    </form>
  </div>

  <div class="buttons-area">
    <button type="button" (click)="submit()">Confirmar</button>
    <button type="button" (click)="closeModal()">Cancelar</button>
  </div>
</div>
