import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { ModalFileComponent } from 'src/app/components/modal-file/modal-file.component';
import { ArquivosService } from 'src/app/services/arquivos.service';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';

interface ObjetoLocalidade {
  id: number;
  nome: string;
  microrregiao: {
    id: number;
    nome: string;
    mesorregiao: {
      id: number;
      nome: string;
      UF: {
        id: number;
        sigla: string;
        nome: string;
        regiao: {
          id: number;
          sigla: string;
          nome: string;
        };
      };
    };
  };
  'regiao-imediata': {
    id: number;
    nome: string;
    'regiao-intermediaria': {
      id: number;
      nome: string;
      UF: {
        id: number;
        sigla: string;
        nome: string;
        regiao: {
          id: number;
          sigla: string;
          nome: string;
        };
      };
    };
  };
}

@Component({
  selector: 'app-solicitacao-info',
  templateUrl: './solicitacao-info.component.html',
  styleUrls: ['./solicitacao-info.component.scss']
})
export class SolicitacaoInfoComponent implements OnInit {

  form!: FormGroup;
  loading: boolean = false;
  view: boolean = false;
  private storage!: Storage;
  isAdmin: boolean = false;
  validating: boolean = false;


  user: any;
  solicitacoes: any;
  chatForm!: FormGroup;
  fileList:any = {};
  constructor(
    private solicitacoesService: SolicitacoesService,
    private activeRouter: ActivatedRoute,
    private fb: FormBuilder,
    private arquivosService: ArquivosService,
    public dialog: MatDialog,
    private router : Router,
  ) {
    const id = this.activeRouter.snapshot.queryParamMap.get('id');
    this.storage = window.sessionStorage;
    this.user = this.storage.getItem('user');
    this.user = this.user ? JSON.parse(this.user) : {};
    this.storage = window.sessionStorage;
    this.form = this.fb.group({
      requestNumber : [''],
      producerName : ['', Validators.required],
      ownerDocument: ['', Validators.required],
      propertyName: ['', Validators.required],
      uf: ['', Validators.required],
      town: ['', Validators.required],
      car: ['', Validators.required],
      ccir: [''],
      statusCar : [''],

      //documents
      ccirFilePath: [''],
      producerPropertyFilePath: [''],
      carFilePath: [''],
      producerDocumentPath: [''],
      kmlPath: [''],

      // options
      slavery: [true],
      ibamaEmbargo: [true],
      deter: [true],
      prodes: [true],
      indigenousLand: [true],
      conservationUnit: [true],
      archeologicalSites: [true],
      publicForests: [true],
      quilombolaAreas: [true]
    });

    this.chatForm = this.fb.group({
      requestNumber: ['', Validators.required],
      text: ['', Validators.required],
      username: [''],
      attachments: this.fb.array([]),
    });
  }

  getFormArray(type: string) {
    return (<UntypedFormArray>this.chatForm.get(type)).controls;
  }


  ngOnInit(): void {
    this.getAnaliseById();
  }


  protected getAnaliseById() {
    const id = this.activeRouter.snapshot.queryParamMap.get('id');
    this.loading = true;
    this.solicitacoesService.getRequestById(id as string).subscribe((res: any) => {
      this.loading = false;
      this.solicitacoes = res;
      this.form.get('requestNumber')?.setValue(res.requestNumber);
      this.chatForm.get('requestNumber')?.setValue(res.requestNumber || '');
      this.form.get('producerName')?.setValue(res.producer.corporateName);
      this.form.get('ownerDocument')?.setValue(res.producer.document);
      this.form.get('propertyName')?.setValue(res.producer.place.nickname);
      this.form.get('uf')?.setValue(res.producer.place.address.stateIdentifier);
      this.form.get('town')?.setValue(res.producer.place.address.city);
      this.form.get('car')?.setValue(res.producer.place.code.replace('-', '').toUpperCase());
      this.form.get('ccir')?.setValue(res.producer.place.ccir);
      this.form.get('statusCar')?.setValue(true);
      this.form.get('slavery')?.setValue(res.request && res.request.slavery ? true : false);
      this.form.get('ibamaEmbargo')?.setValue(res.request && res.request.ibamaEmbargo ? true : false);
      this.form.get('deter')?.setValue(res.request && res.request.deter ? true : false);
      this.form.get('prodes')?.setValue(res.request && res.request.prodes ? true : false);
      this.form.get('indigenousLand')?.setValue(res.request && res.request.indigenousLand ? true : false);
      this.form.get('conservationUnit')?.setValue(res.request && res.request.conservationUnit ? true : false);
      this.form.get('archeologicalSites')?.setValue(res.request && res.request.archeologicalSites ? true : false);
      this.form.get('publicForests')?.setValue(res.request && res.request.publicForests ? true : false);
      this.form.get('quilombolaAreas')?.setValue(res.request && res.request.quilombolaAreas ? true : false);

      this.form.get('ccirFilePath')?.setValue(res.fileList.ccirFilePath);
      this.form.get('producerPropertyFilePath')?.setValue(res.fileList.producerPropertyFilePath);
      this.form.get('carFilePath')?.setValue(res.fileList.carFilePath);
      this.form.get('producerDocumentPath')?.setValue(res.fileList.producerDocumentPath);
      this.form.get('kmlPath')?.setValue(res.fileList.kmlPath);

      this.setFileList(res.fileList)
      if (res.status === 'VALIDATING' || res.status === 'PENDING') {
        this.validating = true;
      }

      this.form.disable();
      this.view = true;
    }, error => {
      this.loading = false;
    });
    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};
    if (user.authorities.includes('ROLE_GEOMONITORING_ADMIN')) {
      this.isAdmin = true;
    } else {
      this.form.get('ccirFilePath')?.setValidators(Validators.required);
      this.form.get('producerPropertyFilePath')?.setValidators(Validators.required);
      this.form.get('carFilePath')?.setValidators(Validators.required);
      this.form.get('producerDocumentPath')?.setValidators(Validators.required);
    }
  }

  protected back() : void{
    this.router.navigate(['/safe-gis/solicitacoes/novas-solicitacoes']);
  }

  submitMessage() {
    const formArray = this.chatForm.get('attachments') as UntypedFormArray;
    const attachments = formArray.value.reduce((acc: any, obj: any) => {
      acc[obj.name] = obj.value;
      return acc;
    }, {});

    const body = {
      requestNumber: this.form.get('requestNumber')?.value,
      text: this.chatForm.get('text')?.value,
      username: this.user.sub,
      attachments: attachments,
    };
    if (this.chatForm.valid) {
      this.loading = true;
      this.solicitacoesService.sendMessage(body).subscribe( res => {
        this.loading = false;
        this.getAnaliseById();
        this.chatForm.get('text')?.setValue('');
        formArray.clear();
      }, error => {
        this.loading = false;
        this.chatForm.get('text')?.setValue('');
        formArray.clear();
      });
    }
  }

  private createAttachment(attachment: any = { name: '', value: ''}) {
    return this.fb.group({
      name: [attachment.name, Validators.required],
      value: [attachment.value, Validators.required],
    });
  }

  public removeIndexForm(index: number) {
    const formaArray = this.chatForm.get('attachments') as UntypedFormArray;
    formaArray.removeAt(index);
  }


  downloadFile(filePath: any) {
    const fileName = atob(filePath);

    if (filePath) {
      this.loading = true;
      this.arquivosService.getFile(filePath).subscribe((arquivo: Blob) => {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.split('/').pop() as string;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.loading = false;
      }, error => {
        this.loading = false;
        this.openModalSuccessOrError(true, 'Não foi possível baixar o arquivo');
      });
    }
  }

  openModalAssets() {
    const dialogref = this.dialog.open(ModalFileComponent, {
      data: {
        title: 'Carregar Arquivo',
        fileTitle: true,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.loading = true;
        this.arquivosService.uploadFile(result.file).subscribe(res => {
          this.loading = false;
        }, error => {
          const attachments = this.chatForm.get('attachments') as UntypedFormArray;
          attachments.push(this.createAttachment({name: result.fileTitle, value: error.error.text}));
          this.loading = false;
        });
      }
     });
  }


  getCity() {
    let car = this.form.get('car')?.value;
    if (car && car.length === 41) {
    let cityCode = car.slice(2, 9);
    this.loading = true;
    this.solicitacoesService.getCityById(cityCode).subscribe(res => {
      this.loading = false;
      const address: ObjetoLocalidade = res as ObjetoLocalidade;
      this.form.get('town')?.setValue(address.nome);
      this.form.get('uf')?.setValue(address.microrregiao.mesorregiao.UF.sigla);
    }, error => {
      this.loading = false;
      this.openModalSuccessOrError(true, 'CAR inválido, não foi possível encontrar a cidade');
    });
    } else {
      this.openModalSuccessOrError(true, 'CAR inválido, verifique a quantidade de caracteres');
    }
  }

  protected sendRequest() : void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const dialogref = this.dialog.open(ModalConfirmComponent, {
        data: {
          fornecedor: this.form.value,
        },
      });

      dialogref.afterClosed().subscribe(result => {
        if (result && result.confirm) {
          this.loading = true;
          let date = new Date();
          let requestDate = (date.getFullYear() + "-" + ((date.getMonth() + 1)) + "-" + (date.getDate()));
          const item = this.storage.getItem('user');
          const user = item ? JSON.parse(item) : {};
          const whiteList = user.whiteList
          whiteList.push(user.customerId);
          const body = {
            requestNumber: this.form.get('requestNumber')?.value.toUpperCase(),
            requestDate: requestDate,
            request: {
              slavery: this.form.get('slavery')?.value ? true : false,
              ibamaEmbargo: this.form.get('ibamaEmbargo')?.value ? true : false,
              deter: this.form.get('deter')?.value ? true : false,
              prodes: this.form.get('prodes')?.value ? true : false,
              indigenousLand: this.form.get('indigenousLand')?.value ? true : false,
              conservationUnit: this.form.get('conservationUnit')?.value ? true : false,
              archeologicalSites: this.form.get('archeologicalSites')?.value ? true : false,
              publicForests: this.form.get('publicForests')?.value ? true : false,
              quilombolaAreas: this.form.get('quilombolaAreas')?.value ? true : false
            },
            producer: {
              document: this.form.get('ownerDocument')?.value,
              corporateName:this.form.get('producerName')?.value,
              place: {
                address: {
                  stateIdentifier: this.form.get('uf')?.value,
                  city: this.form.get('town')?.value,
                },
                ccir: this.form.get('ccir')?.value,
                code: this.formatCar(),
                codeType: "CAR",
                nickname: this.form.get('propertyName')?.value
              },
            },
            whiteList: whiteList,
            fileList: {
              'ccirFilePath': this.form.get('ccirFilePath')?.value,
              'producerPropertyFilePath': this.form.get('producerPropertyFilePath')?.value,
              'carFilePath': this.form.get('carFilePath')?.value,
              'producerDocumentPath': this.form.get('producerDocumentPath')?.value,
              'kmlPath': this.form.get('kmlPath')?.value,
              ...this.fileList
            }
          }

          this.loading = true;
          this.solicitacoesService.registerRequest(body).subscribe({
            next: (res: any) => {
              this.loading = false;
              this.openModalSuccessOrError();
            },
            error: (err) => {
              this.loading = false;
              this.openModalSuccessOrError(true);
            },
          });
        }
      });
    } else {
      this.openModalSuccessOrError(true, 'É necessário preencher todos os campos obrigatórios');
    }
  }

  openModalFile(type: string) {
    if (!this.view) {
      let title = '';
      switch(type){
        case 'ccirFilePath':
          title = 'CCIR';
          break;
        case 'producerPropertyFilePath':
          title = 'Vinculo produtor x propriedade'
          break;
        case 'carFilePath':
          title = 'CAR'
          break;
        case 'producerDocumentPath':
          title = 'Documento proprietário'
          break;
        case 'kmlPath':
          title = 'Arquivo KML'
          break;
        default:
          title = 'Arquivo';
      }
      this.dialog.open(ModalFileComponent, {
        data: {
          title: `Inserir o ${title}`,
        },
      }).afterClosed().subscribe( result => {
        if (result && result.confirm) {
          this.loading = true;
          this.arquivosService.uploadFile(result.file).subscribe(res => {
            this.loading = false;
            this.form.get(type)?.markAsTouched();
            this.form.get(type)?.setValue(res);
          }, error => {
            this.form.get(type)?.setValue(error.error.text);
            this.loading = false;
            this.form.get(type)?.markAsTouched();
          })
        } else {
          this.form.get(type)?.markAsTouched();
        }
      });
    } else {
      this.downloadFile(this.form.get(type)?.value);
    }
  }


  validateRequest() {
    if (this.view) {
      const id = this.activeRouter.snapshot.queryParamMap.get('id');
      this.dialog.open(ModalConfirmComponent, {
        data: {
          text: 'Deseja Validar?'
        },
      }).afterClosed().subscribe(result => {
        if (result.confirm) {
          this.loading = true;
          this.solicitacoesService.validateRequest(id as string).subscribe(res => {
            this.loading = false;
            this.openModalSuccessOrError(false, 'Validada com sucesso');
            this.back();
          }, error => {
            this.loading = false;
            this.openModalSuccessOrError(true, 'Não foi possível validar');
          })
        }
       });
    }
  }

  formatCar():string {
    let car = this.form.get('car')?.value as String;
    car = car.toUpperCase();
    const siglaEstado = car.substring(0, 2);
    const codigoCidade = car.substring(2, 9);
    const restante = car.substring(9);
    const carFormatado = `${siglaEstado}-${codigoCidade}-${restante}`;
    return carFormatado;
  }

  openModalSuccessOrError(error = false, msg?: string) {
    this.loading = false;
    if (error) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: msg ? msg : '',
        },
      });
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          success: true,
          successText: msg ? msg : '',
        },
      }).afterClosed().subscribe( result => {
        //this.closeModal(true);
      });
    }
  }

  getFileName(filePath: string) {
    return atob(filePath).slice(18);
  }

  deleteFilePath(type: string) {
    this.form.get(type)?.setValue('');
  }


  downloadFiles() {
    if(this.fileList){
      Object.keys(this.fileList).map(key=>{
        this.downloadFile(this.fileList[key])
      })
    }
  }

  hasFiles(){
    return Object.keys(this.fileList).length>0
  }
  setFileList(fileList:any){

    Object.keys(fileList).forEach(key=>{
      if(!this.isDefaultPath(key)){
        this.fileList[key] = fileList[key]
      }
    })
  }

  isDefaultPath(key: any):boolean{
    let defaultPath= false;
    switch(key){
      case 'ccirFilePath':
        defaultPath = true;
        break;
      case 'producerPropertyFilePath':
        defaultPath = true
        break;
      case 'carFilePath':
        defaultPath = true
        break;
      case 'producerDocumentPath':
        defaultPath = true
        break;
      case 'kmlPath':
        defaultPath = true
        break;
    }
    return defaultPath;
  }
  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
