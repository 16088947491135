import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SolicitacoesComponent } from './solicitacoes.component';
import { SolicitacoesRespondidasComponent } from './solicitacoes-respondidas/solicitacoes-respondidas.component';
import { SolicitacaoRespondidaComponent } from './solicitacao-respondida/solicitacao-respondida.component';
import { AnaliseDetalhadaComponent } from './analise-detalhada/analise-detalhada.component';
import { PendentesInformacaoComponent } from './pendentes-informacao/pendentes-informacao.component';
import { SolicitacaoInfoComponent } from './solicitacao-info/solicitacao-info.component';
import { LaudoMonitoramentoComponent } from './laudo-monitoramento/laudo-monitoramento.component';
import { MapaSobreposicaoComponent } from './mapa-sobreposicao/mapa-sobreposicao.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'novas-solicitacoes' },
  { path: 'novas-solicitacoes', component: SolicitacoesComponent },
  { path: 'solicitacoes-respondidas', component: SolicitacoesRespondidasComponent},
  { path: 'pendentes-informacao', component: PendentesInformacaoComponent},
  { path: 'ver-solicitacao', component: SolicitacaoRespondidaComponent },
  { path: 'analise-detalhada', component: AnaliseDetalhadaComponent },
  { path: 'solicitacao-info', component: SolicitacaoInfoComponent },
  { path: 'laudo-monitoramento', component: LaudoMonitoramentoComponent },
  { path: 'mapa-de-sobreposicao', component: MapaSobreposicaoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SolicitacoesRoutingModule { }
