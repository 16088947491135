import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-see-datas-geomonitoring',
  templateUrl: './see-datas-geomonitoring.component.html',
  styleUrls: ['./see-datas-geomonitoring.component.scss']
})
export class SeeDatasGeomonitoringComponent implements OnInit {
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'provider_code',
    'provider_name',
    'sif',
    'address',
    'outsourced',
    'local',
    'memr',
    'geolocation',
    'coord',
    'analysis',
    // 'double_check',
    'status',
  ];

  private storage: Storage;

  private closeYearFilter() {
    this.checkboxActive.year = false;
    this.checkboxActive.brand = false;
    this.checkboxActive.provider = false;
    this.checkboxActive.status = false;
  }

  checkboxActive: {
    year: boolean;
    brand: boolean;
    provider: boolean;
    status: boolean;
  } = { year: false, brand: false, provider: false, status: false };

  datesFilter: number[] = [];
  tableActive: boolean = false;
  dates: Array<{ value: string | null; checked: boolean }> = [];
  classProduct: string | null = '';
  tableData: any = [];
  statusFilter: Array<{ checked: boolean; value: string }> = [
    { checked: false, value: 'Em andamento' },
    { checked: false, value: 'Liberado' },
    { checked: false, value: 'Suspenso' },
  ];
  brandFilter: Array<{ checked: boolean; value: string }> = [
    { checked: false, value: 'Regular' },
    { checked: false, value: 'Exclusiva' }
  ];
  auditedCompanyList: Array<{ checked: boolean, value: string, document: string }> = [];
  loading: boolean = false;
  firstRender: boolean = true;

  form = this.fb.group({
    start: [''],
    finish: [''],
    sif: [''],
    sicar: [''],
    document: [''],
    provider_name: [''],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private companyService: CompanyService,
  ) {
    this.storage = window.localStorage;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // let filtersList = this.auditList
    //   .filter((audit: any) => (audit.provider_company && audit.provider_company.corporate_name ? audit.provider_company.corporate_name.toLowerCase().includes(filterValue.toLowerCase()) : false)
    //     || (audit.provider_company ? audit.provider_company.document.toLowerCase().includes(filterValue.toLowerCase()) : false)
    //     || this.datePipe.transform(audit.auditingDate, 'dd/MM/yyyy')?.includes(filterValue));

    // this.dataSource = new MatTableDataSource(filtersList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
