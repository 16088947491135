<div class="content">
  <div class="header-area">
    <h1>Novas Solicitações</h1>
    <div class="buttons-area">
      <button class="button" (click)="openModal()">
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.2627 17.3625V10.2375M9.2627 10.2375V3.11255M9.2627 10.2375H16.3877M9.2627 10.2375H2.1377"
                stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>
        <span>Nova Solicitação</span>
      </button>
    </div>
  </div>
  <!-- <div class="options_section">
    <div>
      <form [formGroup]="formFilterRequest" method="post">
        <input type="text" placeholder="Nome do Produtor" name="corporate_name" formControlName="corporateName">
        <input type="text" placeholder="Documento" name="document" formControlName="document">
        <input type="text" placeholder="Status" name="status" formControlName="status">
      </form>
    </div>
    <button class="button-filtrar" (click)="filter()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M11.435 10.0628H10.7123L10.4561 9.81577C11.3836 8.73999 11.8934 7.36658 11.8924 5.94618C11.8924 4.77014 11.5436 3.6205 10.8902 2.64266C10.2369 1.66482 9.3082 0.90268 8.22168 0.452628C7.13516 0.00257639 5.93958 -0.115178 4.78614 0.114257C3.63269 0.343691 2.57318 0.910009 1.7416 1.7416C0.910009 2.57318 0.343691 3.63269 0.114257 4.78614C-0.115178 5.93958 0.00257639 7.13516 0.452628 8.22168C0.90268 9.3082 1.66482 10.2369 2.64266 10.8902C3.6205 11.5436 4.77014 11.8924 5.94618 11.8924C7.419 11.8924 8.7729 11.3526 9.81577 10.4561L10.0628 10.7123V11.435L14.6367 15.9998L15.9998 14.6367L11.435 10.0628ZM5.94618 10.0628C3.66834 10.0628 1.8296 8.22402 1.8296 5.94618C1.8296 3.66834 3.66834 1.8296 5.94618 1.8296C8.22402 1.8296 10.0628 3.66834 10.0628 5.94618C10.0628 8.22402 8.22402 10.0628 5.94618 10.0628Z" fill="#004877"/>
      </svg>
      Filtrar
    </button>
  </div> -->
  <div class="table-area">
    <mat-form-field appearance="standard" id="filtro">
        <mat-label>Pesquisar...</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="requestNumber">
          <th mat-header-cell *matHeaderCellDef>Nº da Solicitação</th>
          <td mat-cell *matCellDef="let element">{{ element.requestNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="requestDate">
          <th mat-header-cell *matHeaderCellDef>Data Solicitação</th>
          <td mat-cell *matCellDef="let element">{{ element.requestDate | date:'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="corporateName">
          <th mat-header-cell *matHeaderCellDef>Nome Produtor</th>
          <td mat-cell *matCellDef="let element">{{ element.producer.corporateName }}</td>
      </ng-container>
      <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef>Documento</th>
          <td mat-cell *matCellDef="let element">{{ maskCpfCnpj(element.producer.document) }}</td>
      </ng-container>
      <ng-container matColumnDef="nickname">
          <th mat-header-cell *matHeaderCellDef>Nome do Imóvel</th>
          <td mat-cell *matCellDef="let element">{{ element.producer.place.nickname }}</td>
      </ng-container>
      <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>CAR</th>
          <td mat-cell *matCellDef="let element">{{ element.producer.place.code }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" [ngClass]="getStatusColor(element.status)">
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="getStatusColor(element.status) === 'green'">
            <path d="M5.225 11.5114L0 6.28644L1.30625 4.98019L5.225 8.89894L13.6354 0.488525L14.9417 1.79478L5.225 11.5114Z" fill="#00D68F"/>
          </svg>
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="getStatusColor(element.status) === 'red'">
            <path d="M14.1385 12.6801C14.3322 12.8739 14.4411 13.1366 14.4411 13.4106C14.4411 13.6846 14.3322 13.9474 14.1385 14.1411C13.9448 14.3348 13.682 14.4437 13.408 14.4437C13.1341 14.4437 12.8713 14.3348 12.6776 14.1411L7.2214 8.6832L1.76351 14.1394C1.56977 14.3331 1.30702 14.4419 1.03304 14.4419C0.75906 14.4419 0.496303 14.3331 0.30257 14.1394C0.108838 13.9456 2.88684e-09 13.6829 0 13.4089C-2.88684e-09 13.1349 0.108838 12.8722 0.30257 12.6784L5.76046 7.22226L0.304289 1.76437C0.110556 1.57063 0.00171879 1.30788 0.00171879 1.0339C0.00171879 0.759919 0.110556 0.497162 0.304289 0.303429C0.498021 0.109697 0.760778 0.000859234 1.03476 0.000859231C1.30874 0.000859228 1.57149 0.109697 1.76523 0.303429L7.2214 5.76132L12.6793 0.30257C12.873 0.108838 13.1358 -4.56449e-09 13.4098 0C13.6837 4.56449e-09 13.9465 0.108838 14.1402 0.30257C14.334 0.496302 14.4428 0.75906 14.4428 1.03304C14.4428 1.30702 14.334 1.56978 14.1402 1.76351L8.68234 7.22226L14.1385 12.6801Z" fill="#F43841"/>
          </svg>
          <span>
            {{ getStatusName(element.status )}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ações </th>
        <td mat-cell *matCellDef="let element">
          <button type="button" class="actions-button" (click)="viewRequest(element)">
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.125 6.875C13.125 7.7038 12.7958 8.49866 12.2097 9.08471C11.6237 9.67076 10.8288 10 10 10C9.1712 10 8.37634 9.67076 7.79029 9.08471C7.20424 8.49866 6.875 7.7038 6.875 6.875C6.875 6.0462 7.20424 5.25134 7.79029 4.66529C8.37634 4.07924 9.1712 3.75 10 3.75C10.8288 3.75 11.6237 4.07924 12.2097 4.66529C12.7958 5.25134 13.125 6.0462 13.125 6.875Z" fill="#99CA3C"/>
              <path d="M0 6.875C0 6.875 3.75 0 10 0C16.25 0 20 6.875 20 6.875C20 6.875 16.25 13.75 10 13.75C3.75 13.75 0 6.875 0 6.875ZM10 11.25C11.1603 11.25 12.2731 10.7891 13.0936 9.96859C13.9141 9.14812 14.375 8.03532 14.375 6.875C14.375 5.71468 13.9141 4.60188 13.0936 3.78141C12.2731 2.96094 11.1603 2.5 10 2.5C8.83968 2.5 7.72688 2.96094 6.90641 3.78141C6.08594 4.60188 5.625 5.71468 5.625 6.875C5.625 8.03532 6.08594 9.14812 6.90641 9.96859C7.72688 10.7891 8.83968 11.25 10 11.25Z" fill="#99CA3C"/>
            </svg>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="8">Nenhum registro encontrado</td>
      </tr>
    </table>
  </div>
  <div class="paginacao">
      <mat-paginator id="paginator" [pageSizeOptions]="[5, 10, 25]" [length]="tableData.length" showFirstLastButtons></mat-paginator>
  </div>
</div>
