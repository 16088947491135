import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class XlsXService {
  storage: Storage;
  uploadFiles: any = [];
  token: any;
  item: any;
  httpOtions: any;
  userItem: any;
  user: any;
  constructor(
    private http: HttpClient
  ) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};
    this.userItem = this.storage.getItem('user');
    this.user = this.userItem ? JSON.parse(this.userItem) : {};

    this.httpOtions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    }
  }

  public exportQuery() {
    return this.http.get(`${environment.api}/qualityXLSX-service/geo-monitoring-analysis-without-protocol/${this.user.customerId}`, this.httpOtions).pipe(
      map((res) => {
        try {
          return JSON.stringify(res);
        } catch (e) {
          return res;
        }
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }
}
