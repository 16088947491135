<div class="products-list-container">
  <div class="header">
    <h2>Lista de Produtos</h2>
    <button type="button" (click)="closeModal()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4046 3.90302C20.1985 3.10913 20.1985 1.82198 19.4046 1.02809L18.9719 0.595418C18.178 -0.198473 16.8909 -0.198472 16.097 0.595418L10 6.6924L3.90302 0.59542C3.10913 -0.19847 1.82198 -0.198472 1.02809 0.595419L0.595418 1.02809C-0.198473 1.82198 -0.198472 3.10913 0.595418 3.90302L6.6924 10L0.59542 16.097C-0.198471 16.8909 -0.198473 18.178 0.595418 18.9719L1.02809 19.4046C1.82198 20.1985 3.10913 20.1985 3.90302 19.4046L10 13.3076L16.097 19.4046C16.8909 20.1985 18.178 20.1985 18.9719 19.4046L19.4046 18.9719C20.1985 18.178 20.1985 16.8909 19.4046 16.097L13.3076 10L19.4046 3.90302Z" fill="white"/>
      </svg>
    </button>
  </div>
  <div class="table-area">
    <mat-form-field appearance="standard" id="filtro">
      <mat-label>Pesquisar...</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Nome, Documento, Telefone..." #input>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> PLU </th>
        <td mat-cell *matCellDef="let element"> {{ element.plu }} </td>
      </ng-container>
      <ng-container matColumnDef="ean">
        <th mat-header-cell *matHeaderCellDef> EAN </th>
        <td mat-cell *matCellDef="let element"> {{ element.ean }} </td>
      </ng-container>
      <ng-container matColumnDef="productType">
        <th mat-header-cell *matHeaderCellDef> Tipo </th>
        <td mat-cell *matCellDef="let element"> {{ getFeatureByName(element, 'productType') }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Nenhum registro encontrado</td>
      </tr>
    </table>
  </div>
  <div class="paginacao">
    <mat-paginator id="paginator" [pageSizeOptions]="[5]" [length]="tableData.length" showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
