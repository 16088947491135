import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSemicolonAtEnd'
})
export class RemoveSemicolonAtEndPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/;$/, '');
  }

}
