<div class="resultado-analise-content">
  <div class="header green" *ngIf="!red">
    <h4>{{ title }} </h4>
  </div>
  <div class="header red" *ngIf="red">
    <h4>{{ title }} </h4>
  </div>
  <div class="no-data" *ngIf="!red">
    {{ legend }}
  </div>
  <div class="with-data" *ngIf="red">
    <span>{{ legend }}</span>
    <img [src]="convertBlobToUrl()" alt="" *ngIf="imageUrl && blobLoad" />
  </div>
</div>
