import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';
import { UploadLaudoComponent } from './upload-laudo/upload-laudo.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { ArquivosService } from 'src/app/services/arquivos.service';
import { ModalFileComponent } from 'src/app/components/modal-file/modal-file.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


interface requestParams {
  [key: string]: boolean;
}

interface Producer {
  document: string;
  corporateName: string;
  place: {
    address: {
      stateIdentifier: string;
      city: string;
    };
    ccir: string;
    code: string;
    codeType: string;
    nickname: string;
  };
}

@Component({
  selector: 'app-solicitacao-respondida',
  templateUrl: './solicitacao-respondida.component.html',
  styleUrls: ['./solicitacao-respondida.component.scss']
})
export class SolicitacaoRespondidaComponent implements OnInit {
  form!: FormGroup;
  editing: boolean = false;
  loading: boolean = false;
  request: requestParams = {};
  response: any;
  storage: Storage;
  user: any;
  isAdmin: boolean = false;
  laudo: any;
  analiseDetalhada: boolean = false;
  producer!: Producer;
  solicitacao: any;

  adCompany: any;
  chatForm!: FormGroup;
  imageList: Array<any> = []
  constructor(
    private router : Router,
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    private solicitacoesService: SolicitacoesService,
    public dialog: MatDialog,
    private arquivosService: ArquivosService,
    private sanitizer: DomSanitizer,
  ) {
    this.storage = window.sessionStorage;
    this.user = this.storage.getItem('user');
    this.user = this.user ? JSON.parse(this.user) : {};
    if (this.user.authorities.some((item: string) => item === 'ROLE_GEOMONITORING_ADMIN')) {
      this.isAdmin = true;
    }

    this.form = this.fb.group({
      id: [''],
      requestNumber: ['', Validators.required],
      requestDate: [''],
      producerName: ['', Validators.required],
      producerDocument: [''],
      placeName: [''],
      state: [''],
      city: [''],
      car: [''],
      ccir: [''],
      statusCar: [''],

      slavery: [''],
      ibamaSHPEmbargo: [''],
      ibamaXLSEmbargo: [''],
      deterAmazonia: [''],
      deterCerrado: [''],
      deterPantanal: [''],
      prodesAmazon: [''],
      prodesCerrado: [''],
      prodesMataAtlantica: [''],
      prodesCaatinga: [''],
      prodesPampa: [''],
      prodesPantanal: [''],
      indigenousLand: [''],
      conservationUnit: [''],
      archeologicalSites: [false],
      publicForests: [false],
      quilombolaAreas: [false],
      result: [''],
      reportFile: [''],

      //documents
      ccirFilePath: [''],
      producerPropertyFilePath: [''],
      carFilePath: [''],
      producerDocumentPath: [''],
      kmlPath: [''],

      sendDate: [''],
      status: [''],
    });

    this.chatForm = this.fb.group({
      requestNumber: ['', Validators.required],
      text: ['', Validators.required],
      username: [''],
      attachments: this.fb.array([]),
    })

  }

  getFormArray(type: string) {
    return (<UntypedFormArray>this.chatForm.get(type)).controls;
  }

  ngOnInit(): void {
    this.editing = false;
    const analiseDetalhada = this.activeRouter.snapshot.queryParamMap.get('analiseDetalhada');
    if (analiseDetalhada === 'true' ) {
      this.analiseDetalhada = true;
    }
    this.getAnaliseEdit();
  }

  getAnaliseEdit() {
    const id = this.activeRouter.snapshot.queryParamMap.get('id');
    if (id) {
      this.loading = true;
      this.solicitacoesService.getId(id).subscribe(res => {
        this.editing = true;
        this.solicitacao = res;
        this.form.get('id')?.setValue(res.id || '');
        this.form.get('requestNumber')?.setValue(res.requestNumber || '');
        this.chatForm.get('requestNumber')?.setValue(res.requestNumber || '');
        this.form.get('producerName')?.setValue(res.producer.corporateName || '');
        this.form.get('requestDate')?.setValue(res.requestDate);
        this.form.get('producerDocument')?.setValue(res.producer.document || '');
        this.form.get('placeName')?.setValue(res.producer.place.nickname || '');
        this.form.get('state')?.setValue(res.producer.place.address.stateIdentifier || '');
        this.form.get('city')?.setValue(res.producer.place.address.city || '');
        this.form.get('car')?.setValue(res.producer.place.code || '');
        this.form.get('ccir')?.setValue(res.producer.place.ccir || '');
        this.form.get('statusCar')?.setValue(''); // Não tem esse campo
        this.form.get('slavery')?.setValue(res.response && res.response.slavery ? true : false);
        this.form.get('ibamaSHPEmbargo')?.setValue(res.response && res.response.ibamaSHPEmbargo ? true : false);
        this.form.get('ibamaXLSEmbargo')?.setValue(res.response && res.response.ibamaXLSEmbargo ? true : false);
        this.form.get('deterAmazonia')?.setValue(res.response && res.response.deterAmazonia ? true : false);
        this.form.get('deterCerrado')?.setValue(res.response && res.response.deterCerrado ? true : false);
        this.form.get('deterPantanal')?.setValue(res.response && res.response.deterPantanal ? true : false);

        this.form.get('prodesAmazon')?.setValue(res.response && res.response.prodesAmazon ? true : false);
        this.form.get('prodesCerrado')?.setValue(res.response && res.response.prodesCerrado ? true : false);
        this.form.get('prodesMataAtlantica')?.setValue(res.response && res.response.prodesAtlanticForest ? true : false);
        this.form.get('prodesCaatinga')?.setValue(res.response && res.response.prodesCaatinga ? true : false);
        this.form.get('prodesPampa')?.setValue(res.response && res.response.prodesPampa ? true : false);
        this.form.get('prodesPantanal')?.setValue(res.response && res.response.prodesPantanal ? true : false);

        this.form.get('indigenousLand')?.setValue(res.response && res.response.indigenousLand ? true : false);
        this.form.get('conservationUnit')?.setValue(res.response && res.response.conservationUnit ? true : false);
        this.form.get('archeologicalSites')?.setValue(res.response && res.response.archeologicalSites ? true : false);
        this.form.get('publicForests')?.setValue(res.response && res.response.publicForests ? true : false);
        this.form.get('quilombolaAreas')?.setValue(res.response && res.response.quilombolaAreas ? true : false);
        this.form.get('result')?.setValue(res.response && res.response.result === 'ENABLED' ? true : false);
        this.form.get('sendDate')?.setValue(res.sendDate ? res.sendDate : 'Não Registrado');
        this.form.get('status')?.setValue(res.status || '');
        this.form.get('reportFile')?.setValue(res.reportFile || null);


        this.form.get('ccirFilePath')?.setValue(res.fileList && res.fileList.ccirFilePath ? res.fileList.ccirFilePath : '');
        this.form.get('producerPropertyFilePath')?.setValue(res.fileList && res.fileList.producerPropertyFilePath ? res.fileList.producerPropertyFilePath : '');
        this.form.get('carFilePath')?.setValue(res.fileList && res.fileList.carFilePath ? res.fileList.carFilePath : '');
        this.form.get('producerDocumentPath')?.setValue(res.fileList && res.fileList.producerDocumentPath ? res.fileList.producerDocumentPath : '');
        this.form.get('kmlPath')?.setValue(res.fileList && res.fileList.kmlPath ? res.fileList.kmlPath : '');


        this.request = res.request;
        this.response = res.response;
        this.producer = res.producer;
        this.form.disable();

        if (this.solicitacao.response.images) {
          const images = this.solicitacao.response.images
          const chaves = Object.keys(images);
          chaves.forEach(chave => {
            this.arquivosService.getFile(images[chave]).subscribe(
              res => {
                this.imageList.push(this.convertBlobToUrl(res));
              }, error => {

              }
            )
          });
        }

        if (this.isAdmin && this.analiseDetalhada) {
          this.form.get('status')?.enable();
        }
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
  }

  convertBlobToUrl(arquivoblob: Blob): SafeUrl {
    const objectURL = URL.createObjectURL(arquivoblob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  getStatusColor(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'green',
      'POST_ANALYSIS_ENABLED': 'green',
      'NOT_ENABLED': 'yellow',
      'POST_ANALYSIS_NOT_ENABLED': 'red',
      'ANALYSIS_IN_PROGRESS' : 'yellow',
      'VALIDATING': 'yellow',
      'PENDING': 'red',
    };
    return statusColors[status] || '';
  }

  checkRequestOptions(option: string): boolean {
    if (option) {
      return this.request[option];
    }
    return false;
  }

  getStatusName(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'Habilitado',
      'POST_ANALYSIS_ENABLED': 'Habilitado AD',
      'NOT_ENABLED': 'Em Análise AD',
      'POST_ANALYSIS_NOT_ENABLED': 'Não Habilitado AD',
      'ANALYSIS_IN_PROGRESS' : 'Analisando',
      'VALIDATING': 'Validando',
      'PENDING': 'Pendente',
    };
    return statusColors[status] || '';
  }

  protected back() : void{
    this.router.navigate(['/safe-gis/solicitacoes/solicitacoes-respondidas']);
  }

    submitForm() {
    const dialogref = this.dialog.open(ModalConfirmComponent, {
      data: {
        fornecedor: this.form.value,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        this.loading = true;
        if (this.laudo) {
          this.solicitacoesService.uploadFile(this.laudo).subscribe(res => {
            this.loading = false;
            if (res) {
              const item = this.storage.getItem('user');
              const user = item ? JSON.parse(item) : {};
              const whiteList = user.whiteList
              whiteList.push(user.customerId);
              whiteList.push(this.adCompany);
              this.form.get('reportFile')?.setValue(res);
              let solicitacao = this.solicitacao;

              solicitacao.status = this.form.get('status')?.value
              solicitacao.reportFile = this.form.get('reportFile')?.value
              solicitacao.whiteList=whiteList

              const body = solicitacao;
              this.solicitacoesService.updateRequest(body).subscribe(res => {
                const dialogref = this.dialog.open(ModalConfirmComponent, {
                  data: {
                    success: true,
                    successText: 'Salvo com Sucesso!'
                  },
                });

                dialogref.afterClosed().subscribe(result => {
                  this.back();
                });
              }, error => {
                this.loading = false;
                this.dialog.open(ModalConfirmComponent, {
                  data: {
                    error: true,
                    errorText: 'Não foi possível salvar'
                  },
                });
              });
            }
          }, error => {
            this.loading = false;
            if (error.error.text) {
              const item = this.storage.getItem('user');
              const user = item ? JSON.parse(item) : {};
              const whiteList = user.whiteList
              whiteList.push(user.customerId);
              whiteList.push(this.adCompany);
              this.form.get('reportFile')?.setValue(error.error.text);
              const body = {
                id: this.form.get('id')?.value,
                requestNumber: this.form.get('requestNumber')?.value,
                requestDate: this.form.get('requestDate')?.value,
                producer: this.producer,
                sendDate: this.form.get('sendDate')?.value,
                request: this.request,
                response: this.response,
                status: this.form.get('status')?.value,
                whiteList: whiteList,
                reportFile: this.form.get('reportFile')?.value,
              };
              this.loading = true;
              this.solicitacoesService.updateRequest(body).subscribe(res => {
                this.loading = false;
                const dialogref = this.dialog.open(ModalConfirmComponent, {
                  data: {
                    success: true,
                    successText: 'Salvo com Sucesso!'
                  },
                });
                dialogref.afterClosed().subscribe(result => {
                  this.back();
                });
              }, error => {
                this.loading = false;
                this.dialog.open(ModalConfirmComponent, {
                  data: {
                    error: true,
                    errorText: 'Não foi possível salvar'
                  },
                });
              });
            }
          });
        } else {
          const item = this.storage.getItem('user');
          const user = item ? JSON.parse(item) : {};
          const whiteList = user.whiteList
          whiteList.push(user.customerId);
          whiteList.push(this.adCompany);
          this.form.get('reportFile')?.setValue('');
          let solicitacao = this.solicitacao;
          solicitacao.status = this.form.get('status')?.value
          solicitacao.reportFile = this.form.get('reportFile')?.value
          solicitacao.whiteList= whiteList

          const body = solicitacao;
          this.loading = true;
          this.solicitacoesService.updateRequest(body).subscribe(res => {
            this.loading = false;
            const dialogref = this.dialog.open(ModalConfirmComponent, {
              data: {
                success: true,
                successText: 'Salvo com Sucesso!'
              },
            });
            dialogref.afterClosed().subscribe(result => {
              this.back();
            })
          }, error => {
            this.loading = false;
            this.dialog.open(ModalConfirmComponent, {
              data: {
                error: true,
                errorText: 'Não foi possível salvar'
              },
            });
          });
        }
      }
    });
  }


  submitMessage() {
    const formArray = this.chatForm.get('attachments') as UntypedFormArray;
    const attachments = formArray.value.reduce((acc: any, obj: any) => {
      acc[obj.name] = obj.value;
      return acc;
    }, {});

    const body = {
      requestNumber: this.form.get('requestNumber')?.value,
      text: this.chatForm.get('text')?.value,
      username: this.user.sub,
      attachments: attachments,
    };
    if (this.chatForm.valid) {
      this.loading = true;
      this.solicitacoesService.sendMessage(body).subscribe( res => {
        this.loading = false;
        this.getAnaliseEdit();
        this.chatForm.get('text')?.setValue('');
        attachments.clear();
      }, error => {
        this.loading = false;
        this.chatForm.get('text')?.setValue('');
        attachments.clear();
      });
    }
  }

  private createAttachment(attachment: any = { name: '', value: ''}) {
    return this.fb.group({
      name: [attachment.name, Validators.required],
      value: [attachment.value, Validators.required],
    });
  }

  public removeIndexForm(index: number) {
    const formaArray = this.chatForm.get('attachments') as UntypedFormArray;
    formaArray.removeAt(index);
  }


  openModalAssets() {
    const dialogref = this.dialog.open(ModalFileComponent, {
      data: {
        title: 'Carregar Arquivo',
        fileTitle: true,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.loading = true;
          this.arquivosService.uploadFile(result.file).subscribe(res => {
            this.loading = false;
            const attachments = this.chatForm.get('attachments') as UntypedFormArray;
            attachments.push(this.createAttachment({name: result.fileTitle, value: res}));
          }, error => {
            const attachments = this.chatForm.get('attachments') as UntypedFormArray;
            attachments.push(this.createAttachment({name: result.fileTitle, value: error.error.text}));
            this.loading = false;
          });
      }
     });
  }

  downloadFile(type: any) {
    let file = '';
    let fileName = '';
    if (type === 'laudo') {
      file = this.form.get('reportFile')?.value;
      fileName = atob(file);
    }
    if (file) {
      this.solicitacoesService.getFile(file).subscribe((arquivo: Blob) => {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.slice(7);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, error => {
      });
    }
  }

  uploadFile() {
    const dialogref = this.dialog.open(UploadLaudoComponent, {
      data: {
        selectFile: true,
        title: 'Carregar Laudo'
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.laudo = result.file;
      }
     });
  }

  getFileName(filePath: string) {
    return atob(filePath).slice(18);
  }

  downloadFileName(type: any) {
    let file = '';
    let fileName = '';

    switch(type){
      case 'ccirFilePath':
        file = this.form.get('ccirFilePath')?.value;
        break;
      case 'producerPropertyFilePath':
        file = this.form.get('producerPropertyFilePath')?.value;
        break;
      case 'carFilePath':
        file = this.form.get('carFilePath')?.value;
        break;
      case 'producerDocumentPath':
        file = this.form.get('producerDocumentPath')?.value;
        break;
      default:
        file = this.form.get('producerDocumentPath')?.value;
    }
    fileName = atob(file);

    if (file) {
      this.arquivosService.getFile(file).subscribe((arquivo: Blob) => {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.slice(18);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

      }, error => {
        this.openModalSuccessOrError(true, 'Não foi possível baixar o arquivo');
      });
    }

  }

  openModalSuccessOrError(error = false, msg?: string) {
    this.loading = false;
    if (error) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: msg ? msg : '',
        },
      });
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          success: true,
          successText: msg ? msg : '',
        },
      }).afterClosed().subscribe( result => {
      });
    }
  }
}
