import { ArquivosService } from './../../../../services/arquivos.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-laudo-monitoramento',
  templateUrl: './laudo-monitoramento.component.html',
  styleUrls: ['./laudo-monitoramento.component.scss']
})
export class LaudoMonitoramentoComponent implements OnInit {
  @ViewChild('laudo') laudo!: ElementRef;

  blobUrl$:any;
  dataNow: Date = new Date();
  request: any;
  images: any = [];



  blobUrl: string = '';

  constructor(
    private solicitacoesService: SolicitacoesService,
    private activeRouter: ActivatedRoute,
    private arquivosService: ArquivosService,
  ) { }

  ngOnInit(): void {

    const id = this.activeRouter.snapshot.queryParamMap.get('id');
    if (id) {
      this.solicitacoesService.getRequestById(id).subscribe(res => {
        this.request = res;
      }, error => {

      });
    }
  }

  downloadPDF(): void {
    window.print();
  }

  getStatusColor(status: string): string {
    return this.solicitacoesService.getStatusColor(status);
  }

  getStatusName(status: string): string {
    return this.solicitacoesService.getStatusName(status);
  }

  getTipoAnalise(status: string):string {
    switch (status){
      case 'ENABLED':
        return 'Automática';
      case 'NOT_ENABLED':
        return 'Análise Detalhada';
      case 'POST_ANALYSIS_ENABLED':
        return 'Análise Detalhada';
      case 'POST_ANALYSIS_NOT_ENABLED':
        return 'Análise Detalhada';
      default:
        return 'Análise Detalhada';
    }
  }

  maskCpfCnpj(document: string) {
    return formatCpfCnpj(document);
  }

  getRequestData(type: string) {
    return this.request.request[type];
  }

  getResponseData(type: string) {
    return this.request.response[type];
  }

  getLegend(type: string): string {
    if (this.request.response && this.request.response[type]) {
      return `Apresenta sobreposição de ${this.getResponseArea(type)} ha para o período analisado ${this.converterFormatoData(this.request.requestDate)}`;
    }
    return  `Não apresenta ${this.getTitle(type)} na propriedade analisada.`;
  }

  getImage(type: string): string {
    if (this.request.response && this.request.response.images && this.request.response.images[type]) {
      return this.request.response.images[type];
    }
    return '';
  }


  getResponseArea(type:string): string | number {
    if (this.request.response && this.request.response && this.request.response.responseAreas && this.request.response.responseAreas[type]) {
      return this.request.response.responseAreas[type];
    }
    return 0;
  }


  getTitle(type:string): string {
    switch(type) {
      case 'archeologicalSites':
        return 'Sitios Arqueológicos';
      case 'conservationUnit':
        return 'Unidade de Conservação';
      case 'deterAmazon':
        return 'Deter Amazônia';
      case 'deterCerrado':
        return 'Deter Cerrado';
      case 'deterPantanal':
        return 'Deter Pantanal';
      case 'ibamaEmbargo':
        return 'Embargo Ibama';
      case 'indigenousLand':
        return 'Terra Indígena';
      case 'prodesAmazon':
        return 'Prodes Amazônia';
      case 'prodesAtlanticForest':
        return 'Prodes Mata Atlântica';
      case 'prodesCaatinga':
        return 'Prodes Caatinga';
      case 'prodesCerrado':
        return 'Prodes Cerrado';
      case 'prodesPampa':
        return 'Prodes Pampa';
      case 'prodesPantanal':
        return 'Prodes Pantanal';
      case 'publicForests':
        return 'Florestas Públicas';
      case 'quilombolaAreas':
        return 'Áreas Quilombolas';
      case 'slavery':
        return 'Trabalho Escravo';
      case 'miningAreas':
        return 'Área de Mineração'
      default:
        return '';
    }
  }

  converterFormatoData(dataString: string): string {
    const data = new Date(dataString);
    if (isNaN(data.getTime())) {
      return 'Data inválida';
    }
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  }
}
