import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { iModalConfirm, ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class OpenModalConfirmService {
  constructor(
    private dialog: MatDialog,
  ) { }

  openModalConfirm(data: iModalConfirm) {
    return this.dialog.open(ModalConfirmComponent, {
      data,
    }).afterClosed();
  }
}
