<div class="solicitacao-info-container">
  <form [formGroup]="form">
    <div class="main-area">
      <h2>Dados Produtor/Propriedade</h2>
      <div class="input-area">
        <div class="form-group" tabindex="-1"  style="width: 213px; flex-grow: 0;">
          <input type="text" class="form-input" id="requestNumber" name="requestNumber" formControlName="requestNumber"/>
          <label class="form-label" for="requestNumber" [ngClass]="{ focus:  form.get('requestNumber')?.value }">
            Nº da Solicitação
          </label>
          <span class="alert" *ngIf="
              form.get('requestNumber')?.errors &&
              (form.get('requestNumber')?.touched ||
                form.get('requestNumber')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <div class="form-group" tabindex="-1">
          <input type="text" class="form-input" id="producerName" name="producerName" formControlName="producerName"/>
          <label class="form-label" for="producerName" [ngClass]="{ focus:  form.get('producerName')?.value }">
            Nome do Produtor
          </label>
          <span class="alert" *ngIf="
              form.get('producerName')?.errors &&
              (form.get('producerName')?.touched ||
                form.get('producerName')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <div class="form-group" tabindex="-1" >
          <input type="text" class="form-input" id="ownerDocument" name="ownerDocument" formControlName="ownerDocument" mask="000.000.000-00||00.000.000/0000-00" />
          <label class="form-label" for="ownerDocument"
            [ngClass]="{ focus:  form.get('ownerDocument')?.value }">
            Documento do Proprietario
          </label>
          <span class="alert" *ngIf="
              form.get('ownerDocument')?.errors &&
              (form.get('ownerDocument')?.touched ||
                form.get('ownerDocument')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <div class="form-group" tabindex="-1">
          <input type="text" class="form-input" id="propertyName" name="propertyName" formControlName="propertyName"/>
          <label class="form-label" for="propertyName" [ngClass]="{ focus:  form.get('propertyName')?.value }">
            Nome do Imóvel
          </label>
          <span class="alert" *ngIf="
              form.get('propertyName')?.errors &&
              (form.get('propertyName')?.touched ||
                form.get('propertyName')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <div class="form-group" tabindex="-1">
          <input type="text" class="form-input" id="car" name="car" formControlName="car" mask="SS-0000000-AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
          (blur)="getCity()" />
          <label class="form-label" for="car"
            [ngClass]="{ focus:  form.get('car')?.value }">
            CAR
          </label>
          <span class="alert" *ngIf="
              form.get('car')?.errors &&
              (form.get('car')?.touched ||
                form.get('car')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <div class="form-group" tabindex="-1" style="width: 274px; flex-grow: 0;">
          <input type="text" class="form-input" id="uf" name="uf"
            formControlName="uf" readonly />
          <label class="form-label" for="uf"
            [ngClass]="{ focus:  form.get('uf')?.value }">
            UF
          </label>
          <span class="alert" *ngIf="
              form.get('uf')?.errors &&
              (form.get('uf')?.touched ||
                form.get('uf')?.dirty)
            ">Campo obrigatório</span>
        </div>

        <div class="form-group" tabindex="-1">
          <input type="text" class="form-input" id="town" name="town"
            formControlName="town" readonly />
          <label class="form-label" for="town"
            [ngClass]="{ focus:  form.get('town')?.value }">
            Município
          </label>
          <span class="alert" *ngIf="
              form.get('town')?.errors &&
              (form.get('town')?.touched ||
                form.get('town')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <div class="form-group" tabindex="-1">
          <input type="text" class="form-input" id="ccir" name="ccir"
            formControlName="ccir"/>
          <label class="form-label" for="ccir"
            [ngClass]="{ focus:  form.get('ccir')?.value }">
            CCIR
          </label>
          <span class="alert" *ngIf="
              form.get('ccir')?.errors &&
              (form.get('ccir')?.touched ||
                form.get('ccir')?.dirty)
            ">Campo obrigatório</span>
        </div>
        <!-- <div class="select-container" tabindex="-1" style="width: 213px; flex-grow: 0;">
          <select
            type="text"
            class="form-input select"
            id="statusCar"
            name="statusCar"
            formControlName="statusCar"
          >
            <option value="" disabled></option>
            <option [value]="true">Ativo</option>
            <option [value]="false">Inativo</option>
          </select>

          <label class="form-label" for="statusCar"
            [ngClass]="{ focus: form.get('statusCar')?.value }">
            Status CAR
          </label>
          <span class="alert" *ngIf="
              form.get('statusCar')?.errors &&
              (form.get('statusCar')?.touched ||
                form.get('statusCar')?.dirty)
            ">Campo obrigatório
          </span>
        </div> -->
      </div>
      <div class="interactions">
        <h2>Interações</h2>
        <div class="chat-area">
          <div class="messages-area" *ngIf="solicitacoes && solicitacoes.messages">
            <div [class]="user.sub === message.username ? 'message owner' : 'message'" *ngFor="let message of solicitacoes.messages">
              <p>{{ message.text }}</p>
              <div *ngIf="message.attachments" class="assets">
                <div *ngFor="let item of message.attachments | keyvalue" class="asset">
                  <button type="button" (click)="downloadFile(item.value)">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.4286 10H9.85716M3.4286 12.5714H12.4286M3.4286 15.1429H7.28574M16.2857 16.4286V7.42857L9.85716 1H3.4286C2.74662 1 2.09257 1.27092 1.61033 1.75315C1.12809 2.23539 0.857178 2.88944 0.857178 3.57143V16.4286C0.857178 17.1106 1.12809 17.7646 1.61033 18.2468C2.09257 18.7291 2.74662 19 3.4286 19H13.7143C14.3963 19 15.0503 18.7291 15.5326 18.2468C16.0148 17.7646 16.2857 17.1106 16.2857 16.4286Z" stroke="#2B81AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>{{ item.key }}</span>
                  </button>
                </div>
              </div>
              <span><strong>{{ message.username }} </strong> - {{ message.timestamp | date:'dd/MM/yyyy - HH:mm'}} </span>
            </div>
          </div>
        </div>
        <div class="input-area-message" onsubmit="submitMessage()" >
          <form [formGroup]="chatForm">
            <div class="text-area">
              <div class="form-group">
                <!-- <input type="text" class="form-input" id="text" name="text" formControlName="text"/> -->
                <textarea type="text" class="form-input" id="text" name="text" formControlName="text" > </textarea>
                <label class="form-label text-area-label" for="text" [ngClass]="{ focus:  chatForm.get('text')?.value }">
                  Nova Mensagem
                </label>
              </div>
              <button class="button" type="button" (click)="submitMessage()">
                Enviar
              </button>
            </div>
            <div class="assets">
              <div [formGroupName]="i" *ngFor="let attachment of getFormArray('attachments'); let i = index" class="asset">
                  <button type="button" class="attachment-button" (click)="downloadFile(attachment.get('value')?.value)">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.4286 10H9.85716M3.4286 12.5714H12.4286M3.4286 15.1429H7.28574M16.2857 16.4286V7.42857L9.85716 1H3.4286C2.74662 1 2.09257 1.27092 1.61033 1.75315C1.12809 2.23539 0.857178 2.88944 0.857178 3.57143V16.4286C0.857178 17.1106 1.12809 17.7646 1.61033 18.2468C2.09257 18.7291 2.74662 19 3.4286 19H13.7143C14.3963 19 15.0503 18.7291 15.5326 18.2468C16.0148 17.7646 16.2857 17.1106 16.2857 16.4286Z" stroke="#2B81AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>{{ attachment.get('name')?.value }}</span>
                  </button>
                  <button class="button" type="button" (click)="removeIndexForm(i)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.6 2.8H8.4C8.4 2.4287 8.2525 2.0726 7.98995 1.81005C7.7274 1.5475 7.3713 1.4 7 1.4C6.6287 1.4 6.2726 1.5475 6.01005 1.81005C5.7475 2.0726 5.6 2.4287 5.6 2.8ZM4.2 2.8C4.2 2.05739 4.495 1.3452 5.0201 0.820101C5.5452 0.294999 6.25739 0 7 0C7.74261 0 8.4548 0.294999 8.9799 0.820101C9.505 1.3452 9.8 2.05739 9.8 2.8H13.3C13.4857 2.8 13.6637 2.87375 13.795 3.00503C13.9263 3.1363 14 3.31435 14 3.5C14 3.68565 13.9263 3.8637 13.795 3.99497C13.6637 4.12625 13.4857 4.2 13.3 4.2H12.6826L12.0624 11.438C12.0028 12.1369 11.683 12.788 11.1663 13.2624C10.6496 13.7369 9.97366 14.0001 9.2722 14H4.7278C4.02634 14.0001 3.35039 13.7369 2.8337 13.2624C2.31702 12.788 1.99722 12.1369 1.9376 11.438L1.3174 4.2H0.7C0.514348 4.2 0.336301 4.12625 0.205025 3.99497C0.0737498 3.8637 0 3.68565 0 3.5C0 3.31435 0.0737498 3.1363 0.205025 3.00503C0.336301 2.87375 0.514348 2.8 0.7 2.8H4.2ZM9.1 7C9.1 6.81435 9.02625 6.6363 8.89497 6.50503C8.7637 6.37375 8.58565 6.3 8.4 6.3C8.21435 6.3 8.0363 6.37375 7.90503 6.50503C7.77375 6.6363 7.7 6.81435 7.7 7V9.8C7.7 9.98565 7.77375 10.1637 7.90503 10.295C8.0363 10.4263 8.21435 10.5 8.4 10.5C8.58565 10.5 8.7637 10.4263 8.89497 10.295C9.02625 10.1637 9.1 9.98565 9.1 9.8V7ZM5.6 6.3C5.41435 6.3 5.2363 6.37375 5.10503 6.50503C4.97375 6.6363 4.9 6.81435 4.9 7V9.8C4.9 9.98565 4.97375 10.1637 5.10503 10.295C5.2363 10.4263 5.41435 10.5 5.6 10.5C5.78565 10.5 5.9637 10.4263 6.09497 10.295C6.22625 10.1637 6.3 9.98565 6.3 9.8V7C6.3 6.81435 6.22625 6.6363 6.09497 6.50503C5.9637 6.37375 5.78565 6.3 5.6 6.3Z" fill="white"/>
                    </svg>
                  </button>
              </div>
              <button type="button" class="button" (click)="openModalAssets()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.49984 15.3333C1.99567 15.3333 1.56392 15.1536 1.20459 14.7943C0.845255 14.435 0.665894 14.0035 0.666505 13.5V10.75H2.49984V13.5H13.4998V10.75H15.3332V13.5C15.3332 14.0041 15.1535 14.4359 14.7942 14.7952C14.4348 15.1545 14.0034 15.3339 13.4998 15.3333H2.49984ZM7.08317 11.6666V4.19579L4.69984 6.57913L3.41651 5.24996L7.99984 0.666626L12.5832 5.24996L11.2998 6.57913L8.9165 4.19579V11.6666H7.08317Z" fill="white"/>
                </svg>
                <span> Upload Arquivo</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <h2>Critérios a serem avaliados: </h2>
      <section class="checkbox-section">
        <mat-checkbox class="check-option" formControlName="slavery">Trabalho Escravo</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="ibamaEmbargo">Embargo Ibama</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="deter">Deter</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="prodes">Prodes AMZ Legal</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="indigenousLand">Terra Indígena</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="conservationUnit">Unidade de Conservação</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="archeologicalSites">Sítios Arqueológicos</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="publicForests">Florestas Públicas</mat-checkbox>
        <mat-checkbox class="check-option" formControlName="quilombolaAreas">Áreas Quilombolas</mat-checkbox>
      </section>
    </div>

    <div class="upload-arquivo">
      <div class="file-area">
        <button type="button" class="button" (click)="openModalFile('ccirFilePath')">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!view">
            <path d="M6.00531 4.69334C6.42915 5.07407 6.89609 4.81187 7.04335 4.65742L8.76743 2.80403V13.479C8.76743 13.8848 9.09429 14.2117 9.50017 14.2117C9.90605 14.2117 10.2329 13.8848 10.2329 13.479V2.80044L11.957 4.65383C12.2336 4.95195 12.6969 4.96632 12.995 4.68975C13.2931 4.41318 13.3075 3.94983 13.0309 3.65171L10.0354 0.433421C9.89527 0.286155 9.70131 0.199951 9.50017 0.199951C9.29543 0.199951 9.10147 0.286155 8.96499 0.433421L5.96939 3.65171C5.69282 3.95342 5.7036 4.42395 6.00531 4.69334Z" fill="white"/>
            <path d="M17.5673 11.8411C17.1614 11.8411 16.8345 12.1679 16.8345 12.5738V16.338H2.16548V12.5738C2.16548 12.1679 1.83862 11.8411 1.43275 11.8411C1.02687 11.8411 0.700012 12.1679 0.700012 12.5738V17.0708C0.700012 17.4767 1.02687 17.8035 1.43275 17.8035H17.5673C17.9732 17.8035 18.3 17.4767 18.3 17.0708V12.5738C18.3 12.1679 17.9732 11.8411 17.5673 11.8411Z" fill="white"/>
          </svg>
          <span> {{ view ? 'CCIR' : 'Upload CCIR' }}</span>
        </button>
        <div class="uploaded-file" *ngIf="!view">
          <button type="button" (click)="downloadFile(form.get('ccirFilePath')?.value)"> {{ getFileName(form.get('ccirFilePath')?.value) }} </button>
          <button type="button" class="button" *ngIf="form.get('ccirFilePath')?.value && !view" (click)="deleteFilePath('ccirFilePath')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z" fill="white"/>
            </svg>
          </button>
        </div>
        <span style="font-size: 12px; color: red;" *ngIf="form.get('ccirFilePath')?.errors && (form.get('ccirFilePath')?.touched || form.get('ccirFilePath')?.dirty)">Campo obrigatório</span>
      </div>
      <div class="file-area">
        <button type="button" class="button" (click)="openModalFile('producerPropertyFilePath')">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!view">
            <path d="M6.00531 4.69334C6.42915 5.07407 6.89609 4.81187 7.04335 4.65742L8.76743 2.80403V13.479C8.76743 13.8848 9.09429 14.2117 9.50017 14.2117C9.90605 14.2117 10.2329 13.8848 10.2329 13.479V2.80044L11.957 4.65383C12.2336 4.95195 12.6969 4.96632 12.995 4.68975C13.2931 4.41318 13.3075 3.94983 13.0309 3.65171L10.0354 0.433421C9.89527 0.286155 9.70131 0.199951 9.50017 0.199951C9.29543 0.199951 9.10147 0.286155 8.96499 0.433421L5.96939 3.65171C5.69282 3.95342 5.7036 4.42395 6.00531 4.69334Z" fill="white"/>
            <path d="M17.5673 11.8411C17.1614 11.8411 16.8345 12.1679 16.8345 12.5738V16.338H2.16548V12.5738C2.16548 12.1679 1.83862 11.8411 1.43275 11.8411C1.02687 11.8411 0.700012 12.1679 0.700012 12.5738V17.0708C0.700012 17.4767 1.02687 17.8035 1.43275 17.8035H17.5673C17.9732 17.8035 18.3 17.4767 18.3 17.0708V12.5738C18.3 12.1679 17.9732 11.8411 17.5673 11.8411Z" fill="white"/>
          </svg>
          <span> {{ view ? 'Vinculo produtor x propriedade' : 'Upload Vinculo produtor x propriedade' }}</span>
        </button>
        <div class="uploaded-file" *ngIf="!view">
          <button type="button" (click)="downloadFile(form.get('producerPropertyFilePath')?.value)">{{ getFileName(form.get('producerPropertyFilePath')?.value) }} </button>
          <button type="button" class="button" *ngIf="form.get('producerPropertyFilePath')?.value && !view" (click)="deleteFilePath('producerPropertyFilePath')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z" fill="white"/>
            </svg>
          </button>
        </div>
        <span style="font-size: 12px; color: red;" *ngIf="form.get('producerPropertyFilePath')?.errors && (form.get('producerPropertyFilePath')?.touched || form.get('producerPropertyFilePath')?.dirty)">Campo obrigatório</span>
      </div>
      <div class="file-area">
        <button type="button" class="button" (click)="openModalFile('carFilePath')">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!view">
            <path d="M6.00531 4.69334C6.42915 5.07407 6.89609 4.81187 7.04335 4.65742L8.76743 2.80403V13.479C8.76743 13.8848 9.09429 14.2117 9.50017 14.2117C9.90605 14.2117 10.2329 13.8848 10.2329 13.479V2.80044L11.957 4.65383C12.2336 4.95195 12.6969 4.96632 12.995 4.68975C13.2931 4.41318 13.3075 3.94983 13.0309 3.65171L10.0354 0.433421C9.89527 0.286155 9.70131 0.199951 9.50017 0.199951C9.29543 0.199951 9.10147 0.286155 8.96499 0.433421L5.96939 3.65171C5.69282 3.95342 5.7036 4.42395 6.00531 4.69334Z" fill="white"/>
            <path d="M17.5673 11.8411C17.1614 11.8411 16.8345 12.1679 16.8345 12.5738V16.338H2.16548V12.5738C2.16548 12.1679 1.83862 11.8411 1.43275 11.8411C1.02687 11.8411 0.700012 12.1679 0.700012 12.5738V17.0708C0.700012 17.4767 1.02687 17.8035 1.43275 17.8035H17.5673C17.9732 17.8035 18.3 17.4767 18.3 17.0708V12.5738C18.3 12.1679 17.9732 11.8411 17.5673 11.8411Z" fill="white"/>
          </svg>
          <span> {{ view ? 'CAR' : 'Upload CAR' }} </span>
        </button>
        <div class="uploaded-file" *ngIf="!view">
          <button type="button" (click)="downloadFile(form.get('carFilePath')?.value)"> {{ getFileName(form.get('carFilePath')?.value) }} </button>
          <button type="button" class="button" *ngIf="form.get('carFilePath')?.value && !view" (click)="deleteFilePath('carFilePath')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z" fill="white"/>
            </svg>
          </button>
        </div>
        <span style="font-size: 12px; color: red;" *ngIf="form.get('carFilePath')?.errors && (form.get('carFilePath')?.touched || form.get('carFilePath')?.dirty)">Campo obrigatório</span>
      </div>
      <div class="file-area">
        <button type="button" class="button" (click)="openModalFile('producerDocumentPath')">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!view">
            <path d="M6.00531 4.69334C6.42915 5.07407 6.89609 4.81187 7.04335 4.65742L8.76743 2.80403V13.479C8.76743 13.8848 9.09429 14.2117 9.50017 14.2117C9.90605 14.2117 10.2329 13.8848 10.2329 13.479V2.80044L11.957 4.65383C12.2336 4.95195 12.6969 4.96632 12.995 4.68975C13.2931 4.41318 13.3075 3.94983 13.0309 3.65171L10.0354 0.433421C9.89527 0.286155 9.70131 0.199951 9.50017 0.199951C9.29543 0.199951 9.10147 0.286155 8.96499 0.433421L5.96939 3.65171C5.69282 3.95342 5.7036 4.42395 6.00531 4.69334Z" fill="white"/>
            <path d="M17.5673 11.8411C17.1614 11.8411 16.8345 12.1679 16.8345 12.5738V16.338H2.16548V12.5738C2.16548 12.1679 1.83862 11.8411 1.43275 11.8411C1.02687 11.8411 0.700012 12.1679 0.700012 12.5738V17.0708C0.700012 17.4767 1.02687 17.8035 1.43275 17.8035H17.5673C17.9732 17.8035 18.3 17.4767 18.3 17.0708V12.5738C18.3 12.1679 17.9732 11.8411 17.5673 11.8411Z" fill="white"/>
          </svg>
          <span>{{ view ? 'Documento Proprietário' : 'Upload Documento Proprietário'}}</span>
        </button>
        <div class="uploaded-file" *ngIf="!view">
          <button type="button" (click)="downloadFile(form.get('producerDocumentPath')?.value)"> {{ getFileName(form.get('producerDocumentPath')?.value) }} </button>
          <button type="button" class="button" *ngIf="form.get('producerDocumentPath')?.value && !view" (click)="deleteFilePath('producerDocumentPath')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z" fill="white"/>
            </svg>
          </button>
        </div>
        <span style="font-size: 12px; color: red;" *ngIf="form.get('producerDocumentPath')?.errors && (form.get('producerDocumentPath')?.touched || form.get('producerDocumentPath')?.dirty)">Campo obrigatório</span>
      </div>
      <div class="file-area">
        <button type="button" class="button" (click)="downloadFiles()" *ngIf="hasFiles()">Arquivos diversos </button>
        <div *ngFor="let key of getObjectKeys(fileList)">
         <button  (click)="downloadFile(fileList[key])">{{ key }}
         </button>
       </div>
     </div>
      <div class="file-area" *ngIf="isAdmin && (!view || form.get('kmlPath')?.value)">
        <button type="button" class="button" (click)="openModalFile('kmlPath')">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!view">
            <path d="M6.00531 4.69334C6.42915 5.07407 6.89609 4.81187 7.04335 4.65742L8.76743 2.80403V13.479C8.76743 13.8848 9.09429 14.2117 9.50017 14.2117C9.90605 14.2117 10.2329 13.8848 10.2329 13.479V2.80044L11.957 4.65383C12.2336 4.95195 12.6969 4.96632 12.995 4.68975C13.2931 4.41318 13.3075 3.94983 13.0309 3.65171L10.0354 0.433421C9.89527 0.286155 9.70131 0.199951 9.50017 0.199951C9.29543 0.199951 9.10147 0.286155 8.96499 0.433421L5.96939 3.65171C5.69282 3.95342 5.7036 4.42395 6.00531 4.69334Z" fill="white"/>
            <path d="M17.5673 11.8411C17.1614 11.8411 16.8345 12.1679 16.8345 12.5738V16.338H2.16548V12.5738C2.16548 12.1679 1.83862 11.8411 1.43275 11.8411C1.02687 11.8411 0.700012 12.1679 0.700012 12.5738V17.0708C0.700012 17.4767 1.02687 17.8035 1.43275 17.8035H17.5673C17.9732 17.8035 18.3 17.4767 18.3 17.0708V12.5738C18.3 12.1679 17.9732 11.8411 17.5673 11.8411Z" fill="white"/>
          </svg>
          <span> {{ view ? 'Arquivo KML' : 'Upload de Arquivo KML'}} </span>
        </button>
        <div class="uploaded-file">
          <button type="button" (click)="downloadFile('kmlPath')"> {{ getFileName(form.get('kmlPath')?.value) }} </button>
          <button type="button" class="button" *ngIf="form.get('kmlPath')?.value && !view" (click)="deleteFilePath('kmlPath')">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.2 3.6H10.8C10.8 3.12261 10.6104 2.66477 10.2728 2.32721C9.93523 1.98964 9.47739 1.8 9 1.8C8.52261 1.8 8.06477 1.98964 7.72721 2.32721C7.38964 2.66477 7.2 3.12261 7.2 3.6ZM5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6H17.1C17.3387 3.6 17.5676 3.69482 17.7364 3.8636C17.9052 4.03239 18 4.2613 18 4.5C18 4.73869 17.9052 4.96761 17.7364 5.1364C17.5676 5.30518 17.3387 5.4 17.1 5.4H16.3062L15.5088 14.706C15.4321 15.6046 15.021 16.4417 14.3567 17.0517C13.6924 17.6617 12.8233 18.0001 11.9214 18H6.0786C5.17672 18.0001 4.30765 17.6617 3.64333 17.0517C2.97902 16.4417 2.56786 15.6046 2.4912 14.706L1.6938 5.4H0.9C0.661305 5.4 0.432387 5.30518 0.263604 5.1364C0.0948211 4.96761 0 4.73869 0 4.5C0 4.2613 0.0948211 4.03239 0.263604 3.8636C0.432387 3.69482 0.661305 3.6 0.9 3.6H5.4ZM11.7 9C11.7 8.7613 11.6052 8.53239 11.4364 8.3636C11.2676 8.19482 11.0387 8.1 10.8 8.1C10.5613 8.1 10.3324 8.19482 10.1636 8.3636C9.99482 8.53239 9.9 8.7613 9.9 9V12.6C9.9 12.8387 9.99482 13.0676 10.1636 13.2364C10.3324 13.4052 10.5613 13.5 10.8 13.5C11.0387 13.5 11.2676 13.4052 11.4364 13.2364C11.6052 13.0676 11.7 12.8387 11.7 12.6V9ZM7.2 8.1C6.96131 8.1 6.73239 8.19482 6.5636 8.3636C6.39482 8.53239 6.3 8.7613 6.3 9V12.6C6.3 12.8387 6.39482 13.0676 6.5636 13.2364C6.73239 13.4052 6.96131 13.5 7.2 13.5C7.43869 13.5 7.66761 13.4052 7.8364 13.2364C8.00518 13.0676 8.1 12.8387 8.1 12.6V9C8.1 8.7613 8.00518 8.53239 7.8364 8.3636C7.66761 8.19482 7.43869 8.1 7.2 8.1Z" fill="white"/>
            </svg>
          </button>
        </div>
        
        <span style="font-size: 12px; color: red;" *ngIf="form.get('kmlPath')?.errors && (form.get('kmlPath')?.touched || form.get('kmlPath')?.dirty)">Campo obrigatório</span>
      </div>
    </div>
    <div class="buttons">
      <button type="button" class="button" (click)="back()"> {{ view ? 'Voltar' : 'Cancelar'}} </button>
      <button class="button_save" type="submit" (click)="sendRequest()" *ngIf="!view">Salvar</button>
      <button class="button_save" type="submit" (click)="validateRequest()" *ngIf="view && isAdmin && validating">Validar</button>
    </div>
  </form>
</div>

<app-spinner *ngIf="loading"></app-spinner>
