import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewAnalysesComponent } from './new-analyses.component';
import { NewAnalysesRoutingModule } from './new-analyses-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    NewAnalysesComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NewAnalysesRoutingModule,
    ComponentsModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [
    NewAnalysesComponent
  ]
})

export class NewAnalysesModule { }
