import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { CompanyService } from 'src/app/services/company.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';

@Component({
  selector: 'app-store-cd-select-filter',
  templateUrl: './store-cd-select-filter.component.html',
  styleUrls: ['./store-cd-select-filter.component.scss']
})
export class StoreCDSelectFilterComponent {
  @Input() isUserProvider: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() operationalType: string = '';
  @Input() isCarrefour: boolean = false;
  @Input() economicGroup: string = '';
  @Input() carrefourLocal: string = '';
  @ViewChild('inputStoreCD') inputStoreCD!: SelectInputComponent;
  @Output() handleChangeStoreCDValue  = new EventEmitter();
  storeCDOptionsList: Array<{ name: string, value: string }> = [];
  loading: boolean = false;
  storeCDsList: any[] = [];
  totalItems: number = 0;

  constructor(
    private companyService : CompanyService,
  ) { }

  changeStoreCDValue(event:any ){
    this.handleChangeStoreCDValue.emit(event);
  }

  loadMoreOptionsList(event: any) {
    const options = {
      page: this.inputStoreCD.page,
      size: 10,
      corporateName: this.inputStoreCD.searchWord,
      operationalType: this.operationalType as string,
      economicGroup: this.economicGroup,
    };
    this.loading = true;
    this.inputStoreCD.loadMoreButton = true;
    this.companyService.findAllByCorporateNameAndOperationalType(options).subscribe((res: any) => {
      this.totalItems = res.totalItems;
      if (this.inputStoreCD.page === 0) {
        this.storeCDsList = res.content;
      } else {
        res.content.map((item: any) => {
          if (!this.storeCDsList.some((element: any) => element === item)) {
            this.storeCDsList.push(item);
          }
        });
      }
      if (res.totalItems === this.storeCDsList.length) {
        this.inputStoreCD.loadMoreButton = false;
      }
      this.inputStoreCD.page++;
      this.getStoreCDList();
      this.inputStoreCD.changeOptionsFilter(this.storeCDOptionsList);
      this.inputStoreCD.optionsShow = true;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  setValue(value: any) {
    this.inputStoreCD.value = value;
    this.inputStoreCD.change = false;
  }

  public getStoreCDList():void {
    this.loading = true;
    this.storeCDOptionsList = this.storeCDsList.filter((item: any) => item.corporateName).map((item: any) => {
      let option = {
        name: `${item.enterpriseCode} - ${item.corporateName} - ${formatCpfCnpj(item.document)}`,
        value: item.document
      }
      return option;
    });
    this.inputStoreCD.changeOptionsFilter(this.storeCDOptionsList);
    this.loading = false;
  }

  getStoreCDSearchOptions(event: any) {
    if (!this.isCarrefour) {
      if (event.length > 0) {
        this.inputStoreCD.page = 0;
        this.loadMoreOptionsList(null);
      } else {
        this.loadMoreOptionsList(null);
      }
    } else {
      this.loading = true;
      // const economicGroup = this.formFilter.get('carrefourLocal')?.value;
      this.companyService.getEnterpriseByOperationalTypeEconomicGroup('', '', this.carrefourLocal).subscribe((res: any) => {
        this.storeCDsList = res.content;
        this.totalItems = res.totalItems;
        this.getStoreCDList();
        this.inputStoreCD.changeOptionsFilter(this.storeCDOptionsList);
        this.inputStoreCD.optionsShow = true;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    }
  }

  handleChanged() {
    this.inputStoreCD.change = true;
  }

  cleanFilter() {
    this.inputStoreCD.value = '';
  }
}
