import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from './company.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  economicGroup: string | undefined;
  user: any;
  enterprise: any;
  private documentSubject = new BehaviorSubject<any>(null);
  document$ = this.documentSubject.asObservable();

  setDocument(data: any) {
    this.documentSubject.next(data);
  }

  getDocument() {
    return this.documentSubject.getValue();
  }

  constructor(
    private companyService: CompanyService,
  ) {
    this.updateData();
  }


  updateData() {
    const item = sessionStorage.getItem('user');
    const user = item ? JSON.parse(item) : {};
    this.user = user;

    const economicGroup = sessionStorage.getItem('economicGroup');
    const documentProvider = sessionStorage.getItem('document');
    if (documentProvider) {
      this.setDocument(documentProvider);
    }
    if (economicGroup) {
      this.economicGroup = economicGroup;
    } else {
      this.companyService.getEnterpriseProjection().subscribe((res: any) => {
        this.economicGroup = res.economicGroup;
        this.setDocument(res.document);
        sessionStorage.setItem('document', res.document);
        sessionStorage.setItem('economicGroup', this.economicGroup as string);
      });
    }
  }


  getEconomicGroup(): string {
    return this.economicGroup as string;
  }

  getUser() {
    return this.user;
  }


  getEnterprise() {
    return this.enterprise;
  }
}
