import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { CompanyService } from 'src/app/services/company.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-modal-supplier',
  templateUrl: './modal-supplier.component.html',
  styleUrls: ['./modal-supplier.component.scss']
})
export class ModalSupplierComponent implements OnInit {

  companyList: Array<any> = [];
  protected openOptions: boolean = false;
  loading: boolean = false;

  protected form: FormGroup = new FormGroup({
    provider: new FormControl('', Validators.required),
    documentProvider: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
  });

  constructor(
    private companyService: CompanyService,
    private dialogRef: MatDialogRef<ModalSupplierComponent>,
    private fornecedoresService: FornecedoresService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataModal: any
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

  public confirmModal(): void {

    if (!this.form.valid) {
      return
    }

    const data = this.form.get("company")?.value;
    this.dialogRef.close(data);
  }

  public onClickOpenOptions(): void {
    this.openOptions = true;
  }

  getProviders() {
    this.loading = true;
    this.fornecedoresService.getProviderOutsourcedByName(this.form.get('provider')?.value).subscribe((res: any) => {
      this.loading = false;
      if (res.length > 0) {
        this.companyList = res.content;
        this.openOptions = true;
      }
      else if (this.dataModal.auditing) {
        this.dialogRef.close(false);
      }
    }, error => {
      this.loading = false;
    });
  }

  openModalSuccessOrError(error = false, msg?: string) {
    this.loading = false;
    if (error) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: msg ? msg : '',
        },
      });
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          success: true,
        },
      }).afterClosed().subscribe(result => {
        this.closeModal();
      });
    }

    this.loading = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {

    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('#input-provider') && this.openOptions) {
      this.openOptions = false;
    }

  }

  onChangeInput() {
    if (this.dataModal.enterpriseDocument === this.form.get('documentProvider')?.value) {
      this.openModalSuccessOrError(true, 'O Documento cadastrado não pode ser o mesmo do fornecedor selecionado');
      this.dialogRef.close();
    } else {
      const document: string = this.form.get('documentProvider')?.value;
      const documentNumber: string = document.replace(/\D/g, '');
      this.form.get('documentProvider')?.setValue(formatCpfCnpj(document));
      this.loading = true;

      this.companyService.getEnterpriseByDocument(documentNumber).subscribe({
        next: (res: any) => {
          if (res.length > 0) {
            this.form.get('provider')?.setValue(res[0].corporateName);
            this.form.get('company')?.setValue(res[0]);
          }
          else if (this.dataModal?.auditing) {
            this.dialogRef.close({ find: false, document: documentNumber});
          }

          this.loading = false;

        },
        error: (err) => {
          this.loading = false;
        },
      });
    }
  }

  getValue(provider: any) {
    this.form.get('provider')?.setValue(provider.corporateName);
    this.form.get('documentProvider')?.setValue(provider.document);
    this.form.get('company')?.setValue(provider);
    this.openOptions = false;
  }

}
