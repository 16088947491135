<div class="flex-area">
  <input type="text" placeholder="Texto da pergunta" [(ngModel)]="question.questionText" class="question-text">
  <select [(ngModel)]="question.type" (change)="onTypeChange($event)">
    <option value="TEXT">Texto</option>
    <option value="CHECKBOX">Checkbox</option>
    <option value="MULTIPLE_CHOICE">Múltipla Escolha</option>
    <option value="LIST_OF_VALUES">Lista de Valores</option>
  </select>
  <button type="button" class="button" (click)="remove.emit()">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 3.5H9.5C9.5 3.10218 9.34196 2.72064 9.06066 2.43934C8.77936 2.15804 8.39782 2 8 2C7.60218 2 7.22064 2.15804 6.93934 2.43934C6.65804 2.72064 6.5 3.10218 6.5 3.5ZM5 3.5C5 2.70435 5.31607 1.94129 5.87868 1.37868C6.44129 0.816071 7.20435 0.5 8 0.5C8.79565 0.5 9.55871 0.816071 10.1213 1.37868C10.6839 1.94129 11 2.70435 11 3.5H14.75C14.9489 3.5 15.1397 3.57902 15.2803 3.71967C15.421 3.86032 15.5 4.05109 15.5 4.25C15.5 4.44891 15.421 4.63968 15.2803 4.78033C15.1397 4.92098 14.9489 5 14.75 5H14.0885L13.424 12.755C13.3601 13.5038 13.0175 14.2014 12.4639 14.7098C11.9103 15.2181 11.1861 15.5001 10.4345 15.5H5.5655C4.81393 15.5001 4.08971 15.2181 3.53611 14.7098C2.98252 14.2014 2.63988 13.5038 2.576 12.755L1.9115 5H1.25C1.05109 5 0.860322 4.92098 0.71967 4.78033C0.579018 4.63968 0.5 4.44891 0.5 4.25C0.5 4.05109 0.579018 3.86032 0.71967 3.71967C0.860322 3.57902 1.05109 3.5 1.25 3.5H5ZM10.25 8C10.25 7.80109 10.171 7.61032 10.0303 7.46967C9.88968 7.32902 9.69891 7.25 9.5 7.25C9.30109 7.25 9.11032 7.32902 8.96967 7.46967C8.82902 7.61032 8.75 7.80109 8.75 8V11C8.75 11.1989 8.82902 11.3897 8.96967 11.5303C9.11032 11.671 9.30109 11.75 9.5 11.75C9.69891 11.75 9.88968 11.671 10.0303 11.5303C10.171 11.3897 10.25 11.1989 10.25 11V8ZM6.5 7.25C6.30109 7.25 6.11032 7.32902 5.96967 7.46967C5.82902 7.61032 5.75 7.80109 5.75 8V11C5.75 11.1989 5.82902 11.3897 5.96967 11.5303C6.11032 11.671 6.30109 11.75 6.5 11.75C6.69891 11.75 6.88968 11.671 7.03033 11.5303C7.17098 11.3897 7.25 11.1989 7.25 11V8C7.25 7.80109 7.17098 7.61032 7.03033 7.46967C6.88968 7.32902 6.69891 7.25 6.5 7.25Z" fill="white"/>
    </svg>
  </button>
</div>
<div class="options-container" *ngIf="showOptions">
  <app-option
    *ngFor="let option of question.options; let i = index"
    [option]="option"
    (remove)="removeOption(i)"
  ></app-option>
  <button type="button" class="button" (click)="addOption()">Adicionar Opção</button>
</div>
